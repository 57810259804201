import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import LocalSEOServices from "./../Elements/LocalSEOServices";
import { Helmet } from "react-helmet";

var bnrimg = require("../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Local SEO Services Provider | Shadow infosystem</title>
          <meta name="description" content="Shadow infosystem, the best local SEO services provider, offering expert strategies to boost your local search rankings &amp; drive targeted traffic to your business" />
          <meta name="keywords" content="Local SEO Services" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/seo-services-company-noida" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <LocalSEOServices />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
