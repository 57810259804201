import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1>Vital UK</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo">
                  <img
                    src={require("./../../../images/logo/logo_1e3aa21cb8559feac88a05f0e4741106_2x.png")}
                    className=""
                    alt="Shadow"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543"> Vital Radiology Services</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> Kylna Business Centre, Wood Lane End,
                      Hemel Hempstead, Hertfordshire, HP2 4BF, UK
                    </li>
                    <li>
                      <b>Services:</b> Radiology{" "}
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://www.vitalrad.co.uk/"
                        class="text-white"
                        target="_blank"
                      >
                        www.vitalrad.co.uk
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      {/*<li>
                            <a href="#" className="facebook" target="_blank">
                                <i className="fa fa-facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="#" className="instagram" target="_blank">
                                <i className="fa fa-instagram" />
                            </a>
                        </li>*/}
                      <li>
                        <a href="#" className="tumblr" target="_blank">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      {/*<li>
                            <a href="#" className="twitter" target="_blank"><img src={require('./../../../images/twitter (1).png')} className="county-logo1" alt="Shadow infosystem" /></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" className="youtube">
                                <i className="fa fa-youtube" />
                            </a>
                        </li>*/}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/products/WhatsApp Image 2024-02-04 at 5.27.07 PM.jpeg")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/new/vital_desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="m-b30 bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p>
                        Vital Radiology is a global firm endowed and empowered
                        with eminent medical professionals to offer general and
                        sub-specialty reporting services, end-to-end diagnostic
                        services, and expert super-specialist reporting services
                        by UK-based consultants. Our team holds vast knowledge
                        in the healthcare domain and is proficient in delivering
                        quality reporting services to support clinical radiology
                        in India, Africa, UK, UAE, and other countries. We are a
                        team of qualified and experienced veteran medical
                        professionals with people coming from varied domains of
                        medicine like health IT experts, consultants, marketing
                        researchers, operation managers, and outstanding
                        entrepreneurs, who all work in tandem to provide
                        distinctive services at Vital Radiology. Our squad
                        comprises professionals who are young, energetic, and
                        dynamic with decades of experience in providing
                        technology-driven services in the healthcare sector
                        across organizations, public as well as private.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
