import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

function PartnerWhyus() {
  return (
    <>
      {" "}
      <div
        className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer text-center">
              <div className="color-change bg-moving bg-repeat-x  white-text">
                <h2 className="wfgrbwe ert678 heading mb-3">
                  Why Partner With Us?
                </h2>
                <p>
                  Partnering with us for our Digital Marketing Partnership
                  Program offers several compelling reasons:
                </p>
              </div>
            </div>
          </div>
          {/* TITLE END */} {/* IMAGE CAROUSEL START */}
          <div className="section-content">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                  <img
                    className="technology-img"
                    src={require("./../../../images/partner/DV10.webp")}
                    alt="Shadow "
                  />
                  <div className="sx-post-info  bg-white sdfgj56564 servdert-one1 seo-servie">
                    <div className="WhyChooseShadow">
                      <img
                        className=""
                        src={require("./../../../images/partner/DV10.webp")}
                        alt="Shadow "
                      />
                      <h4 className="post-title">Transparency</h4>
                    </div>

                    <div className="">
                      <p>
                        We believe in clear communication and openness. You'll
                        always know what strategies we're employing and how
                        they're performing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                  <img
                    className="technology-img"
                    src={require("./../../../images/partner/DV9.webp")}
                    alt="Shadow "
                  />
                  <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                    <div className="WhyChooseShadow">
                      <img
                        className=""
                        src={require("./../../../images/partner/DV9.webp")}
                        alt="Shadow "
                      />
                      <h4 className="post-title">Increased Revenue</h4>
                    </div>

                    <div className="">
                      <p>
                        By collaborating with us, you can expect to boost your
                        earnings. Our effective digital marketing strategies are
                        designed to drive results and increase your bottom line.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                  <img
                    className="technology-img"
                    src={require("./../../../images/partner/DV8.webp")}
                    alt="Shadow "
                  />
                  <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                    <div className="WhyChooseShadow">
                      <img
                        className=""
                        src={require("./../../../images/partner/DV8.webp")}
                        alt="Shadow "
                      />
                      <h4 className="post-title">
                        Time and Resource Efficiency
                      </h4>
                    </div>

                    <div className="">
                      <p>
                        Instead of investing valuable time and resources into
                        building an in-house digital marketing team, partner
                        with us to leverage our expertise and save on overhead
                        costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                  <img
                    className="technology-img"
                    src={require("./../../../images/partner/DV7.webp")}
                    alt="Shadow "
                  />
                  <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                    <div className="WhyChooseShadow">
                      <img
                        className=""
                        src={require("./../../../images/partner/DV7.webp")}
                        alt="Shadow "
                      />
                      <h4 className="post-title">Reliable Expertise</h4>
                    </div>

                    <div className="">
                      <p>
                        As a seasoned digital marketing agency, we bring years
                        of experience to the table. You can rely on us to
                        navigate the complexities of the digital landscape and
                        deliver consistent, high-quality results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12"></div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <TypeDesign className="vndjn-popup" />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12"></div>
            </div>
          </div>
        </div>
        <div className="hilite-title text-left p-l50 text-uppercase">
          <strong>Partner</strong>
        </div>
      </div>
    </>
  );
}

export default PartnerWhyus;
