import React from 'react';
import { NavLink } from 'react-router-dom';

const achievements1 = [
    {
        title: 'Core PHP Development',
        image: require('./../../../images/icon/php.png'),

    },
    {
        title: 'laravel Development',
        image: require('./../../../images/icon/laravel-icon-497x512-uwybstke.png'),
    },
    {
        title: 'Codeigniter Development',
        image: require('./../../../images/icon/free-codeigniter-4-1175201.png'),
    }
]



var bgimg1 = require('./../../../images/background/bg-map.png');
var bgimg2 = require('./../../../images/background/cross-line2.png');

class Achievements1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-no-repeat bg-bottom-center mobile-page-padding" >

                    <div className="container">
                        
                        <div className="section-content ">
                            <div className="row">
                            <div className="col-xl-7 col-lg-6 col-md-12">

                                {/* TITLE START */}
                        <div className="">
                            <div className="sx-separator-outer separator-left">
                                <div className="bg-moving bg-repeat-x">
                                    <h3 className="">We Website Develop</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        
                                <ul className="alal tyu">
                                        <li><img src={require('./../../../images/icon/right-arrow.png')} alt="" /> Get Customer-Centric Websites</li>
                                        <li><img src={require('./../../../images/icon/right-arrow.png')} alt="" /> Get Scalable Solutions for Revenue Growth</li>
                                        <li><img src={require('./../../../images/icon/right-arrow.png')} alt="" /> Get SEO-Friendly Websites from the Word Go</li>
                                        <li><img src={require('./../../../images/icon/right-arrow.png')} alt="" /> Get High-End Designs Incorporated with the Best Functionalities</li>
                                        <li><img src={require('./../../../images/icon/right-arrow.png')} alt="" /> Get Interactive Web Design Services for Customer Retention</li>

                                    </ul>
                                    <div className="ertyui098">
                                                    <a href="javascript:;" className="ecfrto-kjg">Request Plan</a>
                                                    <a href="javascript:;" className="ecfrto-kjg">Call Us +91-88003 84880</a>
                                                </div>
                                </div>
                           
                                <div className="col-xl-5 col-lg-6 col-md-12 m-b30">
                                <NavLink to="#" className="">
                                    {achievements1.map((item, index) => (
                                        <div className="sx-box our-story cvcvcv" key={index}>
                                            
                                            <div className="tret">
                                            
                                             <img src={item.image} alt="" />
                                                <h4>{item.title}</h4>
                                                
                                            </div>
                                        </div>
                                    ))}
                                    </NavLink>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Achievements1;