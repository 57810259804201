import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Limited Visibility",
    description:
      "Radiology global services may struggle to achieve visibility in search results due to the high competition in the healthcare industry. One solution is to optimize the website's content, including keywords and meta descriptions, to rank higher in search engine results.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Complex Terminology",
    description:
      "Radiology terminology can be complex and technical, making it difficult for patients to understand. A solution is to create user-friendly content, including infographics and videos, that explains medical terms in simple language.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Reputation Management",
    description:
      "Radiology global services need to maintain a positive reputation to attract patients. A solution is to monitor online reviews and address negative feedback promptly and professionally.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Compliance ",
    description:
      "Radiology global services need to adhere to healthcare regulations, which can restrict the use of certain marketing tactics. A solution is to work with a digital marketing agency that specializes in healthcare marketing and understands compliance requirements.",
  },
  {
    number: <div class="number fontColor5">5</div>,
    heading: "Patient Privacy",
    description:
      "Radiology global services need to protect patient privacy when using digital marketing and SEO. A solution is to ensure that the website is secure and that patient information is encrypted.",
  },
  {
    number: <div class="number fontColor6">6</div>,
    heading: "Mobile Optimization",
    description:
      "Radiology global services need to optimize their website for mobile devices to reach patients who use smartphones and tablets. A solution is to use a responsive design that adjusts the website layout based on the user's device.",
  },
];

class Challenges extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      margin: 30,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 3,
        },
      },
    };
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h2 class="ert678">The Challenges & Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
