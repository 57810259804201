import React from "react";
import TypeDesign from "./../TypeDesign";
var bgimg1 = require("../../../images/flaticon/img-bg.jpg");
function PPCCall() {
  return (
    <>
      <section
        class="img-bg mb-5 "
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div class="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div className="caption asdfghj-jhgfds">
                <div class="caption">
                  <div class="digihead">
                    <div className="heading text-white">
                      Check Out Our Maintenance Service Plans
                    </div>
                  </div>
                  <p className="text-white">
                    Falling short on leads? Let us drive your success with
                    strategic PPC campaigns. Boost profitability now!
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <TypeDesign className="vndjn-popup" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PPCCall;
