import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Regulatory compliance",
    description:
      "IVF centers need to comply with several regulatory guidelines, such as maintaining patient records, ensuring patient confidentiality, and adhering to ethical and legal practices.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Patient acquisition",
    description:
      "IVF centers need to attract patients to their center, which can be challenging in a highly competitive market.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Staff recruitment and retention",
    description:
      "IVF centers require specialized medical professionals, such as embryologists and fertility specialists. Recruiting and retaining such skilled professionals can be a challenge.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Technology and equipment",
    description:
      "IVF centers need to stay up-to-date with the latest technologies and equipment to provide the best possible care to their patients. However, this can be expensive, and maintaining these technologies can be a challenge.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
