import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Data analysis and insights",
    description:
      "An IT company can help a school collect and analyze data on student performance, attendance, and behavior, which can provide valuable insights to teachers and administrators for improving student outcomes",
  },
  {
    icon: <i class="fa fa-shield"></i>,
    heading: "Improved cybersecurity",
    description:
      "With the increasing reliance on technology in education, cybersecurity threats are also on the rise. Partnering with an IT company can ensure that a school's data and systems are secure from potential cyber attacks.",
  },
  {
    icon: <i class="fa fa-laptop"></i>,
    heading: "Improved educational technology",
    description:
      "An IT company can help a school implement and maintain the latest educational technology, such as smartboards, virtual learning, and online collaboration tools, which can enhance the learning experience for students",
  },
  {
    icon: <i class="fa fa-pencil"></i>,
    heading: "Streamlined administrative processes",
    description:
      "IT solutions can automate and streamline administrative tasks, such as attendance tracking, grade management, and scheduling, which can save time and reduce errors.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p>
                    Partnering with an IT company can provide several advantages
                    for a school, including :
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div class="card-wrap">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h1 class="card-title">{item.heading}</h1>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
