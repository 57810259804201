import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 hg-123">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h2 className="wfgrbwe ert678 heading">
                    Why Choose Shadow infosystem?
                  </h2>
                  <p>
                    At Shadow infosystem, we are dedicated to delivering
                    exceptional web application solutions that drive success and
                    innovation for our clients. Here’s why you should choose us
                    as your trusted partner:
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/computer_data_analysis_company_business.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/computer_data_analysis_company_business.png")}
                          alt=""
                        />
                        <h4 className="post-title">Expertise</h4>
                      </div>

                      <div className="">
                        <p>
                          Our team consists of seasoned professionals with
                          extensive experience in various web technologies. We
                          stay abreast of the latest industry trends to deliver
                          cutting-edge solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                          alt=""
                        />
                        <h4 className="post-title">Customization</h4>
                      </div>

                      <div className="">
                        <p>
                          We understand that every business is unique. Our
                          custom solutions are designed to meet your specific
                          needs, ensuring that you get the most out of your web
                          application.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/wa1.webp")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/wa1.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Cost-Effective Solutions</h4>
                      </div>

                      <div className="">
                        <p>
                          We offer high-quality web development services at
                          competitive prices. Our goal is to provide you with
                          the best value for your investment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/wa2.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/wa2.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Quality Assurance</h4>
                      </div>

                      <div className="">
                        <p>
                          We adhere to strict quality assurance practices to
                          ensure that your web application is bug-free and
                          performs optimally. Our QA team conducts thorough
                          testing to guarantee a seamless user experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/wa3.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/wa3.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Innovation</h4>
                      </div>

                      <div className="">
                        <p>
                          We are committed to fostering innovation in every
                          project we undertake. Whether it's implementing
                          advanced features or finding creative solutions, we
                          strive to keep your project at the forefront of
                          technological advancement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/wa4.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/wa4.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Client-Centric Approach</h4>
                      </div>

                      <div className="">
                        <p>
                          At Shadow infosystem, we place our clients at the
                          heart of everything we do. We work closely with you
                          throughout the development process to ensure that the
                          final product exceeds your expectations.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 text-center">
                  <p>
                    <strong>
                      Let Shadow infosystem be your partner in building powerful
                      and innovative web applications. Contact us today to
                      discuss your project and discover how we can help you
                      achieve your business objectives.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Why Us</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
