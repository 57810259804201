import React from "react";
import { NavLink } from "react-router-dom";
import IServiceSection2Icon1 from "./../../../images/head-icon/info-1.webp";
import IServiceSection2Icon2 from "./../../../images/head-icon/info-2.webp";
import IServiceSection2Icon3 from "./../../../images/head-icon/info-3.webp";
import IServiceSection2Icon4 from "./../../../images/head-icon/info-4.webp";
import IServiceSection2Icon5 from "./../../../images/head-icon/info-5.webp";

const informaticaService = [
  {
    title: "Intelligent Data Management Cloud (IDMC)",
    desc: "Intelligent Data Management Cloud optimizes data storage, access, and analysis through advanced AI algorithms. It efficiently organizes and secures vast amounts of data while providing actionable insights for businesses. Leveraging machine learning and automation, it streamlines data workflows, enhances decision-making, and ensures regulatory compliance.",
    img: IServiceSection2Icon1,
  },
  {
    title: "Master Data Management (MDM)",
    desc: "It entails consolidating all pertinent information about individuals, locations, or entities within an organization into a unified master record. This comprehensive record is sourced from diverse internal and external data outlets and applications. MDM ensures data integrity and consistency across the enterprise, facilitating informed decision-making and enhancing operational efficiency.",
    img: IServiceSection2Icon2,
  },
  {
    title: "Informatica SAAS",
    desc: `Informatica Saas is Informatica's cloud-based offering, providing access to data integration, quality, and governance tools via subscription. It eliminates the need for on-premises infrastructure, offering scalability and flexibility. Users can manage and analyze data efficiently, leveraging Informatica's solutions hosted on the cloud for improved accessibility and ease of deployment.`,
    img: IServiceSection2Icon3,
  },
  {
    title: "Data Quality",
    desc: `Data quality denotes the suitability of a dataset for processing and analysis within the database, data warehouse, or analytics system. It assesses the dataset's capacity to be effectively utilized for various purposes beyond its original context. High-quality data ensures ease of processing and analysis, enabling organizations to derive valuable insights and make informed decisions.`,
    img: IServiceSection2Icon4,
  },
  {
    title: "Data Governance",
    desc: `Data governance encompasses principles, guidelines, and methodologies aimed at maintaining the reliability and consistency of organizational data. Effective data governance establishes repeatable processes for achieving these goals, fostering trust in data quality and integrity throughout the organization's operations and strategic initiatives.`,
    img: IServiceSection2Icon5,
  },
];

function Iservices() {
  return (
    <>
      <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45 informatica-service-section">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer text-center">
              <div className="color-change bg-moving bg-repeat-x">
                <h3 className="wfgrbwe ert678 mb-3">
                  Types of Informatica Services
                </h3>
              </div>
            </div>
          </div>
          <div className="section-content ">
            <div className="row">
              {informaticaService.map((item, index) => (
                <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={item.img}
                      alt={item.title}
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img className="" src={item.img} alt={item.title} />
                        <h4 className="post-title">{item.title}</h4>
                      </div>

                      <div className="">
                        <p>{item.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Iservices;
