import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import Iservices from "./../Elements/Informatica/Iservices";
import Ibanner from "./../Elements/Informatica/Ibanner";
import Iabout from "./../Elements/Informatica/Iabout";
import Icontect from "./../Elements/Informatica/Icontect";
import Ibenefits from "./../Elements/Informatica/Ibenefits";
import EnquireSection from "./../Elements/Informatica/EnquireSection";
import Testimonials from "./../Elements/Testimonials";
import { Helmet } from "react-helmet";

// var bnrimg = require('./../../images/banner/development-banner.jpg');

class InformaticaProducts extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Best Website Maintenance Services for Seamless Performance
          </title>
          <meta
            name="description"
            content="Get top-notch website maintenance services at Shadow infosystem. Enhance performance, security, and functionality. Trust us for seamless management."
          />
          <meta name="keywords" content="Top IT Company in Noida" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/informatica"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <Ibanner />
          <Iabout />
          <Iservices />
          <Icontect />
          <Ibenefits />
          <EnquireSection />
          <Testimonials />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default InformaticaProducts;
