import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Our partnership with Shadow has been instrumental in
                        transforming our business. As per our products, we
                        operate in a highly specialized and technical market.
                        Shadow not only understood the intricacies of our
                        industry but also helped us significantly enhance our
                        digital presence and drive business growth.”
                      </p>
                      <h6>Ashish Tatiyal</h6>
                      <p className="mb__0">Owner, Advanced Amorphous</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
