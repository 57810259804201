import React from 'react';
import Header2 from './../Common/Header3';
import Footer2 from './../Common/Footer2';
import Banner from './../Elements/Logo/Banner';
import TypeDesign from './../Elements/Logo/TypeDesign';
import About from './../Elements/Logo/About';
import WhyChooseUs from './../Elements/Logo/WhyChooseUs';
import client from './../Elements/Logo/client';
import FAQ from './../Elements/Logo/FAQ';
import CommenEnquiry from './../Elements/CommenEnquiry';
import Testimonials from './../Elements/Testimonials';

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner/>
                    <About/>
                    <TypeDesign />
                    <WhyChooseUs />
                     <CommenEnquiry />
                    <Testimonials/>
                    <FAQ/>
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;