import React from "react";
import TypeDesign from "./../TypeDesign";
var bgimg1 = require("../../../images/flaticon/img-bg.jpg");
function PPCCall() {
  return (
    <>
      <section
        class="img-bg mb-5 "
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div class="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div class="caption asdfghj-jhgfds">
                <div class="digihead">
                  <div className="alcnsja text-white">
                    Boost Productivity and Efficiency with Us
                  </div>
                  <p className="text-white">
                    Elevate productivity with our online note solution: Simple,
                    intuitive, and accessible from any device.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <TypeDesign className="vndjn-popup" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PPCCall;
