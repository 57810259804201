import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="ertyui">
                                        <h3 className="ert678">We offer custom graphic design services at a competitive price!</h3>
                                        <h6>Let the SEO Experts rank your website on top of search engine sites like Google, Yahoo, and Bing!</h6>
                                        <p>SEO is the one-stop solution that helps you to get your brand on top of the search engines. The SEO experts at SEO Experts can help you with your website or blog ranking. SEO Experts can easily provide SEO for your business and help you to take advantage of the latest in online marketing that is available today.</p>
                                    </div>
                                </div>
                            </div>
                        
                        
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;