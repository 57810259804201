import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b__30 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 sfdd">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h3 className="ert678">Fixed Price</h3>
                          </div>
                          <ul className="alal">
                            <li>
                              Under fixed cost fixed time model, Shadow works
                              with clients to define expected deliverable and
                              timelines to determine a mutually agreed fixed
                              price. The option is best for small or medium
                              sized projects that have fixed scope,
                              requirements, specifications and schedules.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-6">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/projects/fixed-price-1.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-6">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/projects/time-and-material-1.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="offset-xl-1 col-xl-6 col-lg-6 col-md-6 sfdd">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h3 className="ert678">Time & Material</h3>
                          </div>
                          <ul className="alal">
                            <li>
                              The flexible and dynamic time & material model
                              best suits complex projects that are inclined to
                              specification and design changes. Through this
                              option, SHADOW lets its customers to modify
                              project requirements as per upcoming market
                              trends.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 sfdd">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h3 className="ert678">Dedicated Team</h3>
                          </div>
                          <ul className="alal">
                            <li>
                              Through dedicated resource engagement model, we
                              implement customer specific quality/process
                              frameworks at client’s end with the help of a
                              dedicated team, which includes team members,
                              project managers, equipment and infrastructure.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-6">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/projects/dedicated-team-1.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
