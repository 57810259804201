import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/1311.jpg');

class Error extends React.Component {
    render() {
        return (
            <>
               {/*<Header2/>*/}
                <div className="page-content">
                    
                    {/* SECTION CONTENTG START */}
                    <div className="section-full mobile-page-padding p-tb150 bg-bottom-left bg-no-repeat asfwqd1312313" style={{ backgroundImage: 'url(' + bnrimg + ')' }}>
                        <div className="container">
                            <div className="section-content">
                                <div className="page-notfound row">
                                    
                                    <div className="col-md-5">
                                        <div className="error-404 white-text sdwwwddw">
                                            <strong>I think you are in the wrong page</strong>
                                        <h1 className="ert678">404 Page</h1>
                                        <p></p>
                                        <div className="buttonfgerb">
                                                        <div className="header-section-12">
                                                            <NavLink to={"/"} rel="bookmark" className="hs12-button">
                                                                Go Back To Home
                                                            </NavLink>
                                                        </div>

                                                       
                                                    </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        {/*<img src={require('./../../images/error-404.png')} alt="" />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

               
            </>
        );
    };
};

export default Error;