import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h3 className="wfgrbwe ert678">
                    Comprehensive Solutions for Your Business Needs
                  </h3>
                  <p>
                    At Shadow Infosystem, we specialize in creating powerful,
                    scalable, and user-friendly web applications tailored to
                    meet your specific business requirements. Leveraging the
                    latest technologies and industry best practices, we deliver
                    solutions that drive growth and efficiency. Explore our wide
                    range of web application services
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content sfsfcsc13232">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 servdert-one2">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Enterprise Web App Development
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We build robust and scalable enterprise web
                          applications designed to streamline your business
                          processes and enhance productivity. Our solutions
                          integrate seamlessly with your existing systems,
                          offering a unified platform to manage your operations
                          efficiently.
                        </p>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/determine_the_business_development_plan.png")}
                          alt=""
                        />
                        <h4 className="post-title">Web Portal Solutions</h4>
                      </div>

                      <div className="">
                        <p>
                          Our team develops feature-rich web portals for various
                          industries, including B2B, B2C, and community-based
                          portals. We focus on creating user-friendly, secure,
                          and engaging portals that cater to your audience's
                          specific needs and drive user interaction.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Web Application Consulting
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          Our expert consultants provide strategic guidance to
                          help you define, design, and implement successful web
                          applications. We assess your business objectives,
                          analyze your requirements, and recommend the best
                          technologies and practices to achieve your goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Custom Web App Development
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We deliver bespoke web applications tailored to your
                          unique business needs. Our custom development services
                          ensure that your application is precisely aligned with
                          your objectives, offering a personalized user
                          experience and enhanced functionality.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          API Development and Integration
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We specialize in developing and integrating APIs to
                          extend the capabilities of your web applications. Our
                          API solutions ensure seamless communication between
                          different software systems, enabling a more connected
                          and efficient digital ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Maintenance and Support</h4>
                      </div>

                      <div className="">
                        <p>
                          We provide comprehensive maintenance and support
                          services to keep your web applications running
                          smoothly and securely. Our team is dedicated to
                          offering timely updates, bug fixes, and technical
                          support to ensure optimal performance and user
                          satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Why Choose</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
