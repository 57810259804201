import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Enhancing Technical SEO",
    description:
      "Optimizing the technical aspects of the website basically involves improving the website’s structure to make it easily crawlable by search engines.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Creating High-Quality, SEO-Friendly Content",
    description:
      "Generating high-quality content that is both informative and SEO-friendly that engaged users, adhered to SEO best practices, and provided valuable information about the company and its products.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Local SEO and Google My Business Optimization",
    description:
      "Improving local search visibility by optimizing the company's local SEO efforts by incorporating location-based keywords and creating localized content. Managing and optimizing Google My Business listings, and ensuring accurate business information were crucial steps to enhance local search rankings and visibility.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Building a Strong Backlink Profile",
    description:
      "Acquiring high-quality backlinks was essential for boosting domain authority and search engine rankings. Our focus was on creating valuable content that naturally attracted backlinks and adhering to ethical SEO practices to avoid penalties. This approach required consistent effort and innovative strategies to secure good backlinks.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
