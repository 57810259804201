import React from 'react';
import { NavLink } from 'react-router-dom';
import TypeDesign from './TypeDesign';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const clientele = [
  {
    id: 1,
    image: require("./../../images/client/webdesign/vital.jpg"),
    items: ["UK", "SGP", "IND"],
  },

  {
    id: 2,
    image: require("./../../images/client/webdesign/sara.jpg"),
    items: ["Canada"],
  },

  {
    id: 3,
    image: require("./../../images/client/webdesign/ssr.jpg"),
    items: ["USA"],
  },

  {
    id: 4,
    image: require("./../../images/client/webdesign/yocarz.jpg"),
    items: ["IND"],
  },

  {
    id: 5,
    image: require("./../../images/client/webdesign/aparna.jpg"),
    items: ["IND", "UAE"],
  },

  {
    id: 6,
    image: require("./../../images/client/webdesign/santosh.jpg"),
    items: ["IND"],
  },

  {
    id: 7,
    image: require("./../../images/client/webdesign/dentalkraft.jpg"),
    items: ["IND"],
  },

  {
    id: 8,
    image: require("./../../images/client/webdesign/4qt.jpg"),
    items: ["IND"],
  },

  {
    id: 9,
    image: require("./../../images/client/webdesign/nail_rituals.jpg"),
    items: ["IND"],
  },

  {
    id: 10,
    image: require("./../../images/client/webdesign/vedam.jpg"),
    items: ["IND"],
  },

  {
    id: 11,
    image: require("./../../images/client/webdesign/engrever.jpg"),
    items: ["IND"],
  },

  {
    id: 12,
    image: require("./../../images/client/webdesign/av.jpg"),
    items: ["IND"],
  },

  {
    id: 13,
    image: require("./../../images/client/webdesign/roadload.jpg"),
    items: ["IND"],
  },

  {
    id: 14,
    image: require("./../../images/client/webdesign/david.jpg"),
    items: ["Canada"],
  },

  {
    id: 15,
    image: require("./../../images/client/webdesign/greenvent.jpg"),
    items: ["IND"],
  },

  {
    id: 16,
    image: require("./../../images/client/webdesign/shrimahalaxmi.jpg"),
    items: ["IND"],
  },

  {
    id: 17,
    image: require("./../../images/client/webdesign/tatva.jpg"),
    items: ["IND"],
  },

  {
    id: 18,
    image: require("./../../images/client/webdesign/trishul.jpg"),
    items: ["IND"],
  },
];

var bgimg1 = require('./../../images/banner/web-development-banner.jpg');
var bgimg2 = require('./../../images/background/cross-line2.png');
var bgimg3 = require('./../../images/background/home-banner.jpg');

class ClientsLogo1 extends React.Component {
  componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {

        return (
          <>
            <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                
                    <div className="container ">
                        {/* TITLE START */}
                        <div className="row">
                            <div className="col-md-7">
                                <div className="section-head mnbv123">
                            <div className="sx-separator-outer ">
                                <div className="bg-repeat-x color-change ewgr8900">
                                    <h3 className="ert678">Transform Your Business with Strategic SMM</h3>
                                    <p>We empower businesses through strategic social media marketing, helping them reach wider audiences and achieve growth objectives effectively</p>
                                </div>
                            </div>
                            <TypeDesign/>
                        </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mnbv1231">
                                    <img src={require('./../../images/smo-1 (1).gif')} alt="" />
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        
                        
                        
                    </div>
                    
                </div>
            <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
            <div className="container-fluid">
            <div className="section-content">
            <div className="container">
                <div className="section-head">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="m-b30 bg-white">
                                <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                    <img src={require("./../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="sx-separator-outer separator-left">
                                <div className="ertyui">
                                    <h1 className="ert678 heading">Best Digital Marketing Agency in Delhi</h1>

                                    <p>Shadow infosystem stands as the foremost digital marketing agency in Delhi, distinguished
for its pioneering solutions tailored to suit the diverse requirements of businesses spanning
various industries. Renowned for our track record of excellence, we excel in crafting innovative
strategies, employing data-driven methodologies, and ensuring client success.</p>
                                    <p>Our achievements lie in our team of seasoned experts boasting extensive experience in the
realm of digital marketing. From mastering search engine optimization (SEO) and pay-per-click
(PPC) advertising to dominating social media marketing and content creation, our repertoire of
services is comprehensive, aimed at increasing online visibility, boosting traffic, and optimizing
ROI.</p>
                                 </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            <p>What truly distinguishes us as the premier digital marketing agency in Delhi is our steadfast
commitment to comprehending each client&#39;s distinct objectives and hurdles. By embracing a
personalized approach, we tailor bespoke strategies that resonate deeply with target audiences
and yield tangible outcomes. Furthermore, our dedication to remaining at the forefront of
industry trends ensures that clients benefit from the latest advancements and technologies in
the dynamic digital arena.</p>
<p>With a core focus on transparency, integrity, and excellence, Shadow infosystem continues to
be the preferred partner for businesses endeavoring to elevate their online presence and secure
sustainable growth amidst the fiercely competitive digital landscape.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser"
          style={{ backgroundImage: "url(" + bgimg3 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x  white-text">
                  <h2 className="wfgrbwe ert678">Digital Marketing Services We Offer in Delhi</h2>
                  
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img className="technology-img dgtal-img" src={require('./../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png')} alt="" />
                      <div className="sx-post-info  bg-white sdfgj56564 servdert-one1 dgtal">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                            alt=""
                          />
                          <h4 className="post-title">
                            Search Engine Optimization (SEO)
                          </h4>
                        </div>

                        <div className="">
                          <p>Enhance online visibility by optimizing website content and structure, improving rankings on
search engine results pages.</p>
                        </div>
                        
                      </div>
                    </div>
                 
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img className="technology-img dgtal-img" src={require('./../../images/locationpagesimages/tick-with-pencil-illustration.png')} alt="" />
                      <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                            alt=""
                          />
                          <h4 className="post-title">Social Media Management</h4>
                        </div>

                        <div className="">
                          <p>Strategically engage and grow audience across various platforms, fostering brand awareness,
customer engagement, and community building.</p>
                        </div>
                        
                      </div>
                    </div>
                 
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  
                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img className="technology-img dgtal-img" src={require('./../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png')} alt="" />
                      <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                            alt=""
                          />
                          <h4 className="post-title">Pay-Per-Click (PPC) Advertising</h4>
                        </div>

                        <div className="">
                          <p>Craft targeted ad campaigns, driving immediate traffic to websites and achieving specific
marketing objectives efficiently.</p>
                        </div>
                        
                      </div>
                    </div>
                 
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  
                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img className="technology-img dgtal-img" src={require('./../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png')} alt="" />
                      <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png")}
                            alt=""
                          />
                          <h4 className="post-title">Content<br/> Marketing</h4>
                        </div>

                        <div className="">
                          <p>Develop compelling and relevant content to attract, engage, and retain the target audience,
establishing authority and driving conversions.</p>
                        </div>
                       
                      </div>
                    </div>
                 
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  
                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img className="technology-img dgtal-img" src={require('./../../images/locationpagesimages/smartphone_test_data_analysis.png')} alt="" />
                      <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                            alt=""
                          />
                          <h4 className="post-title">Social Media Optimization (SMO)</h4>
                        </div>

                        <div className="">
                          <p>Boost your brand&#39;s online presence organically with strategic Social Media Optimization (SMO).
Enhance visibility, engage your audience, and efficiently drive traffic to your Business.</p>
                        </div>
                        
                      </div>
                    </div>
                 
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  
                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img className="technology-img dgtal-img" src={require('./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png')} alt="" />
                      <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                            alt=""
                          />
                          <h4 className="post-title">Social Media Marketing (SMM)</h4>
                        </div>

                        <div className="">
                          <p>Unlock your brand&#39;s potential with our paid SMM expertise. Engage, captivate, and grow your
audience across platforms. Let&#39;s elevate your social presence today!</p>
                        </div>
                        
                      </div>
                    </div>
                 
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                                   
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12">
                                    <TypeDesign className="vndjn-popup" />
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12">
                                    
                                </div>
                                

                
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Marketing</strong>
          </div>
        </div>

<div className="section-full  mobile-page-padding bacg-ser p-t80 p-b10 bg-repeat">
    <div className="container">
        {/* TITLE START */}
        <div className="section-head">
            <div className="sx-separator-outer separator-center">
                <div className="bg-repeat-x color-change">
                    <div className="ert678 heading">
                    Why Choose Shadow infosystem For Website Development Services?
                    </div>
                    <p>
                        Want to boost organic traffic and generate more leads? Contact us now to secure your place on Google's first page!
                    </p>
                </div>
                <div className="common-enq-bot">
                    <a href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello Shadow" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/whatsapp-button (1).png")} alt="" />
                    </a>
                    <span>OR</span>
                    <a href="tel:+91-88003 84880" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/Call-Now-Button.png")} alt="" />
                    </a>
                </div>
            </div>
        </div>
        {/* TITLE END */}
    </div>
</div>

    <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x">
                <h2 className="wfgrbwe ert678 heading text-white">
                   How We Drive Success Through Digital
Marketing?
                </h2>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/online_business_analyst_data_computer.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Explore
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                The initial phase involves understanding your audience - their characteristics, needs, and how your offerings can meet those needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Analyze
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                A thorough evaluation of your website's current status - assessing its functionality, visitor engagement, and identifying any potential issues.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Strategize
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                We determine the optimal approach - evaluating the feasibility of targeting specific keywords, conducting necessary site optimizations, and considering strategies like guest posts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Evaluate
                                </h3>
                            </div>
                            </div>

                            <div className="">
                                <p>
                                    Early data analysis serves as the cornerstone of success - monitoring, and understanding how your strategies are performing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/vector-isometric-concept.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Report
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Comprehensive and detailed reporting to support your business's growth in the most effective directions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/smartphone_test_data_analysis.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Adapt
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Data-driven decision-making guides our actions - identifying areas requiring improvement, and determining strategies to progress based on successful outcomes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                    <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
            </div>
        </div>
    </div>
    <div className="hilite-title text-left p-l50 text-uppercase">
        <strong>Why Choose</strong>
    </div>
</div>

 <div className="section-full inner-page-padding p-b50 p-t80 ">
                    <div className="container-fluid">
                        <div className="section-content">
                            <div className="container">
                                {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="ertyui">
                                        <h3 className="ert678">Loved By 3000+ Customers All Over The World</h3>
                                    <h6>Our greatest asset is the customers and clients Satisfaction!</h6>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                                <div className="row">
                                    {clientele.map((item) => (
                      <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb__20">
                        <ul key={`item-${item.id}`} className="cl_container">
                          <div className="client_logo_img_box">
                            <div className="client_logo_img">
                              <img src={item.image} alt="client-logo" />
                            </div>
                          </div>

                          <div className="client_logo_title_container">
                            {item.items.map((country, index) => (
                              <li key={`country-${index}`}>{country}</li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    ))}
                                </div>
                                


                            </div>
                            </div>
                            


                        </div>

                    </div>
                </div>
                 <div className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x  white-text">
                <h2 className="wfgrbwe ert678 heading">
                    Why Choose Shadow infosystem For Digital
Marketing Services?
                </h2>
                <p>Elevate your digital presence and propel your business toward success with Shadow
infosystem's unrivaled expertise in digital marketing. With a proven track record of delivering
tailored strategies, innovative solutions, and measurable results, we are your trusted partner as
the best <b>digital marketing company in Noida</b> in navigating the dynamic digital landscape and
achieving sustainable growth.</p>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/2.png')} alt="" />
                    <div className="sx-post-info  bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/2.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                               Expertise and Experience
                            </h3>
                        </div>

                        <div className="">
                            <p>With years of industry experience and a team of seasoned professionals, Shadow infosystem
brings unparalleled expertise to every digital marketing project. We understand the nuances of
the digital landscape and leverage proven strategies to deliver results that drive business
growth and exceed client expectations.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                <img className="technology-img" src={require('./../../images/locationpagesimages/determine_the_business_development_plan.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/determine_the_business_development_plan.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                               Customized Solutions
                            </h3>
                        </div>

                        <div className="">
                            <p>As the top digital marketing agency in Noida, we believe in the power of tailored solutions to
meet the unique needs and objectives of each client. Our digital marketing services are
customized to your specific industry, target audience, and business goals, ensuring maximum
effectiveness and ROI for every campaign we undertake.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/12.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/12.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Cutting-Edge Technology
                            </h3>
                        </div>

                        <div className="">
                            <p>As the premier digital marketing company in Noida, we stay ahead of the curve by embracing
the latest technologies and tools in digital marketing. From advanced analytics platforms to
innovative marketing automation solutions, we leverage cutting-edge technology to optimize
campaigns, streamline processes, and drive superior results for our clients.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/14.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/14.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                               Transparent Reporting
                            </h3>
                        </div>

                        <div className="">
                            <p>We prioritize transparency and open communication throughout every stage of the digital
marketing process. From initial strategy development to ongoing campaign management and
reporting, we keep our clients informed and involved, providing regular updates, insights, and
performance reports to ensure accountability and mutual success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="hilite-title text-left p-l50 text-uppercase">
    <strong>Services</strong>
</div>
</div>
<div className="page-content">
    <div className="section-full p-t80 p-b80 inner-page-padding ">
        {/*
        <div className="trtrtrtyuy"></div>
        */}
        <div className="container">
            <div className="faq-1">
                {/* TITLE START */}
                <div className="section-head">
                    <div className="sx-separator-outer separator-left">
                        <div className="">
                            <h3 className="ert678">Frequently Asked Questions</h3>
                        </div>
                    </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                    <div className="col-md-12">
                        {/* Accordian */}
                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                            <div className="panel sx-panel">
                                <div className="acod-head acc-actives">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                          How does digital marketing help businesses promote their services and products?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOne5" className="acod-body collapse show">
                                    <div className="acod-content p-a15">
                                        <p>Digital marketing facilitates businesses in reaching a wider audience through targeted
advertising, social media engagement, and SEO strategies. It enhances brand visibility, fosters
customer engagement, and enables precise analytics for optimizing campaigns, ultimately
driving sales and growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                           What are the benefits of hiring a digital marketing company in India?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseTwo5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Hiring a digital marketing company like shadow infosystem in India can offer expertise in diverse
digital channels, cost-effective solutions, tailored strategies for the Indian market, access to
advanced analytics, efficient campaign management, and cultural understanding for targeted
messaging.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                           What are the best and result-oriented digital marketing activities?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseThree5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>The best and result-oriented digital marketing activities include targeted social media
advertising, search engine optimization (SEO) strategies, and email marketing campaigns.
These tactics effectively engage audiences, drive traffic, and generate conversions for
businesses across various industries.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                           What is brand awareness in digital marketing?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseFour5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Brand awareness in digital marketing refers to the extent to which a brand is recognized by its
target audience online. It encompasses familiarity with the brand name, logo, products, and
services, ultimately influencing consumer trust, consideration, and loyalty.</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    {/* SECTION CONTENT END */}
</div>




          </>
        );
    }
};

export default ClientsLogo1;