import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import IccBanner from "./../Elements/Icc/IccBanner";

import IccMembers from "./../Elements/Icc/IccMembers";

import ClientsLogo2 from "./../Elements/ClientsLogo2";

var bnrimg = require("./../../images/banner/shadow-banner-19.jpg");

class Icc extends React.Component {
  render() {
    return (
      <>
        <Header2 />
        <div className="page-content">
          <IccBanner />

          <IccMembers />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Icc;
