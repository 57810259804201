import React from 'react';
import Components from './components/Components';
import ScrolToTop from './components/Elements/ScrolToTop';


const App = () => {
  
  return (
    <div className="App">
      <Components />
      <ScrolToTop/>
      </div>
  );
}

export default App;