import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import WebAppBanner from "./../Elements/WebApp/WebAppBanner";
import WebAppOnline from "./../Elements/WebApp/WebAppOnline";
import WebAppProcess from "./../Elements/WebApp/WebAppProcess";
import WebAppTechno from "./../Elements/WebApp/WebAppTechno";
import WebAppAbout from "./../Elements/WebApp/WebAppAbout";
import WebAppWhyUs from "./../Elements/WebApp/WebAppWhyUs";
import WebAppChooseUs from "./../Elements/WebApp/WebAppChooseUs";
import WebCustomPlan from "./../Elements/WebsiteDesign/WebCustomPlan";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import Testimonials from "./../Elements/Testimonials";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Web Application Development | Shadow infosystem</title>
          <meta
            name="description"
            content="Get the best web application development services with Shadow infosystem. Our expert team delivers innovative solutions tailored to your business needs"
          />
          <meta name="keywords" content="" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/website-development"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <WebAppBanner />
          <WebAppAbout />
          <WebAppTechno />
          <WebCustomPlan />
          <WebAppProcess />
          <CommenEnquiry />
          <WebAppWhyUs />
          <WebAppChooseUs />
          <WebAppOnline />
          <Testimonials />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
