import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
            animateOut: "fadeOut",
        };
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x">
                                    <h3 className="wfgrbwe ert678">Wordpress Development</h3>
                                    <p>We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <NavLink to={"#"}>
                                        <div className="blog-post blog-lg date-style-3 block-shadow">
                                            <div className="sx-post-info  bg-white sdfgj56564">
                                                <div className="">
                                                    <img className="iocn1234" src={require("./../../../images/icon/settings.png")} alt="" />
                                                    <h4 className="post-title">Configuration And Installation</h4>
                                                </div>

                                                <div className="">
                                                    <p>
                                                        Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s
                                                        strengths. We specialize in assisting you in establishing a strong online presence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <NavLink to={"#"}>
                                        <div className="blog-post blog-lg date-style-3 block-shadow">
                                            <div className="sx-post-info bg-white sdfgj56564">
                                                <div className="">
                                                    <img className="iocn1234" src={require("./../../../images/icon/app-development (2).png")} alt="" />
                                                    <h4 className="post-title">WordPress Customization</h4>
                                                </div>

                                                <div className="">
                                                    <p>
                                                        Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s
                                                        strengths. We specialize in assisting you in establishing a strong online presence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <NavLink to={"#"}>
                                        <div className="blog-post blog-lg date-style-3 block-shadow ">
                                            <div className="sx-post-info bg-white sdfgj56564">
                                                <div className="">
                                                    <img className="iocn1234" src={require("./../../../images/icon/coding (1).png")} alt="" />
                                                    <h4 className="post-title">Plugin Development</h4>
                                                </div>

                                                <div className="">
                                                    <p>
                                                        Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s
                                                        strengths. We specialize in assisting you in establishing a strong online presence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <NavLink to={"#"}>
                                        <div className="blog-post blog-lg date-style-3 block-shadow">
                                            <div className="sx-post-info bg-white sdfgj56564">
                                                <div className="">
                                                    <img className="iocn1234" src={require("./../../../images/icon/uptime.png")} alt="" />
                                                    <h4 className="post-title">Uptime monitoring</h4>
                                                </div>

                                                <div className="">
                                                    <p>
                                                        Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s
                                                        strengths. We specialize in assisting you in establishing a strong online presence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
            </>
        );
    }
}

export default Blog2;
