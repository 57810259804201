import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-6">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Working with Shadow has been a transformative
                        experience for our dental clinic. In a competitive
                        industry where patient engagement and trust are
                        paramount, Shadow provided us with the digital tools and
                        strategies necessary to stand out and grow our business
                        significantly.”
                      </p>
                      <h6>Dr. Alok Bharati</h6>
                      <p className="mb__0">Owner, Geetanjali Dentals</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/geetanjalidentals/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
