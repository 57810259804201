import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import DigitalMarketingAgencyIndia from "./../Elements/DigitalMarketingAgencyIndia";
import { Helmet } from "react-helmet";

var bnrimg = require("../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Digital Marketing agency in India | Shadow infosystem</title>
          <meta name="description" content="Transform your business with India&#39;s premier digital marketing agency. Contact us to grow your business and increase ROI. Get Started Today!" />
          <meta name="keywords" content="Digital marketing agency in India" />
          <link rel="canonical" href=" https://www.shadowinfosystem.com/digital-marketing-agency-India" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <DigitalMarketingAgencyIndia />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
