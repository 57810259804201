import React from 'react';

class WhatWeDo5 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full  mobile-page-padding bg-white  p-t80 p-b30 bg-repeat overflow-hide back-ground-color">
                        <div className="container right-half-bg-image-outer">
                            
                            <div className="section-content">
                               
                                <div className="large-title-block full-content request-section bg-repeat1 ">
                                    <div className="row text-tirt">
                                        <div className="col-lg-9 col-md-12 col-sm-12">
                                            <div className="large-title white-text">
                                                <h3 className="m-tb0">Need Some Custom Plan?</h3>
                                                <p>Connect with our support team and ask for our Business & eCommerce Premium plans.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-12	col-sm-12 rtreert">
                                            <div className="large-title-info white-botton">
                                               
                                                <div className="text-md-end ">
                                                    <a href="javascript:;" className="ecfrto-kjg">Request Plan</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default WhatWeDo5;