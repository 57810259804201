import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import DigitalMarketingCompanyNoida from "./../Elements/DigitalMarketingCompanyNoida";
import { Helmet } from "react-helmet";

var bnrimg = require("../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Digital Marketing Company in Noida</title>
          <meta name="description" content="Elevate your online presence with the best digital marketing company in Noida. Drive growth, engagement, and success with expert strategies." />
          <meta name="keywords" content="Main Keyword - Digital marketing company in Noida" />
          <link rel="canonical" href=" https://www.shadowinfosystem.com/digital-marketing-company-noida" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <DigitalMarketingCompanyNoida />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
