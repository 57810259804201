import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x  white-text">
                  <h2 className="wfgrbwe ert678 mb-3">
                    Social Media Marketing Services
                  </h2>
                  <p>
                    Our specialized suite of services in social media marketing
                    encompasses, but is not limited to, the following platforms:
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}

                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img dgtal-img"
                      src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white sdfgj56564 servdert-one1 dgtal">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Social Media Audit and Strategy
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We assess the existing performance metrics of all your
                          social media pages to devise strategies for enhancing
                          their effectiveness.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}

                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img dgtal-img"
                      src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Content Creation and Promotion
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We assist in generating excitement across various
                          social media platforms through our concise, pertinent,
                          and engaging content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}

                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img dgtal-img"
                      src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Multi-Channel
                          <br /> Integration
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          Unlock your maximum potential through our impactful
                          multi-channel integration strategies, enabling
                          seamless connection with your audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}

                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img dgtal-img"
                      src={require("./../../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png")}
                          alt=""
                        />
                        <h4 className="post-title">Social Media Advertising</h4>
                      </div>

                      <div className="">
                        <p>
                          Generate immediate traffic, amplify your brand's
                          reach, and capture more leads to enhance your return
                          on investment (ROI) through our consistent social
                          media campaigns.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}

                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img dgtal-img"
                      src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Social Listing and Monitoring
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We vigilantly monitor social conversations pertaining
                          to your interests, empowering you to uncover
                          additional opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}

                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img dgtal-img"
                      src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 dgtal">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Tracking and <br />
                          Reporting
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We consistently monitor social media metrics, evaluate
                          performance, and analyze campaigns to furnish you with
                          comprehensive reports.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Marketing</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
