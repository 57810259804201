import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Identifying & Targeting Niche Keywords",
    description:
      "Finding and targeting the right keywords for a CRM software solutions provider was crucial yet challenging. The market is saturated with high-competition keywords. We conducted an extensive keyword research to identify niche, long-tail keywords that potential customers might use. Balancing between high search volume and low competition required a deep understanding of customer pain points and search behaviors, allowing the company to attract a more targeted audience.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Managing Content Diversity & Consistency",
    description:
      "The CRM software industry serves a wide range of business sizes and sectors, each with specific needs and requirements. We tried to ensure that the content addressed diverse audiences while maintaining consistency in messaging and quality. This involved creating tailored content for different buyer personas, ensuring that all materials were aligned with the brand's voice and strategic goals.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Optimizing for Technical SEO",
    description:
      "Technical SEO optimization is a crucial asset to ensure the company's website was easily crawlable and indexable by search engines. For that purpose, regular technical audits and updates were taken to maintain optimal performance and stay ahead of competitors in search engine rankings.",
  },
  {
    number: <div class="number fontColor4">4</div>,

    heading: "Creating High-Quality, Technical Content",
    description:
      "Developing high-quality, technical content that resonates with decision-makers in businesses of various sizes was a significant challenge. We created detailed blog posts that were not only informative and engaging but also optimized for search engines.",
  },
  {
    number: <div class="number fontColor1">5</div>,
    heading: "Building a Strong Backlink Profile",
    description:
      "Acquiring high-quality backlinks in the competitive CRM software market was another significant challenge. We tried to develop a robust backlink strategy, which included guest posting on reputable websites, and creating shareable content. ",
  },
  {
    number: <div class="number fontColor2">6</div>,
    heading: "Monitoring and Adapting to SEO Trends",
    description:
      "The SEO landscape is constantly evolving, with frequent updates to search engine algorithms and industry best practices. We tried to continuously monitor SEO trends, analyzing performance data, and adapting strategies accordingly.  Staying up-to-date with algorithm changes and ensuring compliance with the latest SEO guidelines is essential to maintain and improve search engine rankings.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
