import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const testimonialsVital = [
  {
    image: require("./../../../images/solution/v-seo1.webp"),
  },
  {
    image: require("./../../../images/solution/v-seo2.webp"),
  },
  {
    image: require("./../../../images/solution/v-seo3.webp"),
  },
  {
    image: require("./../../../images/solution/v-seo4.webp"),
  },
];
class Social extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      margin: 30,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        400: {
          items: 1,
        },
        540: {
          items: 1,
        },
        768: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1350: {
          items: 3,
        },
      },
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container pt-50">
            <div className="row">
              <div className="col-md-12">
                <div class="sx-separator-outer separator-left">
                  <div class="ertyui">
                    <h3 class="ert678">RESULTS ACHIEVED USING SEO</h3>
                  </div>
                </div>
                <OwlCarousel
                  className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                  {...options}
                >
                  {testimonialsVital.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="hover-animation-1 bg-gray">
                        <div className="testimonial-detail clearfix">
                          <div className="scale-in-center dfgh34235">
                            <img src={item.image} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div class="undefined section-full mobile-page-padding p-b50 p-t80 back-ground-color">
            <div class="section-content">
              <div class="container">
                <div class="">
                  <div class="row align-items-center">
                    <div className="col-md-12">
                      <div class="sx-separator-outer separator-left">
                        <div class="ertyui">
                          <h3 class="ert678">SOCIAL HANDLE SCREENSHOT</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="m-b30 bg-white">
                        <div class="number-block-one  animate-in-to-top fgghhjkhjjg smo-img">
                          {/* <img src="/src/images/vital-uk-linkedin.png" alt="" /> */}
                          <img
                            src={require("./../../../images/vital-uk-linkedin.png")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Social;
