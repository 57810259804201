import React from "react";

function banner() {
  return (
    <>
      <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
        <div className="container">
          <div className="row">
            <div className="col-md-12 sx-separator-outer separator-center">
              <div className="case-banner">
                <h1>Ashoka Institute</h1>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
              <div className="case-logo">
                <img
                  src={require("./../../../images/ashoka/ashoka-logo.webp")}
                  className=""
                  alt="Shadow"
                />
              </div>
              <div className="mxnv046">
                <h5 className="dfghj876543">Ashoka Institute</h5>
                <ul className="scnv-case">
                  <li>
                    <b>Location:</b> Varanasi, UP
                  </li>
                  <li>
                    <b>Services:</b> Education
                  </li>
                  <li>
                    <b>Website:</b>{" "}
                    <a
                      href="https://ashokainstitute.com/"
                      className="text-white"
                      target="_blank"
                    >
                      ashokainstitute.com
                    </a>
                  </li>
                </ul>
                <div className="full-social-bg">
                  <h6 className="m-t0">Follow US: </h6>
                  <ul className="ertyupoi">
                    <li>
                      <a
                        href="https://www.facebook.com/Ashokavaranasi"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ashokainstitute/"
                        className="instagram"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/ashoka-institute"
                        className="tumblr"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/aitmvns"
                        className="twitter"
                        target="_blank"
                      >
                        <img
                          src={require("./../../../images/twitter (1).png")}
                          className="county-logo1"
                          alt="Shadow infosystem"
                        />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.youtube.com/@ashokainstitute"
                        target="_blank"
                        className="youtube"
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 sx-separator-outer">
              <div className="case-web-banner">
                <img
                  src={require("./../../../images/ashoka/mobile.webp")}
                  className="phone-banner"
                  alt="Shadow"
                />
                <img
                  src={require("./../../../images/ashoka/desktop.webp")}
                  className="web-banner"
                  alt="Shadow"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="qpqq">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="m-b30 bg-white">
                    <h2 class="ert678">Introduction</h2>
                    <p>
                      Ashoka Institute of Management and Technology (AIMT) is a
                      private, co-educational engineering and management
                      institution located in Varanasi, Uttar Pradesh, India.
                      Established in 2010, AIMT offers undergraduate and
                      postgraduate degree programs in the fields of engineering,
                      management, computer applications, and education.
                    </p>
                    <p>
                      AIMT is affiliated with Dr. A.P.J. Abdul Kalam Technical
                      University (AKTU) and is approved by the All India Council
                      for Technical Education (AICTE). The institution aims to
                      provide a holistic education experience to students,
                      enabling them to become skilled professionals who can
                      contribute to society and the global workforce.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default banner;
