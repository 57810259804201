import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/app-about.webp")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h2 className="ert678 mb-3">
                              Harnessing the Power of Cutting-Edge Technologies
                            </h2>
                            <p>
                              At Shadow infosystem, we specialize in delivering
                              robust and scalable web application solutions
                              tailored to meet your business needs. Our team of
                              experienced developers is proficient in a wide
                              array of technologies, ensuring that your web
                              applications are not only functional but also
                              secure, efficient, and user-friendly.
                            </p>
                            <p>
                              Our approach goes beyond just building websites;
                              we strive to create digital experiences that
                              engage, inspire, and drive results. Whether you're
                              launching a new venture or seeking to enhance your
                              existing online presence, we have the expertise
                              and dedication to turn your vision into reality.
                            </p>
                            <p>
                              We prioritize efficiency and scalability, laying
                              the foundation for your web applications to grow
                              and evolve alongside your business. Our solutions
                              are designed with performance in mind, delivering
                              lightning-fast load times and seamless user
                              experiences across all devices.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
