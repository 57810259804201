import React from 'react';


var bnrimg = require('./../../../images/banner/7.jpg');

class Faq extends React.Component {
    render() {
        return (
            <>
                
                <div className="page-content">
                    
                    <div className="section-full p-t80 p-b80 inner-page-padding ">
                    
                    {/*<div className="trtrtrtyuy"></div>*/}
                        <div className="container">
                            <div className="faq-1">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left">
                                        <div className="">
                                            <h3 className="ert678">FAQs</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                <div className="row">
                                    
                                    <div className="col-md-6">
                                        {/* Accordian */}
                                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                           What all is included in this service?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15">
                                                        <p>This service includes everything related to website management. We take care of website hosting, website backups, website security, WordPress and plugin upgrades, and any content update on the website, like, updating logos, testimonials, content, images, videos, awards, blogs, FAQs, career opportunities, google codes, and adding portfolio, team members, legal pages, awards, & certifications.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            Can I cancel anytime?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Yes, absolutely. Just drop us an email and we will initiate the cancellation process. In case of month-to-month option, our billing stops from next month. In case of yearly option, you are not billed for the next year.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                           Can I get a refund?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Yes, we have a 30-day money back policy. In case you don’t like our service within the first 30-days, you can request cancellation and we will refund the complete amount (applicable to yearly plans).</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                            Do I need to sign up a long-term contract?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFour5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>There is no long-term contracts or lock-in period for any of our website maintenance plans. You can cancel anytime by requesting before the Commencement of next billing cycle. However, there is financial discount if you opt for a longer duration.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                            What is Unlimited Technical Support?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive5" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Considering the responsibility, we understand that all the technical issues should be our scope of work. Hence, we provide unlimited Standard (within the website) technical support.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-md-6">
                                        {/* Accordian */}
                                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                           What all is included in this service?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15">
                                                        <p>This service includes everything related to website management. We take care of website hosting, website backups, website security, WordPress and plugin upgrades, and any content update on the website, like, updating logos, testimonials, content, images, videos, awards, blogs, FAQs, career opportunities, google codes, and adding portfolio, team members, legal pages, awards, & certifications.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            Can I cancel anytime?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Yes, absolutely. Just drop us an email and we will initiate the cancellation process. In case of month-to-month option, our billing stops from next month. In case of yearly option, you are not billed for the next year.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                           Can I get a refund?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Yes, we have a 30-day money back policy. In case you don’t like our service within the first 30-days, you can request cancellation and we will refund the complete amount (applicable to yearly plans).</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                            Do I need to sign up a long-term contract?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFour5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>There is no long-term contracts or lock-in period for any of our website maintenance plans. You can cancel anytime by requesting before the Commencement of next billing cycle. However, there is financial discount if you opt for a longer duration.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                            What is Unlimited Technical Support?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive5" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Considering the responsibility, we understand that all the technical issues should be our scope of work. Hence, we provide unlimited Standard (within the website) technical support.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                
            </>
        );
    };
};

export default Faq;