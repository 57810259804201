import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/asdfgfd.jpg");
var bgimg2 = require("./../../images/website-hosting-concept-with-bright-light.jpg");
var bgimg3 = require("./../../images/businessman-hand-holding-creative-light-bulb-with-marketing-network-icons-planning-strategy.jpg");

class Navigation extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/mobilenav.js");
  }

  render() {
    return (
      <>
        <ul className="nav navbar-nav SMN_effect-16">
          <li className="active">
            <NavLink to={"/"}>Home</NavLink>
          </li>

          <li>
            <NavLink to={"#"} aria-hidden="true">
              {" "}
              Services<i className="fa fa-angle-down" aria-hidden="true"></i>
            </NavLink>
            <ul className="sub-menu">
              <div className="row wdfgfd4567654">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4 skncs">
                      <div
                        className="mnnmnm"
                        style={{ backgroundImage: "url(" + bgimg3 + ")" }}
                      >
                        <h4>Services</h4>
                        <p>Your Gateway to Cutting-Edge IT Services</p>
                      </div>
                    </div>
                    <div className="col-md-4 dfghjh rtytr">
                      <li>
                        <NavLink to={"/web-applications"}>
                          <img
                            src={require("./../../images/head-icon/1.webp")}
                            alt="shadow_services_icon"
                          />
                          Web Application
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/website-development"}>
                          <img
                            src={require("./../../images/head-icon/17.webp")}
                            alt="shadow_services_icon"
                          />
                          Website Development
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to={"/e-Commerce-website-development"}>
                          <img
                            src={require("./../../images/head-icon/3.webp")}
                            alt="shadow_services_icon"
                          />
                          eCommerce Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/website-maintenance-services"}>
                          <img
                            src={require("./../../images/head-icon/4.webp")}
                            alt="shadow_services_icon"
                          />
                          Website Maintenance
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/managed-cloud-services"}>
                          <img
                            src={require("./../../images/head-icon/8.webp")}
                            alt="shadow_services_icon"
                          />
                          Managed Cloud Services
                        </NavLink>
                      </li>
                    </div>
                    <div className="col-md-4 dfghjh rtytr">
                      <li>
                        <NavLink to={"/search-engine-optimization"}>
                          <img
                            src={require("./../../images/head-icon/5.webp")}
                            alt="shadow_services_icon"
                          />
                          Search Engine Optimization
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/social-media-marketing"}>
                          <img
                            src={require("./../../images/head-icon/2.webp")}
                            alt="shadow_services_icon"
                          />
                          Social Media Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/pay-per-click"}>
                          <img
                            src={require("./../../images/head-icon/7.webp")}
                            alt="shadow_services_icon"
                          />
                          Pay Per Click (PPC)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/informatica"}>
                          <img
                            src={require("./../../images/head-icon/16.webp")}
                            alt="shadow_services_icon"
                          />
                          Informatica
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to={"/cyber-secuirity"}>
                          <img
                            src={require("./../../images/head-icon/9.webp")}
                            alt="shadow_services_icon"
                          />
                          Cyber Security
                        </NavLink>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </li>

          <li>
            <NavLink to={"/case-study"}>Case Study</NavLink>
          </li>
          <li>
            <NavLink to={"/career"}>Career</NavLink>
          </li>
          <li>
            <NavLink to={"#"}>
              {" "}
              Company<i className="fa fa-angle-down" aria-hidden="true"></i>
            </NavLink>
            <ul className="sub-menu ththt">
              <div className="row wdfgfd4567654">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4 skncs">
                      <div
                        className="mnnmnm"
                        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                      >
                        <h4>About Company</h4>
                        <p>Nurturing Growth Beyond Boundaries</p>
                      </div>
                    </div>

                    <div className="col-md-4 dfghjh rtytr">
                      <li>
                        <NavLink to={"/about-us"}>
                          <img
                            src={require("./../../images/head-icon/10.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          About Us{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/awards"}>
                          <img
                            src={require("./../../images/head-icon/11.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          Awards{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/achievements"}>
                          <img
                            src={require("./../../images/head-icon/12.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          Achievements{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/testimonial"}>
                          <img
                            src={require("./../../images/head-icon/13.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          Client Reviews
                        </NavLink>
                      </li>
                    </div>
                    <div className="col-md-4 dfghjh rtytr">
                      <li>
                        <NavLink to={"/csr-at-shadow"}>
                          <img
                            src={require("./../../images/head-icon/18.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          CSR at Shadow{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/clientele"}>
                          <img
                            src={require("./../../images/head-icon/14.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          Clientele{" "}
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to={"/digital-marketing-partnership-program"}>
                          <img
                            src={require("./../../images/partner/part.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          Partnership Program{" "}
                        </NavLink>
                      </li>

                      <li>
                        <a
                          href={"https://blog.shadowinfosystem.com/"}
                          target="_blank"
                        >
                          <img
                            src={require("./../../images/head-icon/15.webp")}
                            alt="shadow_about_icon"
                          />{" "}
                          Blog{" "}
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </li>

          <li>
            <NavLink to={"/contact-us"}>Contact us</NavLink>
          </li>
          <div className="5uigfh sdcsc23232">
            <div className="sx-icon-box-wraper left p-b30">
              <div className="icon-xs inline-icon m-b20 scale-in-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    fill-rule="evenodd"
                    d="m16.1 13.359l.456-.453c.63-.626 1.611-.755 2.417-.317l1.91 1.039c1.227.667 1.498 2.302.539 3.255l-1.42 1.412c-.362.36-.81.622-1.326.67c-1.192.111-3.645.051-6.539-1.643l3.964-3.963Zm-5.91-5.876l.287-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.065 1.14.453 3.22 2.149 5.776l4.039-4.038Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="white"
                    d="M12.063 11.497c-2.946-2.929-1.88-4.008-1.873-4.015l-4.039 4.04c.667 1.004 1.535 2.081 2.664 3.204c1.14 1.134 2.26 1.975 3.322 2.596L16.1 13.36s-1.082 1.076-4.037-1.862Z"
                    opacity=".6"
                  />
                </svg>
              </div>
              <div className="icon-content">
                <h6 className="m-t0">Mobile: </h6>
                <p>
                  <a href="tel:+91-8800384880"> +91-8800384880 </a>
                </p>
                <p>
                  <a href="tel:+91-8285560008"> +91-8285560008 </a>
                </p>
              </div>
            </div>

            <div className="sx-icon-box-wraper left p-b30">
              <div className="icon-xs inline-icon m-b20 scale-in-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="white"
                    d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l217.6-163.2c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176v208c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V176L294.4 339.2a63.9 63.9 0 0 1-76.8 0L0 176z"
                  />
                </svg>
              </div>
              <div className="icon-content">
                <h6 className="m-t0">Mail: </h6>
                <p>
                  <a href="mailto:info@shadowinfosystem.com">
                    {" "}
                    info@shadowinfosystem.com
                  </a>
                </p>
                <p>
                  <a href="mailto:sales@shadowinfosystem.com">
                    {" "}
                    sales@shadowinfosystem.com
                  </a>
                </p>
              </div>
            </div>
            <div className="sx-icon-box-wraper left p-b30">
              <div className="icon-xs inline-icon m-b20 scale-in-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="white"
                    d="M128 16a88.1 88.1 0 0 0-88 88c0 75.3 80 132.17 83.41 134.55a8 8 0 0 0 9.18 0C136 236.17 216 179.3 216 104a88.1 88.1 0 0 0-88-88Zm0 56a32 32 0 1 1-32 32a32 32 0 0 1 32-32Z"
                  />
                </svg>
              </div>
              <div className="icon-content">
                <h6 className="m-t0">India Location: </h6>
                <p>
                  <a
                    href="https://maps.app.goo.gl/FyznSkDMjUiupHcP7"
                    target="_blank"
                  >
                    {" "}
                    Electronic City, G-90, Sector-63, Noida-201301 (Delhi / NCR)
                  </a>
                </p>
              </div>
            </div>
            <div className="sx-icon-box-wraper left p-b30">
              <div className="icon-xs inline-icon m-b20 scale-in-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="white"
                    d="M128 16a88.1 88.1 0 0 0-88 88c0 75.3 80 132.17 83.41 134.55a8 8 0 0 0 9.18 0C136 236.17 216 179.3 216 104a88.1 88.1 0 0 0-88-88Zm0 56a32 32 0 1 1-32 32a32 32 0 0 1 32-32Z"
                  />
                </svg>
              </div>
              <div className="icon-content">
                <h6 className="m-t0">USA Location: </h6>
                <p>
                  <a href=""> 10104 Des Moines ST, Parker CO 80134 </a>
                </p>
              </div>
            </div>
            <div className="sx-icon-box-wraper left p-b30">
              <div className="icon-xs inline-icon m-b20 scale-in-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="white"
                    d="M128 16a88.1 88.1 0 0 0-88 88c0 75.3 80 132.17 83.41 134.55a8 8 0 0 0 9.18 0C136 236.17 216 179.3 216 104a88.1 88.1 0 0 0-88-88Zm0 56a32 32 0 1 1-32 32a32 32 0 0 1 32-32Z"
                  />
                </svg>
              </div>
              <div className="icon-content">
                <h6 className="m-t0">Canada Location: </h6>
                <p>
                  <a href="">
                    {" "}
                    8820 Jasper Avenue, Edmonton Alberta Canada-T5H4E8
                  </a>
                </p>
              </div>
            </div>
          </div>
        </ul>
      </>
    );
  }
}

export default Navigation;
