import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const bars = [
  {
    icon: <span class="fa fa-mobile-phone fa-3x"></span>,
    heading: "All Devices compatibility",
  },
  {
    icon: <span class="fa fa-keyboard-o fa-3x"></span>,
    heading: "CMS Management",
  },
  {
    icon: <span class="fa fa-users fa-3x"></span>,
    heading: "User Roles Management",
  },
  {
    icon: <span class="fa fa-picture-o fa-3x"></span>,
    heading: "Stunning UI design",
  },
  {
    icon: <span class="fa fa-server fa-3x"></span>,
    heading: "Large Spacing Server",
  },
  {
    icon: <span class="fa fa-external-link fa-3x"></span>,
    heading: "CDN implementations",
  },
];

class Innovation extends React.Component {
  render() {
    return (
      <>
        <section class="service-categories text-xs-center">
          <div class="container">
            <div class="row">
              <div class="col-md-12 sx-separator-outer separator-center">
                <div class="case-banner">
                  <h2 class="ert678">Innovation That Sets the Bar Higher</h2>
                </div>
              </div>
            </div>
            <div class="row">
              {bars.map((item, index) => (
                <div class="col-lg-4 col-md-6" key={index}>
                  <div class="card service-card card-inverse">
                    <div class="card-block">
                      {item.icon}
                      <h4 class="card-title">{item.heading}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Innovation;
