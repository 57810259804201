import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/background/cross-line2.png');

class Blog2 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
            animateOut: 'fadeOut',
        };
        return (
            <>
                 
                <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser">
                
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x">
                                    <h3 className="wfgrbwe ert678">Our Services</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                            
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info  bg-white">

                                            
                                            <div className="sx-post-title iconse">
                                            <img src={require('./../../../images/icon/responsive-design.png')} alt="" />
                                               <h4 className="post-title">Website Development</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>If you need a customized CMS for your site, then we can help you with that. Let us know what features you want, we can make it happen for your site. As the leading Web Development Company in India, we extend useful assistance in developing CMS.</p>
                                            </div>
                                            <div className="clearfix">
                                                
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info  bg-white">
                                            
                                            <div className="sx-post-title iconse">
                                            <img src={require('./../../../images/icon/ecommerce.png')} alt="" />
                                               <h4 className="post-title">E-Commerce Solutions</h4>

                                            </div>
                                            
                                            <div className="">
                                                <p>If you need a customized CMS for your site, then we can help you with that. Let us know what features you want, we can make it happen for your site. As the leading Web Development Company in India, we extend useful assistance in developing CMS.
</p>
                                            </div>
                                            <div className="clearfix">
                                             
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info  bg-white">
                                            
                                            <div className="sx-post-title iconse">
                                            <img src={require('./../../../images/icon/php.png')} alt="" />

                                               <h4 className="post-title">Custom Development</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>If you need a customized CMS for your site, then we can help you with that. Let us know what features you want, we can make it happen for your site. As the leading Web Development Company in India, we extend useful assistance in developing CMS.</p>
                                            </div>
                                            
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                        
                                        <div className="sx-post-info  bg-white ">
                                            
                                            <div className="sx-post-title iconse">
                                            <img src={require('./../../../images/icon/cms.png')} alt="" />
                                               <h4 className="post-title">CMS Web Development</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>If you need a customized CMS for your site, then we can help you with that. Let us know what features you want, we can make it happen for your site. As the leading Web Development Company in India, we extend useful assistance in developing CMS.</p>
                                            </div>
                                            <div className="clearfix">
                                              
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                        
                                        <div className="sx-post-info  bg-white ">
                                            
                                            <div className="sx-post-title iconse">
                                            <img src={require('./../../../images/icon/wordpress.png')} alt="" />
                                               <h4 className="post-title">Wordpress Development</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>If you need a customized CMS for your site, then we can help you with that. Let us know what features you want, we can make it happen for your site. As the leading Web Development Company in India, we extend useful assistance in developing CMS.</p>
                                            </div>
                                            <div className="clearfix">
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                        
                                        <div className="sx-post-info  bg-white ">
                                            
                                            <div className="sx-post-title iconse">
                                            <img src={require('./../../../images/icon/responsive-design.png')} alt="" />
                                               <h4 className="post-title">Laravel Development</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>If you need a customized CMS for your site, then we can help you with that. Let us know what features you want, we can make it happen for your site. As the leading Web Development Company in India, we extend useful assistance in developing CMS.</p>
                                            </div>
                                            <div className="clearfix">
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title common-enq-bot1 p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
                
            </>
        );
    }
};

export default Blog2;