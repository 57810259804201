import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Improved online presence",
    description:
      "An IT company can help the resort in creating a professional and user-friendly website that showcases their offerings and the services. This can also attract more number of visitors which also boosts their online presence.",
  },
  {
    icon: <i class="fa fa-users"></i>,
    heading: "Enhanced customer experience",
    description:
      "With the help of IT solutions, the resort can offer their guests a seamless & hassle-free booking process, an easy check-in and check-out process. This can improve customer satisfaction and loyalty.",
  },
  {
    icon: <i class="fa fa-database"></i>,
    heading: "Efficient operations",
    description:
      "IT solutions provided by IT company can help the resort streamline their operations, such as managing bookings, inventory, staff scheduling, and billing. This can lead to more efficient and cost-effective operations.",
  },
  {
    icon: <i class="fa fa-pie-chart"></i>,
    heading: "Data-driven insights",
    description:
      "IT solutions can provide valuable insights into guest preferences and behavior, which can help the tenthouse provider and resort make informed decisions about their offerings, marketing strategies, and business operations.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="mb__25 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p className="mb__15">
                    Partnering with an IT company can provide several advantages
                    to a tent-house provider & resort. By leveraging technology
                    and digital solutions, a resort can provide enhanced & much
                    better services to their customers. Here are some potential
                    benefits :
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div class="card-wrap mt__0">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h3 class="card-title">{item.heading}</h3>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
