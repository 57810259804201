import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/3dimages/1.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h1 className="ert678">
                              Web Design, Development and Application
                              Programming
                            </h1>
                            <p>
                              Shadow infosystem stands as a premier web design
                              and development company, dedicated to crafting
                              designs that resonate authentically with your
                              brand's identity. Comprising a team of experienced
                              advisors, creative minds, skilled coders, and
                              digital marketing experts, we recognize the
                              importance of impactful first impressions and
                              seamless user experiences.
                            </p>
                            <p>
                              As your entrusted web design partner, we
                              collaborate closely with you to design
                              mobile-first websites that captivate your audience
                              right from their initial interaction. Now is the
                              opportune moment to solidify your connection with
                              your audience by hiring a web design agency.
                            </p>
                            <p>
                              Boasting over 15 years of expertise, we specialize
                              in tailoring bespoke websites with effortless
                              navigation across all devices, elevating the
                              overall user experience. Turn to us for our robust
                              technical acumen, proficiency in crafting
                              intuitive user interfaces, and a results-driven,
                              and SEO-centric approach. Your journey to online
                              success begins with the industry-leading web
                              design prowess of Shadow infosystem.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
