import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Improved Efficiency",
    description:
      "IT solutions can help automate and streamline various operations, such as scheduling, routing, dispatching, and tracking, which can significantly improve efficiency which helps in reducing the risk.",
  },
  {
    icon: <i class="fa fa-shield"></i>,
    heading: "Enhanced Safety",
    description:
      "IT solutions can provide real-time information about the location and condition of vehicles, as well as monitor driver behavior, to ensure safe driving practices and reduce the risk of accidents.",
  },
  {
    icon: <i class="fa fa-laptop"></i>,
    heading: "Data-Driven Insights",
    description:
      " IT solutions can help transport & logistics company gather and analyze data from various sources, enabling them to make informed decisions about their operations, resource allocation, and service offerings.",
  },
  {
    icon: <i class="fa fa-pie-chart"></i>,
    heading: "Real-Time Tracking",
    description:
      "With the help of GPS and other tracking technologies, transport & logistics company can get real-time updates on the location and status of their vehicles, improving visibility and control over their operations.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="mb__25 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p className="mb__15">
                    Partnering with an IT company can provide several advantages
                    to a transport & logistics company, such as :
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div class="card-wrap mt__0">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h3 class="card-title">{item.heading}</h3>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
