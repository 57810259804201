import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Ozone Ayurvedics</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/ozone_ayurvedics/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Ozone Ayurvedics</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      Ozone House, Block A3, 1 LSc, Janakpuri, New Delhi, INDIA
                    </li>
                    <li>
                      <b>Speciality:</b> Beauty Products
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://www.ozoneayurvedics.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.ozoneayurvedics.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/Ozoneayurvedic/"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/ozone_ayurvedics/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/ozone_ayurvedics/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/ozone_ayurvedics/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Ozone Group is the 'Doctrine of LIFE' that firmly
                        advocates adding life to your living years rather than
                        just adding idle years to your life. And building on
                        this thought a step further, 'Ozone Ayurvedics division
                        of Ozone Pharmaceuticals Ltd.' believes in nurturing and
                        invigorating our skin with the goodness of active
                        organic, unadulterated ingredients. Thus, enlivening our
                        dull and dead skin and bringing it back to life with a
                        healthy glow and radiance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
