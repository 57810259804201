import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Identifying and Targeting Relevant Keywords",
    description:
      "Finding the right keywords to target was particularly challenging due to the niche nature of the stylish temple market. We performed extensive keyword research to identify terms that potential customers were using. Balancing between high-search volume and low-competition keywords required a nuanced approach, focusing on long-tail keywords and specific phrases that accurately described the unique offerings of the stylish temple provider.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Optimizing for Technical SEO",
    description:
      "Ensuring that the stylish temple provider’s website was fully optimized for search engines required meticulous attention to technical SEO. We worked on to improve site speed, ensure mobile-friendliness, and implement structured data.  Regular technical audits and updates were necessary to maintain optimal website performance, enhance crawlability, and improve indexability by search engines, which are crucial for achieving higher rankings.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Creating High-Quality, Culturally Relevant Content",
    description:
      "Producing high-quality content that resonated with the target audience and was optimized for search engines posed a significant challenge. The content needed to be not only informative and engaging but also culturally relevant. We worked on developing blog posts, and articles that highlighted the design, and cultural significance of the stylish temples.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Building a Robust Backlink Profile",
    description:
      "Acquiring high-quality backlinks is essential for improving the site's authority and search engine rankings. We developed a comprehensive outreach strategy to build these robust backlinks. Creating shareable content, such as visually appealing lookbooks and informative guides, helped attract reputable backlinks, enhancing the credibility and visibility of the website.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
