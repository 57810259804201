import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/wm-1.webp")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h1 className="ert678">
                              Website Maintenance Services
                            </h1>
                            <h6>
                              Your Reliable Partner for Comprehensive Website
                              Maintenance Services and Dedicated Support.
                            </h6>
                            <p>
                              Website Maintenance involves ongoing tasks to
                              ensure optimal performance, security, and
                              functionality. It includes updates, bug fixes,
                              content management, and monitoring to address
                              issues and enhance the user experience. Elevate
                              your online presence with our expert Website
                              Maintenance Services customized as per the
                              client's preferences. Our dedicated team ensures
                              seamless functionality, security updates, and
                              optimal performance, allowing you to focus on your
                              core objectives while we handle the technical
                              intricacies.{" "}
                            </p>
                            <p>
                              From regular content updates to bug fixes and
                              compatibility checks, we prioritize the smooth
                              operation of your website. Proactive monitoring
                              and timely interventions guarantee minimal
                              downtime and enhanced user experience. With a
                              commitment to excellence, our maintenance services
                              empower your digital platform to thrive in the
                              ever-evolving online landscape.{" "}
                            </p>
                            <p>
                              <b>
                                Partner with us for reliable support, and let
                                your website shine with confidence and
                                efficiency.
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
