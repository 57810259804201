import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/background/cross-line2.png');

class Blog2 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
            animateOut: 'fadeOut',
        };
        return (
            <>
                 
                <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser">
                
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x">
                                    <h3 className="wfgrbwe ert678">Offering Result Driven<br/> Graphic Designing Services</h3>
                                    <p>We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                            
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info  bg-white sdfgj56564">

                                            
                                            <div className="sx-post-title ">
                                            <img className="iocn1234" src={require('./../../../images/icon/product-design.png')} alt="" /> 
                                                <h4 className="post-title"><NavLink to={"#"}>Logo Design</NavLink></h4>
                                            </div>
                                            
                                            <div className="sx-post-text">
                                                <p>Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s strengths. We specialize in assisting you in establishing a strong online presence.</p>
                                            </div>
                                           <div className="clearfix">
                                                
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                            <div className="sx-post-title ">
                                            <img className="iocn1234" src={require('./../../../images/icon/backward.png')} alt="" /> 
                                                <h4 className="post-title"><NavLink to={"#"}>Packaging Design</NavLink></h4>

                                            </div>
                                            
                                            <div className="sx-post-text">
                                                <p>Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s strengths. We specialize in assisting you in establishing a strong online presence.</p>
                                            </div>
                                           <div className="clearfix">
                                                
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>

                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                           
                                            <div className="sx-post-title ">
                                            <img className="iocn1234" src={require('./../../../images/icon/layers.png')} alt="" /> 
                                                <h4 className="post-title"><NavLink to={"#"}>Brochure Design</NavLink></h4>
                                            </div>
                                           
                                            <div className="sx-post-text">
                                                <p>Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s strengths. We specialize in assisting you in establishing a strong online presence.</p>
                                            </div>
                                            <div className="clearfix">
                                                
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                            <div className="sx-post-title ">
                                            <img className="iocn1234" src={require('./../../../images/icon/webpage.png')} alt="" /> 
                                                <h4 className="post-title"><NavLink to={"#"}>Flyer Design</NavLink></h4>
                                            </div>
                                            
                                            <div className="sx-post-text">
                                                <p>Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s strengths. We specialize in assisting you in establishing a strong online presence.</p>
                                            </div>
                                            <div className="clearfix">
                                                
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                            <div className="sx-post-title ">
                                            <img className="iocn1234" src={require('./../../../images/icon/news.png')} alt="" /> 
                                                <h4 className="post-title"><NavLink to={"#"}>Newsletter Designing</NavLink></h4>
                                            </div>
                                            
                                            <div className="sx-post-text">
                                                <p>Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s strengths. We specialize in assisting you in establishing a strong online presence.</p>
                                            </div>
                                            <div className="clearfix">
                                                
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                           
                                            <div className="sx-post-title ">
                                            <img className="iocn1234" src={require('./../../../images/icon/seo (6).png')} alt="" /> 
                                                <h4 className="post-title"><NavLink to={"#"}>Business Card Designs</NavLink></h4>
                                            </div>
                                            
                                            <div className="sx-post-text">
                                                <p>Website development has emerged as a significant advantage for businesses worldwide. Keeping up with the latest trends to meet the expectations of users is one of our company’s strengths. We specialize in assisting you in establishing a strong online presence.</p>
                                            </div>
                                            <div className="clearfix">
                                                
                                               
                                            <div className="common-enq-bot1">
                                            <NavLink to="#" className="bottt2"><span>View More</span></NavLink>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
                
            </>
        );
    }
};

export default Blog2;