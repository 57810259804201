import React from 'react';
import { NavLink } from 'react-router-dom';




var bgimg1 = require('./../../../images/background/bg-12.jpg');
var bgimg2 = require('./../../../images/background/cross-line2.png');


class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding dfbb7680978 fgg5455 p-t80 p-b10 bg-repeat" >
                <div class="blur-bg-blocks">
            <aside class="blur-bg-set">
               <div class="blur-bg blur-bg-a"></div>
               <div class="blur-bg blur-bg-b"></div>
               <div class="blur-bg blur-bg-c"></div>
            </aside>
         </div>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="bg-repeat-x color-change ewgr8900">
                                    <h3 className="ert678">Get Awesome Custom Software<br/> Development Services!</h3>
                                    <p>Shadow is a reputed custom software development company that provides software keeping client’s requirements in mind. We’ve been providing services for more than a decade and have the required expertise.</p>
                                </div>
                            </div>
                            <div className="buttonfgerb sgreg">
                                                        <div className="sx-post-readmore pull-left yttrh SMN_effect-27">
                                                            <NavLink to={"#"} title="READ MORE" rel="bookmark" className="ecfrto-kjgw">
                                                                Get Quote <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/><path fill="white" d="M16.06 10.94a1.5 1.5 0 0 1 0 2.12l-5.656 5.658a1.5 1.5 0 1 1-2.121-2.122L12.879 12L8.283 7.404a1.5 1.5 0 0 1 2.12-2.122l5.658 5.657Z"/></g></svg>
                                                            </NavLink>
                                                        </div>

                                                       
                                                    </div>
                        </div>
                        {/* TITLE END */}
                        
                        
                        
                    </div>
                    
                </div>
            </>
        );
    }
};

export default ClientsLogo1;