import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Intense Competition",
    description:
      "Resorts face intense competition from other hotels and resorts in the same area, as well as from alternative accommodation options such as vacation rentals and home-sharing platforms.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Marketing and branding",
    description:
      "Effective marketing and branding are essential for attracting guests to the resort. However, creating a strong brand and marketing strategy can be a challenge, especially for smaller resorts with limited budgets.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Maintenance and upkeep",
    description:
      "Maintaining a resort requires a lot of resources and staff, including regular cleaning, landscaping, and maintenance of facilities and equipment. This can be a significant challenge, especially for larger resorts.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Security and safety",
    description:
      "Ensuring the safety and security of guests and staff is a top priority for resorts. This can be challenging, especially for larger properties that have multiple entrances and facilities.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
