import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/CyberSecuirity-image/personal-data.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h2 class="ert678 mb-3">Our Cyber Security Services</h2>
                  <p>We Guard. We Protect. We Secure.</p>
                </div>
              </div>
            </div>
            <div className="section-content ">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/css1.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/css1.webp")}
                          alt=""
                        />
                        <h4 className="post-title">SSL Certificates</h4>
                      </div>

                      <div className="">
                        <p>
                          It protects your customers' sensitive data, including
                          passwords, credit cards, and personal information.
                          Obtaining an SSL certificate is the simplest way to
                          boost customer trust in your online business & ensure
                          secure transactions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/css2.webp")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/css2.webp")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Code Guard Website Backup
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          All websites, regardless of size, are susceptible to
                          crashes and data loss. CodeGuard's cloud backup
                          solution allows you to restore your website's data
                          instantly with just a few clicks, providing peace of
                          mind and continuity.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/css3.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/css3.webp")}
                          alt=""
                        />
                        <h4 className="post-title">SiteLock</h4>
                      </div>

                      <div className="">
                        <p>
                          SiteLock conducts daily scans of your entire web
                          presence to identify and address security
                          vulnerabilities. This proactive monitoring helps
                          protect your website from potential threats, ensuring
                          a secure experience for your users.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/css4.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/css4.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Cloud security</h4>
                      </div>

                      <div className="">
                        <p>
                          Cloud security is a branch of cybersecurity focused on
                          safeguarding cloud computing systems. It involves
                          protecting data privacy and integrity across online
                          infrastructure and applications, ensuring secure and
                          reliable cloud-based services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/css5.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/css5.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Application Security</h4>
                      </div>

                      <div className="">
                        <p>
                          App security involves implementing measures at the
                          application level to prevent unauthorized access and
                          data breaches. These protections are crucial for
                          safeguarding sensitive information and maintaining the
                          integrity of your software applications.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/head-icon/css6.webp")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/head-icon/css6.webp")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Critical Infrastructure Security
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          Critical infrastructure security encompasses IT
                          solutions designed to protect essential systems and
                          data. It includes secure servers, storage solutions,
                          and hybrid cloud strategies, ensuring the resilience
                          and security of vital infrastructure.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
