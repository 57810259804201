import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing User-Friendly & Technically Robust Website",
    description:
      "Creating a user-friendly and technically robust website for a transformer manufacturer was a significant challenge. The website needed to present complex technical information in an accessible and engaging manner. We tried to balance the detailed technical content with an intuitive design, ensuring that potential customers could easily find the information they needed. The website also required robust functionality to handle product catalogs, detailed specifications, and industry certifications.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Identifying and Targeting Technical Keywords",
    description:
      "Finding and targeting the right keywords in the transformer manufacturing industry was particularly challenging due to its technical nature. We aimed to perform extensive keyword research to identify high-impact technical terms and phrases that potential customers were using. This involved a strategic approach to balance high-search volume and low-competition keywords, focusing on specific transformer types, applications, and industry standards.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Creating High-Quality, Technical Content",
    description:
      "Producing high-quality, technical content that resonated with industry professionals was another significant challenge. We tried to develop detailed blog posts that highlighted the technical specifications, benefits, and applications of various transformers. This content needed to be both informative and authoritative, appealing to the technical expertise of the audience while also being optimized for search engines to improve rankings and drive organic traffic.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Social Media Optimization (SMO)",
    description:
      "Optimizing social media presence for a highly technical industry posed unique challenges. We aimed to create and share content that not only promoted products but also engaged and educated the audience about transformer technology and applications. This involved developing a content calendar, creating visually appealing and informative posts, and engaging with industry professionals across platforms like LinkedIn, Twitter, and Facebook. Balancing technical content with engaging social media strategies required careful planning and execution.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
