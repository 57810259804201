import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import TSBanner from "./../Elements/TermsOfServices/TSBanner";
import TSContent from "./../Elements/TermsOfServices/TSContent";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Header2 />
        <div className="page-content">
          <TSBanner />
          <TSContent />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
