import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home5 from "./Pages/Home5";
// New Page //
import WebDesign from "./Pages/WebDesign";
import SocialMediaOptimization from "./Pages/SocialMediaOptimization";
import SearchEngineOptimization from "./Pages/SearchEngineOptimization";
import PayPerClick from "./Pages/PayPerClick";
import EcommerceWebsiteDevelopment from "./Pages/EcommerceWebsiteDevelopment";
import HostingServices from "./Pages/HostingServices";
import CustomSoftwareDevelopment from "./Pages/CustomSoftwareDevelopment";
import GraphicsDesign from "./Pages/GraphicsDesign";
// Tech //
import PHP from "./Pages/PHP";
import WordpressDevelopment from "./Pages/WordpressDevelopment";
import ContentWriting from "./Pages/ContentWriting";
import Maintenance from "./Pages/Maintenance";
import InformaticaProducts from "./Pages/InformaticaProducts";
import Anniversary from "./Pages/Anniversary";
// Tech //
import Clientele from "./Pages/Clientele";
import Achievements from "./Pages/Achievements";
import OurProcess from "./Pages/OurProcess";
import CSRatShadow from "./Pages/CSRatShadow";
import LifeatShadow from "./Pages/LifeatShadow";
import Awords from "./Pages/Awords";
import Testimonial from "./Pages/Testimonial";
import CaseStudy from "./Pages/CaseStudy";
import Logo from "./Pages/Logo";
import EngagemenModels from "./Pages/EngagemenModels";
import Career from "./Pages/Career";
import ThankYou from "./Pages/ThankYou";
import Bank from "./Pages/Bank";
//Case Study Page //
import Case1 from "./Pages/CaseStudyDetails/Case1";
import Case2 from "./Pages/CaseStudyDetails/Case2";
import Case3 from "./Pages/CaseStudyDetails/Case3";
import Case4 from "./Pages/CaseStudyDetails/Case4";
import Case5 from "./Pages/CaseStudyDetails/Case5";
import Case6 from "./Pages/CaseStudyDetails/Case6";
import Case7 from "./Pages/CaseStudyDetails/Case7";
import Case8 from "./Pages/CaseStudyDetails/Case8";
import Case9 from "./Pages/CaseStudyDetails/Case9";
import Case10 from "./Pages/CaseStudyDetails/Case10";
import Case11 from "./Pages/CaseStudyDetails/Case11";
import Case12 from "./Pages/CaseStudyDetails/Case12";
import Case13 from "./Pages/CaseStudyDetails/Case13";
import Case14 from "./Pages/CaseStudyDetails/Case14";
import Case15 from "./Pages/CaseStudyDetails/Case15";
import Case16 from "./Pages/CaseStudyDetails/Case16";
import Case17 from "./Pages/CaseStudyDetails/Case17";
import Case18 from "./Pages/CaseStudyDetails/Case18";
import Case19 from "./Pages/CaseStudyDetails/Case19";
import Case20 from "./Pages/CaseStudyDetails/Case20";
import Case21 from "./Pages/CaseStudyDetails/Case21";
import Case22 from "./Pages/CaseStudyDetails/Case22";
import Case23 from "./Pages/CaseStudyDetails/Case23";
import Case24 from "./Pages/CaseStudyDetails/Case24";
import Case25 from "./Pages/CaseStudyDetails/Case25";
import Case26 from "./Pages/CaseStudyDetails/Case26";
import Case27 from "./Pages/CaseStudyDetails/Case27";
import Case28 from "./Pages/CaseStudyDetails/Case28";
import Case29 from "./Pages/CaseStudyDetails/Case29";
import Case30 from "./Pages/CaseStudyDetails/Case30";
import Case31 from "./Pages/CaseStudyDetails/Case31";
import Case32 from "./Pages/CaseStudyDetails/Case32";
import Case33 from "./Pages/CaseStudyDetails/Case33";
import Case34 from "./Pages/CaseStudyDetails/Case34";
import Case35 from "./Pages/CaseStudyDetails/Case35";
import Case36 from "./Pages/CaseStudyDetails/Case36";
import Case37 from "./Pages/CaseStudyDetails/Case37";
import Case38 from "./Pages/CaseStudyDetails/Case38";
import Case39 from "./Pages/CaseStudyDetails/Case39";
import Case40 from "./Pages/CaseStudyDetails/Case40";
//Internal Pages //
import About1 from "./Pages/About1";
import Error from "./Pages/Error";
import Blog from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import ContactUs from "./Pages/ContactUs";
// import ScrollToTop from "./Common/ScrollToTop";
import CyberSecuirity from "./Pages/CyberSecuirity";
import Icc from "./Pages/Icc";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import TermsOfServices from "./Pages/TermsOfServices";
import WebApp from "./Pages/WebApp";
//Location Page //
import SEOServicesCompanyNoida from "./Pages/SEOServicesCompanyNoida";
import WebDevelopmentServicesDelhi from "./Pages/WebDevelopmentServicesDelhi";
import WebsiteDevelopmentCompanyNoida from "./Pages/WebsiteDevelopmentCompanyNoida";
import DigitalMarketingCompanyNoida from "./Pages/DigitalMarketingCompanyNoida";
import DigitalMarketingAgencyDelhi from "./Pages/DigitalMarketingAgencyDelhi";
import SEOServicesDelhi from "./Pages/SEOServicesDelhi";
import WebsiteDevelopmentCompanyIndia from "./Pages/WebsiteDevelopmentCompanyIndia";
import DigitalMarketingAgencyIndia from "./Pages/DigitalMarketingAgencyIndia";
import SEOCompanyInDenverColorado from "./Pages/SEOCompanyInDenverColorado";
import LocalSEOServices from "./Pages/LocalSEOServices";
import Partnership from "./Pages/Partnership";

//Location Page End

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        {/*<ScrollToTop />*/}
        <div className="page-wraper">
          <Routes>
            <Route path="/" element={<Home5 />} />
            {/*Services Pages*/}
            <Route path="/website-development" element={<WebDesign />} />
            <Route path="/social-media-marketing" element={<SocialMediaOptimization />}/>
            <Route path="/search-engine-optimization" element={<SearchEngineOptimization />}/>
            <Route path="/pay-per-click" element={<PayPerClick />} />
            <Route path="/e-Commerce-website-development" element={<EcommerceWebsiteDevelopment />}/>
            <Route path="/hosting-services" element={<HostingServices />} />
            <Route path="/custom-software-development" element={<CustomSoftwareDevelopment />}/>
            <Route path="/graphics-designing-services"  element={<GraphicsDesign />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/php-development" element={<PHP />} />
            <Route path="/wordpress-website-development" element={<WordpressDevelopment />}/>
            <Route path="/content-writing" element={<ContentWriting />} />
            <Route path="/managed-cloud-services" element={<HostingServices />}/>
            <Route path="/website-maintenance-services" element={<Maintenance />}/>
            <Route path="/web-applications" element={<WebApp />} />

            {/*Case Study pages*/}
            <Route path="/case-study-vital-uk" element={<Case1 />} />
            <Route path="/case-study-stteresa" element={<Case2 />} />
            <Route path="/case-study-sara-consultancy" element={<Case3 />} />
            <Route path="/case-study-ashoka-institute" element={<Case4 />} />
            <Route path="/case-study-allwayslogistics" element={<Case5 />} />
            <Route path="/case-study-davidthetreeguy" element={<Case6 />} />
            <Route path="/case-study-ssrstudio" element={<Case7 />} />
            <Route path="/case-study-roadload" element={<Case8 />} />
            <Route path="/case-study-meridianivf" element={<Case9 />} />
            <Route path="/case-study-tatvabirresort" element={<Case10 />} />
            <Route path="/case-study-mydentalkraft" element={<Case11 />} />
            <Route path="/case-study-shrimahalaxmiassociates" element={<Case12 />}/>
            <Route path="/case-study-ozoneayurvedics" element={<Case13 />} />
            <Route path="/case-study-santoshacademia" element={<Case14 />} />
            <Route path="/case-study-nailrituals" element={<Case15 />} />
            <Route path="/case-study-olivastu" element={<Case16 />} />
            <Route path="/case-study-massistcrm" element={<Case17 />} />
            <Route path="/case-study-engraverssign" element={<Case18 />} />
            <Route path="/case-study-jindaltiles" element={<Case19 />} />
            <Route path="/case-study-advanced-amorphous" element={<Case20 />} />
            <Route path="/case-study-trishul" element={<Case21 />} />
            <Route path="/case-study-300-experts" element={<Case22 />} />
            <Route path="/case-study-geetanjali-dentals" element={<Case23 />} />
            <Route path="/case-study-sg-public-school" element={<Case24 />} />
            <Route path="/case-study-symmetry-dentals" element={<Case25 />} />
            <Route path="/case-study-microtek-group" element={<Case26 />} />
            <Route path="/case-study-vital-india" element={<Case27 />} />
            <Route path="/case-study-glow-skin-dental" element={<Case28 />} />
            <Route path="/case-study-greenvent" element={<Case29 />} />
            <Route path="/case-study-esheesh" element={<Case30 />} />
            <Route path="/case-study-ivorycounty" element={<Case31 />} />
            <Route path="/case-study-worldofvedam" element={<Case32 />} />
            <Route path="/case-study-ausunflower" element={<Case33 />} />
            <Route path="/case-study-omsaidriving" element={<Case34 />} />
            <Route path="/case-study-gctc" element={<Case35 />} />
            <Route path="/case-study-dipm-council" element={<Case36 />} />
            <Route path="/case-study-cenjows" element={<Case37 />} />
            <Route path="/case-study-andosa" element={<Case38 />} />
            <Route path="/case-study-imr-media" element={<Case39 />} />
            <Route path="/case-study-hqids" element={<Case40 />} />

            {/*Internal Pages*/}
            <Route path="/bank" element={<Bank />} />
            <Route path="/about-us" element={<About1 />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-details" element={<BlogDetails />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
            <Route path="/terms-of-services" element={<TermsOfServices />} />
            <Route path="/informatica" element={<InformaticaProducts />} />
            <Route path="/logo-designing-services" element={<Logo />} />
            <Route path="/clientele" element={<Clientele />} />
            <Route path="/icc" element={<Icc />} />
            <Route path="/cyber-secuirity" element={<CyberSecuirity />} />
            <Route path="/achievements" element={<Achievements />} />
            <Route path="/our-process" element={<OurProcess />} />
            <Route path="/csr-at-shadow" element={<CSRatShadow />} />
            <Route path="/life-at-shadow" element={<LifeatShadow />} />
            <Route path="/awards" element={<Awords />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/case-study" element={<CaseStudy />} />
            <Route path="/engagement-models" element={<EngagemenModels />} />
            <Route path="/career" element={<Career />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/16-Anniversary" element={<Anniversary />} />
            
            {/*Location Pages*/}
            <Route path="/seo-services-company-noida" element={<SEOServicesCompanyNoida />}/>
            <Route path="/web-development-services-delhi" element={<WebDevelopmentServicesDelhi />}/>
            <Route path="/website-development-company-noida" element={<WebsiteDevelopmentCompanyNoida />}/>
            <Route path="/digital-marketing-company-noida" element={<DigitalMarketingCompanyNoida />}/>
            <Route path="/digital-marketing-agency-delhi" element={<DigitalMarketingAgencyDelhi />}/>
            <Route path="/seo-services-delhi" element={<SEOServicesDelhi />} />
            <Route path="/website-development-company-india" element={<WebsiteDevelopmentCompanyIndia />}/>
            <Route path="/digital-marketing-agency-India" element={<DigitalMarketingAgencyIndia />}/>
            <Route path="/local-seo-services" element={<LocalSEOServices />} />
            <Route path="/seo-company-in-denver-colorado" element={<SEOCompanyInDenverColorado />} />
            <Route path="/digital-marketing-partnership-program" element={<Partnership />}/>
            <Route path="/*" element={<Error />} />
            <Route element={<Error />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}
export default Components;
