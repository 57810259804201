import React from "react";
import TypeDesign from "./../TypeDesign";
var bgimg1 = require("../../../images/flaticon/img-bg.jpg");
function PartnerOnline() {
  return (
    <>
      <section
        class="img-bg"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div class="container">
          <div className="row">
            <div className="col-md-9">
              <div class="caption asdfghj-jhgfds cnsajvjv-js_jdsc">
                <div class="digihead">
                  <div className="alcnsja text-white">
                    Ready to take your business to new heights?
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <TypeDesign className="vndjn-popup" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PartnerOnline;
