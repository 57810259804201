import React from "react";

function ClientReview() {
  return (
    <>
      <div className="section-full mobile-page-padding ptb_40 bg-repeat">
        <div className="container">
          <div className="section-content">
            <div className="qpqq sdfghjkl-case">
              <div className="row align-items-center">
                <div className="col-xl-9 col-lg-9 col-md-6">
                  <div className="m-b30 bg-white">
                    <div className="case-banner">
                      <h1 className="cmvnn-csefvs">Client Review</h1>
                    </div>
                    <p>
                      “Your team clearly has a strong understanding of our
                      target audience and has been able to effectively engage
                      with them through creative and relevant content. The
                      results of the campaign have exceeded our expectations and
                      we’ve seen a significant increase in website traffic and
                      engagement on our social media platforms. We appreciate
                      your dedication and flexibility in working with us to
                      achieve our goals.”
                    </p>
                    <h6>Er. Ankit Maurya</h6>
                    <p>
                      Chairman, Ashoka Institute of Technology and Management
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className=" bg-white ">
                    <img
                      src={require("./../../../images/ashoka/head.webp")}
                      className="cnjf-cade"
                      alt="Shadow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientReview;
