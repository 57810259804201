import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import WebBanner from "./../Elements/WebsiteDesign/WebBanner";
import WebWebSiteProcess from "./../Elements/WebsiteDesign/WebWebSiteProcess";
import WebTypeDesign from "./../Elements/WebsiteDesign/WebTypeDesign";
import WebAbout from "./../Elements/WebsiteDesign/WebAbout";
import WebWhyChooseUs from "./../Elements/WebsiteDesign/WebWhyChooseUs";
import Webclient from "./../Elements/WebsiteDesign/Webclient";
import WebCustomPlan from "./../Elements/WebsiteDesign/WebCustomPlan";
import WebFAQ from "./../Elements/WebsiteDesign/WebFAQ";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import Testimonials from "./../Elements/Testimonials";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Website Development Company</title>
          <meta
            name="description"
            content=" Shadow Infosystem specializes in website development, custom web applications, and web application development to enhance your business's online presence."
          />
          <meta name="keywords" content="Custom Web Application, Web Application Development" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/website-development"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <WebBanner />
          <WebAbout />

          <WebTypeDesign />
          <WebCustomPlan />
          <WebWebSiteProcess />
          <CommenEnquiry />
          {/*<WebAboutNumber />*/}
          <WebWhyChooseUs />
          <Webclient />
          <Testimonials />
          <WebFAQ />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
