import React from 'react';
import { NavLink } from 'react-router-dom';




var bgimg1 = require('./../../../images/background/bg-12.jpg');
var bgimg2 = require('./../../../images/background/cross-line2.png');


class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding dfbb7680978 fgg5455 p-t80 p-b10 bg-repeat" >
                <div class="blur-bg-blocks">
            <aside class="blur-bg-set">
               <div class="blur-bg blur-bg-a"></div>
               <div class="blur-bg blur-bg-b"></div>
               <div class="blur-bg blur-bg-c"></div>
            </aside>
         </div>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="bg-repeat-x color-change ewgr8900">
                                    <h3 className="ert678">Top IT Companies in Noida – Shadow infosystem</h3>
                                    <p>An IT (Information Technology) company, also known as a tech company or technology firm, is an organization that specializes in providing a wide range of technology-related services, and solutions to individuals, businesses, and other organizations. These top IT companies in Noida typically focus on various</p>
                                </div>
                            </div>
                            
                        </div>
                        {/* TITLE END */}
                        
                        
                        
                    </div>
                    
                </div>
            </>
        );
    }
};

export default ClientsLogo1;