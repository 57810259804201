import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Enhancing Data Security and Privacy",
    description:
      "Protecting sensitive student and faculty data is a paramount concern for educational institutions. It has been acheived by implementing robust data security measures to prevent unauthorized access, data breaches, and ensure compliance with educational data protection regulations.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Technical SEO Optimization",
    description:
      "Technical SEO poses another set of challenges, particularly with ensuring that the educational institute’s website was fully optimized for search engines. This involves improving the site’s architecture, enhancing page load speeds, ensuring mobile-friendliness, and implementing structured data.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Ensuring Scalability and Flexibility",
    description:
      "Educational institutes often experience fluctuations in enrollment and must be prepared to scale their technology infrastructure accordingly. This could be accomplished by providing scalable solutions that could handle varying loads without compromising performance.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Monitoring & Analyzing SEO Performance",
    description:
      "Tracking the effectiveness of SEO strategies and making data-driven adjustments can be a challenge. It can be achieved by implementing robust analytics techniques to monitor key performance indicators (KPIs) such as organic traffic, keyword rankings, and user engagement metrics.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
