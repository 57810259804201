import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">Client's Review</h2>
                      </div>
                      <p>
                        “Your team clearly has a strong understanding of our
                        target audience and has been able to effectively engage
                        with them through creative and relevant content. The
                        results of the campaign have exceeded our expectations
                        and we’ve seen a significant increase in website traffic
                        and engagement on our social media platforms. We
                        appreciate your dedication and flexibility in working
                        with us to achieve our goals.”
                      </p>
                      <h6>Renu Shrivastava</h6>
                      <p>Principal, St. Teresa School</p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/stteresa/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
