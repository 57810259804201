import React from "react";

var bnrimg = require("./../../../images/banner/7.jpg");

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="page-content bg-gray">
          <div className="section-full p-t80 p-b80 inner-page-padding ">
            {/*<div className="trtrtrtyuy"></div>*/}
            <div className="container">
              <div className="faq-1">
                {/* TITLE START */}
                <div className="">
                  <div className="sx-separator-outer text-center">
                    <div className="bg-moving bg-repeat-x">
                      <h3 className="ert678 mb-3">
                        Frequently Asked Questions
                      </h3>
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                  <div className="col-md-12">
                    {/* Accordian */}
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              Do you offer e-commerce website design?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              Yes, we do! Our specialty lies in crafting
                              websites that captivate users, ensuring enjoyable
                              shopping experiences. Emphasizing speed,
                              attractiveness, and innovation, our designs
                              prioritize responsiveness and exceptional user
                              interaction, fostering customer engagement and
                              satisfaction.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Which Content Management System Do You Use?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Our team boasts deep expertise across leading
                              e-commerce and CMS platforms like WordPress,
                              React.js, and Shopify. We tailor your website to
                              your preferred platform and ensure comprehensive
                              support for vital e-Commerce Functionalities,
                              including user management, shopping cart
                              integration, product search, and secure payment
                              processing.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Will you test my website design for cross-browser
                              compatibility?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              In today's mobile-centric world, our focus is on
                              crafting e-commerce websites that seamlessly adapt
                              to various browsers, screen sizes, and resolutions
                              while maintaining visual appeal. Rigorous testing
                              ensures high quality, bug-free performance, and
                              compatibility across browsers before project
                              delivery.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How often can I make changes to my website design?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              We'll provide layout options and designs for your
                              review. Upon your selection, we'll meticulously
                              refine the chosen design to align with your
                              vision. Following this, we'll proceed with
                              development, incorporating only content
                              adjustments and bug fixes thereafter, maintaining
                              design integrity.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Will my website design be search engine friendly?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              In crafting a mobile-first design, our development
                              team adheres to Google's mobile-first indexing
                              guidelines, prioritizing search engine
                              friendliness. Our websites integrate SEO tools,
                              yet for advanced services like keyword research
                              and content optimization, please consider signing
                              up for our dedicated SEO services.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Is your web designs mobile friendly?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Yes, our website designs are mobile-friendly and
                              thoroughly tested to ensure compatibility across
                              various browsers, device sizes, and resolutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Can you help me with Online Marketing?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Yes, we provide comprehensive online marketing and
                              digital solutions tailored for e-commerce,
                              including SEO, PPC, and SMO to achieve better
                              results.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
      </>
    );
  }
}

export default Faq;
