import React from "react";
import { NavLink } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h2 className="wfgrbwe ert678 heading text-white mb-3">
                    How Do We Work?
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/online_business_analyst_data_computer.png")}
                          alt=""
                        />
                        <div className="sx-post-title ">
                          <h3 className="post-title sub-heading">Explore</h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          The initial phase involves understanding your audience
                          - their characteristics, needs, and how your offerings
                          can meet those needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")}
                          alt=""
                        />
                        <div className="sx-post-title ">
                          <h3 className="post-title sub-heading">Analyze</h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          A thorough evaluation of your website's current status
                          - assessing its functionality, visitor engagement, and
                          identifying any potential issues.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <div className="sx-post-title ">
                          <h3 className="post-title sub-heading">Strategize</h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          We determine the optimal approach - evaluating the
                          feasibility of targeting specific keywords, conducting
                          necessary site optimizations, and considering
                          strategies like guest posts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <div className="sx-post-title ">
                          <h3 className="post-title sub-heading">Evaluate</h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          Early data analysis serves as the cornerstone of
                          success - monitoring, and understanding how your
                          strategies are performing.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/vector-isometric-concept.png")}
                          alt=""
                        />
                        <div className="sx-post-title ">
                          <h3 className="post-title sub-heading">Report</h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          Comprehensive and detailed reporting to support your
                          business's growth in the most effective directions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                          alt=""
                        />
                        <div className="sx-post-title ">
                          <h3 className="post-title sub-heading">Adapt</h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          Data-driven decision-making guides our actions -
                          identifying areas requiring improvement, and
                          determining strategies to progress based on successful
                          outcomes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Why Choose</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
