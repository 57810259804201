import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1>SSR Studio</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo">
                  <img
                    src={require("./../../../images/ssr_studio/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">SSR Studio</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> 10104 Des Moines St, Parker, CO 80134,
                      USA
                    </li>
                    <li>
                      <b>Services:</b> Cosmetics
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://ssrstudio.us/"
                        class="text-white"
                        target="_blank"
                      >
                        www.ssrstudio.us
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/ssrstudio.us/"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/ssrstudio.us/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/ssrstudio"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/studio_ssr"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCbGYeaLymp8mhIkRHbj9u2w"
                          target="_blank"
                          className="youtube"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/ssr_studio/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/ssr_studio/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__15">
                        SSR Studio started in India in 1993 and later moved to
                        US, Sana has both theoretical and practical extensive
                        experience in corrective, special effects, bridal, ramp,
                        prom, fashion, natural, nude, and evening makeup.
                        Specialized in Bridal Makeup Artistry, Skincare
                        Consultation and Hair styling, Sana is able to bring her
                        vision to life. Sana has an artistic eye and uncanny
                        ability to translate her clients’ wishes, from a fresh
                        new haircut to an elaborate editorial concept, into
                        reality.
                      </p>

                      <p className="mb__0">
                        For Sana, the possibilities are endless. She finds
                        beauty and inspiration everywhere and is constantly
                        pushing herself to think outside of the box. She
                        naturally levels with her clients and easily collaborate
                        knowledge and ideas, bringing their vision to life with
                        a flare! She is also committed to continuing education.
                        The beauty and fashion industries are constantly
                        changing and evolving and Sana is dedicated to staying
                        on top of all the latest tools, techniques, trends and
                        styles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
