import React from "react";
import { NavLink } from "react-router-dom";
import MoreInform from "./../MoreInform";
var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row">
              <div className="col-md-7">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        We're dedicated to expanding your business
                      </h3>
                      <p>
                        Shadow infosystem strives for transformative growth for
                        clients, measuring success by the impact on
                        profitability. As a leading SEO agency, we offer
                        comprehensive digital marketing solutions.
                      </p>
                    </div>
                  </div>

                  <MoreInform />
                </div>
              </div>
              <div className="col-md-5">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/head-icon/about.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
