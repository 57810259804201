import React from "react";

const ibenefits = [
  {
    icon: (
      <img
        className="iocn1234"
        src={require("./../../../images/head-icon/cs2.webp")}
        alt=""
      />
    ),
    heading: "(VA & PT)",
    description: "Venerability Access & Pentation Testing Services",
  },
  {
    icon: (
      <img
        className="iocn1234"
        src={require("./../../../images/head-icon/cs3.webp")}
        alt=""
      />
    ),
    heading: " (OS int)",
    description: "Open Source Intelligence",
  },
  {
    icon: (
      <img
        className="iocn1234"
        src={require("./../../../images/head-icon/cs4.webp")}
        alt=""
      />
    ),
    heading: " (ISMS)",
    description: "Information Security Management System",
  },
  {
    icon: (
      <img
        className="iocn1234"
        src={require("./../../../images/head-icon/cs5.webp")}
        alt=""
      />
    ),
    heading: " ( IAM )",
    description: "Identity and Access Management",
  },
];

function Ibenefits() {
  return (
    <>
      <section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="m-b30 text-center">
                <h2 class="ert678">CYBER SECURITY SERVICES</h2>
                <p>
                  Cyber security services consist of technologies, processes and
                  controls designed to protect systems, networks, programs,
                  devices and data from cyber attacks.
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
            {ibenefits.map((item, index) => (
              <div class="col-md-3 mb-5" key={index}>
                <div class="service-card">
                  <div class="icon-wrapper">{item.icon}</div>
                  <h3>{item.heading}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="background-cyber-secuirity">
        <div className="container pt-5">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="m-b30  text-center">
                <h2 className="ert678 text-white">
                  CYBER SECURITY SERVICES IS MUCH MORE THAN A MATTER OF IT
                </h2>
              </div>
            </div>

            <div className="container right-half-bg-image-outer">
              <div
                className="bg-parallax bg-fixed bg-top-right"
                data-stellar-background-ratio={0}
                style={{}}
              />

              <div className="section-content ">
                <div className="row number-block-one-outer justify-content-center">
                  <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className="section-head">
                      <div className="sx-separator-outer">
                        <div className="">
                          <h4 className="text-white">
                            Are you maintaining your Cyber Hygiene?
                          </h4>
                          <p className="text-white">
                            {" "}
                            Cyber hygiene refers to the practices and steps that
                            all computer or device users within a business take
                            to maintain and continuously improve their security
                            both on and offline. These practices are often part
                            of a routine to ensure the safety of identity and
                            other details that could be stolen or corrupted. As
                            you have personal hygiene practices to maintain your
                            own health, cyber hygiene best practices help
                            protect the health of your organization’s network
                            and assets.{" "}
                          </p>
                          <h4 className="text-white">
                            This is where we come in
                          </h4>
                          <p className="text-white">
                            Altogether our entire business is designed to make
                            this difficult scenario strengthen, facile, and
                            unpretentious for our customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="number-block-one  animate-in-to-top ">
                      <img
                        className="wfefw"
                        src={require("./../../../images/CyberSecuirity-image/ai-robot-using-cyber-security-protect-information-privacy.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="m-b30 mt-3">
                      <h4 className="text-white">
                        Cybercrime went up by 500%, We’re are protecting 2000+
                        Clients.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section-full  mobile-page-padding bg-white1  p-t80 p-b30 bg-repeat overflow-hide back-ground-color">
        {/*<span class="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>*/}
        <div className="container right-half-bg-image-outer">
          <div
            className="bg-parallax bg-fixed bg-top-right"
            data-stellar-background-ratio={0}
            style={{}}
          />

          <div className="section-content ">
            <div className="row number-block-one-outer justify-content-center">
              <div className="col-lg-7 col-md-12 col-sm-12 m-b30">
                <div className="number-block-one  animate-in-to-top ">
                  <img
                    className="wfefw"
                    src={require("./../../../images/head-icon/cs6.webp")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 m-b30">
                <div className="section-head">
                  <div className="sx-separator-outer">
                    <div className="">
                      <p>
                        Endpoint protection refers to securing endpoints, or
                        end-user gadgets like desktops, laptops, and cellular
                        devices. Endpoints serve as factors of access to a
                        business enterprise community and create ways of entry
                        that can be exploited by using malicious actors.
                      </p>

                      <p>
                        Endpoint protection software program protects these
                        factors of entry from risky endeavour and/or malicious
                        attack. When agencies can ensure endpoint compliance
                        with records security standards, they can keep increased
                        control over the rising wide variety and kind of access
                        factors to the network.
                      </p>
                      <p>
                        The evolving tools, tactics and procedures used by
                        cybercriminals to breach networks means that it’s
                        important to regularly test your organisation’s cyber
                        security.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ibenefits;
