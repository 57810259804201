import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import WebBanner from "./../Elements/ecommerceDevelopment/WebBanner";
import WebWebSiteTech from "./../Elements/ecommerceDevelopment/WebWebSiteTech";
import WebWebSiteProcess from "./../Elements/ecommerceDevelopment/WebWebSiteProcess";
import WebTypeDesign from "./../Elements/ecommerceDevelopment/WebTypeDesign";
import WebAbout from "./../Elements/ecommerceDevelopment/WebAbout";
import WebWhyChooseUs from "./../Elements/ecommerceDevelopment/WebWhyChooseUs";
import WebFAQ from "./../Elements/ecommerceDevelopment/WebFAQ";
import Webclient from "./../Elements/ecommerceDevelopment/Webclient";
import Testimonials from "./../Elements/Testimonials";
import WebCustomPlan from "./../Elements/ecommerceDevelopment/WebCustomPlan";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/web-development-banner.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Best Ecommerce Web Development Company | Shadow infosystem
          </title>
          <meta
            name="description"
            content="Shadow infosystem, an e-commerce web development company, delivers, Best solutions tailored to your needs. Get our expertise for unmatched online success"
          />
          <meta name="keywords" content="Website Development Company" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/e-Commerce-website-development"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <WebBanner />
          <WebAbout />

          <WebTypeDesign />
          <WebCustomPlan />
          <WebWebSiteProcess />
          <WebWhyChooseUs />
          <CommenEnquiry />
          <Testimonials />
          <WebFAQ />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
