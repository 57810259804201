import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Improved Efficiency",
    description:
      "An IT company can help a consultancy services company improve its efficiency by automating routine tasks, streamlining workflows, and optimizing data management. This can free up staff time and reduce errors and delays.",
  },
  {
    icon: <i class="fa fa-laptop"></i>,
    heading: "Competitive Advantage",
    description:
      "Partnering with an IT company can give a consultancy services company a competitive advantage by enabling it to offer cutting-edge technology solutions and expertise to its clients.",
  },
  {
    icon: <i class="fa fa-shield"></i>,
    heading: "Enhanced Data Security",
    description:
      "With increasing concerns around data privacy and security, an IT company can help a consultancy services company implement robust cybersecurity measures to protect sensitive client information.",
  },
];

function Overview() {
  return (
    <>
      <div className="section-full mobile-page-padding p-b10 bg-repeat">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="m-b30 bg-white">
                <h2 class="ert678">Overview</h2>
                <p>
                  Partnering with an IT company can provide several advantages
                  for a school, including :
                </p>
              </div>
            </div>
            {overview.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div class="card-wrap">
                  <div class="card-header three">{item.icon}</div>
                  <div class="card-content">
                    <h1 class="card-title">{item.heading}</h1>
                    <p class="card-text">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="m-b30 bg-white">
                <p>
                  Overall, partnering with an IT company can help a consultancy
                  services company improve its operations, enhance its
                  offerings, and stay competitive in a rapidly changing business
                  environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
