import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import ContentWritingBanner from './../Elements/ContentWriting/ContentWritingBanner';
import ContentWritingAbout from './../Elements/ContentWriting/ContentWritingAbout';
import ContentWritingClient from './../Elements/ContentWriting/ContentWritingClient';
import WebSiteProcess from './../Elements/WebSiteProcess';
import ContentWritingWhyChooseUs from './../Elements/ContentWriting/ContentWritingWhyChooseUs';
import ContentWritingService from './../Elements/ContentWriting/ContentWritingService';
import ContentWritingServices from './../Elements/ContentWriting/ContentWritingServices';
import Services7 from './../Elements/Services7';
import ClientsLogo2 from './../Elements/ClientsLogo2';
import ContentWritingFAQ from './../Elements/ContentWriting/ContentWritingFAQ';
import CustomPlan from './../Elements/CustomPlan';
import SocialMediaIcon from './../Elements/SocialMediaIcon';
import CommenEnquiry from './../Elements/CommenEnquiry';
import Testimonials from './../Elements/Testimonials';


var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <ContentWritingBanner />
                    <ContentWritingAbout />
                    <ContentWritingServices />
                    {/*<ContentWritingService />*/}
                    <ContentWritingWhyChooseUs/>
                    <ContentWritingClient />
                    <CommenEnquiry />
                    <Testimonials/>
                </div>
               
                <Footer2/>
            </>
        );
    };
};

export default Service1;