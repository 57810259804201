import React from "react";
import { NavLink } from "react-router-dom";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class PPCBanner extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row d-inline-flex">
              <div className="col-md-8">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <div className="ert678 heading text-white">
                        Boost Your Brand with Strategic PPC Solutions
                      </div>
                      <p>
                        Let us propel your brand to new heights through
                        effective PPC campaigns designed to deliver measurable
                        results.
                      </p>
                    </div>
                  </div>
                  <TypeDesign />
                </div>
              </div>
              <div className="col-md-4">
                <div className="">
                  <img
                    src={require("./../../../images/head-icon/ppc-4.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default PPCBanner;
