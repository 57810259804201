import React from "react";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

function termContent() {
  return (
    <>
      <div className="section-full mobile-page-padding p-b50 p-t80 back-ground-color">
        <div className="container-fluid">
          <div className="section-content">
            <div className="container">
              <div className="section-head">
                <div className="row">
                  <div className="col-md-12">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <p>
                          This privacy policy sets out how Shadow infosystem
                          uses and protects any information that you give Shadow
                          infosystem when you use this website.
                        </p>
                        <p>
                          Shadow infosystem is committed to ensuring that your
                          privacy is protected. Should we ask you to provide
                          certain information by which you can be identified
                          when using this website, then you can be assured that
                          it will only be used in accordance with this privacy
                          statement.
                        </p>
                        <p>
                          Shadow infosystem may change this policy from time to
                          time by updating this page. You should check this page
                          from time to time to ensure that you are happy with
                          any changes. This policy is effective from 1 January
                          2016.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="container">
              <div className="section-head">
                <div className="row">
                  <div className="col-md-12">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <h2 className="ert678 heading">What we collect</h2>
                        <p>
                          We Provides you with dedicated resources that work as
                          true part of your teams. From developers to
                          assistants, we identify the best people available in
                          the market and hire them to be part of your team. Our
                          strength is finding best qualified candidates that
                          match your exact requirements and team culture through
                          which you can reach you desired output. We may collect
                          the following information:
                        </p>
                        <ul className="sdnvdn">
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;Name
                            and job title
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Contact information including email address
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Demographic information such as postcode,
                            preferences and interests
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;Other
                            information relevant to customer surveys and/or
                            offers
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <h2 className="ert678 heading">
                          What we do with the information we gather
                        </h2>
                        <p>
                          We require this information to understand your needs
                          and provide you with a better service, and in
                          particular for the following reasons:
                        </p>
                        <ul className="sdnvdn">
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Internal record keeping
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;We
                            may use the information to improve our products and
                            services
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;We
                            may periodically send promotional emails about new
                            products, special offers or other
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Information which we think you may find
                            interesting using the email address which you have
                            provided
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;From
                            time to time, we may also use your information to
                            contact you for market research purposes
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;We
                            may contact you by email, phone, fax or mail. We may
                            use the information to customise the website
                            according to your interests Security
                          </li>
                        </ul>
                        <h2 className="ert678 heading">Security</h2>
                        <p>
                          We are committed to ensuring that your information is
                          secure. In order to prevent unauthorised access or
                          disclosure, we have put in place suitable physical,
                          electronic and managerial procedures to safeguard and
                          secure the information we collect online.
                        </p>
                        <h2 className="ert678 heading">How we use cookies</h2>
                        <p>
                          We use traffic log cookies to identify which pages are
                          being used. This helps us analyse data about webpage
                          traffic and improve our website in order to tailor it
                          to customer needs. We only use this information for
                          statistical analysis purposes and then the data is
                          removed from the system.Overall, cookies help us
                          provide you with a better website by enabling us to
                          monitor which pages you find useful and which you do
                          not. A cookie in no way gives us access to your
                          computer or any information about you, other than the
                          data you choose to share with us.You can choose to
                          accept or decline cookies. Most web browsers
                          automatically accept cookies, but you can usually
                          modify your browser setting to decline cookies if you
                          prefer. This may prevent you from taking full
                          advantage of the website.
                        </p>
                        <h2 className="ert678 heading">
                          Links to other websites
                        </h2>
                        <p>
                          Our website may contain links to other websites of
                          interest. However, once you have used these links to
                          leave our site, you should note that we do not have
                          any control over that other website. Therefore, we
                          cannot be responsible for the protection and privacy
                          of any information which you provide whilst visiting
                          such sites and such sites are not governed by this
                          privacy statement. You should exercise caution and
                          look at the privacy statement applicable to the
                          website in question.
                        </p>
                        <h2 className="ert678 heading">Employment</h2>
                        <p>
                          This letter contains broad terms and conditions of
                          service governing this appointment which are subject
                          to change from time to time. You are also bound by the
                          terms relating to Non – Disclosure, Intellectual
                          Property Assignment, Non-Solicitation, Confidentiality
                          and Non-Compete agreement annexed hereto at Annexure
                          A. You are requested to contact the People Function
                          for any clarifications on policies/rules/regulations,
                          which are applicable to you. We expect you to keep the
                          salary details confidential at all times.
                        </p>
                        <h2 className="ert678 heading">Nature Of Employment</h2>
                        <p>
                          This offer is made on the clear understanding that
                          your employment is on whole time basis and that you
                          will not undertake any other part time/full-time work,
                          without the consent of the company. Also this
                          employment is of continuous nature and does not entail
                          any compensation for any overtime worked by you.
                        </p>
                        <h2 className="ert678 heading">Transfer</h2>
                        <p>
                          You could be transferred to any of other
                          offices/branches/subsidiaries/affiliates either
                          domestic or abroad, should the need arise. You will
                          abide by the company’s rules and regulations as may be
                          in effect from time to time with respect to your
                          function, grade or location where you work in.
                        </p>
                        <h2 className="ert678 heading">Methods</h2>
                        <p>
                          If you conceive any new or advanced methods of
                          improving process/formulae/systems/product in relation
                          to the operation of the company, such developments
                          will be fully communicated to the company and will
                          remain sole right/property of the company.
                        </p>
                        <h2 className="ert678 heading">Code Of Conduct</h2>
                        <p>
                          Shadow has a code of conduct, confidentiality and
                          non-compete agreements, primarily to address working
                          standards and business interests at the time of
                          interview are discussed orally.
                        </p>
                        <h2 className="ert678 heading">Termination</h2>
                        <p>
                          Either party may terminate the appointment by giving
                          the other party one-month (30 Days) notice in writing.
                          In case have during work continuity or notice period
                          surviving found misbehavior, taking more than a
                          one-day vacation, the disturbing work environment of
                          the company will fire to you on the same day and same
                          time without any payment. If any, in lieu of notice,
                          even without assigning any reason thereof waiver of
                          the notice period is at the sole discretion of the
                          company.
                        </p>
                        <p>
                          The amount required to “buy out” the notice period is
                          equivalent to the wages which would have accrued to
                          the employee during the required notice period.
                          “Wages” is defined very broadly under the Employment
                          Ordinance (EO) to include most cash allowances, but
                          does not include any payment which is gratuitous or
                          which is payable only at the discretion of the
                          employer. The EO indicates the minimum amount, which
                          must be paid. If the contract of employment provides
                          for a more generous payment then it is necessary to
                          comply with the terms of the contract.
                        </p>
                        <h2 className="ert678 heading">Tax Implications</h2>
                        <p>
                          You are solely responsible for declarations and
                          implications arising thereof for all personal income
                          tax purposes.
                        </p>
                        <h2 className="ert678 heading">
                          Background Check & References
                        </h2>
                        <p>
                          We will conduct a background and reference check on
                          your previous employment details. In few days, you are
                          required to furnish documents regarding education,
                          previous employment and any other documents that
                          Shadow infosystem Private Limited may deem necessary
                          for a background check. Our authorized partner, will
                          contact you in order to conduct the background check
                          process and you are required to extend your
                          cooperation to them in facilitating the process by
                          providing relevant details or documents. Your joining
                          us is contingent upon a satisfactory report on the
                          background check relating to employment, experience,
                          work history, etc., conducted by a Shadow infosystem
                          approved agency.
                        </p>
                        <h2 className="ert678 heading">
                          Performance Management And Salary RevisionPerformance
                          Management And Salary Revision
                        </h2>
                        <p>
                          You will be eligible for semi-annual or quarterly
                          Performance Review of the respective year. Your career
                          and compensation progression will be based on
                          performance and company policies prevailing at that
                          point of time.
                        </p>
                        <h2 className="ert678 heading">Indemnity</h2>
                        <p>
                          The Employee will indemnify the Employer in respect of
                          any liability incurred by the Employer as a direct
                          consequence of the Employee’s negligence, breach of
                          contract, breach of duty or breach of trust in
                          relation to the affairs of the Employer.
                        </p>
                        <h2 className="ert678 heading">
                          Absences Due To Sickness Or Injury
                        </h2>

                        <ul className="sdnvdn">
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;In
                            the event of incapacity due to sickness or injury
                            the Employee must advise HR Manager not later than
                            [Time to report sick] on the first day of absence.
                            Full reasons must be given at this time.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;If
                            the Employee is absent for four days or less, he/she
                            shall immediately on returning to work complete a
                            self-certification form in respect of each day of
                            absence.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;If
                            the Employee is absent for more four days, he/she
                            must on the fifth day of absence provide the
                            Employer with a medical certificate from a General
                            Practitioner stating the reason for absence and
                            provide subsequent certificates to cover any
                            subsequent periods of absence.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;The
                            Employee will, at the expense of the Employer,
                            submit to a medical examination by a registered
                            medical practitioner nominated by the Employer and
                            shall provide blood, urine or other like specimens
                            for analysis if so requested.
                          </li>
                        </ul>
                        <h2 className="ert678 heading">Grievances</h2>
                        <p>
                          If the Employee has any grievance in relation to
                          his/her employment, he/she should raise it with Mrs.
                          Savita Verma (HR Manager) orally or in writing. For
                          further details of the grievance procedure applicable
                          to this employment, the Employee should refer to the
                          Director.
                        </p>
                        <h2 className="ert678 heading">Data Protection Act</h2>
                        <p>
                          The Employee consents to the holding and processing of
                          personal data provided by him/her to the Employer for
                          all purposes relating to this employment, but not
                          limited to administering and maintaining personnel
                          records, paying and reviewing salary and other
                          remuneration and benefits, undertaking performance
                          appraisals and reviews, maintaining sickness and other
                          absence records and taking decisions as to the
                          Employee’s fitness for work.
                        </p>
                        <p>
                          The Employee further acknowledges and agrees that the
                          Employer may, in the course of its duties as an
                          employer, be required to disclose personal data
                          relating to him/her, after the end of his/her
                          employment.
                        </p>
                        <h2 className="ert678 heading">Confidentiality</h2>
                        <p>
                          The Employee may not either during or at any time
                          after the termination of his/her employment with the
                          Employer disclose to anyone other than in the proper
                          course of his/her employment, any information of a
                          confidential nature relating to the Employer, the
                          Employer’s customers or suppliers and shall further
                          not use any such information in a manner which may
                          either directly or indirectly cause loss to the
                          Employer. Confidential information includes (but is
                          not limited to) financial information, commercial
                          information, technical information, sales and
                          marketing information, trade secrets and application
                          software not to be sale to others.
                        </p>
                        <h2 className="ert678 heading">Property</h2>
                        <p>
                          The Employee is required to read the policy and rules
                          and take the necessary steps to comply. Failure to
                          comply may result in disciplinary action and, in
                          serious cases, dismissal. The Employer has a detailed
                          IT, Communications and Monitoring Policy, a copy of
                          which is available from Shadow infosystem.The Employee
                          acknowledges that all files, customer records, lists,
                          books, records, literature, software, products and
                          work products developed by the Employee in the course
                          of his/her employment with the Employer, and other
                          materials owned by the Employer or used by the
                          Employer in connection with the conduct of business by
                          the Employer shall at all times remain the sole
                          property of the Employer, and the Employee agrees that
                          upon request and upon termination of the Employee’s
                          employment hereunder, howsoever arising, the Employee
                          shall surrender to the Employer all such files,
                          customer records, lists, books, records, literature,
                          products, software, work products, and any copies
                          thereof and all other property belonging to the
                          Employer.
                          <br />
                          <b>
                            In case have found missuses of company properties,
                            information and outsider working or freelance work,
                            company will take legal action against those who are
                            involved.
                          </b>
                        </p>
                        <h2 className="ert678 heading">Committed</h2>
                        <p>
                          As an employee working in SHADOW, the employee can’t
                          associate directly or indirectly with any other
                          organization and not firm any company those providing
                          common services. Download Employee Undertaking Paper
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default termContent;
