import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing Patient-Centric & Informative Website",
    description:
      "Creating a patient-centric and informative website for a dental clinic was a significant challenge. The website needed to provide clear information about services, treatments, and the clinic's expertise while being easy to navigate. We designed a site that balanced aesthetic appeal with functionality, incorporating features like online appointment booking, patient testimonials, service descriptions, and educational content. Ensuring the website was mobile-friendly and accessible was also crucial, given the increasing number of patients using mobile devices to access information.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Identifying and Targeting Local SEO Keywords",
    description:
      "Finding and targeting the right keywords in the dental industry, particularly for local SEO, was challenging. We performed extensive keyword research to identify high-impact terms and phrases that potential patients were using. This required a strategic approach to balance high-search volume and low-competition keywords, focusing on location-specific terms, dental services, and patient needs. Local SEO was particularly important for attracting patients in the clinic's geographical area and improving search engine rankings.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Building a Robust Local Backlink Profile",
    description:
      "Acquiring high-quality backlinks, particularly from local sources, was essential for improving the website’s authority and search engine rankings. We faced the challenge of developing a comprehensive outreach strategy to build relationships with relevant industry websites, dental blogs, local news outlets, and community organizations. Creating valuable, shareable content and securing credible backlinks required persistent effort and strategic planning to enhance the website’s credibility and visibility.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Adapting to Algorithm Changes and SEO Trends",
    description:
      "The SEO landscape is constantly evolving, with frequent updates to search engine algorithms and industry best practices. We faced the challenge of staying up-to-date with these changes and adapting their strategies accordingly. This required continuous learning, regular performance analysis, and agile adjustments to the SEO and SMO plans. Staying ahead of competitors and maintaining high search engine rankings demanded a proactive approach to understanding and implementing the latest SEO and social media optimization best practices.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
