import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import WebBanner from './../Elements/CustomSoftwareDevelopment/WebBanner';
import WebWebSiteTech from './../Elements/CustomSoftwareDevelopment/WebWebSiteTech';
import WebWebSiteProcess from './../Elements/CustomSoftwareDevelopment/WebWebSiteProcess';
import WebTypeDesign from './../Elements/CustomSoftwareDevelopment/WebTypeDesign';
import WebAbout from './../Elements/CustomSoftwareDevelopment/WebAbout';
import WebWhyChooseUs from './../Elements/CustomSoftwareDevelopment/WebWhyChooseUs';
import Webclient from './../Elements/CustomSoftwareDevelopment/Webclient';
import Testimonials from './../Elements/Testimonials';
import WebCustomPlan from './../Elements/CustomSoftwareDevelopment/WebCustomPlan';
import CommenEnquiry from './../Elements/CommenEnquiry';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <WebBanner/>
                    <WebAbout/>
                    <WebTypeDesign />
                    {/*<WebWebSiteProcess />*/}
                    <WebWhyChooseUs />
                    <Webclient /> 
                    <CommenEnquiry />
                    <Testimonials/>
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;