import React from 'react';
import { NavLink } from 'react-router-dom';
import TypeDesign from './TypeDesign';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const clientele = [
  {
    id: 1,
    image: require("./../../images/client/webdesign/vital.jpg"),
    items: ["UK", "SGP", "IND"],
  },

  {
    id: 2,
    image: require("./../../images/client/webdesign/sara.jpg"),
    items: ["Canada"],
  },

  {
    id: 3,
    image: require("./../../images/client/webdesign/ssr.jpg"),
    items: ["USA"],
  },

  {
    id: 4,
    image: require("./../../images/client/webdesign/yocarz.jpg"),
    items: ["IND"],
  },

  {
    id: 5,
    image: require("./../../images/client/webdesign/aparna.jpg"),
    items: ["IND", "UAE"],
  },

  {
    id: 6,
    image: require("./../../images/client/webdesign/santosh.jpg"),
    items: ["IND"],
  },

  {
    id: 7,
    image: require("./../../images/client/webdesign/dentalkraft.jpg"),
    items: ["IND"],
  },

  {
    id: 8,
    image: require("./../../images/client/webdesign/4qt.jpg"),
    items: ["IND"],
  },

  {
    id: 9,
    image: require("./../../images/client/webdesign/nail_rituals.jpg"),
    items: ["IND"],
  },

  {
    id: 10,
    image: require("./../../images/client/webdesign/vedam.jpg"),
    items: ["IND"],
  },

  {
    id: 11,
    image: require("./../../images/client/webdesign/engrever.jpg"),
    items: ["IND"],
  },

  {
    id: 12,
    image: require("./../../images/client/webdesign/av.jpg"),
    items: ["IND"],
  },

  {
    id: 13,
    image: require("./../../images/client/webdesign/roadload.jpg"),
    items: ["IND"],
  },

  {
    id: 14,
    image: require("./../../images/client/webdesign/david.jpg"),
    items: ["Canada"],
  },

  {
    id: 15,
    image: require("./../../images/client/webdesign/greenvent.jpg"),
    items: ["IND"],
  },

  {
    id: 16,
    image: require("./../../images/client/webdesign/shrimahalaxmi.jpg"),
    items: ["IND"],
  },

  {
    id: 17,
    image: require("./../../images/client/webdesign/tatva.jpg"),
    items: ["IND"],
  },

  {
    id: 18,
    image: require("./../../images/client/webdesign/trishul.jpg"),
    items: ["IND"],
  },
];

var bgimg1 = require('./../../images/banner/web-development-banner.jpg');
var bgimg2 = require('./../../images/background/cross-line2.png');
var bgimg3 = require('./../../images/background/home-banner.jpg');

class ClientsLogo1 extends React.Component {
  componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {

        return (
          <>
            <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1" style={{ backgroundImage: "url(" + bgimg1 + ")" }} >
              <div className="container ">
                {/* TITLE START */}
                <div className="row d-inline-flex">
                  <div className="col-md-6">
                    <div className="section-head mnbv123">
                      <div className="sx-separator-outer ">
                        <div className="bg-repeat-x color-change ewgr8900">
                          <h3 className="ert678">
                           Transforming Ideas into Stunning Web Realities
                          </h3>
                          <p>Our specialty is to design custom, interactive websites with rich features tailored to your needs. Let us create your digital presence!</p>
                        </div>
                      </div>
                       <TypeDesign/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mnbv1231">
                      <img src={require("./../../images/coding-gif.gif")} alt="" />
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
              </div>
            </div>
            <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
            <div className="container-fluid">
            <div className="section-content">
            <div className="container">
                <div className="section-head">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="m-b30 bg-white">
                                <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                    <img src={require("./../../images/locationpagesimages/computer_data_analysis_company_business.png")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="sx-separator-outer separator-left">
                                <div className="ertyui">
                                    <h1 className="ert678 heading">Best Web Development Services in Delhi</h1>

                                    <p>Shadow Infosystem, acclaimed as the premier website development company in Delhi, stands as a beacon of excellence in crafting digital solutions that empower businesses to thrive online. With a distinguished history of innovation and unwavering customer satisfaction, we offer an extensive range of <b>Web Development Services in Delhi</b> tailored to meet diverse needs.</p>
                                    <p>Our team of seasoned professionals brings unparalleled expertise and creativity to every project, ensuring exceptional quality and functionality. From dynamic websites to intricate e-commerce platforms, we harness cutting-edge technologies and industry best practices to deliver bespoke solutions that seamlessly align with your brand identity and objectives.</p>
                                    <p>Setting ourselves apart is our steadfast commitment to client satisfaction. We prioritize clear communication, transparency, and collaboration, ensuring our clients remain engaged at every stage of the development process.</p>
                                 </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            <p> Whether you're a startup, SME, or enterprise, we provide personalized attention and dedicated support to transform your vision into reality.</p>
                            <p>Recognizing the critical importance of a robust online presence in today's digital landscape, we go above and beyond to not just meet but exceed our client's expectations. Our mission is to deliver results that catalyze growth and success. Embark on your journey to digital excellence with Shadow Infosystem, the leading provider of <b>web development services in Delhi</b>. Your path to success begins here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser" style={{ backgroundImage: 'url(' + bgimg3 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x white-text">
                                    <h3 className="wfgrbwe ert678">Our Expertise in Website Development
Technologies</h3>
                                    <p>As the top website development company in Noida, we excel in crafting dynamic, responsive
websites that seamlessly integrate aesthetics and functionality, providing users with an
exceptional experience. Our team focuses on ensuring your online presence not only stands out
but also drives engagement and conversions for maximum impact in the digital landscape.</p>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                 <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                    <img className="technology-img" src={require('./../../images/WebsiteImage/Image-1.png')} alt="" />
                                        <div className="sx-post-info bg-white servdert-one1 website-deve">
                                          <div className="WhyChooseShadow">
                                                <img src={require('./../../images/icon/structure.png')} alt="" />
                                                <h4 className="post-title">React Js Development</h4>
                                            </div>
                                            <div className="">
                                                <p>Maximize your online impact with the robust capabilities of WordPress. Our development team crafts visually appealing, scalable websites that leverage the power of WordPress. The result is a compelling digital footprint that seamlessly combines user-friendly design with powerful features, creating a lasting and positive impression on your audience.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                    <img className="technology-img" src={require('./../../images//WebsiteImage/Image-2.png')} alt="" />
                                        <div className="sx-post-info bg-white servdert-one1 website-deve">
                                            <div className="WhyChooseShadow">
                                                <img src={require('./../../images/icon/node-js.png')} alt="" />
                                               <h4 className="post-title">Node JS Development</h4>
                                            </div>
                                            <div className="">
                                                <p>We excel in crafting dynamic, responsive websites that seamlessly integrate aesthetics and functionality, providing users with an exceptional experience. Our team focuses on ensuring your online presence not only stands out but also drives engagement and conversions for maximum impact in the digital landscape.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow "> 
                                    <img className="technology-img" src={require('./../../images//WebsiteImage/Image-4.png')} alt="" />
                                        <div className="sx-post-info  bg-white servdert-one1 website-deve">
                                            <div className="WhyChooseShadow">
                                                <img src={require('./../../images/icon/laravel-icon-497x512-uwybstke.png')} alt="" />
                                               <h4 className="post-title">Laravel Development</h4>
                                            </div>
                                            <div className="">
                                                <p>Take your web applications to new heights with our Laravel development expertise. We guarantee secure, high-performance solutions that establish a reliable foundation for your digital projects. Benefit from a framework that seamlessly combines efficiency, scalability, and modern development practices, ensuring optimal results for your web applications.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                    <img className="technology-img" src={require('./../../images//WebsiteImage/Image-5.png')} alt="" />
                                        <div className="sx-post-info  bg-white servdert-one1 website-deve">
                                            <div className="WhyChooseShadow">
                                                <img src={require('./../../images/icon/social-media.png')} alt="" />
                                               <h4 className="post-title">Codeigniter Development</h4>
                                            </div>
                                            <div className="">
                                                <p>Tailored to meet unique business needs, our custom development services offer innovative solutions that enhance operational efficiency and drive your digital initiatives forward. We empower you with a distinct advantage, delivering solutions that are precisely aligned with your strategic goals in the dynamic digital marketplace.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                    <img className="technology-img" src={require('./../../images//WebsiteImage/Image-3.png')} alt="" />
                                        <div className="sx-post-info  bg-white servdert-one1 website-deve">
                                            <div className="WhyChooseShadow">
                                                <img src={require('./../../images/icon/wordpress.png')} alt="" />
                                               <h4 className="post-title">Wordpress Development</h4>
                                            </div>
                                            <div className="">
                                                <p>With expertise in WordPress development, we craft dynamic, user-friendly websites precisely tailored to your needs. Our team seamlessly integrates design and functionality, guaranteeing a robust online presence that perfectly aligns with your brand and achieves your goals with precision</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                    <img className="technology-img" src={require('./../../images//WebsiteImage/Image-2.png')} alt="" />
                                        <div className="sx-post-info  bg-white servdert-one1 website-deve">
                                            <div className="WhyChooseShadow">
                                                <img src={require('./../../images/icon/cms.png')} alt="" />
                                               <h4 className="post-title">CMS Web Development</h4>
                                            </div>
                                            <div className="">
                                                <p>Simplify content management with our scalable and intuitive CMS solutions. Our platforms facilitate effortless website updates and management, ensuring dynamic, engaging, and easily maintainable digital experiences for your audience. Streamline your content processes and maintain a consistently captivating online presence with our advanced CMS solutions.</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                   
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12">
                                    <TypeDesign className="vndjn-popup" />
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title common-enq-bot1 p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
<div className="section-full  mobile-page-padding bacg-ser p-t80 p-b10 bg-repeat">
    <div className="container">
        {/* TITLE START */}
        <div className="section-head">
            <div className="sx-separator-outer separator-center">
                <div className="bg-repeat-x color-change">
                    <div className="ert678 heading">
                        Get A Free Website Audit & Consultation From Our Experts
                    </div>
                    <p>
                        Want to boost organic traffic and generate more leads? Contact us now to secure your place on Google's first page!
                    </p>
                </div>
                <div className="common-enq-bot">
                    <a href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello Shadow" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/whatsapp-button (1).png")} alt="" />
                    </a>
                    <span>OR</span>
                    <a href="tel:+91-88003 84880" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/Call-Now-Button.png")} alt="" />
                    </a>
                </div>
            </div>
        </div>
        {/* TITLE END */}
    </div>
</div>
<div className="section-full mobile-page-padding p-t80 p-b50 ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x">
                <h2 className="wfgrbwe ert678 heading text-white">
                    How Do We Work?
                </h2>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/online_business_analyst_data_computer.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Explore
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                The initial phase involves understanding your audience - their characteristics, needs, and how your offerings can meet those needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Analyze
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                A thorough evaluation of your website's current status - assessing its functionality, visitor engagement, and identifying any potential issues.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Strategize
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                We determine the optimal approach - evaluating the feasibility of targeting specific keywords, conducting necessary site optimizations, and considering strategies like guest posts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Evaluate
                                </h3>
                            </div>
                            </div>

                            <div className="">
                                <p>
                                    Early data analysis serves as the cornerstone of success - monitoring, and understanding how your strategies are performing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/vector-isometric-concept.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Report
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Comprehensive and detailed reporting to support your business's growth in the most effective directions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/smartphone_test_data_analysis.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Adapt
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Data-driven decision-making guides our actions - identifying areas requiring improvement, and determining strategies to progress based on successful outcomes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                    <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
            </div>
        </div>
    </div>
    <div className="hilite-title text-left p-l50 text-uppercase">
        <strong>Why Choose</strong>
    </div>
</div>

 <div className="section-full inner-page-padding p-b50 p-t80 ">
                    <div className="container-fluid">
                        <div className="section-content">
                            <div className="container">
                                {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="ertyui">
                                        <h3 className="ert678">Loved By 3000+ Customers All Over The World</h3>
                                    <h6>Our greatest asset is the customers and clients Satisfaction!</h6>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                                <div className="row">
                                    {clientele.map((item) => (
                      <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb__20">
                        <ul key={`item-${item.id}`} className="cl_container">
                          <div className="client_logo_img_box">
                            <div className="client_logo_img">
                              <img src={item.image} alt="client-logo" />
                            </div>
                          </div>

                          <div className="client_logo_title_container">
                            {item.items.map((country, index) => (
                              <li key={`country-${index}`}>{country}</li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    ))}
                                </div>
                                


                            </div>
                            </div>
                            


                        </div>

                    </div>
                </div>
                <div className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x  white-text">
                <h2 className="wfgrbwe ert678 heading">
                   Why Choose Shadow infosystem For Website Development Services?
                </h2>
                <p>At Shadow, we recognize the profound impact of excellent web development, where the perfect blend of colors, layout, and impeccable code converges to amplify leads and conversions. With us, you can be sure of working with</p>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content sfsfcsc13232">
            <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info  bg-white sdfgj56564 servdert-one2">
                            <div className="WhyChooseShadow">
                                <img src={require('./../../images/locationpagesimages/tick-with-pencil-illustration.png')} alt="" />
                                <h4 className="post-title">
                                    Experience & <br />
                                    Expertise
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Leverage our 15 years of website development expertise. Our experienced team brings a wealth of collective experience, ensuring your digital presence stays ahead with industry insights and mastery of
                                    evolving technologies for a lasting impact.
                                </p>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../images/locationpagesimages/determine_the_business_development_plan.png')} alt="" />
                                <h4 className="post-title">Professional & Creative Team</h4>
                            </div>

                            <div className="">
                                <p>
                                    Entrust your vision to a dedicated professional and creative team. Merging technical prowess with artistic flair, we deliver websites that meet functional needs and captivate audiences with visually
                                    stunning designs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png')} alt="" />
                                <h4 className="post-title">
                                    Agile <br />
                                    Methodology
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Embrace flexibility and efficiency with our agile development. We adapt to changing needs, ensuring a streamlined and collaborative approach. Stay involved, provide feedback, and witness real-time
                                    evolution through agile practices.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png')} alt="" />
                                <h4 className="post-title">
                                    Quality Assurance<br />
                                    Check
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Your satisfaction is our priority. Stringent quality checks guarantee a flawless final product. Meticulously inspecting functionality and performance, we ensure your website meets the highest standards
                                    before going live.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../images/locationpagesimages/smartphone_test_data_analysis.png')} alt="" />
                                <h4 className="post-title">
                                    User <br />
                                    Friendly
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Prioritize understanding your audience. Incorporating user-centric design, we create websites that resonate with your target audience. Focusing on user interests guarantees an engaging, satisfying online
                                    experience, enhancing your brand's digital presence.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png')} alt="" />
                                <h4 className="post-title">
                                    Timely Quality <br />
                                    Control
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Value your time with our commitment to timely quality control. Ensure your website not only meets deadlines but exceeds expectations. Trust us to deliver a well-designed, high-performing website, allowing
                                    you to focus on what matters most—your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
<div className="hilite-title text-left p-l50 text-uppercase">
    <strong>Services</strong>
</div>
</div>
<div className="page-content">
    <div className="section-full p-t80 p-b80 inner-page-padding ">
        {/*
        <div className="trtrtrtyuy"></div>
        */}
        <div className="container">
            <div className="faq-1">
                {/* TITLE START */}
                <div className="section-head">
                    <div className="sx-separator-outer separator-left">
                        <div className="">
                            <h3 className="ert678">Frequently Asked Questions</h3>
                        </div>
                    </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                    <div className="col-md-12">
                        {/* Accordian */}
                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                            <div className="panel sx-panel">
                                <div className="acod-head acc-actives">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                            Why Does Every Business Need A Website?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOne5" className="acod-body collapse show">
                                    <div className="acod-content p-a15">
                                        <p>Every business needs a website to establish credibility, reach a wider audience, and provide essential information. It serves as a 24/7 storefront, enhances brand visibility, fosters customer engagement, and enables convenient transactions, ultimately boosting sales and growth opportunities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                            How much does designing a website in Delhi NCR cost?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseTwo5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>The cost of designing a website in Delhi NCR can vary based on factors like complexity, features, and the expertise of the designer. It's advisable to consult with multiple professionals for accurate quotes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                           Why Shadow infosystem is the best website designing company in Delhi?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseThree5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Shadow infosystem stands out as the premier website designing company in Delhi due to its innovative designs, user-friendly interfaces, robust functionality, and unmatched customer satisfaction. With a talented team and a commitment to excellence, it consistently delivers top-notch results.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                           What Should You Avoid When Developing a Search-Optimized Website?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseFour5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>When developing a search-optimized website, avoid keyword stuffing, neglecting mobile optimization, ignoring user experience, and overlooking the importance of quality content. These pitfalls can hinder visibility, user engagement, and ultimately, the effectiveness of your website in search engine results.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                            What is E-commerce Website Development?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseFive5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Developing e-commerce websites entails setting up online storefronts for companies to offer goods and services. It encompasses designing user interfaces, integrating secure payment gateways, managing inventory, and ensuring smooth functionality for a seamless online shopping experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    {/* SECTION CONTENT END */}
</div>




          </>
        );
    }
};

export default ClientsLogo1;