import React from "react";
import { NavLink } from "react-router-dom";

import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

function PartnerBanner() {
  return (
    <>
      <div
        className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div className="container ">
          {/* TITLE START */}
          <div className="row d-inline-flex">
            <div className="col-md-7">
              <div className="section-head mnbv123">
                <div className="sx-separator-outer ">
                  <div className="bg-repeat-x color-change ewgr8900">
                    <h3 className="ert678">
                      Sometimes the Best Results Begin with Partnership
                    </h3>
                    <p>Grow Organically on Google's First Page</p>
                  </div>
                </div>
                <TypeDesign />
              </div>
            </div>
            <div className="col-md-5">
              <div className="mnbv1231">
                <img
                  src={require("./../../../images/partner/partnerBanner.webp")}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* TITLE END */}
        </div>
      </div>
    </>
  );
}

export default PartnerBanner;
