import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 web-e-comm ertyu45 bacg-ser"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <h3 className="wfgrbwe ert678 mb-3">Our Core Technologies</h3>
                  <p>
                    We think big and have hands in all leading technology
                    platforms to provide you wide array of services.
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-1.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/icon/structure.png")}
                          alt=""
                        />
                        <h4 className="post-title">React JS</h4>
                      </div>

                      <div className="">
                        <p>
                          Boost your online presence with our expertise in
                          React.js. From intuitive user interfaces to seamless
                          interactive experiences, we harness the power of
                          React.js to create engaging eCommerce websites and web
                          applications tailored to your unique needs. We bring
                          your vision to life.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/icon/node-js.png")}
                          alt=""
                        />
                        <h4 className="post-title">Node JS</h4>
                      </div>

                      <div className="">
                        <p>
                          Get the power of scalable and efficient server-side
                          development with our proficiency in Node.js. From
                          real-time applications to backend APIs, we leverage
                          Node.js to create robust solutions that drive your
                          business forward with agility and reliability.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-4.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/icon/laravel-icon-497x512-uwybstke.png")}
                          alt=""
                        />
                        <h4 className="post-title">Laravel</h4>
                      </div>

                      <div className="">
                        <p>
                          Get the versatility and robustness of Laravel with our
                          expert development services. From custom web
                          applications to powerful backend systems, we leverage
                          elegance and efficiency to deliver tailored solutions
                          that meet your business objectives with precision and
                          reliability.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-5.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/icon/wordpress.png")}
                          alt=""
                        />
                        <h4 className="post-title">Wordpress</h4>
                      </div>

                      <div className="">
                        <p>
                          With expertise in WordPress development, we craft
                          dynamic, user-friendly websites precisely tailored to
                          your needs. Our team seamlessly integrates design and
                          functionality, guaranteeing a robust online presence
                          that perfectly aligns with your brand and achieves
                          your goals with precision
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-3.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/icon/shopify1.png")}
                          alt=""
                        />
                        <h4 className="post-title">Shopify</h4>
                      </div>

                      <div className="">
                        <p>
                          Harness the power of Shopify with our specialized
                          services. From e-commerce setup to customization, we
                          navigate the intricacies of the platform, ensuring
                          your online store stands out. Trust us to optimize
                          your Shopify experience for maximum performance and
                          customer engagement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/icon/magento (1).png")}
                          alt=""
                        />
                        <h4 className="post-title">Magento</h4>
                      </div>

                      <div className="">
                        <p>
                          Elevate your online business with our Magento
                          expertise. We bring proficiency in developing scalable
                          and feature-rich e-commerce solutions. Our commitment
                          is to empower your brand with a Magento platform that
                          enhances user experience and drives business growth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
