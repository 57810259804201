import React from "react";
import { NavLink } from "react-router-dom";

class EnquireSection extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding bacg-ser ptb-50 bg-repeat">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head m-0">
              <div className="sx-separator-outer separator-center">
                <div className="bg-repeat-x color-change">
                  <h1 className="ert678">
                    Let's Code Your Success Story Together
                  </h1>
                  <p>
                    Get in touch with one of our skilled developers to begin
                    receiving the best design and development services for your
                    ultimate website.
                  </p>
                </div>
                <div className="common-enq-bot pb-0">
                  <a
                    href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello Shadow"
                    target="_blank"
                    className=""
                  >
                    <img
                      className="wdmwoncc019281"
                      src={require("../../../images/icon/whatsapp-button (1).png")}
                      alt=""
                    />
                  </a>
                  <span>OR</span>
                  <a href="tel:+91-88003 84880" target="_blank" className="">
                    <img
                      className="wdmwoncc019281"
                      src={require("../../../images/icon/Call-Now-Button.png")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default EnquireSection;
