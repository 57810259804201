import React from 'react';


var bnrimg = require('./../../../images/banner/7.jpg');

class Faq extends React.Component {
    render() {
        return (
            <>
                
                <div className="page-content">
                    
                    <div className="section-full p-t80 p-b80 inner-page-padding ">
                    
                    {/*<div className="trtrtrtyuy"></div>*/}
                        <div className="container">
                            <div className="faq-1">
                                 <div className="section-head">
                                    <div className="sx-separator-outer separator-left">
                                        <div className="">
                                            <h3 className="ert678">Website Design & Development FAQs</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    
                                     <div className="col-md-12">
                                     
                                        {/* Accordian */}
                                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                           What Is The Cost Of Web Design Services?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15">
                                                        <p>Our website design packages cater to both small and large businesses. If you're unsure about the suitable package or require a customized quote, feel free to contact us for assistance.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            Do you provide support services after the website development is complete?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Yes, we provide post-development support services. Upon project completion, you will receive website maintenance, including text-related updates such as additions, deletions, and modifications. This ensures that your website remains dynamic and up-to-date.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                          Do I have to host my website with you?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>You are not required to host your website with us. We're open to crafting a website for you from scratch or undertaking the redesign of an existing site.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                            Are your websites SEO friendly?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFour5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Our websites are built with strong technical foundations, are responsive, and offer an excellent user experience. They are designed to be SEO-friendly and come equipped with implemented SEO tools. For more advanced SEO services like keyword research and content optimization, kindly consider enrolling in our dedicated SEO services.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                           Do you offer e-commerce website packages?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive5" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Yes! Shadow infosystem provides a comprehensive range of Ecommerce solutions. Our developers possess vast expertise in widely-used Ecommerce and CMS platforms like WordPress, Magento, and more. We will construct your website on the platform you prefer, ensuring support for essential Ecommerce functionalities including user registration and management, shopping cart, product search, and payment gateway.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive11" className="collapsed" data-parent="#accordion5">
                                                          I want to redesign my existing website design. Can you help?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive11" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Yes, we provide website redesign services for those looking to revamp the appearance of their websites. We assess the issues in your current website and carefully comprehend your goals and objectives to propose a suitable website design solution. Explore our services for website redesigning.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive12" className="collapsed" data-parent="#accordion5">
                                                           What is the difference between a “static” and “dynamic” website?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive12" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Static websites present identical content to all visitors through a pre-coded HTML file, making them simpler, faster, and more cost-effective. On the other hand, dynamic websites interactively serve varied content to users, even when accessing the same URL. These are typically written in a server-side scripting language like PHP or ASP. For instance, Ecommerce websites may display prices in different currencies based on customers' IP addresses. The majority of static sites utilize a Content Management System (CMS) that enables internal users to make updates and modifications.</p>
                                                    <p>The decision to opt for a static or dynamic website hinges on factors such as the website's size, the managing team, update frequency, and budget considerations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive13" className="collapsed" data-parent="#accordion5">
                                                          Can I see some samples of website design?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive13" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Yes, take a look at our portfolio showcasing our website design projects.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive14" className="collapsed" data-parent="#accordion5">
                                                           How much does a website cost?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive14" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Determining the cost of your website design involves taking various factors into account. For a precise estimate, please review a comprehensive package detailing the services provided.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive15" className="collapsed" data-parent="#accordion5">
                                                           How long does it take to design or develop a website?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive15" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Crafting a website is a time-consuming endeavor that involves three essential steps. These include collecting requirements, choosing a website development company, and ultimately constructing the website. It is crucial to ensure that each step is executed accurately and within the designated timeframe.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    {/* TITLE START */}
                               
                                {/* TITLE END */}
                                                                             
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                
            </>
        );
    };
};

export default Faq;