import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <h3 className="wfgrbwe ert678 mb-3">
                    Boost your business to the next level with our website
                    development services
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-1.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/structure.png")}
                          alt=""
                        />
                        <h4 className="post-title">React JS Development</h4>
                      </div>
                      <div className="">
                        <p>
                          We specialize in harnessing the capabilities of
                          React.JS to craft innovative solutions tailored to
                          your website requirements. Our team adeptly utilizes
                          this technology component-based architecture for
                          dynamic and scalable applications. With seamless UI
                          rendering and streamlined data flow, we deliver
                          top-notch performance and user experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/node-js.png")}
                          alt=""
                        />
                        <h4 className="post-title">Node.js Development</h4>
                      </div>
                      <div className="">
                        <p>
                          We utilize Node.js technology to provide cutting-edge
                          solutions. We craft seamless applications that meet
                          our clients' needs. By leveraging Node.js, we offer
                          lightning-fast responses, empowering smooth user
                          experiences. From dynamic web apps to scalable APIs,
                          we integrate Node.js to deliver agile and reliable
                          solutions, ensuring unparalleled functionality for our
                          clients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-4.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/laravel-icon-497x512-uwybstke.png")}
                          alt=""
                        />
                        <h4 className="post-title">Laravel Development</h4>
                      </div>
                      <div className="">
                        <p>
                          Take your web applications to new heights with our
                          Laravel development expertise. We guarantee secure,
                          high-performance solutions that establish a reliable
                          foundation for your digital projects. Benefit from a
                          framework that seamlessly combines efficiency,
                          scalability, and modern development practices,
                          ensuring optimal results for your web applications.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-5.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/social-media.png")}
                          alt=""
                        />
                        <h4 className="post-title">Codeigniter Development</h4>
                      </div>
                      <div className="">
                        <p>
                          Tailored to meet unique business needs, our custom
                          development services offer innovative solutions that
                          enhance operational efficiency and drive your digital
                          initiatives forward. We empower you with a distinct
                          advantage, delivering solutions that are precisely
                          aligned with your strategic goals in the dynamic
                          digital marketplace.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-3.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/wordpress.png")}
                          alt=""
                        />
                        <h4 className="post-title">Wordpress Development</h4>
                      </div>
                      <div className="">
                        <p>
                          With expertise in WordPress development, we craft
                          dynamic, user-friendly websites precisely tailored to
                          your needs. Our team seamlessly integrates design and
                          functionality, guaranteeing a robust online presence
                          that perfectly aligns with your brand and achieves
                          your goals with precision
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1 website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/cms.png")}
                          alt=""
                        />
                        <h4 className="post-title">CMS Web Development</h4>
                      </div>
                      <div className="">
                        <p>
                          Simplify content management with our scalable and
                          intuitive CMS solutions. Our platforms facilitate
                          effortless website updates and management, ensuring
                          dynamic, engaging, and easily maintainable digital
                          experiences for your audience. Streamline your content
                          processes and maintain a consistently captivating
                          online presence with our advanced CMS solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div className="hilite-title common-enq-bot1 p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
