import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/smo-about.webp")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h1 className="ert678">
                            Accelerate Your Business with Social Media
                            Strategies
                          </h1>
                          <p>
                            Shadow infosystem excels as a premier social media
                            marketing agency, dedicated to empowering small
                            businesses, medium enterprises, and renowned brands
                            in navigating the complexities of the digital
                            marketing landscape. Our specialization encompasses
                            a comprehensive suite of services including social
                            media management, social listening and response
                            management, Google advertisements, and an array of
                            other offerings. Our overarching objective revolves
                            around facilitating revenue maximization through
                            enhanced sales conversions, while also strategizing
                            the influx of prospective customers primed for
                            purchase through time-tested social media marketing
                            methodologies.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <p>
                            Leveraging the unparalleled potential of social
                            media platforms serves as the ideal medium for
                            engaging with your target audience and fostering
                            reciprocal conversations. Shadow infosystem, a
                            distinguished leader in the realm of social media
                            marketing, possesses the requisite expertise,
                            proficiency, and acumen to craft bespoke strategies
                            tailored to fortify customer retention by
                            consistently furnishing them with compelling and
                            relevant content.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
