import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
            animateOut: "fadeOut",
        };
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x">
                                    <h3 className="wfgrbwe ert678">Why Choose Shadow</h3>
                                    <p>We can help you get the edge over your competition by providing affordable, engaging, and well-optimized web content.</p>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            <div className=" WhyChooseShadow">
                                                <img className="iocn1234" src={require("./../../../images/icon/together.png")} alt="" />
                                                <h4 className="post-title">Expert Writers</h4>
                                            </div>

                                            <div className="">
                                                <p>An excellent blog post begins with a strong premise. By partnering with us, you’ll get a top-quality blog written and published just in time for your target audience to read it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            <div className=" WhyChooseShadow">
                                                <img className="iocn1234" src={require("./../../../images/icon/team-support.png")} alt="" />
                                                <h4 className="post-title">Premium quality content</h4>
                                            </div>

                                            <div className="">
                                                <p>An excellent blog post begins with a strong premise. By partnering with us, you’ll get a top-quality blog written and published just in time for your target audience to read it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            <div className=" WhyChooseShadow">
                                                <img className="iocn1234" src={require("./../../../images/icon/good-review.png")} alt="" />
                                                <h4 className="post-title">Fast delivery</h4>
                                            </div>

                                            <div className="">
                                                <p>An excellent blog post begins with a strong premise. By partnering with us, you’ll get a top-quality blog written and published just in time for your target audience to read it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        <div className="sx-post-info  bg-white sdfgj56564">
                                            <div className=" WhyChooseShadow">
                                                <img className="iocn1234" src={require("./../../../images/icon/reliable.png")} alt="" />
                                                <h4 className="post-title">Multiple revisions</h4>
                                            </div>

                                            <div className="">
                                                <p>An excellent blog post begins with a strong premise. By partnering with us, you’ll get a top-quality blog written and published just in time for your target audience to read it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            <div className=" WhyChooseShadow">
                                                <img className="iocn1234" src={require("./../../../images/icon/trust.png")} alt="" />
                                                <h4 className="post-title">Budget-friendly</h4>
                                            </div>

                                            <div className="">
                                                <p>An excellent blog post begins with a strong premise. By partnering with us, you’ll get a top-quality blog written and published just in time for your target audience to read it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            <div className=" WhyChooseShadow">
                                                <img className="iocn1234" src={require("./../../../images/icon/customer-service.png")} alt="" />
                                                <h4 className="post-title">High conversion</h4>
                                            </div>

                                            <div className="">
                                                <p>An excellent blog post begins with a strong premise. By partnering with us, you’ll get a top-quality blog written and published just in time for your target audience to read it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="werty343252">
                                        <h3>Let's Start a New Project Together</h3>
                                        <NavLink to="" className="">
                                            Inquire Now <span class="circle"></span>{" "}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <g fill="none" fill-rule="evenodd">
                                                    <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"></path>
                                                    <path fill="white" d="M16.06 10.94a1.5 1.5 0 0 1 0 2.12l-5.656 5.658a1.5 1.5 0 1 1-2.121-2.122L12.879 12L8.283 7.404a1.5 1.5 0 0 1 2.12-2.122l5.658 5.657Z"></path>
                                                </g>
                                            </svg>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Why Choose</strong>
                    </div>
                </div>
            </>
        );
    }
}

export default Blog2;
