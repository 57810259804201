import React from 'react';
import Header2 from './../Common/Header3';
import Footer2 from './../Common/Footer2';
import LASbanner from './../Elements/LifeatShadow/LASbanner';
import LASAboutUs from './../Elements/LifeatShadow/LASAboutUs';



var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                   <LASbanner/> 
                   <LASAboutUs/>
                   {/*<CSRWhatWeDo/>
                   <CSRFAQ/>*/}
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;