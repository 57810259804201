import React from "react";

function banner() {
  return (
    <>
      <div className="section-full mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
        <div className="container">
          <div className="row">
            <div className="col-md-12 sx-separator-outer separator-center">
              <div className="case-banner">
                <h1>Sara Consultancy</h1>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
              <div className="case-logo">
                <img
                  src={require("./../../../images/sara/sara.webp")}
                  className=""
                  alt="Shadow"
                />
              </div>
              <div className="mxnv046">
                <h5 className="dfghj876543">Sara Consultancy</h5>
                <ul className="scnv-case">
                  <li>
                    <b>Location:</b> Canada, North America
                  </li>
                  <li>
                    <b>Services:</b> International Consultancy
                  </li>
                  <li>
                    <b>Website:</b>{" "}
                    <a
                      href="https://saraconsultancy.com/"
                      class="text-white"
                      target="_blank"
                    >
                      www.saraconsultancy.com/
                    </a>
                  </li>
                </ul>
                <div className="full-social-bg">
                  <h6 className="m-t0">Follow US: </h6>
                  <ul className="ertyupoi">
                    <li>
                      <a
                        href="https://www.facebook.com/saraconsultancy1"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/saraconsultancy1/"
                        className="instagram"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/sara-consultancy/"
                        className="tumblr"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/SaraConsultancy"
                        className="twitter"
                        target="_blank"
                      >
                        <img
                          src={require("./../../../images/twitter (1).png")}
                          className="county-logo1"
                          alt="Shadow infosystem"
                        />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.youtube.com/@saraconsultancy"
                        target="_blank"
                        className="youtube"
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 sx-separator-outer">
              <div className="case-web-banner">
                <img
                  src={require("./../../../images/sara/mobile.webp")}
                  className="phone-banner"
                  alt="Shadow"
                />
                <img
                  src={require("./../../../images/sara/desktop.webp")}
                  className="web-banner"
                  alt="Shadow"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="qpqq">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="m-b30 bg-white">
                    <h2 class="ert678">Introduction</h2>
                    <p>
                      Sara Consultancy is a business consultancy, led by Sara,
                      who has worked across three continents, namely, North
                      America, Asia, and Europe. With a master’s degree in Law
                      and Ph.D. in Politics, she has more than 16 years of
                      experience, in rebuilding and revitalizing businesses
                      across the world.
                    </p>
                    <p>
                      Prior to founding Sara Consultancy, she worked with
                      numerous mega giants across the globe, as a Professor,
                      Finance and Business Manager, Business Operations Head and
                      Legal Consultant. Now, she has embarked on a journey to
                      provide excellent business solutions to revamp failing
                      businesses and provide support to startups and new
                      businesses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default banner;
