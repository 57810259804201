import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import CyberSecuirityBanner from "./../Elements/CyberSecuirity/CyberSecuirityBanner";
import CyberSecuirityServices from "./../Elements/CyberSecuirity/CyberSecuirityServices";
import ClientsLogo2 from "./../Elements/ClientsLogo2";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import CommenEnquiry2 from "./../Elements/CyberSecuirity/CommenEnquiry";
import CyberOnline from "./../Elements/CyberSecuirity/CyberOnline";
import { Helmet } from "react-helmet";
import Testimonials from "./../Elements/Testimonials";

var bnrimg = require("./../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Cyber Security Services Provider Company - Shadow infosystem
          </title>
          <meta
            name="description"
            content="Shadow infosystem, top cyber security services provider to safeguard your digital assets. Enhance your defense against cyber threats with our expertise"
          />
          <meta
            name="keywords"
            content="Cyber security services provider company"
          />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/cyber-secuirity"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <CyberSecuirityBanner />

          <CyberSecuirityServices />
          {/*<CommenEnquiry />*/}
          <CyberOnline />
          <CommenEnquiry2 />
          <Testimonials />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
