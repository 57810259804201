import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/wm-2.webp")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h3 className="ert678">
                              Benefits of Website Maintenance
                            </h3>
                            <p>
                              Website maintenance is crucial for sustained
                              success. Regular updates and security patches
                              enhance performance, ensuring a seamless user
                              experience. Content updates keep information
                              relevant, improving search engine rankings.{" "}
                            </p>
                            <p>
                              Ongoing monitoring helps identify and address
                              issues promptly, minimizing downtime. Security
                              measures protect against cyber threats,
                              safeguarding sensitive data. It also supports the
                              integration of new features and technologies,
                              keeping your site modern and competitive.
                            </p>
                            <p>
                              In essence, a well-maintained website not only
                              attracts and retains visitors but also fortifies
                              your online presence, fostering trust and
                              longevity.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
