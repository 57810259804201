import React from 'react';


var bnrimg = require('./../../../images/banner/7.jpg');

class Faq extends React.Component {
    render() {
        return (
          <>
            <div className="page-content">
              <div className="section-full p-t80 p-b80 inner-page-padding ">
                {/*<div className="trtrtrtyuy"></div>*/}
                <div className="container">
                  <div className="faq-1">
                    {/* TITLE START */}
                    <div className="section-head">
                      <div className="sx-separator-outer separator-left">
                        <div className="">
                          <h3 className="ert678">FAQs</h3>
                        </div>
                      </div>
                    </div>
                    {/* TITLE END */}
                    <div className="row">
                      <div className="col-md-6">
                        {/* Accordian */}
                        <div
                          className="sx-accordion acc-bg-gray"
                          id="accordion5"
                        >
                          <div className="panel sx-panel">
                            <div className="acod-head acc-actives">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseOne5"
                                  data-parent="#accordion5"
                                >
                                  How do I know if I need SEO services?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseOne5"
                              className="acod-body collapse show"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  Identifying the need for SEO services involves
                                  assessing your online visibility and organic
                                  traffic. If you aim to improve website
                                  ranking, attract more visitors, and enhance
                                  brand recognition, SEO services can be
                                  beneficial.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel sx-panel">
                            <div className="acod-head">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseTwo5"
                                  className="collapsed"
                                  data-parent="#accordion5"
                                >
                                  How do I choose an SEO agency?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseTwo5"
                              className="acod-body collapse"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  When selecting an SEO agency, consider their
                                  experience, track record, client testimonials,
                                  and transparency in their methods. Look for
                                  agencies that align with your goals and offer
                                  customized strategies.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel sx-panel">
                            <div className="acod-head">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseThree5"
                                  className="collapsed"
                                  data-parent="#accordion5"
                                >
                                  How long will it take to see the effects of
                                  SEO?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseThree5"
                              className="acod-body collapse"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  The effects of SEO vary based on multiple
                                  factors such as website status, competition,
                                  and chosen keywords. Typically, noticeable
                                  results take around 4 to 6 months, while
                                  substantial improvements might take 6 to 12
                                  months.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel sx-panel">
                            <div className="acod-head">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseFour5"
                                  className="collapsed"
                                  data-parent="#accordion5"
                                >
                                  How often do search engines make updates that
                                  affect SEO?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseFour5"
                              className="acod-body collapse"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  Search engines constantly evolve, updating
                                  algorithms hundreds of times yearly. Major
                                  updates occur less frequently, but staying
                                  updated with industry changes is crucial for
                                  maintaining SEO effectiveness.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel sx-panel">
                            <div className="acod-head">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseFive5"
                                  className="collapsed"
                                  data-parent="#accordion5"
                                >
                                  Are SEO Services worth it?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseFive5"
                              className="acod-body collapse"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  SEO services can significantly impact online
                                  visibility, traffic, and revenue. The return
                                  on investment (ROI) often justifies the cost,
                                  but success depends on strategy,
                                  implementation, and industry competitiveness.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* Accordian */}
                        <div
                          className="sx-accordion acc-bg-gray"
                          id="accordion5"
                        >
                          <div className="panel sx-panel">
                            <div className="acod-head acc-actives">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseOne52"
                                  data-parent="#accordion5"
                                >
                                  Do I need a different SEO strategy for
                                  different search engines?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseOne52"
                              className="acod-body collapse show"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  Tailoring strategies for different search
                                  engines like Google, Bing, or Yahoo is
                                  essential. Each engine has unique ranking
                                  factors, requiring customized approaches to
                                  maximize visibility and traffic.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel sx-panel">
                            <div className="acod-head">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseTwo35"
                                  className="collapsed"
                                  data-parent="#accordion5"
                                >
                                  How much should I budget for SEO?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseTwo35"
                              className="acod-body collapse"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  SEO service budgets differ according to
                                  objectives, market competition, and project
                                  scale. Discuss your specific needs with our
                                  Sales team, enabling us to tailor a strategy
                                  aligned with your goals. Initiate the
                                  conversation with us to progress in the right
                                  direction.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel sx-panel">
                            <div className="acod-head">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseThree25"
                                  className="collapsed"
                                  data-parent="#accordion5"
                                >
                                  What is the right time to hire an SEO agency?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseThree25"
                              className="acod-body collapse"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  Hiring an SEO agency is ideal during website
                                  launches, redesigns, or when organic traffic
                                  and rankings decline. It's essential to have
                                  an SEO strategy in place at the earliest stage
                                  of your online presence.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel sx-panel">
                            <div className="acod-head">
                              <h4 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseFour15"
                                  className="collapsed"
                                  data-parent="#accordion5"
                                >
                                  Is it important to choose an SEO agency with
                                  specific capabilities?
                                  <span className="indicator">
                                    <i className="fa fa-plus" />
                                  </span>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapseFour15"
                              className="acod-body collapse"
                            >
                              <div className="acod-content p-a15">
                                <p>
                                  Selecting an SEO agency with specific
                                  capabilities relevant to your industry, such
                                  as local SEO, e-commerce expertise, or content
                                  marketing skills, ensures a more tailored and
                                  effective strategy for your business's
                                  success.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SECTION CONTENT END */}
            </div>
          </>
        );
    };
};

export default Faq;