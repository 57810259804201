import React from "react";
import Header2 from "./../../Common/Header3";
import Footer2 from "./../../Common/Footer2";
import Banner from "./../../Elements/CaseStudyMyDentalKraft/Banner";
import Innovation from "./../../Elements/CaseStudyMyDentalKraft/Innovation";
import Overview from "./../../Elements/CaseStudyMyDentalKraft/Overview";
import Techstack from "./../../Elements/CaseStudyMyDentalKraft/Techstack";
import Challenges from "./../../Elements/CaseStudyMyDentalKraft/Challenges";
import Results from "./../../Elements/CaseStudyMyDentalKraft/Results";
import Social from "./../../Elements/CaseStudyMyDentalKraft/Social";
import ClientsReview from "./../../Elements/CaseStudyMyDentalKraft/ClientReview";
import Solution from "./../../Elements/CaseStudyMyDentalKraft/Solutions";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../../images/banner/5.jpg");

function Case5() {
  return (
    <div>
      {" "}
      <>
        <Helmet>
          <title>Case Study | My Dental Kraft</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/case-study-mydentalkraft"
          />
        </Helmet>
        <Header2 />
        <div className="page-content case__study__page">
          <Banner />
          <Overview />
          <Challenges />
          <Solution />
          <Results />
          <Social />
          <Techstack />
          {/* <Innovation /> */}
          <ClientsReview />
        </div>

        <Footer2 />
      </>
    </div>
  );
}

export default Case5;
