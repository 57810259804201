import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/projects/342 [Converted]ttt-01.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h1 className="ert678">
                              Get Our E-commerce Website Design to Engage Your
                              Users
                            </h1>
                            <p>
                              Striving for excellence, we collaborate with
                              cutting-edge technology platforms to deliver a
                              versatile array of services. Our proficiency
                              extends across diverse domains, guaranteeing
                              tailored solutions for your distinct needs and
                              challenges. With an unwavering commitment to
                              innovation, we provide comprehensive and
                              forward-thinking strategies. Whether it's
                              pioneering solutions or addressing intricate
                              challenges, we are dedicated to exceeding
                              expectations. Choose us as your partner, and let
                              our expertise propel your endeavors toward
                              unparalleled success. Embrace innovation with us
                              as we navigate the evolving landscape to ensure
                              your objectives are not just met but surpassed.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
