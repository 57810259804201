import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Managing Costs",
    description:
      "Dental clinics need to balance the cost of providing quality care with the need to keep services affordable for patients. This can be a challenge, particularly as overhead costs like rent, equipment, and supplies continue to rise.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Attracting & Retaining Patients",
    description:
      "Dental clinics need a steady stream of patients to stay in business. This requires effective marketing and branding efforts to attract new patients, as well as a commitment to quality care and excellent customer service to retain existing patients.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Staying Up-to-Date with Technology and Regulations",
    description:
      "Dental clinics need to stay abreast of changes in technology, regulations, and best practices in order to provide the most effective and up-to-date care. This can require significant investment in training, equipment, and other resources.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Managing Staff",
    description:
      "Dental clinics need a skilled and motivated team of professionals to provide quality care. This requires effective staffing, training, and performance management to ensure that staff members are able to work together efficiently and deliver the best possible care to patients.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
