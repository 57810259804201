import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink } from "react-router-dom";

const testimonials = [
  {
    image: require("./../../../images/achi/gctc.webp"),
  },
  {
    image: require("./../../../images/achi/gctc1.webp"),
  },
  {
    image: require("./../../../images/achi/gctc2.webp"),
  },
  {
    image: require("./../../../images/achi/gctc2.webp"),
  },
];
const testimonials1 = [
  {
    image: require("./../../../images/achi/cen1.webp"),
  },
  {
    image: require("./../../../images/achi/cen2.webp"),
  },
];
const testimonials2 = [
  {
    image: require("./../../../images/achi/defexo1.webp"),
  },
  {
    image: require("./../../../images/achi/defexo2.webp"),
  },
];
const testimonials3 = [
  {
    image: require("./../../../images/achi/imr1.webp"),
  },
  {
    image: require("./../../../images/achi/imr2.webp"),
  },
];
const testimonials4 = [
  {
    image: require("./../../../images/achi/cen3.webp"),
  },
  {
    image: require("./../../../images/achi/cen4.webp"),
  },
];
const testimonials5 = [
  {
    image: require("./../../../images/achi/defexo3.webp"),
  },
  {
    image: require("./../../../images/achi/defexo4.webp"),
  },
];
const testimonials6 = [
  {
    image: require("./../../../images/achi/dipm1.webp"),
  },
  {
    image: require("./../../../images/achi/dipm2.webp"),
  },
];
const testimonials7 = [
  {
    image: require("./../../../images/achi/ict1.webp"),
  },
  {
    image: require("./../../../images/achi/ict2.webp"),
  },
];
const testimonials8 = [
  {
    image: require("./../../../images/achi/vi1.webp"),
  },
  {
    image: require("./../../../images/achi/vi2.webp"),
  },
];

var bgimg1 = require("./../../../images/background/bg6.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class Testimonials2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      margin: 30,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        400: {
          items: 1,
        },
        540: {
          items: 1,
        },
        768: {
          items: 1,
        },
        991: {
          items: 1,
        },
        1350: {
          items: 1,
        },
      },
    };
    return (
      <>
        <section className="mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  {/* <div className="case-banner">
                    <h2 class="ert678 t-upper">
                      Global Counter Terrorism Council (Website Launch)
                    </h2>
                  </div> */}
                  <div class="nine">
                    <div className="myachieve">
                      Global Counter Terrorism Council (Website Launch)
                      <span>26-28 April 2023</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    It was a moment of immense pride for Team Shadow as we
                    launched the website of the Global Counter Terrorism Council
                    after months of hard work and dedication. The website,
                    www.gctcworld.org, was inaugurated by Shri Suresh Prabhu, a
                    former Union Minister for Civil Aviation, Railways, Commerce
                    & Industry, Chemicals & Fertilizers, Environment & Forests &
                    Power, Govt. of India and a former G20 & G7 Sherpa. Lt. Gen
                    KJ Singh, PVSM, AVSM & Bar (Retd), a former GOC-in-C,
                    Western Army Command, Indian Army, was also present at the
                    launch event. The Global Counter Terrorism Council organized
                    its 2nd CYBER SECURITY Conference, where Dr. Shekhar Dutt,
                    SM, IAS (Retd), a former Governor-Chhattisgarh, former
                    Defence Secretary, and former Deputy National Security
                    Advisor, Govt. of India, delivered the keynote address.
                  </p>
                </div>
              </div>

              {testimonials.map((item, index) => (
                <div className="col-xl-3 col-lg-3 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat bg-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">
                      CENJOWS iOS App Launching- DRDO Bhawan (Ministry of
                      Defence)
                    </h2>
                  </div>
                </div>
              </div>
              {testimonials1.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Today is the greatest day for Team Shadow to Launching
                    @CENJOWS iOS App at DRDO Bhawan, Ministry of Defence.
                    Heartwarming thanks to Lt Gen Satish Dua, PVSM, UYSM, SM,
                    VSM,CISC. Lt Gen Vinod Bhatia, PVSM, AVSM, VSM, Director
                    CENJOWS, Col YS Pathania and special thanks to Brig Manjeet
                    Singh, DACIDS (DIARA), HQ IDS for given a huge platform to
                    introduce it. CENJOWS is the First Think Tank in INDIA who
                    have iOS and Android Apps. Its abreast of Defence, Aerospace
                    Maritime, Strategic and Jointwarfare Domains.
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://cenjows.in/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">Defexpo 2018</h2>
                  </div>
                </div>
              </div>
              {testimonials2.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    DefExpo 2018 Chennai (Prime Minister of India) Shri Narendra
                    Modi. Inauguration by (Prime Minister of India) Shri
                    Narendra Modi in Chennai on 11 April 2018
                    www.imrmedia.in/defexpo
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://imrmedia.in/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat bg-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">INDIAN MILITARY REVIEW App</h2>
                  </div>
                </div>
              </div>
              {testimonials3.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Such a proud moment for us to launching Android App with Gen
                    Ravi Arora (Indian Military Review) and Mr. Kumar Rajesh
                    (CEO) to help Defence News (Indian Army, Navy, Airforce and
                    other Defence related) This App developed and maitained by
                    Shadow Android Team.
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://imrmedia.in/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">CENJOWS App & Website Launch</h2>
                  </div>
                </div>
              </div>
              {testimonials4.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Big achievement for team Shadow! Lt Gen Vinod Bhatia, PVSM,
                    AVSM, SM, Director CENJOWS & former DGMO (Director General
                    of Military Operations) and our CEO Mr. Kumar Rajesh
                    Launching Mobile App and Website www.cenjows.in for (Centre
                    for Joint Warfare Studies) Android App & Website Developed &
                    Maintained by Shadow infosystem Pvt. Ltd.
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://cenjows.in/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat bg-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">Defexpo 2016</h2>
                  </div>
                </div>
              </div>
              {testimonials5.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    DefExpo 2016 Goa (Prime Minister of India) Shri Narendra
                    Modi. Inauguration by (Defence Minister) Shri Manohar
                    Parrikar in Goa on 28 March 2016
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://imrmedia.in/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">
                      DIPM Council of India App Launching , IT Partner Shadow
                    </h2>
                  </div>
                </div>
              </div>
              {testimonials6.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    Mobile App for DIPM Council of India. Developed by Team
                    Shadow infosystem (P) Limited launched by Dr VK Saraswat,
                    Member NITI Aayog and Chief Patron DIPM Council of India. Lt
                    Gen SK Srivastava, AVSM, Engineer-in-Chief. Shri Arvind
                    Kumar Arora, DG and Shri Pradeep Agarwal, IDSE, President
                    and CEO. Shadow infosystem (P) Limited is IT Partner of the
                    International Conference & Exhibition.
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://dipmcouncil.org/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat bg-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">IC-Tram by Vice President</h2>
                  </div>
                </div>
              </div>
              {testimonials7.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    In the past two decades, Indian Railways has grown manifold
                    not just in terms of size and volume, but also in terms of
                    usage of cutting-edge technologies. Today, Indian Railways
                    is entering new fields, such as High-Speed Railways,
                    Dedicated Freight Corridors, bridging new frontiers by
                    laying tracks in inhospitable terrains of the North-East,
                    building mountain railways, using alternative fuels to
                    reduce dependence on fossil fuels to name a few initiatives.
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://imrmedia.in/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30">
                  <div className="case-banner">
                    <h2 class="ert678 t-upper">
                      VITAL RADIOLOGY (CM UTTARAKHAND)
                    </h2>
                  </div>
                </div>
              </div>
              {testimonials8.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="item">
                    <div className="bg-gray">
                      <div className="testimonial-detail clearfix">
                        <div className="scale-in-center i-shadow">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 mt-3">
                  <p>
                    VitalRadiology services launched, Teleradiology service at
                    Pauri Uttatakhand. The Inauguration took place in the
                    presence of Chief Minister of Uttarakhand
                    #ShriTrivendraSinghRawat. This scheme will boost up health
                    services in state, especially in remote hill places and will
                    help to get timely diagnosis as well as treatment of
                    patients.
                  </p>
                  <div class="text-left common-enq-bot1">
                    <a
                      class="bottt2"
                      href="https://www.vitalrad.co.uk/"
                      target="_blank"
                    >
                      <span>
                        More Info{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">
                          Global Counter Terrorism Council (Website Launch)
                        </h2>
                      </div>
                      <p>
                        It was a moment of immense pride for Team Shadow as we
                        launched the website of the Global Counter Terrorism
                        Council after months of hard work and dedication. The
                        website, www.gctcworld.org, was inaugurated by Shri
                        Suresh Prabhu, a former Union Minister for Civil
                        Aviation, Railways, Commerce & Industry, Chemicals &
                        Fertilizers, Environment & Forests & Power, Govt. of
                        India and a former G20 & G7 Sherpa. Lt. Gen KJ Singh,
                        PVSM, AVSM & Bar (Retd), a former GOC-in-C, Western Army
                        Command, Indian Army, was also present at the launch
                        event. The Global Counter Terrorism Council organized
                        its 2nd CYBER SECURITY Conference, where Dr. Shekhar
                        Dutt, SM, IAS (Retd), a former Governor-Chhattisgarh,
                        former Defence Secretary, and former Deputy National
                        Security Advisor, Govt. of India, delivered the keynote
                        address.
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://gctcworld.org/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials1.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">
                          CENJOWS iOS App Launching- DRDO Bhawan (Ministry of
                          Defence)
                        </h2>
                      </div>
                      <p>
                        Today is the greatest day for Team Shadow to Launching
                        @CENJOWS iOS App at DRDO Bhawan, Ministry of Defence.
                        Heartwarming thanks to Lt Gen Satish Dua, PVSM, UYSM,
                        SM, VSM,CISC. Lt Gen Vinod Bhatia, PVSM, AVSM, VSM,
                        Director CENJOWS, Col YS Pathania and special thanks to
                        Brig Manjeet Singh, DACIDS (DIARA), HQ IDS for given a
                        huge platform to introduce it. CENJOWS is the First
                        Think Tank in INDIA who have iOS and Android Apps. Its
                        abreast of Defence, Aerospace Maritime, Strategic and
                        Jointwarfare Domains.
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://cenjows.in/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">Defexpo 2018</h2>
                      </div>
                      <p>
                        DefExpo 2018 Chennai (Prime Minister of India) Shri
                        Narendra Modi. Inauguration by (Prime Minister of India)
                        Shri Narendra Modi in Chennai on 11 April 2018
                        www.imrmedia.in/defexpo
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://imrmedia.in/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials2.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials3.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">INDIAN MILITARY REVIEW App</h2>
                      </div>
                      <p>
                        Such a proud moment for us to launching Android App with
                        Gen Ravi Arora (Indian Military Review) and Mr. Kumar
                        Rajesh (CEO) to help Defence News (Indian Army, Navy,
                        Airforce and other Defence related) This App developed
                        and maitained by Shadow Android Team.
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://cenjows.in/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">CENJOWS App & Website Launch</h2>
                      </div>
                      <p>
                        Big achievement for team Shadow! Lt Gen Vinod Bhatia,
                        PVSM, AVSM, SM, Director CENJOWS & former DGMO (Director
                        General of Military Operations) and our CEO Mr. Kumar
                        Rajesh Launching Mobile App and Website www.cenjows.in
                        for (Centre for Joint Warfare Studies) Android App &
                        Website Developed & Maintained by SHADOW
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://cenjows.in/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials4.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials5.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">Defexpo 2016</h2>
                      </div>
                      <p>
                        DefExpo 2016 Goa (Prime Minister of India) Shri Narendra
                        Modi. Inauguration by (Defence Minister) Shri Manohar
                        Parrikar in Goa on 28 March 2016
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://imrmedia.in/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">
                          DIPM Council of India App Launching , IT Partner
                          Shadow
                        </h2>
                      </div>
                      <p>
                        Mobile App for DIPM Council of India. Developed by Team
                        Shadow infosystem (P) Limited launched by Dr VK
                        Saraswat, Member NITI Aayog and Chief Patron DIPM
                        Council of India. Lt Gen SK Srivastava, AVSM,
                        Engineer-in-Chief. Shri Arvind Kumar Arora, DG and Shri
                        Pradeep Agarwal, IDSE, President and CEO. Shadow
                        infosystem (P) Limited is IT Partner of the
                        International Conference & Exhibition
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://dipmcouncil.org/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials6.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials7.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">IC-Tram by Vice President</h2>
                      </div>
                      <p>
                        In the past two decades, Indian Railways has grown
                        manifold not just in terms of size and volume, but also
                        in terms of usage of cutting-edge technologies. Today,
                        Indian Railways is entering new fields, such as
                        High-Speed Railways, Dedicated Freight Corridors,
                        bridging new frontiers by laying tracks in inhospitable
                        terrains of the North-East, building mountain railways,
                        using alternative fuels to reduce dependence on fossil
                        fuels to name a few initiatives.
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://imrmedia.in/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content mb-5">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div className="m-b30 bg-white">
                      <div className="case-banner">
                        <h2 class="ert678">VITAL RADIOLOGY (CM UTTARAKHAND)</h2>
                      </div>
                      <p>
                        VitalRadiology services launched, Teleradiology service
                        at Pauri Uttatakhand. The Inauguration took place in the
                        presence of Chief Minister of Uttarakhand
                        #ShriTrivendraSinghRawat. This scheme will boost up
                        health services in state, especially in remote hill
                        places and will help to get timely diagnosis as well as
                        treatment of patients.
                      </p>
                      <div class="text-left common-enq-bot1">
                        <a
                          class="bottt2"
                          href="https://www.vitalrad.co.uk/"
                          target="_blank"
                        >
                          <span>
                            More Info{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm.72 4.594L15.28 11l4 4H9v2h10.28l-4 4l1.44 1.406l5.686-5.687l.72-.72l-.72-.72l-5.687-5.686z"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <OwlCarousel
                      className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                      {...options}
                    >
                      {testimonials8.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="hover-animation-1 bg-gray">
                            <div className="testimonial-detail clearfix">
                              <div className="scale-in-center dfgh34235">
                                <img src={item.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default Testimonials2;
