import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

function Icontect() {
  return (
    <>
      <div
        className="section-full mobile-page-padding p-t80 p-b50 ertyu45"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer separator-center">
              <div className="color-change bg-moving bg-repeat-x white-text">
                <h3 className="wfgrbwe ert678">
                  Know Our Informatica Services Plans
                </h3>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          <div className="product-full-detail m-b30">
            <div className="container">
              <div className="row">
                <div className="col-md-3 offset-md-2">
                  <div className="vncvnn123">
                    <div className="qwer123">
                      <NavLink
                        to={"/contact-us"}
                        rel="bookmark"
                        className="hs12-button"
                      >
                        Request a Consultation
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="vncvnn123">
                    <spam>OR</spam>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="vncvnn123">
                    <div className="qwer123">
                      <a
                        href="tel:+91-88003 84880"
                        rel="bookmark"
                        className="hs12-button"
                      >
                        +91-88003 84880
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Icontect;
