import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h3 className="wfgrbwe ert678">Why Choose Shadow</h3>
                  <p>
                    Choosing Shadow infosystem for managed cloud services offers
                    several compelling advantages. Here’s why you should
                    consider us:
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content sfsfcsc13232">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 servdert-one2">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                          alt=""
                        />
                        <h4 className="post-title">Expertise and Experience</h4>
                      </div>

                      <div className="">
                        <p>
                          We have a team of highly skilled professionals with
                          extensive experience in managing cloud services. Our
                          expertise ensures that your cloud infrastructure is in
                          capable hands.
                        </p>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/determine_the_business_development_plan.png")}
                          alt=""
                        />
                        <h4 className="post-title">Customized Solutions</h4>
                      </div>

                      <div className="">
                        <p>
                          We understand that every business has unique needs. We
                          tailor our managed cloud services to fit your specific
                          requirements, ensuring optimal performance and
                          efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">24/7 Support</h4>
                      </div>

                      <div className="">
                        <p>
                          Our dedicated support team is available around the
                          clock to assist you with any issues or concerns. We
                          are committed to providing prompt and effective
                          solutions to keep your operations running smoothly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                          alt=""
                        />
                        <h4 className="post-title">Security and Compliance</h4>
                      </div>

                      <div className="">
                        <p>
                          We prioritize the security of your data. Our managed
                          cloud services include robust security measures and
                          compliance with industry standards to protect your
                          sensitive information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Scalability</h4>
                      </div>

                      <div className="">
                        <p>
                          As your business grows, so do your cloud needs. We
                          offer scalable solutions that can easily adapt to your
                          changing requirements, ensuring that your cloud
                          infrastructure can grow with you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Innovation and Technology
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We stay at the forefront of technological advancements
                          to provide you with the latest and most efficient
                          cloud solutions. Our innovative approach ensures that
                          you benefit from cutting-edge technology.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Why Choose</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
