import React from "react";
import TypeDesign from "./../TypeDesign";
var bgimg1 = require("../../../images/flaticon/img-bg.jpg");
function PPCCall() {
  return (
    <>
      <section
        class="img-bg"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div class="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div class="caption asdfghj-jhgfds cnsajvjv-js_jdsc1">
                <div class="digihead">
                  <div className="alcnsja text-white">
                    Boost Your Online Presence on Search Engines
                  </div>
                  <p className="text-white">
                    Enhance visibility, increase traffic, climb search ranks,
                    and boost your online presence with our strategic SEO
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <TypeDesign className="vndjn-popup" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PPCCall;
