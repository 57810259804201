import React from "react";
const solutions = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Technology Integration",
    description:
      "Educational institutions need to incorporate technology into their curriculum to keep up with the digital age. This could include providing access to online resources, virtual classrooms, and digital tools that enhance learning.",
  },
  {
    number: <div class="number fontColor2">3</div>,
    heading: "Social media marketing",
    description:
      "Utilizing social media platforms like Facebook, Instagram, and Twitter can help build brand awareness and reach new audiences for the educational institute.",
  },
  {
    number: <div class="number fontColor4">2</div>,
    heading: "Online Assessments",
    description:
      "IT companies can provide online assessment platforms that enable educational institutions to conduct tests and exams online. These platforms can provide features such as automated grading, anti-cheating measures, and real-time feedback. Online assessments can help institutions to save time and resources while providing students with a more convenient and flexible testing environment.",
  },
  {
    number: <div class="number fontColor3">4</div>,
    heading: "Learning Analytics",
    description:
      "IT companies can provide learning analytics tools that enable educational institutions to track the performance of students, identify areas of weakness, and provide personalized feedback. These tools can help institutions to monitor student progress, assess the effectiveness of teaching strategies, and improve the overall quality of education.",
  },
];

function Solution() {
  return (
    <>
      <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
        <div className="container p-t80">
          <div className="row">
            <div className="col-md-12 sx-separator-outer separator-center">
              <div className="case-banner">
                <h2 class="ert678">The Solutions</h2>
              </div>
            </div>
            <div className="col-md-12 sx-separator-outer separator-center"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul class="infoGraphic">
                {solutions.map((item, index) => (
                  <li>
                    <div class="numberWrap">
                      {item.number}
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <h3>{item.heading}</h3>
                      <p>{item.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solution;
