import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const projects = [
    { image: require('./../../images/client-logo/client-logo107-h-2.png') },
    { image: require('./../../images/client-logo/client-logo28-h-1.png') },
    { image: require('./../../images/client-logo/client-logo10-h-1.png') },
    { image: require('./../../images/client-logo/client-logo2-h-1.png') },
    { image: require('./../../images/client-logo/client-logo49-h-1.png') },
    { image: require('./../../images/client-logo/acer-inches-2.jpg') },
    { image: require('./../../images/client-logo/logo_644acc1716add67c03b0a6510d37ff8e_2x.png') },
    { image: require('./../../images/client-logo/Sara-Consultancy-200.png') }
    
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class Projects4 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        const options = {
            loop:true,
            autoplay:true,
            center: false,
            items:3,
            margin:40,
            nav:false,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1,
                    margin:15
                }  
                
            }
        };
        return (
            <>
               <div className="work-carousel-outer">
                                <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel4 owl-btn-vertical-center" {...options}>
                                    {projects.map((item, index) => (
                                        <div key={index} className={`${item.filter} item fadingcol overflow-hide`}>
                                            <div className="sx-box">
                                                <div className="feegggggg">
                                                    <img src={item.image} alt="" />
                                                    
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                                


                            </div>

            </>
        );
    }
};

export default Projects4;