import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             
                        <div className="qpqq">
                          
                            <div className="row">
                            
                                <div className="col-xl-7 col-lg-12 col-md-12">
                                    <div className="section-head">
                                <div className="sx-separator-outer">
                                    <div className="">
                                        <h3 className="ert678">Life @ Shadow infosytem</h3>
                                        
                                    </div>
                                    <ul className="alal">
                                        <li>We are a people-first agency with strong core values that define everything we do!</li>
                                        <li>Step into a vibrant ecosystem that celebrates diversity, creativity, and continuous learning.</li>
                                    </ul>
                                    <div class="client-logo-pic1">
                                                <div className="common-enq-bot sdfghgfd3456">
                                            <NavLink to="/life-at-shadow" className="bottt"><span>Explore More</span></NavLink>
                                           
                                        </div>
                                            </div>
                                    
                                </div>
                            </div>
                                </div>
                                <div className="col-xl-5 col-lg-12 col-md-12">
                                    <div className="m-b30 bg-white">
                                        
                                        <div className="oiuytrew">
                                        <img className="rtyui1234" src={require('./../../../images/pic3wed.jpg')} alt="" />
                                        <img src={require('./../../../images/312191588_484502000364675_3915988247635504_n.jpg')} alt="" />
                                        <img className="rtyui123" src={require('./../../../images/270033480_4621111724641605_2381467991583984506_n.jpg')} alt="" />
                                        

                                        
                                    </div>
                                        
                                    </div>
                                </div>
                                
                                
                                </div>
                                
                        </div>
                        
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;