import React from "react";
import { NavLink } from "react-router-dom";
import MoreInform from "./../MoreInform";
import TypeDesign from "./../TypeDesign";
var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

function AboutBanner() {
  return (
    <>
      <div
        className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div className="container ">
          {/* TITLE START */}
          <div className="row d-inline-flex">
            <div className="col-md-7">
              <div className="section-head mnbv123">
                <div className="sx-separator-outer ">
                  <div className="bg-repeat-x color-change ewgr8900">
                    <h3 className="ert678">
                      Our award-winning legacy sets the bar high
                    </h3>
                    <p>
                      Our renowned legacy, adorned with exceptional awards,
                      continually elevates standards. We pride ourselves on
                      setting a high benchmark, inspiring excellence through
                      acclaimed achievements
                    </p>
                  </div>
                </div>
                <TypeDesign />
              </div>
            </div>
            <div className="col-md-5">
              <div className="mnbv1231">
                <img
                  src={require("./../../../images/flaticon/award-bnr.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* TITLE END */}
        </div>
      </div>
    </>
  );
}

export default AboutBanner;
