import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Nail Rituals</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo normal_logo">
                  <img
                    src={require("./../../../images/nail_rituals/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Nail Rituals</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      J-12/14, Rajouri Garden Extension, New Delhi (110027)
                    </li>
                    <li>
                      <b>Speciality : </b> Nail Salon
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://nailrituals.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.nailrituals.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>

                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/ritualsnail/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/nail.rituals/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/nail_rituals/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/nail_rituals/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Nail Rituals is your go-to nail salon for the best of
                        your trendy nail designs. They believe that every woman
                        in the world deserves the best of pampering and care,
                        therefore they particularly make them feel special and
                        exclusive. Elevate your nail experience to a whole new
                        level of sophistication at Nail Rituals, where nails are
                        not just about being noticed; they are about being
                        remembered.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
