import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding back-ground-color`}>
    <div className="container-fluid">
        <div className="section-content">
            <div className="">
                <div className="">
                    <div className="row caree">
                        <div className="col-xl-6 col-lg-12 col-md-12 sfdd sss4343df sss4343df1">
                            <div className="section-head">
                                <div className="sx-separator-outer">
                                    <div className="">
                                        <h1 className="career-one">
                                            Thank You!
                                        </h1>
                                    </div>
                                    <ul className="alal">
                                        <li>Thank you for contacting Shadow infosystem (P) Limited. We shall revert to your enquiry shortly.</li>
                                    </ul>
                                    <div class="">
                                                <div className="common-enq-bot sdfghgfd3456">
                                            <NavLink to={"/"} className="bottt1 "><span><img className="diw9899" src={require('./../../../images/icon/home.png')} alt="" /> Go Back To Home</span></NavLink>
                                           
                                        </div>
                                            </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 sfdd sss4343df43 bacg-ser">
                            <div className="section-head">
                                <div className="sx-separator-outer scsddsww2">
                                    <h3 className="ert678">Direct Contact With Us!</h3>
                                     
                                    <ul className="wscse">
                                    <li><img className="wdmwoncc01928" src={require('./../../../images/icon/whatsapp-button (1).png')} alt="" /><a href="tel:+91- 8285560008">  +91- 8285560008</a></li>
                                    
                                    <li><img className="wdmwoncc01928" src={require('./../../../images/icon/whatsapp-button-2.jpg')} alt="" /><a href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello" target="_blank" className=""> +91-8800384880</a></li>
                                    </ul>
                                    <div className="scscsaazaza">
                                    <div className=" widget_about">
                                        <h5 className="widget-title">Follow Us</h5>
                                        <ul className="social-icons  sx-social-links">
                                            <li>
                                                <a href="https://www.facebook.com/shadowinfosystemlimited" className="fa fa-facebook" target="_blank"></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/shadowinfosystem/" className="fa fa-instagram" target="_blank"></a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fshadowinfosyste" target="_blank"><img src={require('./../../../images/icon/twitter.png')} className="county-logo11" alt="Inteshape" /></a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/shadow-infosystem/" className="fa fa-linkedin" target="_blank"></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/@Shadowinfosystem" className="fa fa-youtube" target="_blank"></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

                
            </>
        );
    }
};

export default About3;