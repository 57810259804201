import React from 'react';
import { NavLink } from 'react-router-dom';
import ClientsLogo5 from './../../Elements/ClientsLogo5';

var bgimg1 = require('./../../../images/background/bg-5.png');
var bgimg2 = require('./../../../images/background/14803753_Vector_2677-1-scaled.jpg');
var bgimg3 = require('./../../../images/background/cross-line2.png');

class WhatWeDo1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full  mobile-page-padding bg-white1  p-t80 p-b30 bg-repeat overflow-hide back-ground-color">
                {/*<span class="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>*/}
                    <div className="container right-half-bg-image-outer">
                         <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x">
                                    <h3 className="wfgrbwe ert678">How Can We Assist in Growing Business through Social Media Marketing?</h3>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="bg-parallax bg-fixed bg-top-right" data-stellar-background-ratio={0} style={{ backgroundImage: 'url(' + bgimg2 + ')' }} />
                        
                       
                        <div className="section-content">
                            <div className="row number-block-one-outer justify-content-center">

                                
                                <div className="col-lg-7 col-md-12 col-sm-12 m-b30">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/background/Asset 2.png')} alt="" />
                                        
                                    </div>
                                    
                                   
                                    
                                  
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 m-b30">
                                    <div className="">
                                <div className="sx-separator-outer">
                                    <div className="">
                                        
                                        <p>In a world increasingly dominated by mobile devices, Shadow infosystem recognizes the importance of aligning your brand presence with the preferences of your target audience. Our social media marketing (SMM) campaigns are meticulously crafted to enhance user engagement across various platforms. Through our specialized and focused services, we facilitate direct interaction between your brand and your audience, fostering the growth of followers who have the potential to convert into valuable leads. Our dedicated team continually innovates to uphold our position as the premier social media marketing services provider.</p>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                                    
                                   
                                    
                                  
                                </div>

                                

                            </div>

                          

                        </div>

                    </div>
                </div>
            </>
        );
    }
};

export default WhatWeDo1;