import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class Seobanner extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row d-inline-flex">
              <div className="col-md-7">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <div className="ert678 heading text-white">
                        We’re the Sparks You Need in Your Career
                      </div>
                      <p>
                        Be it a startup or an enterprise, we’ll take care of
                        your web design needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 p-0">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/update1/career.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default Seobanner;
