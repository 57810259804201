import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';

class Footer extends React.Component {
    render() {

        return (
            <>
                <footer className="site-footer footer-large  footer-dark	footer-wide">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top footer-top1 overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                
                                
                                {/* USEFUL LINKS */}
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                                    <div className=" widget_services inline-links">
                                        <h5 className="widget-title"><img src={require('./../../images/flag-50.png')} className="county-logo" alt="Inteshape" /> India Office</h5>
                                        <ul className="widget_address">
                                            <li><i class="fa fa-map-marker" aria-hidden="true"></i> Electronic City, G-90, Sector-63, Noida-201301 (Delhi / NCR)</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                                    <div className=" widget_services inline-links">
                                        <h5 className="widget-title"><img src={require('./../../images/usa-50.png')} className="county-logo" alt="Inteshape" /> USA Office</h5>
                                        <ul className="widget_address">
                                            <li><i class="fa fa-map-marker" aria-hidden="true"></i> 10104 Des Moines ST, Parker CO 80134</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                                    <div className=" widget_services inline-links">
                                        <h5 className="widget-title"><img src={require('./../../images/canada-1.png')} className="county-logo" alt="Inteshape" /> Canada Office</h5>
                                        <ul className="widget_address">
                                            <li><i class="fa fa-map-marker" aria-hidden="true"></i> 8820 Jasper Avenue, Edmonton Alberta Canada-T5H4E8</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    
                       
                </footer>
                <Switcher/>

            </>
        );
    };
};

export default Footer;