import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Engravers Sign</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo normal_logo">
                  <img
                    src={require("./../../../images/engravers_sign/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Engravers Sign</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      A-2/67, First Floor, WHS, Kirti Nagar, Delhi
                    </li>
                    <li>
                      <b>Speciality : </b> Decorative Interiors
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://www.engraverssign.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.engraverssign.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>

                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/engraverssigndwarka"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/engravers_sign/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@Engravers_sign"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/engravers_sign/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/engravers_sign/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Engravers Sign is perfect for a range of mandir styles,
                        from hi-tech to traditional, from coolly minimalist to
                        warm and homey. Offering extraordinary design
                        flexibility in a wide range of vibrant colors, Engravers
                        Sign is ideal for countertops, backsplashes and integral
                        sinks, and many other exciting applications. Engravers
                        Sign can be customised to the exact look, color, and
                        style your residential design calls for. So shape it,
                        rout it, or form it to bring your vibrant residential
                        design to life.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
