import React from "react";

const results = [
  {
    image: require("./../../../images/solution/seo.webp"),
    heading: "SEO",
    description:
      "By optimizing the website for search engines, this website can improve its visibility on search engine results pages and attract more customers through organic search. This can be achieved by including relevant keywords in website content and meta tags, building backlinks, and creating high-quality content.",
  },
  {
    image: require("./../../../images/solution/smo.webp"),
    heading: "SMO",
    description:
      "By actively promoting this website on social media platforms, it can increase its online presence and reach a larger audience. This can be achieved by regularly posting updates and engaging with customers on platforms such as Facebook, Instagram, and Twitter, and by running social media advertising campaigns.",
  },
  {
    image: require("./../../../images/solution/ppc.webp"),
    heading: "PPC",
    description:
      "PPC helps to generate targeted traffic to your website by placing your website's ads in front of people who are searching for the website related keywords. This helps to drive targeted traffic to your website, and increase visibility and leads.",
  },
];

class Results extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h2 class="ert678">Results</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="our-history-content m-b30">
                  <p>
                    After creating a website and implementing search engine
                    optimization (SEO), social media optimization (SMO), and
                    pay-per-click (PPC) marketing strategies, you can expect to
                    see an increase in website traffic and visibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                {results.map((item, index) => (
                  <div className="col-lg-4 col-md-12" key={index}>
                    <figure class="snip1200">
                      <img src={item.image} alt="sq-sample27" />
                      <figcaption>
                        <p>{item.description}</p>
                        <div class="heading">
                          <h2>
                            <span>{item.heading}</span>
                          </h2>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Results;
