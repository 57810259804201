import React from 'react';
import { Helmet } from "react-helmet";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
const projects = [
    {image: require('./../../images/16thAnniversary/1 (12).webp'),},
    {image: require('./../../images/16thAnniversary/1 (28).webp'),},
    {image: require('./../../images/16thAnniversary/1 (25).webp'),},
    {image: require('./../../images/16thAnniversary/1 (1).webp'),},
    {image: require('./../../images/16thAnniversary/2 (2).webp'),},
    {image: require('./../../images/16thAnniversary/2 (1).webp'),},
    {image: require('./../../images/16thAnniversary/2 (3).webp'),},
    {image: require('./../../images/16thAnniversary/2 (4).webp'),},
    {image: require('./../../images/16thAnniversary/2 (5).webp'),},
    {image: require('./../../images/16thAnniversary/2 (6).webp'),},
    {image: require('./../../images/16thAnniversary/2 (7).webp'),},
    {image: require('./../../images/16thAnniversary/2 (8).webp'),},
    {image: require('./../../images/16thAnniversary/2 (9).webp'),},
    {image: require('./../../images/16thAnniversary/1 (2).webp'),},
    {image: require('./../../images/16thAnniversary/1 (3).webp'),},
    {image: require('./../../images/16thAnniversary/1 (4).webp'),},
    {image: require('./../../images/16thAnniversary/1 (5).webp'),},
    {image: require('./../../images/16thAnniversary/1 (6).webp'),},
    {image: require('./../../images/16thAnniversary/1 (9).webp'),},
    {image: require('./../../images/16thAnniversary/1 (11).webp'),},
    {image: require('./../../images/16thAnniversary/1 (16).webp'),},
    {image: require('./../../images/16thAnniversary/1 (18).webp'),},
    {image: require('./../../images/16thAnniversary/1 (21).webp'),},
    {image: require('./../../images/16thAnniversary/1 (22).webp'),},
    {image: require('./../../images/16thAnniversary/1 (23).webp'),},
    {image: require('./../../images/16thAnniversary/1 (24).webp'),},
    {image: require('./../../images/16thAnniversary/1 (26).webp'),},
    {image: require('./../../images/16thAnniversary/1 (27).webp'),},
    {image: require('./../../images/16thAnniversary/1 (30).webp'),},
    {image: require('./../../images/16thAnniversary/1 (32).webp'),},
    {image: require('./../../images/16thAnniversary/1 (33).webp'),},
    {image: require('./../../images/16thAnniversary/1 (34).webp'),},
    {image: require('./../../images/16thAnniversary/1 (35).webp'),},
    {image: require('./../../images/16thAnniversary/1 (37).webp'),},
    {image: require('./../../images/16thAnniversary/1 (38).webp'),},
    {image: require('./../../images/16thAnniversary/1 (39).webp'),},
    {image: require('./../../images/16thAnniversary/1 (40).webp'),},
    {image: require('./../../images/16thAnniversary/1 (41).webp'),},
    {image: require('./../../images/16thAnniversary/1 (42).webp'),},
    {image: require('./../../images/16thAnniversary/1 (43).webp'),},
    {image: require('./../../images/16thAnniversary/1 (44).webp'),},       
]
class Projects2 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>  <Helmet>
          <title>Celebrating Excellence with Prestigious Awards</title>
          <meta
            name="description"
            content="Get to know our accolades on our Awards Page. Recognitions showcase our commitment to excellence and innovation in IT & SEO solutions."
          />
          <meta name="keywords" content="Top IT Company" />
        </Helmet>
        <Header2 />
                <div className="section-full  mobile-page-padding bg-white  p-t80 p-b80">
                    <div className="container">

                        <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled m-b0">
                            {projects.map((item, index) => (
                                <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-6 col-sm-12 m-b30`}>
                                <a className="mfp-link" href={item.image}>
                                    <div className="sx-box image-hover-block">
                                        <div className="sx-thum-bx">
                                            <img src={item.image} alt="Shadow-16-Anniversary" />
                                        </div>
                                        
                                        
                                           
                                        
                                    </div>
                                    </a>
                                </div>
                            ))}
                        </ul>
                        {/* GALLERY CONTENT END */}
                        
                    </div>
                </div>
                 <Footer2 />
            </>
        );
    }
};

export default Projects2;