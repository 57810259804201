import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Creating High-Quality, Technical Content",
    description:
      "Producing high-quality, technical content that resonated with industry professionals can be a significant challenge. We develop detailed blog posts, and third party blogs that highlights the technical specifications, benefits, and applications of the products and electrical insulation materials. This content is both informative and authoritative, appealing to the technical expertise of the audience while also being optimized for search engines to improve rankings and drive organic traffic.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Visual SEO and Image Optimization",
    description:
      "The products offered by the company often require visual aids like schematics, and product images, making image optimization a critical aspect of SEO. We try to optimize these high-quality images for faster loading times without compromising on visual clarity and detail. This involved using descriptive file names, alt texts, and captions, as well as implementing lazy loading techniques. Properly optimized images not only improved user experience but also contributed to better search engine rankings.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Local SEO and Industry-Specific Targeting",
    description:
      "While these kind of product market is often niche, local SEO plays a crucial role, especially in attracting clients from specific places or cities. We tried to optimize the company’s online presence for various locales, ensuring that the content was relevant and engaging for different geographical markets. This included managing local business listings, creating region-specific content, and optimizing for local search queries relevant to the electrical insulation industry.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Building a Robust Backlink Profile",
    description:
      "Acquiring high-quality backlinks was essential for improving the website's authority and search engine rankings. We faced the challenge of developing a comprehensive outreach strategy to build network with relevant industry websites, technical blogs, and third party publications. Creating valuable, shareable content and securing credible backlinks required persistent effort and strategic planning to enhance the website's credibility and visibility.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
