import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Geetanjali 32 Dentals</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo ">
                  <img
                    src={require("./../../../images/geetanjalidentals/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Geetanjali 32 Dentals</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      DC-06, 3rd Floor, Medicentre, Galaxy Blue Sapphire Plaza,
                      Gaur City 1, Noida-201318
                    </li>
                    <li>
                      <b>Speciality : </b> Dentist
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://geetanjali32dental.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.geetanjali32dental.in
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/Geetanjali32intactsdentalclinic"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/geetanjali32intacts/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/geetanjalidentals/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/geetanjalidentals/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Geetanjali dental clinic offers dedicated and
                        unparalleled dental care, where your health takes
                        precedence above all else. With a commitment to
                        comprehensive and affordable services, they take immense
                        pride in having served a diverse array of patients over
                        the past years. Dr. Alok Bharati is a distinguished
                        dental surgeon whose expertise transcends conventional
                        boundaries. His dedication to his work has not only
                        earned him widespread acclaim but also garnered him
                        esteemed recognition as one of the best dental surgeons
                        in the National Capital Region.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
