import React from 'react';
import { NavLink } from 'react-router-dom';

const logos = [
    { image: require('./../../images/client-logo/Angular-JS.png') },
    { image: require('./../../images/client-logo/nord-js.png') },
    { image: require('./../../images/client-logo/React-JS.png') },
    { image: require('./../../images/client-logo/net-1.png') },
    { image: require('./../../images/client-logo/php.webp') },
    { image: require('./../../images/client-logo/laravel-2.png') },
    { image: require('./../../images/client-logo/woocommerce-1.png') },
    { image: require('./../../images/client-logo/magento.webp') },
    { image: require('./../../images/client-logo/shopify-1.png') },
    { image: require('./../../images/client-logo/joomla-1.webp') },
    
    { image: require('./../../images/client-logo/wordpress-1 (1).png') }
    
]

var bgimg1 = require('./../../images/background/bg-12.jpg');
var bgimg2 = require('./../../images/background/cross-line2.png');

class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat ">
                 <div className="blur-bg-blocks">
            <aside className="blur-bg-set">
               <div className="blur-bg blur-bg-a"></div>
               <div className="blur-bg blur-bg-b"></div>
               <div className="blur-bg blur-bg-c"></div>
            </aside>
         </div>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                                <div className="">
                                    <h3 className="">Technologies</h3>
                                    <h6>Always in lockstep with latest technologies</h6>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b40">
                                <div className="row justify-content-center">
                                    {logos.map((item, index) => (
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-6 m-b30" key={index}>
                                            <NavLink to={"#"} className="client-logo-pic1">
                                                <img src={item.image} alt=""/>
                                                
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Clients</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default ClientsLogo1;