import React from "react";
import { NavLink } from "react-router-dom";

const teamMembers = [
  {
    image: require("./../../../images/csr/gctc_website_launch.webp"),
    membername: "Global Counter Terrorism Council (Website Launch)",
    URL: "https://www.facebook.com/media/set/?set=a.611082254373315&type=3",
  },
  {
    image: require("./../../../images/csr/national_unity_day_celebration.webp"),
    membername: " National Unity Day Celebration",
    URL: "https://www.facebook.com/media/set/?set=a.497209299093945&type=3",
  },
  {
    image: require("./../../../images/csr/csr_img_3.webp"),
    membername:
      " शिक्षा आपके जीवन के सारे दुखों को खत्म करने का सामर्थ्य होता हैं",
    URL: "https://www.facebook.com/media/set/?set=a.2735068943245902&type=3",
  },
  {
    image: require("./../../../images/csr/csr_img_4.webp"),
    membername: " आओ बनाये सबकी दीपावली खास...!",
    URL: "https://www.facebook.com/media/set/?set=a.2458001390952660&type=3",
  },
  {
    image: require("./../../../images/csr/csr_img_5.webp"),
    membername: ' स्वस्थ बस्ती, स्वस्थ शहर" विशेष स्वास्थ्य जाँच शिविर',
    URL: "https://www.facebook.com/media/set/?set=a.2249232778496190&type=3",
  },
  {
    image: require("./../../../images/csr/csr_img_6.webp"),
    membername: " Free Eyes Test Camp",
    URL: "https://www.facebook.com/media/set/?set=a.1868090559943749&type=3",
  },
  {
    image: require("./../../../images/csr/csr6.webp"),
    membername:
      " Free Eyes Test N Free Specs Distribution For Students N Teachers",
    URL: "https://www.facebook.com/media/set/?set=a.1829073787178760&type=3",
  },
  {
    image: require("./../../../images/csr/csr3.webp"),
    membername: "  Children's Health And Initiative For Learning Development",
    URL: "https://www.facebook.com/media/set/?set=a.1813365318749607&type=3",
  },
  {
    image: require("./../../../images/csr/csr4.webp"),
    membername:
      " World Environment Day Trees Planting With Tehsil Bar Association",
    URL: "https://www.facebook.com/media/set/?set=a.1690991430986997&type=3",
  },
  {
    image: require("./../../../images/csr/csr8.webp"),
    membername: " Empowering Girls Education",
    URL: "https://www.facebook.com/media/set/?set=a.1662202183865922&type=3",
  },
  {
    image: require("./../../../images/csr/csr12.webp"),
    membername:
      " ABHYUDAY-18 At Ashoka Institute Varanasi, Shadow As Sponsor And Digital Partner",
    URL: "https://www.facebook.com/media/set/?set=a.1604089813010493&type=3",
  },
  {
    image: require("./../../../images/csr/csr1.webp"),
    membername: " Children Health Initiative Learning And Development",
    URL: "https://www.facebook.com/media/set/?set=a.1813365318749607&type=3",
  },
  {
    image: require("./../../../images/csr/csr7.webp"),
    membername: " Diwali Sweet Distribution",
    URL: "https://www.facebook.com/media/set/?set=a.1592437447509063&type=3",
  },
  {
    image: require("./../../../images/csr/csr11.webp"),
    membername: " SADRAG Adobe NGO",
    URL: "https://www.facebook.com/media/set/?set=a.1592448180841323&type=3",
  },
  {
    image: require("./../../../images/csr/csr2.webp"),
    membername: " Educational Things Distribution",
    URL: "",
  },
];

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Team1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 mobile-page-padding back-ground-color">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="">
                  <p>
                    <b>
                      Shadow is involved in a wide variety of community
                      development and environmental preservation projects.
                      Shadow social activities related to health, primary
                      education, skills training and entrepreneurship,
                      livelihood, women empowerment and strengthening services
                      for the differently-abled. Businesses are powerful
                      constituents of society and the most successful,
                      respected, and desirable businesses exist to do much more
                      than making money.
                    </b>
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row team-item-four">
                {teamMembers.map((item, index) => (
                  <div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
                    <a href={item.URL} target="_blank">
                      <div className="our-team-2 wcec3222 scsc-csrdv">
                        <div className="profile-image">
                          <img src={item.image} alt="" />
                          <div className="dmfee-efjedcm">
                            <h6>{item.membername}</h6>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Team1;
