import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Marketing and branding",
    description:
      "An IT company can provide solutions for effective marketing and branding, such as social media management, digital advertising, and content creation. This can help resorts establish a strong brand and attract more guests.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Revenue management",
    description:
      "An IT company can help resorts optimize their revenue by providing data-driven insights into demand patterns, pricing strategies, and revenue forecasting. This can help resorts maximize revenue during peak seasons and improve profitability during off-peak seasons.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Online presence",
    description:
      "An IT company can help resorts establish a strong online presence by developing a user-friendly website, creating engaging social media content, and implementing online booking systems. This can help attract more guests and increase direct bookings, reducing reliance on third-party booking platforms.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Asset management",
    description:
      "An IT company can provide software solutions to help resorts streamline maintenance and asset management processes, reducing the workload for staff and improving operational efficiency. This can include tools for preventive maintenance, inventory management, and work order tracking.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
