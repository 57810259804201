import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import SEOServicesCompanyNoidaHome from "./../Elements/WebDevelopmentServicesDelhiHome";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Web Development Services in Delhi NCR | Shadow infosystem</title>
          <meta name="description" content="Partner with the top web development Services in Delhi NCR for the best services. Contact us now to discuss your project &amp; boost your online success" />
          <meta name="keywords" content="Web development services in Delhi" />
          <link rel="canonical" href=" https://www.shadowinfosystem.com/web-development-services-delhi" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <SEOServicesCompanyNoidaHome />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
