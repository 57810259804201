import React from "react";

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Overview</h2>
                      <p className="mb__15">
                        Partnering with an IT company can help a hair & makeup
                        studio to improve and enhance their business with
                        website development and other IT solutions.
                      </p>

                      <p className="mb__0">
                        SSR studio is a beauty establishment that specializes in
                        providing hair and makeup services to clients. Hair
                        services may include haircuts, coloring, styling, and
                        treatments, while makeup services may include
                        traditional makeup, airbrush makeup, bridal makeup, and
                        special occasion makeup.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
