import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing Engaging & User-Friendly Website",
    description:
      "Creating an engaging and user-friendly website for a real estate company was a significant challenge. The website needed to provide a seamless experience for users while showcasing properties effectively. We designed a site that balanced aesthetic appeal with functionality. We also ensured that the website was mobile-friendly and optimized for different devices.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Identifying & Targeting Relevant Keywords",
    description:
      "Finding and targeting the right keywords in the real estate market was challenging due to its competitive nature. We performed extensive keyword research to identify high-impact terms and phrases that potential clients were using. This required a strategic approach to balance high-search volume with low-competition keywords, focusing on location-specific terms, property types, and customer needs. Long-tail keywords were particularly important for attracting highly targeted traffic and improving search engine rankings.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Creating High-Quality, Engaging Content",
    description:
      "Producing high-quality, engaging content that resonated with potential buyers and sellers was another significant challenge. We developed blog posts, property market reports, and neighborhood guides that highlighted the benefits of properties and regions. This content needed to be informative and persuasive, appealing to both buyers and sellers while also being optimized for search engines to improve rankings and drive organic traffic.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Optimizing for Technical SEO",
    description:
      "Ensuring the website’s technical SEO was fully optimized was crucial for improving search engine visibility and user experience. For that, We worked on improving site speed, ensuring mobile-friendliness, implementing structured data, and enhancing website architecture. Regular technical audits and updates were necessary to address any issues and maintain optimal website performance, which is essential for higher search engine rankings.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
