import React from 'react';
import Header2 from './../Common/Header3';
import Footer2 from './../Common/Footer2';
import About from './../Elements/ThankYou/About';

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                
                    <About/>
                     
                   

                    
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;