import React from "react";
import TypeDesign from "./../TypeDesign";
var bgimg1 = require("../../../images/flaticon/img-bg.jpg");
function PPCCall() {
  return (
    <>
      <section
        class="img-bg mb-5 "
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div class="container">
          <div className="row">
            <div className="col-md-9">
              <div class="caption asdfghj-jhgfds">
                <div class="digihead">
                  <div className="alcnsja text-white">
                    From Likes to Leads, We Help To Boost Your Presence
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <TypeDesign className="vndjn-popup" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PPCCall;
