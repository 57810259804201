import React from "react";
import { NavLink } from "react-router-dom";

import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row">
              <div className="col-md-8">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        We ensure peak performance through proactive website
                        maintenance
                      </h3>
                      <p>
                        We offer professional website maintenance services to
                        ensure your site runs smoothly, stays secure, and
                        remains up-to-date with the latest features.
                      </p>
                    </div>
                  </div>
                  <TypeDesign />
                </div>
              </div>
              <div className="col-md-4 p-0">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/head-icon/web-menti.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
