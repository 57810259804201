import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x  white-text">
                  <h2 className="wfgrbwe ert678 heading mb-3">
                    SEO Services We Offer
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/online_business_analyst_data_computer.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white sdfgj56564 website-deve seo-servie">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/online_business_analyst_data_computer.png")}
                          alt=""
                        />
                        <h4 className="post-title">Global SEO Services</h4>
                      </div>

                      <div className="">
                        <p>
                          We provide exceptionally impactful global SEO
                          services, empowering you to attract clients to your
                          business from every corner of the globe.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 website-deve seo-servie">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")}
                          alt=""
                        />
                        <h4 className="post-title">National SEO Services</h4>
                      </div>

                      <div className="">
                        <p>
                          Get our national SEO services to secure high rankings
                          and achieve success. Partner with us to elevate your
                          business into a recognized BRAND.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 website-deve seo-servie">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Local SEO Services</h4>
                      </div>

                      <div className="">
                        <p>
                          Dominate your local market with precision-targeted
                          Local SEO Services. Optimize your online presence to
                          connect with local customers and enhance your business
                          visibility.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 website-deve seo-servie">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Enterprise SEO</h4>
                      </div>

                      <div className="">
                        <p>
                          We provide customized enterprise SEO solutions for big
                          market influencers, empowering them to uphold a strong
                          online presence and amplify their revenue streams.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/vector-isometric-concept.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 website-deve seo-servie">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/vector-isometric-concept.png")}
                          alt=""
                        />
                        <h4 className="post-title">eCommerce SEO Services</h4>
                      </div>

                      <div className="">
                        <p>
                          Boost your online store's performance with our
                          specialized eCommerce SEO Services. Increase product
                          visibility, drive qualified traffic, and maximize
                          sales with strategic optimization tailored for
                          eCommerce success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564 website-deve seo-servie">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Start-Up SEO</h4>
                      </div>

                      <div className="">
                        <p>
                          Regardless of your business's size, we cater to all
                          SEO needs, ensuring a successful start to your journey
                          and providing comprehensive support for your online
                          presence.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
