import React from "react";
const Adpoint = [
  {
    imageBackground: require("./../../../images/locationpagesimages/10.png"),
    image: require("../../../images/locationpagesimages/10.png"),
    title: "Search Ads",
    description:
      "Accelerate the establishment of brand recognition by strategically placing advertisements at the forefront of search engine results.",
  },
  {
    imageBackground: require("./../../../images/locationpagesimages/5.png"),
    image: require("../../../images/locationpagesimages/5.png"),
    title: "Remarketing",
    description:
      "Revitalize your customer base with cleverly targeted ads using Shadow infosystem robust remarketing strategies.",
  },
  {
    imageBackground: require("./../../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png"),
    image: require("../../../images/locationpagesimages/digital_marketing_data_analysis_campaign.png"),
    title: "Display Ads",
    description:
      "We craft compelling and customer-focused advertisements, incorporating images, banners, text, and more, to amplify your online presence.",
  },
  {
    imageBackground: require("./../../../images/locationpagesimages/vector-isometric-concept.png"),
    image: require("../../../images/locationpagesimages/vector-isometric-concept.png"),
    title: "Google Shopping Ads",
    description:
      "For retail marketing businesses, create campaigns to effectively sell products to online customers through the use of Google Shopping Ads.",
  },
];

function PPCAd() {
  return (
    <>
      <section>
        <div className="container ">
          <div className="section-head">
            <div className="sx-separator-outer text-center ">
              <div className="bg-moving bg-repeat-x">
                <h2 className="ert678 heading mb-3">
                  Our PPC Advertising Services
                </h2>
                <p>
                  <b>
                    Get More Traffic, Generate More Leads and Sales with Our PPC
                    Services
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {Adpoint.map((item, index) => (
              <div className="col-md-6 pb-5 date-style-3">
                <div
                  class="sx-post-info sdfgj56564 servdert-one1 block-shadow ppc-ad-word"
                  key={index}
                >
                  <img
                    className="technology-img"
                    src={item.imageBackground}
                    alt=""
                  />
                  <div class="card-content">
                    <div class="">
                      <div class="media d-block">
                        <div class="media-body">
                          <div className="WhyChooseShadow">
                            <img
                              src={item.image}
                              className="ppc-icon"
                              alt={item.title}
                            />
                            <div className="sub-heading">{item.title}</div>
                          </div>
                          <p class="align-self-center">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default PPCAd;
