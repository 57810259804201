import React from 'react';
import { NavLink } from 'react-router-dom';
import TypeDesign from './TypeDesign';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const clientele = [
  {
    id: 1,
    image: require("./../../images/client/webdesign/vital.jpg"),
    items: ["UK", "SGP", "IND"],
  },

  {
    id: 2,
    image: require("./../../images/client/webdesign/sara.jpg"),
    items: ["Canada"],
  },

  {
    id: 3,
    image: require("./../../images/client/webdesign/ssr.jpg"),
    items: ["USA"],
  },

  {
    id: 4,
    image: require("./../../images/client/webdesign/yocarz.jpg"),
    items: ["IND"],
  },

  {
    id: 5,
    image: require("./../../images/client/webdesign/aparna.jpg"),
    items: ["IND", "UAE"],
  },

  {
    id: 6,
    image: require("./../../images/client/webdesign/santosh.jpg"),
    items: ["IND"],
  },

  {
    id: 7,
    image: require("./../../images/client/webdesign/dentalkraft.jpg"),
    items: ["IND"],
  },

  {
    id: 8,
    image: require("./../../images/client/webdesign/4qt.jpg"),
    items: ["IND"],
  },

  {
    id: 9,
    image: require("./../../images/client/webdesign/nail_rituals.jpg"),
    items: ["IND"],
  },

  {
    id: 10,
    image: require("./../../images/client/webdesign/vedam.jpg"),
    items: ["IND"],
  },

  {
    id: 11,
    image: require("./../../images/client/webdesign/engrever.jpg"),
    items: ["IND"],
  },

  {
    id: 12,
    image: require("./../../images/client/webdesign/av.jpg"),
    items: ["IND"],
  },

  {
    id: 13,
    image: require("./../../images/client/webdesign/roadload.jpg"),
    items: ["IND"],
  },

  {
    id: 14,
    image: require("./../../images/client/webdesign/david.jpg"),
    items: ["Canada"],
  },

  {
    id: 15,
    image: require("./../../images/client/webdesign/greenvent.jpg"),
    items: ["IND"],
  },

  {
    id: 16,
    image: require("./../../images/client/webdesign/shrimahalaxmi.jpg"),
    items: ["IND"],
  },

  {
    id: 17,
    image: require("./../../images/client/webdesign/tatva.jpg"),
    items: ["IND"],
  },

  {
    id: 18,
    image: require("./../../images/client/webdesign/trishul.jpg"),
    items: ["IND"],
  },
]

var bgimg1 = require('./../../images/banner/web-development-banner.jpg');
var bgimg2 = require('./../../images/background/cross-line2.png');
var bgimg3 = require('./../../images/background/home-banner.jpg');

class ClientsLogo1 extends React.Component {
  componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {

        return (
          <>
            <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1" style={{ backgroundImage: "url(" + bgimg1 + ")" }} >
              <div className="container ">
                {/* TITLE START */}
                <div className="row d-inline-flex">
                  <div className="col-md-6">
                    <div className="section-head mnbv123">
                      <div className="sx-separator-outer ">
                        <div className="bg-repeat-x color-change ewgr8900">
                          <h3 className="ert678">
                            Get the Best and Professional SEO Services
                          </h3>
                          <p>Grow Organically on Google's First Page</p>
                        </div>
                      </div>
                       <TypeDesign/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mnbv1231">
                      <img src={require("./../../images/gif.gif")} alt="" />
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
              </div>
            </div>
            <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
            <div className="container-fluid">
            <div className="section-content">
            <div className="container">
                <div className="section-head">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="m-b30 bg-white">
                                <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                    <img src={require("./../../images/3dimages/1.png")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="sx-separator-outer separator-left">
                                <div className="ertyui">
                                    <h1 className="ert678 heading">Best SEO Services in Delhi, NCR</h1>

                                    <p>Shadow infosystem distinguishes itself as the foremost provider of premier SEO services in
Delhi. Renowned for our unwavering commitment to excellence and client satisfaction, we have
built an exceptional reputation for delivering tangible results in the digital landscape.</p>
                                    <p>Backed by a team of seasoned professionals adept in the latest SEO methodologies and
algorithms, we devise bespoke strategies to amplify our client's online presence. Whether it's
optimizing website content, conducting meticulous keyword research, or implementing cutting-
edge technical SEO enhancements, our agency ensures comprehensive optimization across all
facets of our client's digital footprint, driving maximum visibility and engagement.</p>
                                   
                                 </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            
                            <p>What sets us apart is our steadfast dedication to staying ahead of the curve in the ever-evolving
realm of SEO. Continuously monitoring industry trends, algorithmic updates, and competitor
strategies, we refine our approach to deliver sustainable outcomes.</p>
                            <p>Transparency and collaboration are at the core of our ethos. We prioritize open communication,
keeping clients fully informed and engaged throughout the optimization journey. This client-
centric approach fosters trust and cultivates enduring partnerships, cementing our position as
the preferred choice for businesses seeking unparalleled SEO services in Delhi, NCR.</p>
<p>Shadow infosystem's fusion of expertise, innovation, and client-centricity positions us as the
premier destination for SEO services in Delhi, NCR, empowering businesses to thrive in the
digital sphere.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
 <div className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x  white-text">
                <h2 className="wfgrbwe ert678 heading">
                    SEO Services We Offer
                </h2>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/online_business_analyst_data_computer.png')} alt="" />
                    <div className="sx-post-info  bg-white sdfgj56564 servdert-one1 seo-servie">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/online_business_analyst_data_computer.png")} alt="" />
                            <h4 className="post-title">Global SEO Services</h4>
                        </div>

                        <div className="">
                            <p>
                                As the provider of the best SEO services in Delhi, we provide exceptionally impactful global
SEO services, empowering you to attract clients to your business from every corner of the
globe.
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                <img className="technology-img" src={require('./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")} alt="" />
                            <h4 className="post-title">
                                National SEO Services
                            </h4>
                        </div>

                        <div className="">
                            <p>Get our national SEO services to secure high rankings and achieve success. Partner with us to
elevate your business into a recognized BRAND.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <h4 className="post-title">Local SEO Services</h4>
                        </div>

                        <div className="">
                            <p>
                               Dominate your local market with precision-targeted Local SEO Services. Optimize your online
presence to connect with local customers and enhance your business visibility.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <h4 className="post-title">Enterprise SEO</h4>
                        </div>

                        <div className="">
                            <p>
                                We provide customized enterprise SEO solutions for big market influencers, empowering them
to uphold a strong online presence and amplify their revenue streams.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/vector-isometric-concept.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/vector-isometric-concept.png")} alt="" />
                            <h4 className="post-title">
                                eCommerce SEO Services
                            </h4>
                        </div>

                        <div className="">
                            <p>
                                Boost your online store's performance with our specialized eCommerce SEO Services in Delhi,
NCR. Increase product visibility, drive qualified traffic, and maximize sales with strategic
optimization tailored for eCommerce success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/smartphone_test_data_analysis.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/smartphone_test_data_analysis.png")} alt="" />
                            <h4 className="post-title">Start-Up SEO</h4>
                        </div>

                        <div className="">
                            <p>
                                Regardless of your business&#39;s size, we cater to all SEO needs, ensuring a successful start to
your journey and providing comprehensive support for your online presence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12"></div>
            <div className="col-lg-3 col-md-12 col-sm-12">
                <TypeDesign className="vndjn-popup" />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12"></div>
        </div>
    </div>
</div>
<div className="hilite-title text-left p-l50 text-uppercase">
    <strong>Services</strong>
</div>
</div>
<div className="section-full  mobile-page-padding bacg-ser p-t80 p-b10 bg-repeat">
    <div className="container">
        {/* TITLE START */}
        <div className="section-head">
            <div className="sx-separator-outer separator-center">
                <div className="bg-repeat-x color-change">
                    <div className="ert678 heading">
                        Get A Free Website Audit & Consultation From Our Experts
                    </div>
                    <p>
                        Want to boost organic traffic and generate more leads? Contact us now to secure your place on Google's first page!
                    </p>
                </div>
                <div className="common-enq-bot">
                    <a href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello Shadow" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/whatsapp-button (1).png")} alt="" />
                    </a>
                    <span>OR</span>
                    <a href="tel:+91-88003 84880" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/Call-Now-Button.png")} alt="" />
                    </a>
                </div>
            </div>
        </div>
        {/* TITLE END */}
    </div>
</div>
 <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x">
                <h2 className="wfgrbwe ert678 heading text-white">
                    How Do We Work?
                </h2>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/online_business_analyst_data_computer.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Explore
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                The initial phase involves understanding your audience - their characteristics, needs, and how your offerings can meet those needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Analyze
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                A thorough evaluation of your website's current status - assessing its functionality, visitor engagement, and identifying any potential issues.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Strategize
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                We determine the optimal approach - evaluating the feasibility of targeting specific keywords, conducting necessary site optimizations, and considering strategies like guest posts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Evaluate
                                </h3>
                            </div>
                            </div>

                            <div className="">
                                <p>
                                    Early data analysis serves as the cornerstone of success - monitoring, and understanding how your strategies are performing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/vector-isometric-concept.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Report
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Comprehensive and detailed reporting to support your business's growth in the most effective directions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/smartphone_test_data_analysis.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Adapt
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Data-driven decision-making guides our actions - identifying areas requiring improvement, and determining strategies to progress based on successful outcomes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                    <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
            </div>
        </div>
    </div>
    <div className="hilite-title text-left p-l50 text-uppercase">
        <strong>Why Choose</strong>
    </div>
</div>
 <div className="section-full inner-page-padding p-b50 p-t80 ">
                    <div className="container-fluid">
                        <div className="section-content">
                            <div className="container">
                                {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="ertyui">
                                        <h3 className="ert678">Loved By 3000+ Customers All Over The World</h3>
                                    <h6>Our greatest asset is the customers and clients Satisfaction!</h6>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                                <div className="row">
                                    {clientele.map((item) => (
                      <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb__20">
                        <ul key={`item-${item.id}`} className="cl_container">
                          <div className="client_logo_img_box">
                            <div className="client_logo_img">
                              <img src={item.image} alt="client-logo" />
                            </div>
                          </div>

                          <div className="client_logo_title_container">
                            {item.items.map((country, index) => (
                              <li key={`country-${index}`}>{country}</li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    ))}
                                </div>
                                


                            </div>
                            </div>
                            


                        </div>

                    </div>
                </div>
               <div className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x  white-text">
                <h2 className="wfgrbwe ert678 heading">
                    Why Choose Shadow infosystem For SEO Services?
                </h2>
                <p>Shadow infosystem stands as the premier provider of the best SEO services in Delhi,
distinguished by our status as the largest SEO agency in the industry. Collaborating with
numerous businesses, we've effectively enhanced our client's Google rankings, amplified
website traffic, increased brand visibility, and augmented online revenue.</p>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/2.png')} alt="" />
                    <div className="sx-post-info  bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/2.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Transparency
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                We believe in open communication and transparency at every stage of our collaboration. Our clients receive regular updates and detailed reports on the progress of our SEO works. We provide clear insights into
                                the strategies implemented, the results achieved, and any adjustments made to enhance performance.
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                <img className="technology-img" src={require('./../../images/locationpagesimages/determine_the_business_development_plan.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/determine_the_business_development_plan.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Experience & Expertise
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                With a solid foundation built on 15 years of experience, as the best SEO services Agency, Shadow infosystem brings a wealth of knowledge and expertise to every project. This extensive experience allows us to
                                navigate the complexities of the digital landscape effectively, providing clients with strategic insights and proven methodologies for long-term success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/12.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/12.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Proven Track Record
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                With a track record of successful SEO strategies across diverse industries, we have consistently delivered tangible results. Our portfolio showcases case studies highlighting significant improvements in
                                website traffic, and keyword rankings for our clients.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/4.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/4.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Customized Strategies
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                Recognizing that every business is unique, we tailor our Search Engine Optimization strategies to meet the specific needs and goals of each client. Our team conducts in-depth analyses to understand the target
                                audience, competitive landscape, and industry trends, ensuring a personalized approach that maximizes results.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<div className="hilite-title text-left p-l50 text-uppercase">
    <strong>Services</strong>
</div>
</div>
<div className="page-content">
    <div className="section-full p-t80 p-b80 inner-page-padding ">
        {/*
        <div className="trtrtrtyuy"></div>
        */}
        <div className="container">
            <div className="faq-1">
                {/* TITLE START */}
                <div className="section-head">
                    <div className="sx-separator-outer separator-left">
                        <div className="">
                            <h3 className="ert678">Frequently Asked Questions</h3>
                        </div>
                    </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                    <div className="col-md-12">
                        {/* Accordian */}
                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                            <div className="panel sx-panel">
                                <div className="acod-head acc-actives">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                           Why is SEO important in 2024?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOne5" className="acod-body collapse show">
                                    <div className="acod-content p-a15">
                                        <p>In the year 2024, search engine optimization (SEO) is still an important factor in ensuring
visibility and relevance for businesses online, especially in the midst of fierce digital competition.
As search engines continue to improve and user behaviors change, SEO strategies must adapt
accordingly to generate organic traffic, build brand credibility, and ultimately, establish
sustainable growth for businesses in the digital realm.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                            Which company provides the best SEO service in Delhi?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseTwo5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>
                                          Shadow Infosystem is a highly reputed provider of SEO services in Delhi NCR. Their expertise,
commitment to client satisfaction, and proven track record of delivering tangible results make
them the top choice for businesses who want unparalleled SEO solutions in the region.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                           Does my website need to be optimized by an SEO expert?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseThree5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Having your website optimized by an SEO expert is crucial for maximizing its online visibility,
attracting organic traffic, and ultimately boosting your business. Shadow Infosystem's SEO
experts possess the knowledge and skills needed to navigate search engine algorithms and
implement effective strategies that drive sustainable growth for your website.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                            Which benefits will SEO services provide?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseFour5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>SEO services provide numerous benefits, such as driving more traffic to websites, increasing
online visibility, building better brand recognition, and improving user experience. Moreover,
SEO helps companies to target their audience more efficiently, generate leads, and ultimately
boost sales and revenue.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                            How should I select an SEO service Agency?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseFive5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>When searching for the best SEO services provider in Delhi, such as Shadow Infosystem, it&#39;s
important to prioritize their expertise, experience, and proven track record of delivering results.
To make an informed decision, look for agencies that offer transparent communication, tailored
strategies, and a focus on achieving your business goals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    {/* SECTION CONTENT END */}
</div>




          </>
        );
    }
};

export default ClientsLogo1;