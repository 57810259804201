import React from "react";

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="mb__25 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p className="mb__15">
                    Partnering with an IT company can bring several advantages
                    to the company. Here are some potential benefits :
                  </p>
                  <p className="mb__0">
                    The strategic partnership with shadow infosystem has
                    propelled the company's growth. This has led to improved
                    operational efficiency, a stronger online presence, and
                    enhanced customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
