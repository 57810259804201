import React from 'react';
import { NavLink } from 'react-router-dom';
import TypeDesign from './../TypeDesign';




var bgimg1 = require('./../../../images/banner/web-development-banner.jpg');
var bgimg2 = require('./../../../images/background/cross-line2.png');


class ClientsLogo1 extends React.Component {
      constructor(props) {
        super(props);
        this.state = { logo: require('./../../../images/logo-light.png') };
    }

    state = { isSearchActive: false };

    handleSearchToggle = () => {
        this.setState({ isSearchActive: !this.state.isSearchActive });
    };

    componentDidMount() {

        const handleScroll = () => {
            const offset = window.scrollY;

            const stickyheader = document.querySelector('.sticky-header ');

            if (offset >= 100) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');

            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
        }

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => {
            this.setState({ logo: logopath });
        };
    }

    render() {
         const isSearchActive = this.state.isSearchActive;

        return (
            <>
                <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                
                    <div className="container ">
                        {/* TITLE START */}
                        <div className="row">
                            <div className="col-md-7">
                                <div className="section-head mnbv123">
                            <div className="sx-separator-outer ">
                                <div className="bg-repeat-x color-change ewgr8900">
                                    <h3 className="ert678">Transforming Ideas into Stunning Web Realities</h3>
                                    <p>Our specialty is to design custom, interactive websites with rich features tailored to your needs. Let us create your digital presence!
</p>
                                </div>
                            </div>
                            {/*<TypeDesign/>*/}
                            <TypeDesign/>

                        </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mnbv1231">
                                    <img src={require('./../../../images/coding-gif.gif')} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* TITLE END */}
                        
                        
                        
                    </div>
                    
                </div>
            </>
        );
    }
};

export default ClientsLogo1;