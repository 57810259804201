import React from "react";
import Header2 from "./../../Common/Header3";
import Footer2 from "./../../Common/Footer2";
import Banner from "./../../Elements/CaseStudyHQIDS/Banner";
import Techstack from "./../../Elements/CaseStudyHQIDS/Techstack";
import Challenges from "./../../Elements/CaseStudyHQIDS/Challenges";
import { Helmet } from "react-helmet";
class OurHistory extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Case Study | HQIDS</title>
          <meta name="description" content="Case Study | HQIDS" />
          <meta name="keywords" content="Case Study | HQIDS" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/case-study-hqids"
          />
        </Helmet>
        <Header2 />
        <div className="page-content case__study__page">
          <Banner />
          <Challenges />
          <Techstack />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default OurHistory;
