import React from "react";

var bnrimg = require("./../../../images/banner/7.jpg");

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="page-content">
          <div className="section-full p-t80 p-b80 inner-page-padding bg-gray ">
            {/*<div className="trtrtrtyuy"></div>*/}
            <div className="container">
              <div className="faq-1">
                <div className="section-head">
                  <div className="sx-separator-outer text-center">
                    <div className="">
                      <h3 className="ert678 mb-3">
                        Frequently Asked Questions
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {/* Accordian */}
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              As a social media marketing agency, what can you
                              provide us as compared to other agencies?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              At Shadow infosystem, we elevate your brand's
                              social media presence with strategic campaigns
                              tailored to your audience. Our unique blend of
                              creativity and data-driven insights ensures
                              maximum engagement and ROI. Unlike other agencies,
                              we prioritize innovative strategies that captivate
                              your audience and drive meaningful connections
                              with your brand.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What are the benefits of SMO services for
                              businesses?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              SMO (Social Media Optimization) services enhance
                              businesses by fostering brand visibility,
                              increasing website traffic, and improving customer
                              engagement. With targeted content strategies and
                              active community management, SMO boosts brand
                              loyalty, drives conversions, and provides valuable
                              insights for refining marketing efforts,
                              ultimately leading to business growth and success.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Which is the best social media marketing agency?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Shadow infosystem is a top-tier social media
                              marketing agency renowned for its innovative
                              strategies and unparalleled results. With a team
                              of experts dedicated to maximizing online presence
                              and engagement, Shadow Infosystem stands out as
                              the go-to choice for businesses seeking superior
                              marketing solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              AIs social media marketing helpful for B2B?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Yes, social media marketing is incredibly
                              beneficial for B2B companies. It enables them to
                              showcase expertise, build relationships, generate
                              leads, and drive traffic to their websites.
                              Platforms like Facebook, Instagram, and LinkedIn
                              are particularly effective for B2B marketing due
                              to their professional networking focus.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Why is SMO important for businesses?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Social Media Optimization (SMO) is crucial for
                              businesses because it enhances brand visibility,
                              fosters engagement with customers, drives website
                              traffic, improves search engine rankings, and
                              cultivates a strong online presence. It also
                              allows businesses to gather valuable insights and
                              stay competitive in today's digital landscape.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive11"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How can your agency help to promote our newly
                              launched products on social media?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive11" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Shadow infosystem can help promote your newly
                              launched products on social media through targeted
                              strategies tailored to your specific audience and
                              goals. We employ a range of techniques including
                              creating engaging content, running targeted ad
                              campaigns, leveraging influencers, and analyzing
                              data to optimize performance and maximize results.
                              With our expertise, we can effectively increase
                              visibility, drive traffic, and boost sales for
                              your products across various social media
                              platforms.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/* TITLE START */}

                    {/* TITLE END */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
      </>
    );
  }
}

export default Faq;
