import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/locationpagesimages/cloud_server_digital_transformation_security.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <h1 className="ert678 mb-3">
                              Empowering Infrastructure through Expert Managed
                              Cloud Services
                            </h1>
                            <p>
                              Revolutionize your business foundation with Shadow
                              infosystem's Expert Managed Cloud Services. We
                              specialize in optimizing and securing your
                              infrastructure, leveraging cutting-edge cloud
                              solutions. From seamless migrations to ongoing
                              management, our experts ensure scalability,
                              reliability, and cost-efficiency. Experience
                              uninterrupted innovation and efficiency as we
                              tailor cloud solutions to your unique needs.
                              Elevate your digital journey with our advanced
                              services, maximizing the potential of cloud
                              technology to drive growth, enhance security, and
                              streamline operations.{" "}
                            </p>
                            <p>
                              Trust us to empower your infrastructure and propel
                              your business into a new era of success.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
