import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Intense Competition",
    description:
      "The real estate industry is highly competitive, with many agencies vying for clients in the same market. This can make it difficult for agencies to stand out and attract new clients.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Fluctuating Market Conditions",
    description:
      "Real estate markets can be volatile, with prices and demand fluctuating frequently. This can make it difficult for agencies to forecast sales and plan for the future.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Technological Advancements",
    description:
      "As technology advances, real estate agencies need to keep up to remain competitive. This can be challenging for smaller agencies that may not have the resources to invest in the latest technologies.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Complex Transactions",
    description:
      "Real estate transactions can be complex, involving multiple parties, legal documents, and regulations. This can make it challenging for agencies to manage transactions effectively and ensure compliance with relevant laws and regulations.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
