import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Social media marketing",
    description:
      "An IT company can help the studio create and manage social media accounts on platforms like Facebook, Instagram, and Twitter. This can help the studio reach a wider audience and engage with existing clients.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Online booking system",
    description:
      "An IT company can create an online booking system that allows clients to schedule appointments easily and quickly. This can reduce the studio's administrative workload and improve customer satisfaction.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Inventory management",
    description:
      "An IT company can develop a system to help the studio manage its inventory of hair and makeup products. This can help the studio keep track of stock levels, reduce waste, and ensure that the products are always available for clients.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Website development",
    description:
      "An IT company can help the studio develop a professional-looking website that showcases its services, portfolio, and pricing. This can help attract new clients and improve the studio's online presence.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
