import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h3 className="wfgrbwe ert678 mb-3">Our SMM Work Includes</h3>
                  <p>
                    At Shadow infosystem, actions speak louder than words. We
                    are committed to delivering on our promises and excelling in
                    our areas of expertise. As a premier social media marketing
                    agency, we offer budget-friendly packages that yield
                    favorable outcomes. Regardless of your business size, our
                    skilled team ensures you establish a dynamic and favorable
                    presence on various social media platforms.
                  </p>
                </div>
              </div>
            </div>
            <div className="section-content ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/WebsiteImage/Image-1.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/WebsiteImage/Image-1.png")}
                          alt=""
                        />
                        <h4 className="post-title">Stand Out</h4>
                      </div>

                      <div className="">
                        <p>
                          We craft robust social media strategies tailored to
                          your specific business goals and marketing objectives.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/WebsiteImage/Image-3.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/WebsiteImage/Image-3.png")}
                          alt=""
                        />
                        <h4 className="post-title">Encourage Dialogue</h4>
                      </div>

                      <div className="">
                        <p>
                          We not only create content but also stimulate
                          discussions to facilitate deeper connections with your
                          audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/WebsiteImage/Image-5.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/WebsiteImage/Image-5.png")}
                          alt=""
                        />
                        <h4 className="post-title">Content Boosting</h4>
                      </div>

                      <div className="">
                        <p>
                          Our team employs strategic methods to significantly
                          enhance the reach of your content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/WebsiteImage/Image-2.png")}
                          alt=""
                        />
                        <h4 className="post-title">Simplicity & Impact</h4>
                      </div>

                      <div className="">
                        <p>
                          We integrate your brand's identity into our social
                          media strategies, ensuring simplicity and
                          effectiveness.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/WebsiteImage/Image-4.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/WebsiteImage/Image-4.png")}
                          alt=""
                        />
                        <h4 className="post-title">Performance Evaluation</h4>
                      </div>

                      <div className="">
                        <p>
                          We continuously measure analytics and
                          campaign-specific metrics to assess overall
                          performance.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white sdfgj56564">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/WebsiteImage/Image-2.png")}
                          alt=""
                        />
                        <h4 className="post-title">Craft Engaging Content</h4>
                      </div>

                      <div className="">
                        <p>
                          Following strategic planning, our team produces
                          interactive, compelling, and concise content to foster
                          connections with your audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
