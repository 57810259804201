import React from 'react';
import { NavLink } from 'react-router-dom';

class BlogSidebar extends React.Component {
    render() {
        return (
            <>
                <div className="side-bar p-a30 bg-gray">
                    {/* SEARCH */}
                    <div className="widget">
                        <h4 className="widget-title ">Search</h4>
                        <div className="search-bx p-a10 bg-white">
                            <form role="search" method="post" action="#">
                                <div className="input-group">
                                    <input name="news-letter" type="text" className="form-control bg-gray" placeholder="Write your text" />
                                    <span className="input-group-btn bg-gray">
                                        <button type="button" className="btn"><i className="fa fa-search" /></button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* RECENT POSTS */}
                    <div className="widget  recent-posts-entry">
                        <h4 className="widget-title  ">Recent Posts</h4>
                        <div className="section-content p-a10 bg-white">
                            <div className="widget-post-bx">
                                <div className="widget-post clearfix">
                                    <div className="sx-post-media">
                                        <img src={require('./../../images/blog/default/Top-IT-Companies-in-Noida-Shadow-infosystem.jpg')} alt=""/>
                                    </div>
                                    <div className="sx-post-info">
                                        <div className="sx-post-header">
                                            <h6 className="post-title">Top IT Companies in Noida – Shadow infosystem</h6>
                                        </div>
                                        <div className="sx-post-meta">
                                            <ul>
                                                <li className="post-author">01 Nov 2023</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-post clearfix">
                                    <div className="sx-post-media">
                                        <img src={require('./../../images/blog/default/Top-IT-Companies-in-Noida-Shadow-infosystem.jpg')} alt=""/>
                                    </div>
                                    <div className="sx-post-info">
                                        <div className="sx-post-header">
                                            <h6 className="post-title">Top IT Companies in Noida – Shadow infosystem</h6>
                                        </div>
                                        <div className="sx-post-meta">
                                            <ul>
                                                <li className="post-author">01 Nov 2023</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-post clearfix">
                                    <div className="sx-post-media">
                                        <img src={require('./../../images/blog/default/Top-IT-Companies-in-Noida-Shadow-infosystem.jpg')} alt=""/>
                                    </div>
                                    <div className="sx-post-info">
                                        <div className="sx-post-header">
                                            <h6 className="post-title">Top IT Companies in Noida – Shadow infosystem</h6>
                                        </div>
                                        <div className="sx-post-meta">
                                            <ul>
                                                <li className="post-author">01 Nov 2023</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    {/* Categories  */}
                    <div className="widget widget_services ">
                        <h4 className="widget-title">Categories</h4>
                        <ul className="p-a10 bg-white">
                            <li><NavLink to={"#"}>Artificial Intelligence<span> (28)</span></NavLink></li>
                            <li><NavLink to={"#"}>Artificial Intelligence<span> (05)</span></NavLink></li>
                            
                        </ul>
                    </div>
                    {/* NEWSLETTER */}
                    <div className="widget widget_newsletter-2">
                        <h4 className="widget-title  ">Newsletter</h4>
                        <div className="newsletter-bx p-a10 bg-white">
                            <div className="newsletter-content">
                                <p>Subscribe to our mailing list to get the update to your email.</p>
                            </div>
                            <div className="m-t20">
                                <form role="search" method="post" action="#">
                                    <div className="input-group">
                                        <input name="news-letter" className="form-control" placeholder="ENTER YOUR EMAIL" type="text" />
                                        <span className="input-group-btn">
                                            <button type="button" className="site-button"><i className="fa fa-paper-plane-o" /></button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                    {/* TAGS */}
                    <div className="widget widget_tag_cloud">
                        <h4 className="widget-title">Tag Cloud</h4>
                        <div className="tagcloud p-a10 bg-white">
                            <NavLink to={"#"}>Artificial Intelligence</NavLink>
                            <NavLink to={"#"}>Artificial Intelligence</NavLink>

                            
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default BlogSidebar;