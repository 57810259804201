import React from "react";

function PartnerEnquiry() {
  return (
    <>
      <div className="section-full  mobile-page-padding bacg-ser p-t80 p-b10 bg-repeat">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer separator-center">
              <div className="bg-repeat-x color-change">
                <div className="ert678 heading">
                  Let's Connect to Discuss Partnerships
                </div>
                <p>
                  We can explore opportunities to collaborate, innovate, and
                  achieve mutual success by combining our strengths and
                  resources.
                </p>
              </div>
              <div className="common-enq-bot">
                <a
                  href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello Shadow"
                  target="_blank"
                  className=""
                >
                  <img
                    className="wdmwoncc019281"
                    src={require("../../../images/icon/whatsapp-button (1).png")}
                    alt=""
                  />
                </a>
                <span>OR</span>
                <a href="tel:+91-88003 84880" target="_blank" className="">
                  <img
                    className="wdmwoncc019281"
                    src={require("../../../images/icon/Call-Now-Button.png")}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          {/* TITLE END */}
        </div>
      </div>
    </>
  );
}

export default PartnerEnquiry;
