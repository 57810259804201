import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="shri_mahalaxmi_h1">
                    Shri Mahalaxmi Associates
                  </h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/shri_mahalaxmi_associates/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Shri Mahalaxmi Associates</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> Flat No-1080, 1st Floor, Pinewood
                      Enclave, Wave City, Sector-2, Ghaziabad, U.P 201002
                    </li>
                    <li>
                      <b>Services:</b> Real Estate
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://www.shrimahalaxmiassociates.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.shrimahalaxmiassociates.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/shrimahalaxmiassociates6"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/shrimahalaxmiassociates6/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://twitter.com/ShriMahalaxmiA1"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/shri-mahalaxmi-associates"
                          target="_blank"
                          className="linkedin"
                        >
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/shri_mahalaxmi_associates/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/shri_mahalaxmi_associates/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__15">
                        Shri Mahalaxmi Associates is a full-service real estate
                        agency dedicated to providing the best possible
                        experience for our clients. Their team of
                        well-experienced and highly-knowledgeable real estate
                        professionals is committed to helping you achieve your
                        real estate goals.
                      </p>

                      <p className="mb__0">
                        Shri Mahalaxmi Associates understand that buying or
                        selling a property can be a complex and stressful
                        journey. That’s why they’re here to guide a individual,
                        every step of the way, from start to finish. Their team
                        of experts is knowledgeable about the real estate market
                        and equipped with the latest updates and tools to make
                        your real estate journey as smooth and stress-free as
                        possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
