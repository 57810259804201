import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Business Intelligence & Analytics",
    description:
      "An IT company can provide data analytics tools that can help real estate agencies to make data-driven decisions, such as market trends, property performance, and customer behavior.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Virtual Tours & Augmented Reality",
    description:
      "An IT company can develop virtual tours and augmented reality tools that can help real estate agencies showcase properties in a more immersive and engaging way. This can help clients to get a better sense of the property without having to visit it physically.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Cybersecurity",
    description:
      "An IT company can provide cybersecurity solutions to help real estate agencies protect their data and systems from cyber threats, such as hacking and data breaches. This can help to ensure compliance with relevant laws and regulations and protect the agency's reputation.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Digital Marketing",
    description:
      "An IT company can develop digital marketing strategies that leverage various channels such as social media, email marketing, and search engine optimization (SEO) to help real estate agencies reach a wider audience and attract more clients.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
