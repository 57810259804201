import React from "react";
import IServiceBenefitsIcon1 from "./../../../images/head-icon/ib-1.webp";
import IServiceBenefitsIcon2 from "./../../../images/head-icon/ib-2.webp";
import IServiceBenefitsIcon3 from "./../../../images/head-icon/ib-3.webp";
import IServiceBenefitsIcon4 from "./../../../images/head-icon/ib-4.webp";
import IServiceBenefitsIcon5 from "./../../../images/head-icon/ib-5.webp";
import IServiceBenefitsIcon6 from "./../../../images/head-icon/ib-6.webp";

const ibenefits = [
  {
    icon: IServiceBenefitsIcon4,
    heading: "Workflow Automation",
    description:
      "This tool streamlines workflow automation by arranging complex data processes seamlessly. Through intuitive interfaces and scheduling capabilities, tasks such as data extraction, transformation, and loading are automated, reducing manual intervention and enhancing operational efficiency. This automation accelerates data delivery, fosters agility, & optimizes resource utilization within organizations.",
  },
  {
    icon: IServiceBenefitsIcon5,
    heading: "Performance Optimization",
    description:
      "Utilizing Informatica ETL tools optimizes performance through advanced tuning & scalability features. These tools employ efficient data processing algorithms, parallel execution, & resource management techniques to enhance throughput & minimize latency. By optimizing performance, organizations can meet stringent SLAs, improve system responsiveness, & maximize efficiency of their data integration workflows.",
  },
  {
    icon: IServiceBenefitsIcon6,
    heading: "Metadata Management",
    description:
      "Incorporating this tool elevates metadata management, providing comprehensive visibility and governance over data assets. With centralized metadata repositories and lineage tracking capabilities, organizations gain insights into data origins, transformations, and usage. This enhances data governance, compliance, and decision-making, fostering trust and reliability in data-driven initiatives.",
  },
  {
    icon: IServiceBenefitsIcon1,
    heading: "Data Integration Efficiency",
    description:
      "Using Informatica ETL tools streamlines data integration, enhancing efficiency in various ways. It automates data extraction, transformation, and loading processes, reducing manual errors and time consumption. With seamless connectivity to diverse data sources, it ensures reliable, consistent, and high-quality data integration, empowering informed decision-making and boosting organizational productivity.",
  },
  {
    icon: IServiceBenefitsIcon2,
    heading: "Data Quality Assurance",
    description:
      "Leveraging this tool bolsters data quality assurance by enforcing rigorous validation and cleansing protocols. These tools identify and rectify inconsistencies, duplicates, and inaccuracies within datasets, ensuring data integrity and compliance. By maintaining high-quality data, organizations can enhance decision-making, streamline operations, and foster trust in their analytics and reporting processes.",
  },
  {
    icon: IServiceBenefitsIcon3,
    heading: "Data Transformation Flexibility",
    description:
      "It offers unparalleled data transformation flexibility, enabling seamless adaptation of diverse data formats, structures, and sources. With robust transformation capabilities, organizations can easily manipulate, enrich, and standardize data according to their specific requirements, empowering agile decision-making, innovative analytics, and efficient data-driven processes across the enterprise.",
  },
];

function Ibenefits() {
  return (
    <>
      <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer separator-center">
              <div className="color-change bg-moving bg-repeat-x text-center">
                <h3 className="wfgrbwe ert678">
                  Top Benefits of Using ETL Tool in Informatica
                </h3>
                <p>
                  Using an ETL (Extract, Transform, Load) tool like Informatica
                  PowerCenter offers several data integration and management
                  benefits.
                </p>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          {/* IMAGE CAROUSEL START */}
          <div className="section-content sfsfcsc13232">
            <div className="row">
              {ibenefits.map((item, index) => (
                <div className="col-lg-4 col-md-12 col-sm-12" key={index}>
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img src={item.icon} alt={item.heading} />
                        <h4 className="post-title">{item.heading}</h4>
                      </div>

                      <div className="">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ibenefits;
