import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
// var bgimg2 = require('./../../../images/background/cross-line2.png');

class ClientsLogo2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        INTERNAL COMPLAINTS COMMITTEE (ICC)
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offset-md-1 col-md-4">
                <div className="mnbv1231">
                  <img src={require("./../../../images/web/icc.webp")} alt="" />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo2;
