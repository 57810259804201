import React from "react";

var bnrimg = require("./../../../images/banner/7.jpg");

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="page-content">
          <div className="section-full p-t80 p-b80 inner-page-padding bg-gray">
            {/*<div className="trtrtrtyuy"></div>*/}
            <div className="container">
              <div className="faq-1">
                {/* TITLE START */}
                <div className="section-head">
                  <div className="sx-separator-outer separator-left">
                    <div className="bg-moving bg-repeat-x">
                      <div className="heading text-center">
                        Frequently Asked Questions
                      </div>
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                  <div className="col-md-6">
                    {/* Accordian */}
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              What is PPC advertising?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              PPC, also known as Pay-Per-Click advertising, is a
                              strategy wherein advertisers pay for each click on
                              their Google ads. Instead of relying on organic
                              methods to generate website traffic, PPC enables
                              businesses to purchase visits to their site
                              directly. Advertisers bid on keywords relevant to
                              their target audience, and their ads appear when
                              users search for those keywords on search engines
                              like Google.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How long does it take to see results?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              In contrast to SEO, PPC allows for relatively
                              quick results. However, initially, these outcomes
                              typically relate only to impressions and clicks.
                              Achieving substantial results in terms of
                              qualified leads and conversions takes time, as it
                              involves thorough optimization of ads through
                              practices aimed at discerning the most effective
                              approaches for engaging your target audience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How PPC can help your business?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              PPC, regardless of business size, offers
                              substantial advantages. A well-executed campaign
                              can swiftly boost profits, outpacing alternative
                              marketing approaches. It efficiently connects with
                              the right audience, precisely when they're primed
                              to transition from prospects to buyers. PPC
                              facilitates business growth by introducing new
                              products, enhancing brand recognition, driving
                              swift and pertinent website traffic, and fostering
                              increased leads and sales worldwide through
                              targeted campaigns.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* Accordian */}
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head  acc-actives">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Is PPC expensive?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseFour5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              While PPC might initially appear costly, it's not
                              necessarily so. This misconception is common among
                              clients. If mismanaged with zero ROI, PPC can
                              indeed seem expensive. Any ineffective digital
                              marketing endeavor incurs costs. Yet, with adept
                              management, like that offered by Shadow
                              infosystem, concerns dissipate. A Google PPC
                              campaign yielding substantial returns renders its
                              cost insignificant.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              data-parent="#accordion5"
                            >
                              Why should businesses try PPC?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Utilizing PPC in your marketing offers numerous
                              advantages. Our PPC services guarantee the
                              following benefits:
                            </p>
                            <div class="sx-separator-outer separator-left">
                              <div class="ertyui">
                                <ul class="sdnvdn">
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Instant, targeted traffic
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Quick, measurable outcomes
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Reduced reliance on SEO
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Immune to Google Algorithmic shifts
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Effortless audience targeting
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Increased business prospects
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Enhanced brand visibility
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Cost-efficient
                                  </li>
                                  <li>
                                    <i class="fa fa-check-square-o"></i>{" "}
                                    &nbsp;Evaluative tool for other marketing
                                    strategies' effectiveness
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive6"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Which Is The Best Google PPC Company?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive6" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Shadow infosystem is a recognized best Google PPC
                              company, offering tailored solutions for
                              businesses aiming to leverage Google Ads
                              effectively. With expertise in PPC management, we
                              provide comprehensive services to optimize ad
                              campaigns, ensuring targeted traffic, measurable
                              results, and enhanced ROI. Our proficiency in
                              Google Ads makes us a reliable choice for
                              businesses seeking effective online advertising
                              strategies.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-5">
                                     <div className="col-md32">
                                         <img src={require('./../../../images/background/3d-hand-hold-mobile-phone-with-check-list-removebg-preview.png')} alt="" />
                                     </div>
                                     
                                     </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
      </>
    );
  }
}

export default Faq;
