import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import Banner from "./../Elements/Achievements/Banner";

import AwardAll from "./../Elements/Achievements/AwardAll";
import { Helmet } from "react-helmet";
import NewAchi from "./../Elements/Achievements/NewAchi";

var bnrimg = require("./../../images/banner/web-development-banner.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Shadow infosystem: Celebrating Milestones and Achievements
          </title>
          <meta
            name="description"
            content="Know our remarkable achievements, milestones, and success stories. Discover our journey of excellence and innovation in technology and SEO solutions."
          />
          <meta name="keywords" content="Top IT Company" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <Banner />
          <NewAchi />
          {/* <AwardAll /> */}
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
