import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="ertyui">
                                        <h3 className="ert678">Professional content writing services to drive<br/>measurable results.</h3>
                                        
                                    </div>
                                </div>
                            </div>
                        <div className="qpqq">
                          
                            <div className="row">
                                
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="m-b30 bg-white">
                                        
                                        <p>No reader likes to read average-quality content with lots of keyword stuffing. Thus, rich content has become more demandable in recent years. Every company wants to publish content that fulfills the purpose of the audience. However, due to the dearth of skilled writers who know the art of storytelling, it becomes difficult for marketers to produce high-quality content.</p>
                                        <p>And if you are someone who is looking to outsource content writing services, your search ends here. We have a team of exceptional wordsmiths who can deliver you the right content that can engage your audience and convince them. Our writers know the nuances of writing that has an essence of human emotions and blend them with the right amount of keywords.</p>
                                        
                                    </div>
                                </div>
                                
                                </div>
                                
                        </div>
                        <div className="SEOform">
                            <form className="cons-contact-form2 form-transparent" method="post" action="#">
                            <div className="section-head">
                                    <div className="sx-separator-outer separator-center">
                                        <div className="bg-moving bg-repeat-x">
                                            <h4 className="">Get A Enquiry</h4>
                                            <p>Want To Drive More Organic Traffic And Leads? Call Us Now Explore the Hidden Potential Of Your Website.</p>
                                        </div>
                                    </div>
                                </div>
                                                            
                                                            <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="input input-animate">
                                                                
                                                                <input defaultValue="" type="text" name="username" id="name" placeholder="Enter Your Name" required />
                                                                <span className="spin" />
                                                            </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input input-animate">
                                                                
                                                                <input defaultValue="" type="email" name="username" id="email" placeholder="Enter Your Email" required />
                                                                <span className="spin" />
                                                            </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="input input-animate">
                                                                
                                                                <input defaultValue="" type="phone" name="username" id="phone" placeholder="Enter Your Phone" required />
                                                                <span className="spin" />
                                                            </div>
                                                            </div>
                                                            <div className="col-lg-2">
                                                               <div class="client-logo-pic1">
                                                               <div className="text-left">
                                                               <NavLink to="#" className="site-button btn-half"><span>Submit</span></NavLink>
                                                               </div>
                                                               </div>
                                                            </div>
                                                            </div>
                                                            
                                                        </form>
                        </div>
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;