import React from "react";
import seoAssistIcon1 from "./../../../images/partner/seo.webp";
import seoAssistIcon2 from "./../../../images/partner/smo.webp";
import seoAssistIcon3 from "./../../../images/partner/content.webp";
import seoAssistIcon4 from "./../../../images/partner/google.webp";

function PartnerService() {
  return (
    <>
      <div
        className={`section-full mobile-page-padding back-ground-color bg-gray`}
      >
        <div className="container-fluid">
          <div className="section-content">
            <div className="container">
              <div className="">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui text-center">
                        <h2 className="ert678 heading">Our Services</h2>

                        <p>
                          With expertise in Google Marketing, SEO, and Social
                          Media Solutions, we’ve partnered with numerous
                          agencies to optimize their clients’ digital marketing
                          strategies. Additionally, we offer strategic
                          consulting on media planning, buying, and effective
                          lead-generation methods tailored to your client’s
                          budget and goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div class="wrapper">
            <div class="table1 basic2">
              <div class="price-section">
                <div class="price-area">
                  <div class="inner-area">
                    <span class="price">
                      <img src={seoAssistIcon4} className="patner-img" alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="package-name"></div>
              <ul class="features">
                <li>
                  <span class="icon red">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">SEM</span>
                </li>
                <li>
                  <span class="icon red">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Google Shopping</span>
                </li>
                <li>
                  <span class="icon red">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Google Display Network</span>
                </li>
                <li>
                  <span class="icon red">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">YouTube Ads</span>
                </li>
              </ul>
            </div>
            <div class="table1 basic">
              <div class="price-section">
                <div class="price-area">
                  <div class="inner-area">
                    <span class="price">
                      <img src={seoAssistIcon1} className="patner-img" alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="package-name"></div>
              <ul class="features">
                <li>
                  <span class="icon yellow">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">SEO</span>
                </li>
                <li>
                  <span class="icon yellow">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">SERM</span>
                </li>
                <li>
                  <span class="icon yellow">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">SEO Referral Program</span>
                </li>
                <li>
                  <span class="icon yellow">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">International SEO</span>
                </li>
                <li>
                  <span class="icon yellow">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Local SEO</span>
                </li>
                <li>
                  <span class="icon yellow">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">SEO Copywriting</span>
                </li>
                <li>
                  <span class="icon yellow">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">E-commerce SEO</span>
                </li>
              </ul>
            </div>
            <div class="table1 premium">
              <div class="price-section">
                <div class="price-area">
                  <div class="inner-area">
                    <span class="price">
                      <img src={seoAssistIcon2} className="patner-img" alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="package-name"></div>
              <ul class="features">
                <li>
                  <span class="icon cross">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Paid Social</span>
                </li>
                <li>
                  <span class="icon cross">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Social Media Management</span>
                </li>
              </ul>
            </div>
            <div class="table1 ultimate">
              <div class="price-section">
                <div class="price-area">
                  <div class="inner-area">
                    <span class="price">
                      <img src={seoAssistIcon3} className="patner-img" alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="package-name"></div>
              <ul class="features">
                <li>
                  <span class="icon check">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Copywriting</span>
                </li>
                <li>
                  <span class="icon check">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Content Marketing</span>
                </li>
                <li>
                  <span class="icon check">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Social Media Content</span>
                </li>
                <li>
                  <span class="icon check">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Infographic Content Creation</span>
                </li>
                <li>
                  <span class="icon check">
                    <i class="fa fa-check-square-o"></i>
                  </span>
                  <span class="list-name">Blog Article</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerService;
