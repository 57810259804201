import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1>Tatva Bir Resort</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/tatvabir/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Tatva Bir Resort</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> Village & PO – Bir, Tehsil – Baijnath,
                      Kangra, Himachal Pradesh
                    </li>
                    <li>
                      <b>Services:</b> Hotel & Resorts
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://tatvabirresorts.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.tatvabirresorts.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/tatvabir"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/tatvabirtentsandresorts/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://twitter.com/tatvabir"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCdGjTmyEPEegSJ7ic6nMR3w"
                          target="_blank"
                          className="youtube"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/tatvabir/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/tatvabir/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__15">
                        With a tastemaker’s advantage in camping, Tatva Bir
                        offers luxurious comfort. With its three-layers tent and
                        deluxe amenities, staying in their camps brings an
                        opportunity to be with nature, and soak in its beauty.
                      </p>

                      <p className="mb__0">
                        They have luxurious resorts for the people who like a
                        royal feel with spacious rooms, and special tents for
                        student groups to make the most out of their trip to the
                        Kangra Valley. Activities like bonfires and live music
                        are there to kindle the people’s mood to find their
                        rhythm with the nature.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
