import React from "react";

function Iabout() {
  return (
    <>
      <div class="section-full mobile-page-padding section-padding back-ground-color">
        <div className="container-fluid">
          <div className="section-content">
            <div className="container">
              <div className="qpqq m-0">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 d">
                    <div className="mobile-mb-30 bg-white">
                      <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                        <img
                          src={require("./../../../images/informatica/i-ab-1.gif")}
                          alt=""
                        />
                        {/* <img src={require("./../../../images/informatica/ab.gif")} alt="" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <div className="section-head m-0">
                      <div className="sx-separator-outer">
                        <div className="is-title">
                          <h2 className="mb-3">Informatica</h2>
                          <h6 className="mb-3">
                            Leverage the power of Informatica Professional
                            Services to convert unprocessed data into a valuable
                            strategic resource
                          </h6>
                          <p className="mb-3">
                            Step into the future of data management with
                            Informatica, where you can seamlessly connect
                            various data sources, from on-premises databases to
                            Cloud applications, to create a unified data
                            network. Utilizing its robust data integration
                            capabilities, organizations can not only link
                            diverse data sources but also transform them into
                            actionable intelligence for enhanced analytics and
                            reporting, paving the way for informed
                            decision-making.
                          </p>
                          <p className="mb-3">
                            As a reputed leader in the industry, Shadow
                            infosystem empowers businesses to harmonize
                            disparate data sources, extract actionable insights,
                            and make informed decisions. Our team of Informatica
                            specialists assists organizations in navigating the
                            data-driven landscape and leveraging the vast
                            potential of Informatica business solutions to stay
                            at the forefront of innovation. Whether it's
                            optimizing data connectivity, arranging complex data
                            workflows, or ensuring data quality, Informatica
                            services are the trusted ally to maintain relevance
                            and competitiveness.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Iabout;
