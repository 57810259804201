import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Streamlining operations",
    description:
      "IT companies can help logistic companies optimize their supply chain operations by developing software solutions that automate manual processes. This can help reduce costs, improve efficiency, and increase accuracy.",
  },
  {
    icon: <i class="fa fa-facebook"></i>,
    heading: "Digital marketing",
    description:
      "IT companies can help logistic companies to develop & implement digital marketing strategies to promote their services and reach a wider audience. This includes website design, seo & social media marketing.",
  },
  {
    icon: <i class="fa fa-database"></i>,
    heading: "Data analytics",
    description:
      "IT companies can help logistic companies to analyze data to identify trends, and optimize their operations. This can help logistic companies to make data-driven decisions and improve their supply chain processes.",
  },
  {
    icon: <i class="fa fa-shield"></i>,
    heading: "Cybersecurity",
    description:
      "IT companies can help logistic companies to protect their data from cyber threats by developing and implementing cybersecurity solutions such as firewalls, anti-virus software, and intrusion detection systems.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="mb__25 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p className="mb__15">
                    Partnering with an IT company can help a logistics company
                    to improve its operations, reduce costs, and increase its
                    competitive advantage in the market.
                  </p>
                  <p className="mb__15">
                    By leveraging technology and digital solutions, logistic
                    companies can enhance their supply chain management
                    capabilities and provide better services to their customers.
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div class="card-wrap mt__0">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h3 class="card-title">{item.heading}</h3>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
