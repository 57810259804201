import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1>St Teresa</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo">
                  <img
                    src={require("./../../../images/stteresa/st-teresa-logo.webp")}
                    className=""
                    alt="Shadow"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543"> St Teresa School</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> Shakti Khand-II, Indirapuram
                      Ghaziabad-201014 (U.P) India
                    </li>
                    <li>
                      <b>Services:</b> International School
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://stteresaschool.in"
                        class="text-white"
                        target="_blank"
                      >
                        www.stteresaschool.in
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/stsindirapuram/"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/stteresaschool.indirapuram/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/school/st-teresa-school/"
                          className="tumblr"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/stsindirapuram"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/c/StTeresaSchoolIndirapuram"
                          target="_blank"
                          className="youtube"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/stteresa/stteresa-mob.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/stteresa/stteresa-pc.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="m-b30 bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p>
                        St. Teresa School is an English Medium co-educational
                        and C.B.S.E. affiliated institution. It is managed by
                        Boman charitable trust, a society consisting of
                        educationists, philanthropists and eminent
                        personalities. It aims at developing mental and physical
                        development of each child. It is our commitment to turn
                        out from the portals of St. Teresa School, well
                        balanced, integrated personalities, with an amalgamation
                        of the east and the west. The school intends to set
                        optimism in its students so that they learn & acquire
                        the positive traits of life as how to learn, anywhere,
                        anytime thus enriching their skills continuously
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
