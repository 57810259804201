import React from 'react';
import Header2 from './../Common/Header3';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import WebBanner from './../Elements/ContactUs/WebBanner';
import WebWebSiteTech from './../Elements/ContactUs/WebWebSiteTech';
import WebWebSiteProcess from './../Elements/ContactUs/WebWebSiteProcess';
import WebTypeDesign from './../Elements/ContactUs/WebTypeDesign';
import WebAbout from './../Elements/ContactUs/WebAbout';
import WebWhyChooseUs from './../Elements/ContactUs/WebWhyChooseUs';
import Webclient from './../Elements/ContactUs/Webclient';
import WebAboutNumber from './../Elements/ContactUs/WebAboutNumber';
import WebCustomPlan from './../Elements/ContactUs/WebCustomPlan';
import WebFAQ from './../Elements/ContactUs/WebFAQ';
import CommenEnquiry from './../Elements/CommenEnquiry';
import Testimonials from './../Elements/Testimonials';
import { Helmet } from 'react-helmet';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
             <Helmet>
        <title>Connect with Shadow infosystem: Reach Out Today!</title>
        <meta name="description" content="Contact Shadow infosystem for innovative IT solutions. Reach us for expert consultations, support, and inquiries. Your success begins here." />
        <meta name="keywords" content="Top IT Company in Noida" />
        {/*<meta name="author" content="Shadowinfosystem" />
        <meta property="og:title" content="Shadowinfosystem" />
        <meta property="og:description" content="Shadowinfosystem" />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="My Page Title" />
        <meta name="twitter:description" content="This is a description of my page" />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />*/}
      </Helmet>
                <Header2 />
                <div className="page-content">
                    <WebBanner/>
                    <WebAbout/>
                    
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;