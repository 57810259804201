import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Working with Shadow infosystem has been a
                        transformative experience for our business. As a
                        decorative temples provider, we were looking for a
                        technology partner who could understand the unique
                        aspects of our market and help us reach a broader
                        audience. Shadow infosystem exceeded our expectations in
                        every way.”
                      </p>
                      <h6>Sachin Sharma</h6>
                      <p className="mb__0">Owner, Engravers Sign</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
