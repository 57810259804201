import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class PPCAbout extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/locationpagesimages/6.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h1 className="ert678 heading">
                            Position yourself at the forefront of the
                            competition through our high-performing PPC service
                          </h1>

                          <p>
                            As the premier PPC Company, we specialize in
                            delivering paid advertising solutions across various
                            search engines, meticulously following a strategic
                            approach to ensure optimal returns on investment.
                          </p>
                          <p>
                            At Shadow infosystem, a leading PPC advertising
                            agency, we conceptualize and execute Pay-Per-Click
                            (PPC) campaigns that pave the way for innovative
                            marketing strategies, propelling your business
                            toward 'Digital Success.' Our team of PPC experts
                            meticulously evaluates, designs, and implements
                            profitable paid marketing strategies, leveraging
                            data-driven insights and creative ingenuity to craft
                            campaigns that yield tangible results.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <p>
                        Our customized Pay Per Click solutions are crafted to
                        attract highly relevant visitors to your website,
                        ultimately driving increased sales and generating more
                        leads. Recognizing the time-consuming and challenging
                        nature of profit-oriented campaigns, our team of highly
                        skilled PPC professionals is dedicated to helping you
                        target the optimal set of keywords, manage bids
                        effectively, and streamline overall marketing expenses.
                        Trust us to navigate the intricacies of PPC advertising,
                        ensuring your business stands out and thrives in the
                        digital landscape.
                      </p>
                      <p>
                        Utilizing our PPC strategies can effectively connect you
                        with an undiscovered customer audience. As the top PPC
                        Company, Shadow infosystem meticulously monitors every
                        aspect of your PPC campaigns, refining and optimizing
                        key elements to ensure a heightened and favorable Return
                        on Investment (ROI).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row">
                    <div className="col-md-12"></div>
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h2 className="ert678 heading">
                            How Can We Help You To Grow?
                          </h2>
                        </div>
                      </div>
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <p>
                            Shadow infosystem stands as an elite PPC management
                            company, recommending PPC solutions as a pivotal
                            digital marketing strategy for achieving optimal
                            brand visibility in the shortest timeframe. We
                            deliver a potent impact by enhancing our PPC
                            services with tailor-made campaigns exclusively for
                            you. Within the budget you allocate for PPC, we
                            craft campaigns designed to elevate your
                            advertisements on various search engines, precisely
                            reaching your intended customer demographic.
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <ul className="sdnvdn">
                                <li>
                                  <i className="fa fa-check-square-o"></i>{" "}
                                  &nbsp;Pay per click (PPC)
                                </li>
                                <li>
                                  <i className="fa fa-check-square-o"></i>{" "}
                                  &nbsp;Boost Traffic
                                </li>
                                <li>
                                  <i className="fa fa-check-square-o"></i>{" "}
                                  &nbsp;Pay on Clicks
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul className="sdnvdn">
                                <li>
                                  <i className="fa fa-check-square-o"></i>{" "}
                                  &nbsp;Generate More Leads
                                </li>
                                <li>
                                  <i className="fa fa-check-square-o"></i>{" "}
                                  &nbsp;Increased Sales
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top dafeqff fgghhjkhjjg">
                          <img
                            src={require("./../../../images/locationpagesimages/15.png")}
                            className=""
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PPCAbout;
