import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Trishul Engineering Co</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/trishul/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Trishul Engineering Co</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      A1/122, Kavi Nagar, Industrial Area, Ghaziabad (U.P)
                    </li>
                    <li>
                      <b>Speciality : </b> Transformer Manufacturer
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://www.trishulengineerings.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.trishulengineerings.com
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/trishulengineering.co/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/trishulengineering.co"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/trishul-engineering-co"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://x.com/trishulengco"
                          class="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            class="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@trishulengineeringco"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/trishul/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/trishul/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Trishul Engineering Co. was established at Bulandshahr
                        (Uttar Pradesh) in 1993. The founder of the company is
                        Mr. Sunil Kumar Agarwal, he has vast experience in Power
                        and Distribution Transformer, through constant
                        development and hard work. Trishul is one of the leading
                        Manufacturer of power transformer such as 3 Phase Power
                        Transformer, DPC Winding Wire, Super Enamelled Winding
                        Wire and Strip of Aluminium and Copper.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
