import React from 'react';
import { NavLink } from 'react-router-dom';

const teamMembers = [
    {
        image: require('./../../../images/products/ashoka.png'),
        membername: 'Ashoka Institute',
        position: 'Education'
    },
    {
        image: require('./../../../images/products/Dees.png'),
        membername: 'Dees Bake Studio',
        position: 'eCommerce    '
    },
    {
        image: require('./../../../images/products/cleo.png'),
        membername: 'Cleo Gold Noida',
        position: 'Real Estate'
    },
    {
        image: require('./../../../images/products/vital.png'),
        membername: 'Vital Radiology',
        position: 'Hospital'
    }
]

var bgimg1 = require('./../../../images/background/cross-line2.png');

class Team1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 mobile-page-padding asfeewg96554">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="">
                                    <h3 className="ert678">Some jaw dropping work we've done</h3>
                                    <p>This is where computer science takes shape and<br/> our roster of talent is positioned to understand both client and computing needs that leverage the overall experience.</p>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row team-item-four">
                                {teamMembers.map((item, index) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 m-b30" key={index}>
                                        <div className="our-team-2 ">
                                            <div className="profile-image">
                                                <img src={item.image} alt="" />
                                                
                                            </div>
                                            <div className="figcaption text-black">
                                                <h4 className="m-t0"><NavLink to={"/team-single"}>{item.membername}</NavLink></h4>
                                                <span className="m-b0">{item.position}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Team1;