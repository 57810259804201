import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             
                        <div className="qpqq">
                          
                            <div className="row">
                              <div className="col-xl-1 col-lg-12 col-md-12">
                                  
                                </div>
                            <div className="col-xl-10 col-lg-12 col-md-12">
                                    <div className="m-b30 bg-white">
                                        
                                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/projects/logo_designing.png')} alt="" />

                                        
                                    </div>
                                        
                                    </div>
                                </div>
                                <div className="col-xl-1 col-lg-12 col-md-12">
                                     
                                </div>
                                
                                
                                </div>
                                
                        </div>
                        
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;