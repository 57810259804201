import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 back-ground-color`}
        >
          <div className="container">
            <div className="section-content">
              <div className="">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                    <h3 className="ert678">
                      We Commit To World-class Services That Set Great Business
                      Standards
                    </h3>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <img src={require("./../../../images/sdf.png")} alt="" />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="">
                      <h4 className="dfghjdf33k">Our Mission</h4>
                      <p>
                        At Shadow infosystem, our mission is to empower
                        businesses with cutting-edge IT solutions, robust
                        cybersecurity measures, and digital marketing
                        strategies. We are committed to delivering innovative
                        and tailored services that not only meet but exceed the
                        evolving needs of our clients. Through our expertise and
                        unwavering dedication, we strive to be the catalyst for
                        our clients' success in the ever-changing landscape of
                        technology.
                      </p>
                    </div>
                    <div className="">
                      <h4 className="hg456">Our Vision</h4>
                      <p>
                        Our vision at Shadow infosystem is to be a trailblazer
                        in the IT industry, recognized for our excellence in
                        cyber security and digital transformation. We aspire to
                        be the go-to partner for businesses seeking reliable and
                        advanced technological solutions. With a focus on
                        continuous innovation, client satisfaction, and ethical
                        practices, we envision a future where businesses thrive
                        in a secure and digitally optimized environment, and
                        Shadow infosystem is at the forefront of this
                        transformation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
