import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        image: require('./../../../images/icon/cms.png'),
        title: 'Custom CMS',
        description: 'Content Management and Authoring, News Feed Management, Media Distribution, Editorial Tools',
    },
    {
        image: require('./../../../images/icon/webpage.png'),
        title: 'Website & Portals',
        description: 'Content Management and Authoring, News Feed Management, Media Distribution, Editorial Tools',
    },
    {
        image: require('./../../../images/icon/ecommerce.png'),
        title: 'E-Commerce',
        description: 'Content Management and Authoring, News Feed Management, Media Distribution, Editorial Tools',
        
    },
    {
        image: require('./../../../images/icon/portfolio.png'),
        title: 'E-Learning',
        description: 'Content Management and Authoring, News Feed Management, Media Distribution, Editorial Tools',
    },
    {
        image: require('./../../../images/icon/partners.png'),
        title: 'Social Networking',
        description: 'Content Management and Authoring, News Feed Management, Media Distribution, Editorial Tools',
    },
    
]

var bgimg1 = require('./../../../images/background/bg-5.png');
var bgimg2 = require('./../../../images/background/cross-line2.png');

class WhatWeDo2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full  mobile-page-padding bg-white  p-t80 p-b50 bg-repeat overflow-hide" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="blur-bg-blocks">
            <aside className="blur-bg-set">
               <div className="blur-bg blur-bg-a"></div>
               <div className="blur-bg blur-bg-b"></div>
               <div className="blur-bg blur-bg-c"></div>
            </aside>
         </div>
                    <div className="container">
                        <div className="large-title-block text-center">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="">
                                        <h3 className="">Our Expertise</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        </div>
                        <div className="section-content">
                            <div className="row number-block-two-outer">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                                            <div className="figcaption bg-white  p-a30">
                                            <img className="what-we-do-icon" src={item.image} alt="" />
                                                <h4 className="m-t0">{item.title}</h4>
                                                <p>{item.description}</p>
                                                
                                                <div className="figcaption-number animate-in-to-top-content">
                                                    <span>{item.count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WhatWeDo2;