import React from "react";
import { NavLink } from "react-router-dom";
import MoreInform from "./../MoreInform";
var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row">
              <div className="col-md-7">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        Unveiling the Path to Digital Excellence: Our Success
                        Stories
                      </h3>
                      <p className="mb__0">
                        Know our inspiring success stories across diverse
                        sectors like E-commerce, Logistics, Education,
                        Healthcare, Real Estate, and more, as we take a step
                        closer to achieving digital excellence.
                      </p>
                    </div>
                  </div>

                  <MoreInform />
                </div>
              </div>
              <div className="col-md-5 d_flex_dir_col_justify_center">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/2x_casestudy_all.gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
