import React from "react";

const overview = [
  {
    icon: <i class="fa fa-globe"></i>,
    heading: "Optimize website structure and content",
    description:
      "Make sure the radiology service provider's website is easy to navigate, loads quickly, and has a responsive design. Also, optimize the website content with relevant keywords, meta tags, and descriptions.",
  },
  {
    icon: <i class="fa fa-link"></i>,
    heading: "Build quality backlinks",
    description:
      "Quality backlinks from reputable websites can boost the radiology service provider's website authority and visibility on search engines. You can achieve this by creating high-quality, informative, and shareable content that people will want to link to.",
  },
  {
    icon: <i class="fa fa-laptop"></i>,
    heading: "Monitor online reviews",
    description:
      "Encourage customers to leave positive reviews on relevant sites. Address negative feedback promptly and professionally to maintain a reputation. Customer feedback shapes reputation, and we're committed to ensuring your experience is positive every step of the way.",
  },
  {
    icon: <i class="fa fa-google"></i>,
    heading: "Leverage social media",
    description:
      "Social media platforms can be a powerful tool for promoting the radiology service provider's business and engaging with customers. Create and manage social media profiles for the radiology service provider on popular platforms such as Facebook, Twitter, and LinkedIn, and post regular updates and engaging content.",
  },
  {
    icon: <i class="fa fa-pie-chart"></i>,
    heading: "Conduct a keyword analysis",
    description:
      "Start by researching the keywords that are most relevant to the radiology service provider's business. This can help you identify which terms potential customers are using to search for these services online. Use this information to optimize the provider's website, blog posts, and other digital content for these keywords.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-b30 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p>
                    As an IT company, there are several ways you can provide SEO
                    and SMO benefits to a radiology service provider. Here are
                    some strategies you can consider:
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div class="card-wrap">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h1 class="card-title">{item.heading}</h1>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
