import React from "react";
import { NavLink } from "react-router-dom";
import MoreInform from "./../MoreInform";

var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class Ibanner extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="section-head">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        Informatica Empowers The Next-Gen Cloud Data Innovation
                      </h3>
                      <p>
                        Get the expertise of Informatica to transform data into
                        a strategic asset, empowering informed decision-making
                        and driving business success.
                      </p>
                    </div>
                  </div>
                  <MoreInform />
                </div>
              </div>
              <div className="col-md-4 p-0">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/head-icon/informatica.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default Ibanner;
