import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

function termBanner() {
  return (
    <>
      <div
        className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div className="container ">
          {/* TITLE START */}
          <div className="row d-inline-flex align-items-center">
            <div className="col-md-6">
              <div className="section-head mnbv123">
                <div className="sx-separator-outer ">
                  <div className="bg-repeat-x color-change ewgr8900">
                    <h3 className="ert678">TERMS & CONDITIONS</h3>
                    <p>Grow Organically on Google's First Page</p>
                  </div>
                </div>
                <div className="buttonfgerb">
                  <div className="header-section-12">
                    <NavLink
                      to={"/contact-us"}
                      rel="bookmark"
                      className="hs12-button"
                    >
                      Get Free Quote
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="offset-md-1 col-md-4">
              <div className="mnbv1231">
                <img src={require("./../../../images/update1/3.webp")} alt="" />
              </div>
            </div>
          </div>
          {/* TITLE END */}
        </div>
      </div>
    </>
  );
}

export default termBanner;
