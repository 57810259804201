import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

import EMTableIcon1 from "./../../../images/engagement-models/table-icon-1.svg";
import EMTableIcon2 from "./../../../images/engagement-models/table-icon-2.svg";
import EMTableIcon3 from "./../../../images/engagement-models/table-icon-3.svg";
import EMTableIcon4 from "./../../../images/engagement-models/table-icon-4.svg";

var engagementModelGIF = require("./../../../images/engagement-models/engagement-model-gif.gif");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding em-section-padding engagement-models-table-section`}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-7 d-flex-dir-col-justify-center">
                <div className="em-table-container">
                  <div className="table-responsive">
                    <table class="table">
                      <tbody>
                        <tr className="em-table-row-icons">
                          <th scope="row">
                            <img src={EMTableIcon1} alt="" />
                          </th>
                          <td>
                            <img src={EMTableIcon2} alt="" />
                          </td>
                          <td>
                            <img src={EMTableIcon3} alt="" />
                          </td>
                          <td>
                            <img src={EMTableIcon4} alt="" />
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Size of the project</th>
                          <td>Small & Medium</td>
                          <td>Medium & Large</td>
                          <td>Large</td>
                        </tr>

                        <tr>
                          <th scope="row">Requirements</th>
                          <td>Defined</td>
                          <td>Evolving</td>
                          <td>Evolving</td>
                        </tr>

                        <tr>
                          <th scope="row">Flexibility</th>
                          <td>-</td>
                          <td>-</td>
                          <td>+</td>
                        </tr>

                        <tr>
                          <th scope="row">Client's Control</th>
                          <td>Little</td>
                          <td>Significant</td>
                          <td>Full</td>
                        </tr>

                        <tr>
                          <th scope="row">Methodology</th>
                          <td>Waterfall</td>
                          <td>Agile</td>
                          <td>Agile</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5">
                <div className="em-section-1-gif">
                  <img src={engagementModelGIF} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
