import React from "react";

const technology = [
  {
    image: require("./../../../images/solution/ci.webp"),
  },
  {
    image: require("./../../../images/solution/php.webp"),
  },
  {
    image: require("./../../../images/solution/html.webp"),
  },
  {
    image: require("./../../../images/solution/css.webp"),
  },
  {
    image: require("./../../../images/solution/js.webp"),
  },
  {
    image: require("./../../../images/solution/sql.webp"),
  },
];

function Techstack() {
  return (
    <>
      <div className="section-full mobile-page-padding p-b10 bg-repeat">
        <div className="container">
          <div className="row">
            <div className="col-md-12 sx-separator-outer separator-center">
              <div className="case-banner">
                <h2 class="ert678">Technology We Are Using</h2>
              </div>
            </div>
            <div className="col-md-12 sx-separator-outer separator-center"></div>
          </div>
          <div className="row">
            {technology.map((item, index) => (
              <div
                className="col-lg-2 col-md-4 col-sm-6 col-xs-6 text-center"
                key={index}
              >
                <img className="i-style" src={item.image} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Techstack;
