import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Allways Logistics</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo">
                  <img
                    src={require("./../../../images/allways/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Allways Logistics</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> 3rd Floor, A 24/5 Mohan Co-operative
                      Industrial Estate, New Delhi–110044
                    </li>
                    <li>
                      <b>Services:</b> Logistics
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://www.allwayslogisticsgroup.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.allwayslogisticsgroup.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/AllWaysLogisticsIndiaPrivateLimited"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/allwayslogisticsindia/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/all-ways-logstics-india/"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/ways_india"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/allways/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/allways/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__15">
                        All-ways provides integrated logistics services through
                        all – modes (Air, ocean, ground) and – ways (road,
                        rail). We are your one-stop solution for comprehensive
                        logistics services in India, be it freight service for
                        import and export, 3PL or 4PL, LTL or FTL cargo
                        handling. We deliver it. Seamlessly.
                      </p>

                      <p className="mb__15">
                        Our long-standing presence, expertise, and vast network
                        mean that there are no hitches in custom clearance and
                        you don’t have to worry about your shipments.
                      </p>

                      <p className="mb__0">
                        We consolidated our efforts in 2008 with a dedicated
                        team of seasoned logistics professionals to fill the
                        gaps in logistics services to meet the growing demand
                        for faster and quality services at affordable rates.
                        Ever since innovation and efficiency have been our
                        driving force to reduce costs and deliver top-tier
                        services to our customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
