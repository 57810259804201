import React from "react";
import Header2 from "./../../Common/Header3";
import Footer2 from "./../../Common/Footer2";
import Banner from "./../../Elements/CaseStudyOzoneAyurvedic/Banner";
import Innovation from "./../../Elements/CaseStudyOzoneAyurvedic/Innovation";
import Overview from "./../../Elements/CaseStudyOzoneAyurvedic/Overview";
import Techstack from "./../../Elements/CaseStudyOzoneAyurvedic/Techstack";
import Challenges from "./../../Elements/CaseStudyOzoneAyurvedic/Challenges";
import Solutions from "./../../Elements/CaseStudyOzoneAyurvedic/Solutions";
import VideoBg from "./../../Elements/CaseStudyOzoneAyurvedic/VideoSection";
import Results from "./../../Elements/CaseStudyOzoneAyurvedic/Results";
import Social from "./../../Elements/CaseStudyOzoneAyurvedic/Social";
import ClientsReview from "./../../Elements/CaseStudyOzoneAyurvedic/ClientReview";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../../images/banner/5.jpg");

class OurHistory extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Case Study | Ozone Ayurvedics</title>
          <meta name="description" content="Case Study | Ozone Ayurvedics" />
          <meta name="keywords" content="Case Study | Ozone Ayurvedics" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/case-study-ozoneayurvedics"
          />
        </Helmet>
        <Header2 />
        <div className="page-content case__study__page">
          <Banner />
          {/* <Overview /> */}
          <Challenges />
          {/* <Solutions /> */}
          <VideoBg />
          <Results />
          <Social />
          {/* <Techstack /> */}
          {/* <Innovation /> */}
          <ClientsReview />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default OurHistory;
