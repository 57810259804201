import React from 'react';
import { NavLink } from 'react-router-dom';

const logos = [
    { image: require('./../../images/client-logo/client-logo107-h-2.png') },
    { image: require('./../../images/client-logo/client-logo28-h-1.png') },
    { image: require('./../../images/client-logo/client-logo10-h-1.png') },
    { image: require('./../../images/client-logo/client-logo2-h-1.png') },
    { image: require('./../../images/client-logo/client-logo49-h-1.png') },
    { image: require('./../../images/client-logo/acer-inches-2.jpg') },
    { image: require('./../../images/client-logo/logo_644acc1716add67c03b0a6510d37ff8e_2x.png') },
    { image: require('./../../images/client-logo/Sara-Consultancy-200.png') },
   
 
    
]


var bgimg1 = require('./../../images/background/bg-12.jpg');
var bgimg2 = require('./../../images/background/cross-line2.png');


class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding bg-gray  p-t80 p-b10 bg-repeat">
                <div className="blur-bg-blocks">
            <aside className="blur-bg-set">
               <div className="blur-bg blur-bg-a"></div>
               <div className="blur-bg blur-bg-b"></div>
               <div className="blur-bg blur-bg-c"></div>
               <div className="blur-bg blur-bg-d"></div>
            </aside>
         </div>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                                <div className="bg-repeat-x color-change">
                                    <h3 className="">Loved By 3000+ Customers <br/>All Over The World</h3>
                                    <h6>Our greatest asset is the customers and clients Satisfaction!</h6>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b40">
                           
                                <div className="row">
                                
                                 <div className="col-md-12">
                                 <div className="row justify-content-center">
                                    {logos.map((item, index) => (
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-6 m-b30" key={index}>
                                            <NavLink to={"#"} className="client-logo-pic1">
                                                <img src={item.image} alt=""/>
                                                
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                                 </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Clients</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default ClientsLogo1;