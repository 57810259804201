import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing User-Friendly & Aesthetic Website",
    description:
      "Creating a visually appealing and user-friendly website for the nail salon was a significant challenge. The website needed to reflect the salon’s brand identity while providing a seamless user experience. This involved designing an intuitive navigation structure, showcasing services and prices clearly, and integrating high-quality images and videos of the salon’s work. We ensured that the website was both functional and aesthetically pleasing to attract and retain visitors.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Mobile Optimization",
    description:
      "With many customers searching for nail salons on their mobile devices, ensuring that the website was fully optimized for mobile use was critical. It was important for us to develop a responsive website that provided an excellent user experience across all devices, from desktops to smartphones. This required careful attention to design elements, loading speeds, and mobile-specific features, ensuring the site was accessible and easy to navigate on smaller screens.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Keyword Research and Local SEO",
    description:
      "Identifying the right keywords to target was essential for driving traffic to the nail salon’s website. For that needed to conduct thorough keyword research to find relevant and high-traffic search terms related to nail services. Additionally, local SEO was particularly important for the salon’s growth, as potential customers often search for nearby services. This included optimizing for location-based keywords, managing Google My Business listings etc.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Measuring and Analyzing SEO Performance",
    description:
      "Tracking the effectiveness of SEO efforts and making data-driven adjustments was an ongoing challenge. For that needed to implement robust analytics tools to monitor key metrics such as website traffic, conversion rates, and keyword rankings. Regular analysis of this data was necessary to identify successful strategies and areas for improvement, ensuring that the SEO tactics remained effective and aligned with the salon’s business goals.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
