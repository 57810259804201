import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Jindal Tiles</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo normal_logo">
                  <img
                    src={require("./../../../images/jindal_tiles/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Jindal Tiles</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      441/8, 1st Floor, Shri Ram Chowk, Kala Pathar Rd, Nyay
                      Khand III, Indirapuram, Ghaziabad - 201014
                    </li>
                    <li>
                      <b>Speciality : </b> Interiors & Furniture
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://www.jindaltiles.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.jindaltiles.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>

                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/jindaltile"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/jindaltile/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCkeUpun1IHmc8dEGV0PmroA"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/jindal_tiles/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/jindal_tiles/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Jindal Tiles is a Subsidiary Holding of RNPM Global
                        Trading Co. which started their journey back in the year
                        2018. They began with a plan to buy first quality tile
                        and sanitaryware directly from the factories, stack the
                        product from floor to ceiling in a warehouse-sized store
                        and price it lower than anyone else around. Also, from
                        starting their priority had always been to maximize
                        customer satisfaction in all expects of their dealing,
                        either it be with regards to prices, quality, range of
                        products, employee behavior, cleanliness at our stores,
                        timely delivery of material etc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
