import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1>David The Tree Guy</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo">
                  <img
                    src={require("./../../../images/david/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">DavidTheTreeGuy</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> Suite 103, 3610, 43 Ave NW, Edmonton AB
                      T6L 5T2
                    </li>
                    <li>
                      <b>Services:</b> Tree Cutting & Pruning
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://www.davidthetreeguy.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.davidthetreeguy.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/davidthetreeguyyeg"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/davidthetreeguy1/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/DavidtheTreeGu1"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@davidthetreeguy"
                          target="_blank"
                          className="youtube"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/david/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/david/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="m-b30 bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p>
                        Servicing Edmonton and surrounding areas, David the Tree
                        Guy brings 18 years of experience removing, pruning, and
                        maintaining trees and shrubs of all sizes. They offer
                        free, no-obligation quotes. David is certified under the
                        International Society of Arboriculture (or otherwise
                        known as ISA–Certificate # PR-5013A), and holds a
                        Diploma in Arboriculture. His primary specialty is fruit
                        tree pruning and can provide seasonal recommendations
                        for this service. He offer fully-insured services for
                        residential and commercial areas from rural farms to
                        urban dwellings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
