import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “We partnered with Shadow to enhance our digital
                        presence, and the results have been transformative for
                        our business. As a manufacturer of transformers, our
                        industry is highly specialized and technical. Shadow not
                        only understood these complexities but also delivered
                        exceptional results across all digital fronts.”
                      </p>
                      <h6>Harshit Agarwal</h6>
                      <p className="mb__0">Trishul Engineering Corporation</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
