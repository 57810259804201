import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h3 className="wfgrbwe ert678 mb-3">
                    We Manage Your Processes So That You Focus On Business!
                  </h3>
                  <p>
                    Manage your cloud infrastructure more efficiently and
                    securely with our robust cloud hosting solutions. Our
                    services give you the best of both worlds- the power of the
                    cloud with business and technical expertise that lets you
                    focus on your business. We manage all your critical
                    processes and lower the operational cost for your seamless
                    business growth.
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white website-deve">
                      <div className="sx-post-title iconse">
                        <img
                          src={require("./../../../images/icon/logo (3).png")}
                          alt=""
                        />
                        <h4 className="post-title">AWS Managed Server</h4>
                      </div>

                      <div className="">
                        <p>
                          It ensures peak performance, scalability, and security
                          for a seamless cloud computing experience.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white website-deve">
                      <div className="sx-post-title iconse">
                        <img
                          src={require("./../../../images/head-icon/cloud-3.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Managed VPS Server</h4>
                      </div>

                      <div className="">
                        <p>
                          It delivers top-tier performance, security, and expert
                          support, ensuring a reliable and efficient hosting
                          solution for businesses.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white website-deve">
                      <div className="sx-post-title iconse">
                        <img
                          src={require("./../../../images/head-icon/cloud-2.webp")}
                          alt=""
                        />

                        <h4 className="post-title">Managed Share Server</h4>
                      </div>

                      <div className="">
                        <p>
                          Lightning Fast Website Optimize your website
                          performance with varnish cache enabled shared linux
                          hosting plans.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info  bg-white website-deve">
                      <div className="sx-post-title iconse">
                        <img
                          src={require("./../../../images/head-icon/cloud-1.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Managed Email Services</h4>
                      </div>

                      <div className="">
                        <p>
                          It offers seamless communication, ensuring
                          reliability, security, and efficiency for your
                          business correspondence needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
