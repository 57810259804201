import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Cost Management",
    description:
      "An IT company can provide software solutions for tracking expenses, managing inventory, and optimizing supply chain management. These tools can help dental clinics reduce costs and improve efficiency.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Technology & Regulatory Compliance",
    description:
      "An IT company can help dental clinics stay up-to-date with the latest technology and regulatory requirements. They can provide guidance on implementing new systems and tools, as well as ensure compliance with HIPAA and other regulations.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Patient Acquisition & Retention",
    description:
      "An IT company can help dental clinics build their online presence and reputation through website design, search engine optimization, and social media management. They can also help clinics implement patient retention strategies, such as automated appointment reminders and personalized marketing campaigns.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Staff Management",
    description:
      "An IT company can provide software solutions for managing employee schedules, training, and performance reviews. They can also provide tools for automating HR tasks, such as payroll and benefits administration.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
