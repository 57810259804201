import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink } from "react-router-dom";

const testimonials = [
  {
    image: require("./../../../images/testimonials/harshakakkar-img-140x140@2x.png"),
    reviewername: "Maj Gen Harsha Kakar (Retd)",
    position: "Gen",
    review:
      "It has been a pleasure dealing with Shadow. Their professionalism, dedication, positivity, and sincerity in work impressed me immensely.  Their response to every query, requirement, and change in setting was acted upon immediately. I would always recommend Shadow for those who seek satisfaction in software solutions.",
  },
  {
    image: require("./../../../images/testimonials/manash-img-140x140@2x.png"),
    reviewername: "Manas Dasgupta",
    position: "Gen",
    review:
      "From BCIC, we are very happy with the flexibility that you have provided to us and openness to feedbacks always.",
  },
  {
    image: require("./../../../images/testimonials/ravi-img1-140x140@2x.png"),
    reviewername: "Maj Gen Ravi Arora (Retd)",
    position: "Gen",
    review:
      "Shadow infosystem has a dynamic and competent team. Their range of work is diverse. Delivery is timely. Technical support is effective. In short they live up to their promise.",
  },
];

var bgimg1 = require("./../../../images/background/bg6.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class Testimonials2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      margin: 30,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
      },
    };
    return (
      <>
        <div className="section-full mobile-page-padding dfbb7680978 bg-repeat p-t80 p-b80">
          <div className="section-content">
            <div className="container">
              {/* TESTIMONIAL START */}
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel
                    className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                    {...options}
                  >
                    {testimonials.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="testimonial-1 hover-animation-1">
                          <div className="testimonial-detail clearfix">
                            <div className="testimonial-pic  scale-in-center">
                              <img
                                src={item.image}
                                alt=""
                                width={100}
                                height={100}
                              />
                            </div>

                            <h4 className="testimonial-name">
                              {item.reviewername}
                            </h4>
                            <span className="fa fa-quote-right" />
                          </div>
                          <div className="testimonial-text">
                            <p>{item.review}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonials2;
