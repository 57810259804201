import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x text-center">
                  <h3 className="wfgrbwe ert678 mb-3">
                    Why Choose Shadow infosystem for Social Media Marketing?
                  </h3>
                  <p>
                    At Shadow infosystem, our team comprises innovative and
                    strategic social media marketing professionals dedicated to
                    empowering startups, medium-sized enterprises, and brands to
                    excel in the digital landscape. When it comes to selecting a
                    partner for social media marketing, Shadow infosystem stands
                    out for several compelling reasons:
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content sfsfcsc13232">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white digtal-choo servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                          alt=""
                        />
                        <h4 className="post-title">Extensive Experience</h4>
                      </div>

                      <div className="">
                        <p>
                          With over 15 years of industry experience, we bring a
                          deep understanding of the intricacies of social media
                          marketing. This extensive experience equips us to
                          handle a diverse range of challenges and deliver
                          effective solutions.
                        </p>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white digtal-choo servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/determine_the_business_development_plan.png")}
                          alt=""
                        />
                        <h4 className="post-title">Proven Track Record</h4>
                      </div>

                      <div className="">
                        <p>
                          We have a solid track record of success. We have
                          consistently delivered impactful social media
                          marketing strategies and campaigns that have driven
                          tangible results for clients across various industry
                          sectors.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white digtal-choo servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Technical Proficiency</h4>
                      </div>

                      <div className="">
                        <p>
                          Our team possesses unmatched technical expertise in
                          SMM. We stay updated with the latest trends, tools,
                          and technologies, ensuring that we leverage the most
                          effective strategies to achieve clients' objectives.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white digtal-choo servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                          alt=""
                        />
                        <h4 className="post-title">Tailored Solutions</h4>
                      </div>

                      <div className="">
                        <p>
                          Recognizing that each client is unique, we customize
                          our strategies to align with specific business goals
                          and objectives. This personalized approach ensures
                          that clients receive solutions that are tailored to
                          their individual needs, maximizing effectiveness.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white digtal-choo servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Transparent Communication
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          We believe in fostering open and transparent
                          communication with clients throughout the engagement
                          process. We keep clients informed at every stage,
                          providing clear insights into the progress of
                          campaigns and strategies.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white digtal-choo servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Customer Satisfaction</h4>
                      </div>

                      <div className="">
                        <p>
                          We are committed to ensuring the satisfaction of our
                          clients. Our primary goal is to deliver best results
                          that not only meet but exceed client expectation. This
                          unwavering dedication fosters long-term partnerships
                          built on trust and mutual success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
