import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                    <div className="col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/seo-1.webp")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h1 className="ert678 heading">
                            Elevate Your Revenue With the Best SEO Agency
                          </h1>

                          <p>
                            Search Engine Optimization (SEO) is the strategic
                            practice of enhancing website visibility on search
                            engines like Google. With over 15+ years of
                            experience in the field, Shadow infosystem
                            specializes in optimizing off page and on page SEO,
                            technical SEO, meta tags, content, and backlinks, to
                            elevate a website's ranking in search results. The
                            primary objective of our SEO approach is to attract
                            organic traffic, augmenting the site's relevance and
                            authority in the eyes of search engines.
                          </p>
                          <p>
                            As the best SEO services provider, Shadow infosystem
                            meticulously develops customized SEO strategies that
                            merge compelling content with informed, data-driven
                            decisions. This strategic approach is vital for
                            planning successful marketing strategies. We
                            recognize that in today's digital landscape, a
                            robust online presence is essential for overcoming
                            the challenges of revenue generation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <p>
                        While SEO plays a pivotal role, we understand that a
                        comprehensive approach requires a keen focus on the
                        overall user experience. This necessitates the creation
                        of an interactive website that seamlessly delivers
                        relevant information in optimal locations. Our
                        commitment to delivering an exceptional user experience
                        is an integral part of our methodology.
                      </p>
                      <p>
                        Acknowledging the dynamic nature of the SEO landscape,
                        our team at Shadow infosystem remains diligently
                        informed about industry innovations. With a wealth of
                        experience and an in-depth understanding of search
                        engine mechanisms, we adeptly craft and implement
                        effective SEO strategies. Our goal is to ensure optimal
                        results for our clients, leveraging our extensive years
                        of experience in the ever-evolving field of search
                        engine optimization.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-center">
                    {/* <div className="col-md-12">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui"></div>
                      </div>
                    </div> */}
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h2 className="ert678 heading mb-3">
                            Boost Your Online Presence with the best SEO
                            Services Company
                          </h2>
                          <ul className="sdnvdn">
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Adapting promptly to algorithm updates and
                              industry trends
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Tailoring SEO strategies to meet the
                              distinct requirements of your business
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Conduct in-depth competitor analysis to stay
                              ahead in the evolving SEO landscape.
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Ensuring transparent reporting and
                              facilitating seamless communication
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Strategically optimizing keywords to boost
                              your website's prominence in search engine results
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 offset-md-1">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top dafeqff fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/seo-3.webp")}
                            className=""
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
