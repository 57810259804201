import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Managing and optimizing complex supply chains",
    description:
      "Transport and logistics companies need to handle complex supply chains involving multiple modes of transport, storage, and distribution points. This can be a challenging task, especially when dealing with a large volume of goods.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Ensuring timely delivery",
    description:
      "Customers expect timely delivery of their goods, which can be a challenge for transport and logistics companies. Any delay in delivery can lead to customer dissatisfaction and loss of business.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Managing inventory and warehouse operations",
    description:
      "Transport and logistics companies need to manage inventory and warehouse operations efficiently to ensure timely delivery of goods. This can be a challenge, especially when dealing with a large volume of goods.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Digital documentation",
    description:
      "An IT company can provide digital documentation solutions that streamline the process of managing and sharing shipping documents, reducing the risk of errors and delays.",
  },
  {
    number: <div class="number fontColor5">5</div>,
    heading: "Route optimization",
    description:
      "By partnering with an IT company that provides transportation management software, a transport and logistics company can optimize its routes and schedules, reducing delivery times, fuel costs, and vehicle wear and tear.",
  },
  {
    number: <div class="number fontColor6">6</div>,
    heading: "Warehouse management",
    description:
      "IT companies can provide warehouse management software that enables efficient inventory management, improves order accuracy, and reduces the risk of stockouts.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
