import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import SEOServicesCompanyNoidaHome from "./../Elements/SEOServicesCompanyNoidaHome";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best SEO Services Company in Noida | shadow infosystem</title>
          <meta name="description" content="Shadow infosystem is the best SEO services company in Noida, offering expert solutions to improve your search engine rankings and drive targeted traffic." />
          <meta name="keywords" content="Best SEO services company in Noida | SEO services in Noida" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/seo-services-company-noida" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <SEOServicesCompanyNoidaHome />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
