import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Our experience with Shadow infosystem has been nothing
                        short of outstanding. As a provider of interior and
                        furniture tiles, our business thrives on aesthetics,
                        quality, and a keen understanding of design trends.
                        Shadow infosystem not only grasped these core aspects
                        but also enhanced our digital presence in ways we
                        couldn't have imagined.”
                      </p>
                      <h6>Paridhi Garg</h6>
                      <p className="mb__0">Owner, Jindal Tiles</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
