import React from "react";

function PartnerAbout() {
  return (
    <>
      <div
        className={`section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
      >
        <div className="container-fluid">
          <div className="section-content">
            <div className="container">
              <div className="section-head">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="m-b30 bg-white">
                      <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                        <img
                          src={require("./../../../images/partner/partner1.webp")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <h1 className="ert678 heading">
                          Our Digital Marketing Partnership Program
                        </h1>

                        <p>
                          Introducing the Shadow infosystem Digital Marketing
                          Partnership Program (DMPP), tailored for agencies in
                          need of comprehensive digital marketing support. Our
                          partner program is crafted to offer tangible benefits
                          to a network of like-minded companies. It's centered
                          on creating a win-win relationship.
                        </p>
                        <p>
                          Specializing in SEO, Google Marketing Solutions, and
                          Social Media Marketing, our dedicated team provides
                          full consultation and technical support with prompt
                          reporting.
                        </p>
                      </div>
                    </div>
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <div className="ert678 sub-heading mb-3">
                          Here’s how it works:
                        </div>
                        <ul className="sdnvdn">
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;{" "}
                            <b>Client Engagement:</b> You introduce your clients
                            to us, and we seamlessly support them under your
                            brand.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;
                            <b>Streamline Your Focus:</b> Focus on your core
                            expertise while ensuring your client receives
                            top-notch digital marketing services from us.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;
                            <b>Client Care:</b> Rest assured, we handle all
                            client inquiries and issues promptly and
                            professionally.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerAbout;
