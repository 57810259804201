import React from 'react';
import { NavLink } from 'react-router-dom';




var bgimg1 = require('./../../../images/banner/web-development-banner.jpg');
var bgimg2 = require('./../../../images/background/cross-line2.png');


class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                
                    <div className="container ">
                        {/* TITLE START */}
                        <div className="row">
                            <div className="col-md-7">
                                <div className="section-head mnbv123">
                            <div className="sx-separator-outer ">
                                <div className="bg-repeat-x color-change ewgr8900">
                                    <h3 className="ert678">Logo Design Services To Enhance Your Brand Identity!</h3>
                                    <p>Be it a startup or an enterprise, we’ll take care of your web design needs.</p>
                                </div>
                            </div>
                            <div className="buttonfgerb">
                                                        <div className="header-section-12">
                                                            <NavLink to={"#"} rel="bookmark" className="hs12-button">
                                                                Get Free Quote
                                                            </NavLink>
                                                        </div>

                                                       
                                                    </div>
                        </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mnbv1231 ergfcvd09u">
                                    <img src={require('./../../../images/graphic-design-GIF-1.gif')} alt="" />
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        
                        
                        
                    </div>
                    
                </div>
            </>
        );
    }
};

export default ClientsLogo1;