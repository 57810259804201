import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const projects = [
    { image: require('./../../images/client-logo/Angular-JS.png') },
    { image: require('./../../images/client-logo/nord-js.png') },
    { image: require('./../../images/client-logo/React-JS.png') },
    { image: require('./../../images/client-logo/net-1.png') },
    { image: require('./../../images/client-logo/php.webp') },
    { image: require('./../../images/client-logo/laravel-2.png') },
    { image: require('./../../images/client-logo/woocommerce-1.png') },
    { image: require('./../../images/client-logo/magento.webp') },
    { image: require('./../../images/client-logo/shopify-1.png') },
    { image: require('./../../images/client-logo/joomla-1.webp') },
    { image: require('./../../images/client-logo/wordpress-1 (1).png') }
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class Projects4 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        const options = {
            loop:true,
            autoplay:true,
            center: false,
            items:3,
            margin:40,
            nav:false,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1,
                    margin:15
                },
                240:{
                    items:2,
                    margin:15
                },
                440:{
                    items:3,
                    margin:15
                },
                600:{
                    items:4,
                    margin:20,
                },
                800:{
                    items:5,
                    margin:20,
                },
                1000:{
                    items:6,
                    margin:20,
                },
                1200:{
                    items:7,
                    margin:20,
                }      
                
            }
        };
        return (
            <>
                <div className="section-full inner-page-padding tech-animat p-b50">
                    <div className="container-fluid">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="ertyui">
                                        <h3 className="">SUCCESS ON SOCIAL MEDIA PLATFORMS!</h3>
                                        <p>Social media has emerged as a powerful marketing tool in recent years. Right from multibillion multinationals to small startup companies are using this incredible tool to reach out to their customers. Where it is Facebook, Instagram, LinkedIn, Twitter, YouTube and Pinterest, social media marketing creates a platform whereby you can connect, interact and get their feedback directly. Social media gives you an insight into what your customers are looking for and that’s why social media marketing services cannot be ignored.</p>
                                        
                                        <p>We are a result-oriented Social media management agency. Social media gives you an insight into what your customers are looking for and that’s why social media marketing services cannot be ignored. From creating eCommerce stores on social media to creating compelling marketing campaigns, Shadow infosystem is a social media marketing Agency, Mumbai with the expertise to make it work for you.</p> 
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                                <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel4 owl-btn-vertical-center" {...options}>
                                    {projects.map((item, index) => (
                                        <div key={index} className={`${item.filter} item fadingcol overflow-hide`}>
                                            <div className="sx-box">
                                                <div className="">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                                


                            </div>
                            


                        </div>

                    </div>
                </div>

            </>
        );
    }
};

export default Projects4;