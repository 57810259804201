import React from "react";

function About2() {
  return (
    <>
      <div class="undefined section-full mobile-page-padding p-b50 p-t80 back-ground-color">
        <div class="container-fluid">
          <div class="section-content">
            <div class="qpqq">
              <div class="row p-0 mnmnmnghhtht">
                <div class="col-xl-6 col-lg-6 col-md-6 sfdve6764534lkjhgf">
                  <div class="section-head">
                    <div class="sx-separator-outer">
                      <div class="ewgr8900 pt-5">
                        <h3 class="ert678">Life @ Shadow infosytem</h3>
                        <h5 className="text-white pt-2">
                          Get the future you want – for yourself, for our
                          clients, and for society as a whole.
                        </h5>
                        {/* <p>Shadow infosystem</p> */}
                      </div>
                      <div class="client-logo-pic1">
                        <div class="common-enq-bot sdfghgfd3456">
                          {/* <a class="bottt" href="/life-at-shadow">
                            <span>Explore More</span>
                          </a> */}
                          <button
                            class="darksoul-hover-fill-button2 ml-3 mb-3"
                            type="button"
                          >
                            <div class="color-fill-2"></div>
                            <a class="" href="/life-at-shadow">
                              <span>Explore More</span>
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <img
                    src="/static/media/pic3wed.e68fa51e1fdff79ea387.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About2;
