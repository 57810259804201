import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/banner/vision-bg-1.png");

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-t80  p-b50  p-t80 back-ground-color mission-about`} style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
                    <div className="container">
                        <div className="section-content mission-eree">
                            <div className="qpqq">
                                <div className="mnbfr-4654">
                                    <h2>Our mission is to build the trustworthy and profitable global IT company providing our customers with high quality software and services.</h2>
                                    <p>Technology and business are tightly interrelated worlds that undergo constant transformation. Yet, they still remain very close to each other, both on the local and global level. What makes this bond so strong and durable? The answer is simple. People and their needs are the main link.</p>
                                    <p>Contemporary business operates in a highly competitive environment. Shadow infosystem’s primary goal is to provide software solutions that satisfy the needs of even the most demanding customers. Each and every day, we focus all our efforts on people and their rising expectations in this dynamically changing world.</p>
                                    <p>We believe that by developing modern, advanced IT solutions, and acting together with our clients, we can significantly improve the quality of life for many people. We take advantage of our expertise and experience to create technologies that can really affect the present day and shape the future.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default About3;
