import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import MaintenanceBanner from "./../Elements/Maintenance/MaintenanceBanner";
import MaintenanceTypeDesign from "./../Elements/Maintenance/MaintenanceTypeDesign";
import MaintenanceAbout from "./../Elements/Maintenance/MaintenanceAbout";
import Tech from "./../Elements/Maintenance/Tech";
import MaintenanceWhyChooseUs from "./../Elements/Maintenance/MaintenanceWhyChooseUs";
import Maintenanceclient from "./../Elements/Maintenance/Maintenanceclient";
import MaintenanceFAQ from "./../Elements/Maintenance/MaintenanceFAQ";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import Testimonials from "./../Elements/Testimonials";
import { Helmet } from "react-helmet";


class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Website Maintenance Services | Shadow infosystem</title>
          <meta
            name="description"
            content="Elevate your online presence with Shadow infosystem's website maintenance services. Trust us to keep your website running smoothly and optimized."
          />
          <meta name="keywords" content="Top IT Company" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/website-maintenance-services"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <MaintenanceBanner />
          <MaintenanceAbout />
          <Tech />
          <MaintenanceTypeDesign />
          <Maintenanceclient />
          <CommenEnquiry />
          <MaintenanceWhyChooseUs />
          <Testimonials />
          <MaintenanceFAQ />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
