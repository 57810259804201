import React from "react";
import { NavLink } from "react-router-dom";
import ClientsLogo5 from "./../../Elements/ClientsLogo5";

var bgimg1 = require("./../../../images/background/bg-5.png");
var bgimg2 = require("./../../../images/background/14803753_Vector_2677-1-scaled.jpg");
var bgimg3 = require("./../../../images/background/cross-line2.png");

class WhatWeDo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding bg-white1 bg-repeat overflow-hide back-ground-color">
          {/*<span class="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>*/}
          <div className="right-half-bg-image-outer">
            <div
              className="bg-parallax bg-fixed bg-top-right"
              data-stellar-background-ratio={0}
              style={{ backgroundImage: "url(" + bgimg2 + ")" }}
            />
            {/* TITLE START */}

            {/* TITLE END */}

            <div className="section-content">
              <div className="row number-block-one-outer justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                    <img
                      src={require("./../../../images/head-icon/app-flow.webp")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhatWeDo1;
