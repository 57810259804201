import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/background/cross-line2.png');

class Blog2 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
            animateOut: 'fadeOut',
        };
        return (
            <>
                 
                <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
    <div className="container">
        {/* TITLE START */}
        <div className="section-head">
            <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x">
                    <h3 className="wfgrbwe ert678">Why Choose Shadow</h3>
                    <p>
                        At Shadow, we recognize the profound impact of excellent web design, where the perfect blend of colors, layout, and impeccable <br />
                        code converges to amplify leads and conversions. With us, you can be sure of working with
                    </p>
                </div>
            </div>
        </div>
        {/* TITLE END */} {/* IMAGE CAROUSEL START */}
        <div className="section-content sfsfcsc13232">
            <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info  bg-white sdfgj56564 servdert-one2">
                            <div className="WhyChooseShadow">
                                <img src={require('./../../../images/locationpagesimages/tick-with-pencil-illustration.png')} alt="" />
                                <h4 className="post-title">
                                    Experience & <br />
                                    Expertise
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Leverage our 15 years of website development expertise. Our experienced team brings a wealth of collective experience, ensuring your digital presence stays ahead with industry insights and mastery of
                                    evolving technologies for a lasting impact.
                                </p>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../../images/locationpagesimages/determine_the_business_development_plan.png')} alt="" />
                                <h4 className="post-title">Professional & Creative Team</h4>
                            </div>

                            <div className="">
                                <p>
                                    Entrust your vision to a dedicated professional and creative team. Merging technical prowess with artistic flair, we deliver websites that meet functional needs and captivate audiences with visually
                                    stunning designs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png')} alt="" />
                                <h4 className="post-title">
                                    Agile <br />
                                    Methodology
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Embrace flexibility and efficiency with our agile development. We adapt to changing needs, ensuring a streamlined and collaborative approach. Stay involved, provide feedback, and witness real-time
                                    evolution through agile practices.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png')} alt="" />
                                <h4 className="post-title">
                                    Quality Assurance<br />
                                    Check
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Your satisfaction is our priority. Stringent quality checks guarantee a flawless final product. Meticulously inspecting functionality and performance, we ensure your website meets the highest standards
                                    before going live.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../../images/locationpagesimages/smartphone_test_data_analysis.png')} alt="" />
                                <h4 className="post-title">
                                    User <br />
                                    Friendly
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Prioritize understanding your audience. Incorporating user-centric design, we create websites that resonate with your target audience. Focusing on user interests guarantees an engaging, satisfying online
                                    experience, enhancing your brand's digital presence.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                            <div className=" WhyChooseShadow">
                                <img src={require('./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png')} alt="" />
                                <h4 className="post-title">
                                    Timely Quality <br />
                                    Control
                                </h4>
                            </div>

                            <div className="">
                                <p>
                                    Value your time with our commitment to timely quality control. Ensure your website not only meets deadlines but exceeds expectations. Trust us to deliver a well-designed, high-performing website, allowing
                                    you to focus on what matters most—your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="hilite-title text-left p-l50 text-uppercase">
        <strong>Why Choose</strong>
    </div>
</div>

                
            </>
        );
    }
};

export default Blog2;