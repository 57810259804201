import React from "react";
import { NavLink } from "react-router-dom";
import MoreInform from "./../MoreInform";
var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
// var bgimg2 = require('./../../../images/background/cross-line2.png');

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">CYBER SECURITY SERVICES</h3>
                      <p>
                        Cyber security services consist of technologies,
                        processes and controls designed to protect systems,
                        networks, programs, devices and data from cyber attacks.
                      </p>
                    </div>
                  </div>

                  <MoreInform />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/head-icon/cs1.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
