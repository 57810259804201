import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h3 className="wfgrbwe ert678 mb-3">Why Choose Shadow</h3>
                  <p>
                    <b>
                      Choosing Shadow infosystem for e-commerce website
                      development can be advantageous for several reasons
                    </b>
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content sfsfcsc13232">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 servdert-one2">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/ec1.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Expertise</h4>
                      </div>

                      <div className="">
                        <p>
                          At Shadow infosystem, we have a team of experienced
                          professionals specializing in e-commerce website
                          development. Our expertise ensures that your website
                          is built to the highest standards and incorporates the
                          latest industry trends and technologies.
                        </p>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/ec2.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Customization</h4>
                      </div>

                      <div className="">
                        <p>
                          We can tailor the e-commerce website according to your
                          specific requirements and preferences. Whether you
                          need a simple online store or a complex e-commerce
                          platform with advanced features, our team can
                          customize the solution to meet your needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/ec3.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Quality Assurance</h4>
                      </div>

                      <div className="">
                        <p>
                          We have stringent quality assurance processes in place
                          to ensure that your website is bug-free,
                          user-friendly, and performs well across different
                          devices and browsers. This ensures a seamless shopping
                          experience for your customers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/ec4.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Timely Delivery</h4>
                      </div>

                      <div className="">
                        <p>
                          At Shadow infosystem, we understand the importance of
                          timely delivery and work efficiently to meet
                          deadlines. This ensures that your e-commerce website
                          is launched within the expected timeframe, allowing
                          you to start selling your products or services online
                          sooner.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/ec5.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Support and Maintenance</h4>
                      </div>

                      <div className="">
                        <p>
                          After the website is launched, our team provides
                          ongoing support and maintenance services to ensure
                          that your website remains operational and up-to-date.
                          This includes regular updates, security patches, and
                          troubleshooting assistance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/ec6.webp")}
                          alt=""
                        />
                        <h4 className="post-title">Cost-effectiveness</h4>
                      </div>

                      <div className="">
                        <p>
                          While the cost of development may vary depending on
                          your specific requirements, at Shadow infosystem, we
                          offer competitive pricing for our e-commerce website
                          development services. This provides you with good
                          value for your investment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Why Choose</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
