import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="">
                      <img
                        src={require("./../../../images/update1/main2.webp")}
                        alt="about_shadow"
                      />

                      <div class="row bertfv">
                        <div class="col-lg-12">
                          <div class="client-logo-pic1">
                            <div className="common-enq-bot"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="m-b30 bg-white">
                      <h2 class="ert678">About Shadow</h2>
                      <p>
                        Shadow infosystem, an esteemed IT company, epitomizes
                        the pinnacle of excellence in the realms of cyber
                        security and digital transformation. Distinguished for
                        our meticulous approach in tailoring solutions to the
                        unique needs of our esteemed clientele, we are
                        unwaveringly committed to utilizing cutting-edge
                        technologies that seamlessly align with the aspirations
                        of our clients.
                      </p>
                      <p>
                        In the dynamic landscape of IT, our experienced team of
                        expert marketers, designers, and developers possess the
                        insight to plan campaigns that transcend conventional
                        boundaries. In our relentless pursuit of tangible
                        results, metrics such as leads and revenue generated
                        occupy the forefront of our focus. The yardstick of our
                        success is intrinsically tied to the prosperity of our
                        clients.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <p>
                      Our comprehensive IT and digital transformation solutions
                      are a harmonious fusion of strategies meticulously
                      designed to augment visibility, enhance conversions, and
                      ultimately elevate revenue. Meanwhile, in the realm of
                      cyber security, our solutions are not merely generic but
                      intricately crafted to address specific challenges,
                      underpinned by the latest technological advancements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>About</strong>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
