import React from 'react';
import { NavLink } from 'react-router-dom';
import Header4 from './../Common/Header2';
import Footer from './../Common/Footer';
import Banner from './../Elements/blog/BlogBanner';
import BlogSidebar from './../Elements/BlogSidebar';
import ReactPlayer from 'react-player';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/banner/8.jpg');

class BlogListing extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: false,
            margin: 30,
            nav: true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 1
                }
            }
        };
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Banner/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* GALLERY CONTENT START */}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <div className="news-listing ">
                                        {/* COLUMNS 1 */}
                                        
                                        {/* COLUMNS 2 */}
                                        <div className="blog-post blog-lg date-style-3 block-shadow">
                                            <div className="sx-post-media sx-img-effect zoom-slow">
                                                <NavLink to={"/blog-details"}><img src={require('./../../images/blog/default/Top-IT-Companies-in-Noida-Shadow-infosystem.jpg')} alt="" /></NavLink>
                                            </div>
                                            <div className="sx-post-info  bg-white">
                                                <div className="sx-post-meta ">
                                                    <ul>
                                                        <li className="post-date"> <strong>01 </strong> <span>Nov 2023</span> </li>
                                                        <li className="post-author"><NavLink to={"/blog-details"}>By <span>Shadow</span></NavLink> </li>
                                                        <li className="post-comment"><NavLink to={"/blog-details"}>0 Comments</NavLink> </li>
                                                    </ul>
                                                </div>
                                                <div className="sx-post-title ">
                                                    <h4 className="post-title"><NavLink to={"/blog-details"}>Top IT Companies in Noida – Shadow infosystem</NavLink></h4>
                                                </div>
                                                <div className="sx-post-text">
                                                    <p>An IT (Information Technology) company, also known as a tech company or technology firm, is an organization that specializes in providing a wide range of technology-related services, and solutions to individuals, businesses, and other organizations. These top IT companies in Noida typically focus on various</p>
                                                </div>
                                                <div className="clearfix">
                                                    <div className="sx-post-readmore pull-left">
                                                        <NavLink to={"/blog-details"} title="READ MORE" rel="bookmark" className="site-button-link">Read More</NavLink>
                                                    </div>
                                                    {/*<div className="widget_social_inks pull-right">
                                                        <ul className="social-icons social-square social-dark m-b0">
                                                            <li><a href="https://www.facebook.com" target="_blank" className="fa fa-facebook" /></li>
                                                            <li><a href="https://twitter.com" target="_blank" className="fa fa-twitter" /></li>
                                                            <li><a href="https://rss.com" target="_blank" className="fa fa-rss" /></li>
                                                            <li><a href="https://www.youtube.com" target="_blank" className="fa fa-youtube" /></li>
                                                            <li><a href="https://www.instagram.com" target="_blank" className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        {/* COLUMNS 3 */}
                                        <div className="blog-post blog-lg date-style-3 block-shadow">
                                            <div className="sx-post-media">
                                                {/*Fade slider*/}
                                                <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right" {...options}>
                                                    <div className="item">
                                                        <div className="aon-thum-bx">
                                                            <img src={require('./../../images/blog/default/Top-IT-Companies-in-Noida-Shadow-infosystem.jpg')} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="aon-thum-bx">
                                                            <img src={require('./../../images/blog/default/Top-IT-Companies-in-Noida-Shadow-infosystem.jpg')} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <div className="aon-thum-bx">
                                                            <img src={require('./../../images/blog/default/Top-IT-Companies-in-Noida-Shadow-infosystem.jpg')} alt="" />
                                                        </div>
                                                    </div>
                                                </OwlCarousel>

                                                {/*fade slider END*/}
                                            </div>
                                            <div className="sx-post-info  bg-white">
                                                <div className="sx-post-meta ">
                                                    <ul>
                                                        <li className="post-date"> <strong>01 </strong> <span>Nov 2023</span> </li>
                                                        <li className="post-author"><NavLink to={"/blog-details"}>By <span>Shadow</span></NavLink> </li>
                                                        <li className="post-comment"><NavLink to={"/blog-details"}>0 Comments</NavLink> </li>
                                                    </ul>
                                                </div>
                                                <div className="sx-post-title ">
                                                    <h4 className="post-title"><NavLink to={"/blog-details"}>Top IT Companies in Noida – Shadow infosystem</NavLink></h4>
                                                </div>
                                                <div className="sx-post-text">
                                                    <p>An IT (Information Technology) company, also known as a tech company or technology firm, is an organization that specializes in providing a wide range of technology-related services, and solutions to individuals, businesses, and other organizations. These top IT companies in Noida typically focus on various</p>
                                                </div>
                                                <div className="clearfix">
                                                    <div className="sx-post-readmore pull-left">
                                                        <NavLink to={"/blog-details"} title="READ MORE" rel="bookmark" className="site-button-link">Read More</NavLink>
                                                    </div>
                                                    {/*<div className="widget_social_inks pull-right">
                                                        <ul className="social-icons social-square social-dark m-b0">
                                                            <li><a href="https://www.facebook.com" target="_blank" className="fa fa-facebook" /></li>
                                                            <li><a href="https://twitter.com" target="_blank" className="fa fa-twitter" /></li>
                                                            <li><a href="https://rss.com" target="_blank" className="fa fa-rss" /></li>
                                                            <li><a href="https://www.youtube.com" target="_blank" className="fa fa-youtube" /></li>
                                                            <li><a href="https://www.instagram.com" target="_blank" className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        {/* COLUMNS 4 */}
                                        <div className="blog-post blog-lg date-style-3 block-shadow">
                                            <div className="sx-post-media sx-img-effect zoom-slow">
                                                <iframe title="Making your vision" width={560} height={400} src="https://www.youtube.com/embed/JT05eaxlEjo?si=utsHAIymZ03Q8God" />
                                            </div>
                                            <div className="sx-post-info  bg-white">
                                                <div className="sx-post-meta ">
                                                    <ul>
                                                        <li className="post-date"> <strong>01 </strong> <span>Nov 2023</span> </li>
                                                        <li className="post-author"><NavLink to={"/blog-details"}>By <span>Shadow</span></NavLink> </li>
                                                        <li className="post-comment"><NavLink to={"/blog-details"}>0 Comments</NavLink> </li>
                                                    </ul>
                                                </div>
                                               <div className="sx-post-title ">
                                                    <h4 className="post-title"><NavLink to={"/blog-details"}>Top IT Companies in Noida – Shadow infosystem</NavLink></h4>
                                                </div>
                                                <div className="sx-post-text">
                                                    <p>An IT (Information Technology) company, also known as a tech company or technology firm, is an organization that specializes in providing a wide range of technology-related services, and solutions to individuals, businesses, and other organizations. These top IT companies in Noida typically focus on various</p>
                                                </div>
                                                <div className="clearfix">
                                                    <div className="sx-post-readmore pull-left">
                                                        <NavLink to={"/blog-details"} title="READ MORE" rel="bookmark" className="site-button-link">Read More</NavLink>
                                                    </div>
                                                    {/*<div className="widget_social_inks pull-right">
                                                        <ul className="social-icons social-square social-dark m-b0">
                                                            <li><a href="https://www.facebook.com" target="_blank" className="fa fa-facebook" /></li>
                                                            <li><a href="https://twitter.com" target="_blank" className="fa fa-twitter" /></li>
                                                            <li><a href="https://rss.com" target="_blank" className="fa fa-rss" /></li>
                                                            <li><a href="https://www.youtube.com" target="_blank" className="fa fa-youtube" /></li>
                                                            <li><a href="https://www.instagram.com" target="_blank" className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        {/* COLUMNS 5 */}
                                        
                                    </div>
                                    <ul className="pagination m-b30">
                                        <li><NavLink to={"#"}>«</NavLink></li>
                                        <li className="active"><NavLink to={"#"}>1</NavLink></li>
                                        <li><NavLink to={"#"}>2</NavLink></li>
                                        <li><NavLink to={"#"}>3</NavLink></li>
                                        <li><NavLink to={"#"}>4</NavLink></li>
                                        <li><NavLink to={"#"}>5</NavLink></li>
                                        <li><NavLink to={"#"}>»</NavLink></li>
                                    </ul>
                                </div>
                                {/* SIDE BAR START */}
                                <div className="col-lg-4 col-md-12 col-sm-12 sticky_column">
                                    <BlogSidebar />
                                </div>
                                {/* SIDE BAR END */}
                            </div>
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <div className="modal fade" id="myModal3" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/112734492' />
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="myModal4" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=Oy2QIiSQT2U' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default BlogListing;