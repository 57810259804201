import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactPlayer from 'react-player';
import ImageGallery from 'react-image-gallery';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
        original: require('./../../../images/products/pic-1.jpg'),
        thumbnail: require('./../../../images/products/thumb/pic-1.jpg'),
    },
    {
        original: require('./../../../images/products/pic-2.jpg'),
        thumbnail: require('./../../../images/products/thumb/pic-2.jpg'),
    },
    {
        original: require('./../../../images/products/pic-3.jpg'),
        thumbnail: require('./../../../images/products/thumb/pic-3.jpg'),
    },
    {
        original: require('./../../../images/products/pic-4.jpg'),
        thumbnail: require('./../../../images/products/thumb/pic-4.jpg'),
    },
    {
        original: require('./../../../images/products/pic-5.jpg'),
        thumbnail: require('./../../../images/products/thumb/pic-5.jpg'),
    }
];

var bgimg1 = require('./../../../images/background/home-banner.jpg');

class Blog2 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
            animateOut: 'fadeOut',
        };
        return (
            <>
                 
                <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                
                    <div className="container">
                       
                       <div className="product-full-detail m-b30">
                                <div className="row">
                                    <div className="col-md-6 sfdd">
                                     {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                                <div className="color-change bg-moving bg-repeat-x white-text ">
                                    <h3 className="wfgrbwe ert678">Distinguish Your Business From The Competitors With Us! </h3>
                                    <p>Our customized logo services and designs, distinguish your business from the competitors. To jumpstart your business with a perfect and customized logo design, get in touch!</p>
                                    
                                </div>

                            </div>
                        </div>
                        {/* TITLE END */}
                                                     </div>
                                                     <div className="col-md-6">
                                                     <div className="sdvdvfe4676890">
                                                     <img src={require('./../../../images/projects/Asset 1 (2).png')} alt="" />
                                                     </div>
                                                     </div>

                                </div>
                                

                            </div> 
                    </div>

                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
                
            </>
        );
    }
};

export default Blog2;