import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/banner/ssds.jpg");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1 dvwddwdwdd"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            {/* TITLE START */}
            <div className="row">
              <div className="col-md-7">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">Connect with us</h3>
                      <p>
                        Cheers to the work that comes from trusted service
                        providers in time.
                      </p>
                    </div>
                  </div>
                  {/* <div className="buttonfgerb">
                                                        <div className="header-section-12">
                                                            <NavLink to={"#"} rel="bookmark" className="hs12-button">
                                                                Get Free Quote
                                                            </NavLink>
                                                            <span>OR</span>
                                                            
                                                        </div>

                                                       
                                                    </div> */}
                </div>
              </div>
              {/*<div className="col-md-5">
                                <div className="mnbv1231">
                                    <img src={require('./../../../images/19870-01.png')} alt="" />
                                </div>
                            </div>*/}
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
