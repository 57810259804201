import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Improved Patient Experience",
    description:
      "An IT company can help a dental clinic implement technology solutions that can improve the patient experience. For example, a digital check-in system, online appointment scheduling, etc.",
  },
  {
    icon: <i class="fa fa-shield"></i>,
    heading: "Enhanced Data Security ",
    description:
      "With increasing concerns around data privacy and security, an IT company can help a dental clinic implement robust cybersecurity measures to protect sensitive patient information.",
  },
  {
    icon: <i class="fa fa-laptop"></i>,
    heading: "Tele-dentistry",
    description:
      "An IT company can help a dental clinic adopt tele-dentistry technologies that allow dentists to provide virtual consultations and follow-ups, which can be particularly useful for patients.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="mb__25 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p className="mb__15">
                    Partnering with an IT company can bring several advantages
                    to a dental clinic. Here are some potential benefits:
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div class="card-wrap mt__0">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h3 class="card-title">{item.heading}</h3>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
