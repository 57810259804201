import React from 'react';
import Header2 from '../Common/Header3'; 
import Banner from '../Elements/PrivacyPolicy/PrivacyBanner';
import Content from '../Elements/PrivacyPolicy/PrivacyContent'; 
import Footer2 from '../Common/Footer2';
import { Helmet } from 'react-helmet';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Best Digital Marketing Company in Noida - Shadow infosystem</title>
                    <meta name="description" content="Uncover excellence in IT and SEO solutions with Shadow infosystem – your trusted digital marketing company in Noida for innovative technology services." />
                    <meta name="keywords" content="Top IT Company in Noida" />
                </Helmet>
                <Header2 />
                <div className="page-content">
                    <div className="">
                        <Banner />
                        <Content />
                         <div>
               
              
                
                </div>

            
                    </div>
                    

                   
                </div>
                <Footer2 />
            </>
        );
    };
};

export default PrivacyPolicy;
