import React from "react";
import seoAssistIcon1 from "./../../../images/head-icon/seo-i1.webp";
import seoAssistIcon2 from "./../../../images/head-icon/seo-i2.webp";
import seoAssistIcon3 from "./../../../images/head-icon/seo-i3.webp";
import seoAssistIcon4 from "./../../../images/head-icon/seo-i4.webp";
import seoAssistIcon5 from "./../../../images/head-icon/seo-i5.webp";
import seoAssistIcon6 from "./../../../images/head-icon/seo-i6.webp";

const seoAssist = [
  {
    icon: seoAssistIcon1,
    heading: "Improve Ranking",
  },
  {
    icon: seoAssistIcon2,
    heading: "Drive traffic",
  },
  {
    icon: seoAssistIcon3,
    heading: "More Lead",
  },
  {
    icon: seoAssistIcon4,
    heading: "Better User Experience",
  },
  {
    icon: seoAssistIcon5,
    heading: "Brand Recognition",
  },
  {
    icon: seoAssistIcon6,
    heading: "Online Visibility",
  },
];

function SeoAssist() {
  return (
    <>
      <section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="m-b30 text-center">
                <h2 class="ert678 heading">
                  How Can We Assist in Growing Your Business Through SEO?
                </h2>
              </div>
            </div>
          </div>
          <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
            {seoAssist.map((item, index) => (
              <div class="col-md-2 mb-5" key={index}>
                <div class="service-card">
                  <div class="icon-wrapper i-benefits-icon-container">
                    <img src={item.icon} alt="" />
                  </div>
                  <div className="points">{item.heading}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default SeoAssist;
