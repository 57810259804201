import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Identifying and Targeting Relevant Keywords",
    description:
      "Finding and targeting the right keywords was a complex task due to the competitive nature of the tiles market. We performed extensive keyword research to identify high-impact keywords that potential customers were using. Balancing high-search volume with low-competition keywords required a strategic approach, focusing on long-tail keywords and specific phrases related to various types of tiles, designs, and applications.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Building a Robust Backlink Profile",
    description:
      "Acquiring high-quality backlinks is essential for improving the website's authority and search engine rankings. It can be achieved by creating valuable and shareable content, and securing credible backlinks that require persistent effort and strategic planning to enhance the website's credibility and visibility.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Creating High-Quality, Engaging Content",
    description:
      "Producing high-quality, engaging content that resonated with different customer segments can be a significant challenge. We developed blog posts, and third party blog articles that highlighted the aesthetic and functional aspects of the tiles. This content needed to be informative and inspirational, appealing to both B2B and B2C audiences, while also being optimized for search engines to improve rankings and drive organic traffic.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Optimizing for Technical SEO",
    description:
      "Ensuring the website's technical SEO is fully optimized or not is very crucial for improving search engine visibility and user experience. We faced challenges such as improving site speed, ensuring mobile-friendliness, implementing structured data, and enhancing website architecture. Regular technical audits and updates were necessary to address any issues and maintain optimal website performance, which is essential for higher search engine rankings.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
