import React from 'react';
import { NavLink } from 'react-router-dom';

const achievements1 = [
    {
        title: 'Social Media Optimization',
        image: require('./../../../images/icon/social-media (2).png'),
    },
    {
        title: 'Social Media Marketing',
        image: require('./../../../images/icon/social-media (2).png'),
    },
    {
        title: 'LinkedIn Marketing',
        image: require('./../../../images/icon/social-media (2).png'),
    }
]

const achievements2 = [
    {
        title: 'Twitter Marketing',
        image: require('./../../../images/icon/social-media (2).png'),
    },
    {
        title: 'Facebook Marketing',
        image: require('./../../../images/icon/social-media (2).png'),
    },
    {
        title: 'Affiliate Marketing',
        image: require('./../../../images/icon/social-media (2).png'),
    }
]

var bgimg1 = require('./../../../images/background/bg-map.png');
var bgimg2 = require('./../../../images/background/cross-line2.png');

class Achievements1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-no-repeat bg-bottom-center mobile-page-padding" >
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="bg-moving bg-repeat-x">
                                    <h3 className="">Services</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content ">
                            <div className="row">
                            {/*<div className="col-xl-4 col-lg-6 col-md-12 m-b30">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/icon/graphic-designer.png')} alt="" />
                                        
                                    </div>
                                </div>*/}
                                <div className="col-xl-6 col-lg-6 col-md-12 m-b30">
                                    <NavLink to="#" className="">
                                    {achievements1.map((item, index) => (
                                        <div className="sx-box our-story cvcvcv" key={index}>
                                            
                                            <div className="tret">
                                            <img src={item.image} alt="" />
                                                <h4>{item.title}</h4>
                                                
                                            </div>
                                        </div>
                                    ))}
                                    </NavLink>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                <NavLink to="#" className="">
                                    {achievements2.map((item, index) => (
                                        <div className="sx-box our-story cvcvcv" key={index}>
                                            
                                            <div className="tret">
                                            <img src={item.image} alt="" />
                                                <h4>{item.title}</h4>
                                                
                                            </div>
                                        </div>
                                    ))}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Achievements1;