import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h3 className="wfgrbwe ert678">
                    Why Choose Us for Website Maintenance Services?
                  </h3>
                  <p>
                    Choosing Shadow infosystem for website maintenance services
                    offers several distinct advantages that set us apart from
                    the competition. We understand that your website is a
                    critical asset to your business, serving as a primary point
                    of interaction with your customers and a reflection of your
                    brand's identity. Therefore, maintaining its optimal
                    performance, security, and relevance is paramount.
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content sfsfcsc13232">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 servdert-one2">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                          alt=""
                        />
                        <h4 className="post-title">Expertise and Experience</h4>
                      </div>

                      <div className="">
                        <p>
                          We boast a team of highly skilled professionals with
                          extensive experience in website maintenance. They are
                          adept at handling various platforms and technologies,
                          ensuring your website remains updated, secure, and
                          functional.
                        </p>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/determine_the_business_development_plan.png")}
                          alt=""
                        />
                        <h4 className="post-title">Security</h4>
                      </div>

                      <div className="">
                        <p>
                          In today’s digital landscape, website security is
                          paramount. We at Shadow infosystem, prioritize
                          security, implementing the latest measures to protect
                          your site from cyber threats, data breaches, and other
                          vulnerabilities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Customized Solutions</h4>
                      </div>

                      <div className="">
                        <p>
                          Understanding that every business is unique, we offer
                          tailored maintenance plans that cater specifically to
                          your website’s needs and business goals. This
                          personalized approach ensures you get the most
                          effective and efficient service.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                          alt=""
                        />
                        <h4 className="post-title">Responsive Support</h4>
                      </div>

                      <div className="">
                        <p>
                          Our customer support is responsive and reliable.
                          Whether you encounter a critical issue or have a minor
                          query, our support team is available to assist you
                          promptly, minimizing downtime and disruption to your
                          business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/smartphone_test_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Cost-Effective</h4>
                      </div>

                      <div className="">
                        <p>
                          By choosing our maintenance services, you can save on
                          the costs associated with hiring and training in-house
                          staff. Our competitive pricing guarantees top-notch
                          service, ensuring affordability without compromising
                          quality.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one2">
                      <div className=" WhyChooseShadow">
                        <img
                          src={require("./../../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Performance Optimization</h4>
                      </div>

                      <div className="">
                        <p>
                          We ensure that your website runs smoothly and
                          efficiently, providing a seamless experience for your
                          visitors. This includes optimizing load times,
                          ensuring compatibility across different devices, and
                          maintaining overall site performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Why Choose</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
