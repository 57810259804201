import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Identifying and Targeting Relevant Keywords",
    description:
      "Finding the right keywords for a smoking accessories provider was another major challenge. The industry involves a wide range of products, from pipes and vaporizers to rolling papers and storage solutions. To overcome this, we conducted extensive keyword research to identify terms that potential customers were using, balancing between high search volume and low competition.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Technical SEO Optimization",
    description:
      "Optimizing the technical aspects of website posed several challenges. This included improving site speed, ensuring mobile-friendliness, and implementing structured data. Given the competitive nature of the industry, We ensured that the website’s technical infrastructure was robust, enhancing crawlability, indexability, and overall user experience.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Creating Engaging and Compliant Content",
    description:
      "Producing high-quality, engaging content that complied with industry regulations could have been a significant hurdle. The content needed to be informative and appealing to the target audience while avoiding language and imagery that could violate advertising policies. We had to strike a balance between creativity and compliance, developing blog content on third party platforms that not only attracted organic traffic but also adhered to legal and platform-specific guidelines.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Building a Strong Backlink Profile",
    description:
      "Acquiring high-quality backlinks to improve the site's authority and rankings can be a complex challenge. Many reputable websites and influencers might be hesitant to link to smoking-related content due to potential reputational risks or policy restrictions. To overcome this, we developed a strategic outreach plan to study relevant industry websites, bloggers, and forums and hence creating valuable, shareable content.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
