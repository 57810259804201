import React from 'react';
import { NavLink } from 'react-router-dom';
import TypeDesign from './TypeDesign';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const clientele = [
  {
    id: 1,
    image: require("./../../images/client/webdesign/vital.jpg"),
    items: ["UK", "SGP", "IND"],
  },

  {
    id: 2,
    image: require("./../../images/client/webdesign/sara.jpg"),
    items: ["Canada"],
  },

  {
    id: 3,
    image: require("./../../images/client/webdesign/ssr.jpg"),
    items: ["USA"],
  },

  {
    id: 4,
    image: require("./../../images/client/webdesign/yocarz.jpg"),
    items: ["IND"],
  },

  {
    id: 5,
    image: require("./../../images/client/webdesign/aparna.jpg"),
    items: ["IND", "UAE"],
  },

  {
    id: 6,
    image: require("./../../images/client/webdesign/santosh.jpg"),
    items: ["IND"],
  },

  {
    id: 7,
    image: require("./../../images/client/webdesign/dentalkraft.jpg"),
    items: ["IND"],
  },

  {
    id: 8,
    image: require("./../../images/client/webdesign/4qt.jpg"),
    items: ["IND"],
  },

  {
    id: 9,
    image: require("./../../images/client/webdesign/nail_rituals.jpg"),
    items: ["IND"],
  },

  {
    id: 10,
    image: require("./../../images/client/webdesign/vedam.jpg"),
    items: ["IND"],
  },

  {
    id: 11,
    image: require("./../../images/client/webdesign/engrever.jpg"),
    items: ["IND"],
  },

  {
    id: 12,
    image: require("./../../images/client/webdesign/av.jpg"),
    items: ["IND"],
  },

  {
    id: 13,
    image: require("./../../images/client/webdesign/roadload.jpg"),
    items: ["IND"],
  },

  {
    id: 14,
    image: require("./../../images/client/webdesign/david.jpg"),
    items: ["Canada"],
  },

  {
    id: 15,
    image: require("./../../images/client/webdesign/greenvent.jpg"),
    items: ["IND"],
  },

  {
    id: 16,
    image: require("./../../images/client/webdesign/shrimahalaxmi.jpg"),
    items: ["IND"],
  },

  {
    id: 17,
    image: require("./../../images/client/webdesign/tatva.jpg"),
    items: ["IND"],
  },

  {
    id: 18,
    image: require("./../../images/client/webdesign/trishul.jpg"),
    items: ["IND"],
  },
]

var bgimg1 = require('./../../images/banner/web-development-banner.jpg');
var bgimg2 = require('./../../images/background/cross-line2.png');
var bgimg3 = require('./../../images/background/home-banner.jpg');

class ClientsLogo1 extends React.Component {
  componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {

        return (
          <>
            <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1" style={{ backgroundImage: "url(" + bgimg1 + ")" }} >
              <div className="container ">
                {/* TITLE START */}
                <div className="row d-inline-flex">
                  <div className="col-md-6">
                    <div className="section-head mnbv123">
                      <div className="sx-separator-outer ">
                        <div className="bg-repeat-x color-change ewgr8900">
                          <h3 className="ert678">
                            Get the Best and Professional SEO Services
                          </h3>
                          <p>Grow Organically on Google's First Page</p>
                        </div>
                      </div>
                       <TypeDesign/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mnbv1231">
                      <img src={require("./../../images/gif.gif")} alt="" />
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
              </div>
            </div>
            <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
            <div className="container-fluid">
            <div className="section-content">
            <div className="container">
                <div className="section-head">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="m-b30 bg-white">
                                <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                    <img src={require("./../../images/3dimages/1.png")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="sx-separator-outer separator-left">
                                <div className="ertyui">
                                    <h1 className="ert678 heading">Best Local SEO Services</h1>

                                    <p>Shadow infosystem offers top-notch Local SEO services tailored to the specific needs of
businesses, ensuring enhanced visibility in local searches and increased customer
engagement. Our approach combines strategic keyword optimization, local business listings,
and geo-targeted content to ensure maximum impact within the target market.</p>
                                    <p>With a focus on precision targeting, we optimize Google My Business profiles, ensuring
accurate business information, compelling descriptions, and high-quality images to attract local
customers effectively. Moreover, we employ advanced techniques such as local citation building
and review management to bolster credibility and trustworthiness, crucial factors for local search
ranking success.</p>
                                   
                                 </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            
                            <p>Our team of SEO experts continuously monitors and analyzes local search trends, refining
strategies to adapt to evolving algorithms and consumer behaviors. By leveraging our expertise
in both technical and content aspects of SEO, we ensure that businesses not only rank higher in
local searches but also maintain a strong online presence, driving sustained growth and
profitability.</p>
                            <p>Our Local SEO services empower businesses to dominate their local market, attract qualified
leads, and establish long-term success in an increasingly competitive digital landscape.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
 <div className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x  white-text">
                <h2 className="wfgrbwe ert678 heading">
                    SEO Services We Offer in India
                </h2>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/online_business_analyst_data_computer.png')} alt="" />
                    <div className="sx-post-info  bg-white sdfgj56564 servdert-one1 seo-servie1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/online_business_analyst_data_computer.png")} alt="" />
                            <h4 className="post-title">Precision Targeting</h4>
                        </div>

                        <div className="">
                            <p>We employ strategic keyword optimization and local business listing techniques to ensure
businesses appear prominently in local search results, effectively reaching their target audience
and driving qualified website traffic.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                <img className="technology-img" src={require('./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")} alt="" />
                            <h4 className="post-title">
                                Google My Business Optimization
                            </h4>
                        </div>

                        <div className="">
                            <p>We meticulously optimize Google My Business profiles with accurate information, compelling
descriptions, and high-quality images, enhancing visibility and credibility within local search
results and attracting potential customers to businesses&#39; physical locations.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <h4 className="post-title">Geo-Targeted Content</h4>
                        </div>

                        <div className="">
                            <p>Develop geo-targeted content strategies tailored to specific local markets, ensuring that
businesses&#39; online presence resonates with local audiences and effectively communicates their
value proposition, driving engagement and conversions.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <h4 className="post-title">Local Citation Building</h4>
                        </div>

                        <div className="">
                            <p>Employ advanced citation-building techniques to establish businesses&#39; online authority and
credibility within their local communities, increasing their visibility in local search results and
driving organic traffic to their websites and physical locations.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/vector-isometric-concept.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/vector-isometric-concept.png")} alt="" />
                            <h4 className="post-title">
                               Review Management
                            </h4>
                        </div>

                        <div className="">
                            <p>Helps businesses manage online reviews effectively, soliciting positive feedback from satisfied
customers and addressing negative reviews promptly and professionally, enhancing their
reputation and trustworthiness in the eyes of potential customers. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/smartphone_test_data_analysis.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564 servdert-one1 seo-servie1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/smartphone_test_data_analysis.png")} alt="" />
                            <h4 className="post-title">Continuous Optimization</h4>
                        </div>

                        <div className="">
                            <p>Our team of SEO experts continuously monitors and analyzes local search trends and algorithm
updates, refining strategies to ensure businesses maintain a competitive edge in local search
results and achieve sustained growth and profitability over time.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12"></div>
            <div className="col-lg-3 col-md-12 col-sm-12">
                <TypeDesign className="vndjn-popup" />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12"></div>
        </div>
    </div>
</div>
<div className="hilite-title text-left p-l50 text-uppercase">
    <strong>Services</strong>
</div>
</div>
<div className="section-full  mobile-page-padding bacg-ser p-t80 p-b10 bg-repeat">
    <div className="container">
        {/* TITLE START */}
        <div className="section-head">
            <div className="sx-separator-outer separator-center">
                <div className="bg-repeat-x color-change">
                    <div className="ert678 heading">
                        Get A Free Website Audit & Consultation From Our Experts
                    </div>
                    <p>
                        Want to boost organic traffic and generate more leads? Contact us now to secure your place on Google's first page!
                    </p>
                </div>
                <div className="common-enq-bot">
                    <a href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello Shadow" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/whatsapp-button (1).png")} alt="" />
                    </a>
                    <span>OR</span>
                    <a href="tel:+91-88003 84880" target="_blank" className="">
                        <img className="wdmwoncc019281" src={require("../../images/icon/Call-Now-Button.png")} alt="" />
                    </a>
                </div>
            </div>
        </div>
        {/* TITLE END */}
    </div>
</div>
 <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x">
                <h2 className="wfgrbwe ert678 heading text-white">
                    How We Drive Success Through Local SEO?
                </h2>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/online_business_analyst_data_computer.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Explore
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                The initial phase involves understanding your audience - their characteristics, needs, and how your offerings can meet those needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/professional_analytical_seo_optimization_business_target.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Analyze
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                A thorough evaluation of your website's current status - assessing its functionality, visitor engagement, and identifying any potential issues.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Strategize
                                </h3>
                            </div>
                        </div>

                        <div className="">
                            <p>
                                We determine the optimal approach - evaluating the feasibility of targeting specific keywords, conducting necessary site optimizations, and considering strategies like guest posts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                        <div className="WhyChooseShadow">
                            <img className="" src={require("./../../images/locationpagesimages/joint_enterprise_business_data_analysis.png")} alt="" />
                            <div className="sx-post-title ">
                                <h3 className="post-title sub-heading">
                                    Evaluate
                                </h3>
                            </div>
                            </div>

                            <div className="">
                                <p>
                                    Early data analysis serves as the cornerstone of success - monitoring, and understanding how your strategies are performing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/vector-isometric-concept.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Report
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Comprehensive and detailed reporting to support your business's growth in the most effective directions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="blog-post blog-lg date-style-3 block-shadow">
                        <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                            <div className="WhyChooseShadow">
                                <img className="" src={require("./../../images/locationpagesimages/smartphone_test_data_analysis.png")} alt="" />
                                <div className="sx-post-title ">
                                    <h3 className="post-title sub-heading">
                                        Adapt
                                    </h3>
                                </div>
                            </div>

                            <div className="">
                                <p>
                                    Data-driven decision-making guides our actions - identifying areas requiring improvement, and determining strategies to progress based on successful outcomes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                    <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
            </div>
        </div>
    </div>
    <div className="hilite-title text-left p-l50 text-uppercase">
        <strong>Why Choose</strong>
    </div>
</div>
 <div className="section-full inner-page-padding p-b50 p-t80 ">
                    <div className="container-fluid">
                        <div className="section-content">
                            <div className="container">
                                {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="ertyui">
                                        <h3 className="ert678">Loved By 3000+ Customers All Over The World</h3>
                                    <h6>Our greatest asset is the customers and clients Satisfaction!</h6>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                                <div className="row">
                                    {clientele.map((item) => (
                      <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb__20">
                        <ul key={`item-${item.id}`} className="cl_container">
                          <div className="client_logo_img_box">
                            <div className="client_logo_img">
                              <img src={item.image} alt="client-logo" />
                            </div>
                          </div>

                          <div className="client_logo_title_container">
                            {item.items.map((country, index) => (
                              <li key={`country-${index}`}>{country}</li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    ))}
                                </div>
                                


                            </div>
                            </div>
                            


                        </div>

                    </div>
                </div>
               <div className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45" style={{ backgroundImage: "url(" + bgimg3 + ")" }} >
<div className="container">
    {/* TITLE START */}
    <div className="section-head">
        <div className="sx-separator-outer separator-center">
            <div className="color-change bg-moving bg-repeat-x  white-text">
                <h2 className="wfgrbwe ert678 heading">
                    Why Choose Shadow infosystem For Local SEO Services?
                </h2>
                <p>Shadow infosystem stands as the premier and best local SEO services company in India,
distinguished by our status as the largest SEO agency in the industry. Collaborating with
numerous businesses, we've effectively enhanced our client's Google rankings, amplified
website traffic, increased brand visibility, and augmented online revenue.</p>
            </div>
        </div>
    </div>
    {/* TITLE END */} {/* IMAGE CAROUSEL START */}
    <div className="section-content">
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/2.png')} alt="" />
                    <div className="sx-post-info  bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/2.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Transparency
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                We believe in open communication and transparency at every stage of our collaboration. Our clients receive regular updates and detailed reports on the progress of our SEO works. We provide clear insights into
                                the strategies implemented, the results achieved, and any adjustments made to enhance performance.
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow ">
                <img className="technology-img" src={require('./../../images/locationpagesimages/determine_the_business_development_plan.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/determine_the_business_development_plan.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Experience & Expertise
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                With a solid foundation built on 15 years of experience, as the best SEO services Agency, Shadow infosystem brings a wealth of knowledge and expertise to every project. This extensive experience allows us to
                                navigate the complexities of the digital landscape effectively, providing clients with strategic insights and proven methodologies for long-term success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/12.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/12.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Proven Track Record
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                With a track record of successful SEO strategies across diverse industries, we have consistently delivered tangible results. Our portfolio showcases case studies highlighting significant improvements in
                                website traffic, and keyword rankings for our clients.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="col-lg-6 col-md-12 col-sm-12">
                {/* COLUMNS 2 */}
                <div className="blog-post blog-lg date-style-3 block-shadow">
                <img className="technology-img" src={require('./../../images/locationpagesimages/4.png')} alt="" />
                    <div className="sx-post-info bg-white sdfgj56564">
                        <div className="WhyChooseShadow">
                            <img className="iocn1234" src={require("./../../images/locationpagesimages/4.png")} alt="" />
                            <h3 className="post-title sub-heading text-dark">
                                Customized Strategies
                            </h3>
                        </div>

                        <div className="">
                            <p>
                                Recognizing that every business is unique, we tailor our Search Engine Optimization strategies to meet the specific needs and goals of each client. Our team conducts in-depth analyses to understand the target
                                audience, competitive landscape, and industry trends, ensuring a personalized approach that maximizes results.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<div className="hilite-title text-left p-l50 text-uppercase">
    <strong>Services</strong>
</div>
</div>
<div className="page-content">
    <div className="section-full p-t80 p-b80 inner-page-padding ">
        {/*
        <div className="trtrtrtyuy"></div>
        */}
        <div className="container">
            <div className="faq-1">
                {/* TITLE START */}
                <div className="section-head">
                    <div className="sx-separator-outer separator-left">
                        <div className="">
                            <h3 className="ert678">Frequently Asked Questions</h3>
                        </div>
                    </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                    <div className="col-md-12">
                        {/* Accordian */}
                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                            <div className="panel sx-panel">
                                <div className="acod-head acc-actives">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                           What is local SEO?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOne5" className="acod-body collapse show">
                                    <div className="acod-content p-a15">
                                        <p>The practice of enhancing a company's online presence to increase its exposure in local search
results is known as local SEO. It involves techniques such as optimizing Google My Business
listings, local keyword targeting, and obtaining local citations to help businesses attract nearby
customers searching for products or services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                           Who needs local SEO?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseTwo5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Local SEO services are essential for businesses with physical locations or those targeting
specific geographic areas. Local businesses like restaurants, retail stores, medical practices,
and service providers must enhance their online visibility and attract nearby customers
searching for products or services within their vicinity.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                           How effective is local SEO?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseThree5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Local SEO works well for companies who want to serve clients in the area. It enhances visibility
in local search results, increases website traffic, drives foot traffic to physical locations, and
boosts conversions. By focusing on geo-targeted strategies, businesses can dominate their
local market and achieve significant ROI.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel sx-panel">
                                <div className="acod-head">
                                    <h4 className="acod-title">
                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                           Who should use local SEO?
                                            <span className="indicator">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseFour5" className="acod-body collapse">
                                    <div className="acod-content p-a15">
                                        <p>Local SEO is essential for any business with a physical location or serving specific geographic
areas. It&#39;s particularly valuable for small businesses, service-based industries, brick-and-mortar
stores, restaurants, healthcare providers, and professionals seeking to attract local customers
and enhance their visibility in local search results.</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    {/* SECTION CONTENT END */}
</div>




          </>
        );
    }
};

export default ClientsLogo1;