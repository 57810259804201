import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Massist CRM </h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo normal_logo">
                  <img
                    src={require("./../../../images/massist/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Massist CRM </h5>
                  <ul className="scnv-case mb__0">
                    <li>
                      <b>Location : </b>
                      B-720 Sector - 62, Gautam Budh Nagar Noida, UP, India
                    </li>
                    <li>
                      <b>Speciality : </b> CRM Software Solutions
                    </li>
                    <li className="pb__0">
                      <b>Website : </b>{" "}
                      <a
                        href="https://massistcrm.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.massistcrm.com
                      </a>
                    </li>
                  </ul>
                  {/* <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>

                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/santoshacademia"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/santoshacademia/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/santosh-academia/"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://x.com/AcademiaSantosh/"
                          class="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            class="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@santoshacademia"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/massist/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/massist/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__15">
                        They have researched almost all the major existing
                        Customer/Client Relationship Management(CRM) Software
                        and realized that they all follow a similar template,
                        though that is necessary to some extent but the
                        definition of Customer/Client also changes drastically
                        from industry to industry and at times from one
                        organization to another.
                      </p>

                      <p className="mb__15">
                        They found out that many large organizations though have
                        CRM implemented in their set-up’s but still it works in
                        parts and therefore for many other areas these companies
                        still depend on traditional excel sheets, on the
                        contrary this company believes either there is Complete
                        Automation Compliance or No Automation at all.
                      </p>

                      <p className="mb__0">
                        That's why they have created a very open system, which
                        gives their users fixed answers to all their problems.
                        which is MAssist. It is the only product in this space
                        that actually analyze organization's structure, then mix
                        and match company's existing offering and add whatever
                        customization required.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
