import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1>EcoXpress Roadload</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo">
                  <img
                    src={require("./../../../images/roadload/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">EcoXpress Roadload</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b> 3rd Floor, A-24/5, Mohan Co-operative
                      Industrial Estate, New Delhi – 110044 (India)
                    </li>
                    <li>
                      <b>Services:</b> Logistics
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://www.roadload.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.roadload.in
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/roadload12/"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/road_load12/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://in.linkedin.com/company/ecoxpress-roadload"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/RoadLoad1"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCnKwuUudJKNW-S8zHkEtf-Q"
                          target="_blank"
                          className="youtube"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/roadload/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/roadload/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__15">
                        EcoXpress Roadload Pvt. Ltd. is a Domestic
                        Transportation and Warehousing Company. They are engaged
                        in domestic transportation services, and handling B2B
                        and D2C Deliveries within Delhi/NCR and are expanding to
                        other parts of India. Also they are developing an
                        electric ecosystem for last-mile deliveries, in
                        Delhi-NCR region.
                      </p>

                      <p className="mb__15">
                        Since multichannel logistics is endlessly complex and
                        challenging. Roadload help businesses of all sizes to
                        adapt, maximize their potential and move to the next
                        level.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
