import React from "react";
const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Increased Competition",
    description:
      "The consultancy industry is highly competitive, and new players are entering the market all the time. Established consultancy services companies must continually innovate to differentiate themselves and stay ahead of the competition.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Client Acquisition and Retention",
    description:
      "Consultancy services companies rely on a steady stream of clients to stay in business. Attracting new clients and retaining existing ones can be a challenge, particularly as clients become more selective in their choice of service providers.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Delivering High-Quality Services",
    description:
      "Consultancy services companies must deliver high-quality services to their clients to retain their business and maintain their reputation. This requires hiring and retaining talented consultants, staying up-to-date with industry trends, and continuously improving services.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Managing Data and Security",
    description:
      "Consultancy services companies handle a lot of sensitive data, and ensuring data security and compliance can be a challenge. This requires implementing robust security measures, staying up-to-date with changing regulations, and ensuring that staff members are trained on data handling and security best practices.",
  },
];

function Challenges() {
  return (
    <>
      <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
        <div className="container p-t80">
          <div className="row">
            <div className="col-md-12 sx-separator-outer separator-center">
              <div className="case-banner">
                <h2 class="ert678">The Challanges</h2>
              </div>
            </div>
            <div className="col-md-12 sx-separator-outer separator-center"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul class="infoGraphic">
                {challenges.map((item, index) => (
                  <li>
                    <div class="numberWrap">
                      {item.number}
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <h3>{item.heading}</h3>
                      <p>{item.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Challenges;
