import React from 'react';
import { NavLink } from 'react-router-dom';

const logos = [
    {
        videoURL: 'https://www.youtube.com/embed/JT05eaxlEjo?si=dC8BBIKHk9vwGQh1', 
    },
    {
        videoURL: 'https://www.youtube.com/embed/ge18llcgiPI?si=qZhm2TMkdT7xOiXF', 
    },
    {
        videoURL: 'https://www.youtube.com/embed/ooTdN7cH-Wc?si=fRL2tsndKM0sqgj-', 
    },
    {
        videoURL: 'https://www.youtube.com/embed/crcifaRLERo?si=xXZTHtHgs35ESINT', 
    },
    {
        videoURL: 'https://www.youtube.com/embed/LvSUUofi538?si=4k9oODvdkYucX4ZA', 
    },
    {
        videoURL: 'https://www.youtube.com/embed/0iE-OHVnSa8?si=Ev6bszYC0ALG018t', 
    }
    
    
]



class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b10 bg-repeat">
                 
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                                <div className={`${this.props.separatoralignment} sx-separator-outer `}>
                                    <div className="">
                                    <h5>Let's work together</h5>
                                        <h1 className="wfgrbwe ert678">What Our Clients Say!</h1>
                                    </div>
                                </div>
                            </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b40">
                                <div className="row justify-content-center">
                                    {logos.map((item, index) => (
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 m-b30">
                                            <iframe title="Making your vision" width={360} height={200} src={item.videoURL} />
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            
                        </div>
                         
                    </div>
                   
                </div>
            </>
        );
    }
};

export default ClientsLogo1;