import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Staying up-to-date with the latest trends and techniques",
    description:
      "The beauty industry is constantly evolving, and staying on top of the latest trends and techniques is crucial to remain competitive. Hair and makeup studios need to invest in continuous education and training to ensure their staff is knowledgeable and skilled.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Managing finances",
    description:
      "Hair and makeup studios need to manage their finances effectively to ensure profitability. This includes managing cash flow, tracking expenses, and setting prices that are competitive and sustainable.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Managing inventory and supplies",
    description:
      "Hair and makeup studios need to keep track of their inventory and supplies, including products, tools, and equipment. They need to ensure that they have enough stock to meet demand while avoiding excess inventory.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Maintaining a consistent level of quality",
    description:
      "Providing consistent quality services to clients is essential for retaining them and attracting new customers. Hair and makeup studios need to maintain a high standard of service and quality control to ensure customer satisfaction.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
