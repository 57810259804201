import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Improved Marketing",
    description:
      "An IT company can help the tree care service improve its online presence, attract more and more customers, and grow its business through content marketing and digital marketing.",
  },
  {
    icon: <i class="fa fa-users"></i>,
    heading: "Better Customer Service",
    description:
      "With the help of an IT company, the tree care service can offer better customer service by using chatbots, email management tools, and other digital communication channels.",
  },
  {
    icon: <i class="fa fa-laptop"></i>,
    heading: "Streamlined Operations",
    description:
      "An IT company can provide software solutions to help the tree care service streamline its operations, such as scheduling and dispatch software, inventory management, and project management tools.",
  },
  {
    icon: <i class="fa fa-server"></i>,
    heading: "Efficient Maintenance",
    description:
      "An IT company can help the tree care service track the health and maintenance needs of trees and shrubs using digital tools, leading to more efficient and effective maintenance practices.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="mb__25 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p className="mb__15">
                    Here are some potential advantages for a person with tree
                    care experience by partnering with an IT company :
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div class="card-wrap mt__0">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h3 class="card-title">{item.heading}</h3>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
