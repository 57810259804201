import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 hg-123">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h2 className="wfgrbwe ert678 heading mb-3">
                    Our SEO Work Includes
                  </h2>
                  <p>
                    With our committed team of experts, we guarantee
                    comprehensive solutions for our esteemed clients by
                    delivering tailored, industry-specific, budget-friendly, and
                    best SEO services. Our SEO work encompasses the following:
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/computer_data_analysis_company_business.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/computer_data_analysis_company_business.png")}
                          alt=""
                        />
                        <h4 className="post-title">
                          Keyword Research and Analysis
                        </h4>
                      </div>

                      <div className="">
                        <p>
                          Understanding the language your target audience uses
                          is key to effective SEO. Our team conducts in-depth
                          keyword research to identify high-value terms relevant
                          to your business. We analyze search volume,
                          competition, and user intent to craft a strategic
                          keyword strategy that aligns with your business goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/tick-with-pencil-illustration.png")}
                          alt=""
                        />
                        <h4 className="post-title">Link Building</h4>
                      </div>

                      <div className="">
                        <p>
                          Quality backlinks play a pivotal role in search engine
                          rankings. We implement a robust link-building strategy
                          to acquire high-authority links, improving your
                          website's credibility in the eyes of search engines.
                          Our approach focuses on ethical and sustainable SEO
                          link building services for long-term success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/flat_isometric_illustration_concept_computer_data_analysis.png")}
                          alt=""
                        />
                        <h4 className="post-title">Website Audit</h4>
                      </div>

                      <div className="">
                        <p>
                          A strong foundation is essential for successful SEO.
                          Our expert team conducts a thorough website audit to
                          identify technical issues, analyze site structure, and
                          evaluate overall performance. Following this, we
                          address issues such as page load speed,
                          mobile-friendliness, and crawlability to ensure your
                          website is search engine-friendly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/online_business_analyst_data_computer.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/online_business_analyst_data_computer.png")}
                          alt=""
                        />
                        <h4 className="post-title">Reporting and Analysis</h4>
                      </div>

                      <div className="">
                        <p>
                          As the leading SEO services agency, transparency is a
                          cornerstone of our approach. We provide regular
                          reports detailing key performance metrics, including
                          organic traffic, and keyword rankings. Our analysis
                          helps you understand the impact of our strategies,
                          enabling informed decision-making and ongoing
                          optimization.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/programming-language-web-developers.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/programming-language-web-developers.png")}
                          alt=""
                        />
                        <h4 className="post-title">SEO Content Creation</h4>
                      </div>

                      <div className="">
                        <p>
                          Content is king in the digital realm, and our SEO
                          content creation services are geared towards providing
                          value to your audience while satisfying search engine
                          algorithms. We produce engaging, informative, and
                          keyword-rich content that not only attracts visitors
                          but also enhances your website's authority in your
                          industry.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="seo-work-include"
                      src={require("./../../../images/locationpagesimages/vector-isometric-concept.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className=""
                          src={require("./../../../images/locationpagesimages/vector-isometric-concept.png")}
                          alt=""
                        />
                        <h4 className="post-title">URL Optimization</h4>
                      </div>

                      <div className="">
                        <p>
                          Efficient URL optimization is integral to enhancing
                          search engine visibility. Our meticulous approach
                          involves optimizing on-page elements such as meta
                          tags, headers, and content. Moreover, we ensure that
                          your URLs are structured for optimal user readability
                          and clear comprehension by search engines, thereby
                          elevating the overall performance of your SEO
                          strategy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
