import React from "react";
import { NavLink } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 bacg-ser ertyu45"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x  white-text">
                  <h2 className="wfgrbwe ert678 heading mb-3">
                    Why Choose Shadow infosystem For SEO Services?
                  </h2>
                  <p>
                    Shadow infosystem stands as the premier and best SEO
                    services provider company, distinguished by our status as
                    the largest SEO agency in the industry. Collaborating with
                    numerous businesses, we've effectively enhanced their Google
                    rankings, amplified website traffic, increased brand
                    visibility, and augmented online revenue.
                  </p>
                  <p>
                    Renowned for our swift adaptation to algorithmic changes,
                    timely strategic shifts, and a commitment to complete
                    transparency in client interactions.
                  </p>
                  <p>
                    Our innovative mindset and passion for continual improvement
                    set us apart from the typical 'ordinary' SEO companies.
                    Furthermore, the following distinctive qualities distinguish
                    us as the Top SEO Agency:
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/2.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/locationpagesimages/2.png")}
                          alt=""
                        />
                        <h3 className="post-title sub-heading text-dark">
                          Transparency
                        </h3>
                      </div>

                      <div className="">
                        <p>
                          We believe in open communication and transparency at
                          every stage of our collaboration. Our clients receive
                          regular updates and detailed reports on the progress
                          of our SEO works. We provide clear insights into the
                          strategies implemented, the results achieved, and any
                          adjustments made to enhance performance.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/determine_the_business_development_plan.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/locationpagesimages/determine_the_business_development_plan.png")}
                          alt=""
                        />
                        <h3 className="post-title sub-heading text-dark">
                          Experience & Expertise
                        </h3>
                      </div>

                      <div className="">
                        <p>
                          With a solid foundation built on 15 years of
                          experience, as the best SEO services Agency, Shadow
                          infosystem brings a wealth of knowledge and expertise
                          to every project. This extensive experience allows us
                          to navigate the complexities of the digital landscape
                          effectively, providing clients with strategic insights
                          and proven methodologies for long-term success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/12.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/locationpagesimages/12.png")}
                          alt=""
                        />
                        <h3 className="post-title sub-heading text-dark">
                          Proven Track Record
                        </h3>
                      </div>

                      <div className="">
                        <p>
                          With a track record of successful SEO strategies
                          across diverse industries, we have consistently
                          delivered tangible results. Our portfolio showcases
                          case studies highlighting significant improvements in
                          website traffic, and keyword rankings for our clients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/14.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/locationpagesimages/14.png")}
                          alt=""
                        />
                        <h3 className="post-title sub-heading text-dark">
                          Cutting-edge Technology and Tools
                        </h3>
                      </div>

                      <div className="">
                        <p>
                          Staying ahead in the rapidly evolving world of SEO
                          requires the utilization of advanced tools and
                          technologies. We invest in state-of-the-art Search
                          Engine Optimization tools and stay updated with the
                          latest industry trends to provide our clients with the
                          most effective and innovative solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/4.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/locationpagesimages/4.png")}
                          alt=""
                        />
                        <h3 className="post-title sub-heading text-dark">
                          Customized Strategies
                        </h3>
                      </div>

                      <div className="">
                        <p>
                          Recognizing that every business is unique, we tailor
                          our Search Engine Optimization strategies to meet the
                          specific needs and goals of each client. Our team
                          conducts in-depth analyses to understand the target
                          audience, competitive landscape, and industry trends,
                          ensuring a personalized approach that maximizes
                          results.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/locationpagesimages/15.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white website-deve">
                      <div className="WhyChooseShadow">
                        <img
                          className="iocn1234"
                          src={require("./../../../images/locationpagesimages/15.png")}
                          alt=""
                        />
                        <h3 className="post-title sub-heading text-dark">
                          Dedicated Team of Experts
                        </h3>
                      </div>

                      <div className="">
                        <p>
                          As the Top SEO agency, our team consists of
                          experienced SEO professionals with a deep
                          understanding of search engine optimization algorithms
                          and digital marketing trends. Each member is committed
                          to staying updated on industry best practices and is
                          dedicated to delivering exceptional results for our
                          clients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
