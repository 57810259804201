import React from "react";
import Header2 from "./../../Common/Header3";
import Footer2 from "./../../Common/Footer2";
import Banner from "./../../Elements/CaseStudySara/banner";
import Innovation from "./../../Elements/CaseStudySara/Innovation";
import Overview from "./../../Elements/CaseStudySara/Overview";
import Techstack from "./../../Elements/CaseStudySara/Techstack";
import Challenges from "./../../Elements/CaseStudySara/Challenges";
import Results from "./../../Elements/CaseStudySara/Results";
import Social from "./../../Elements/CaseStudySara/Social";
import ClientsReview from "./../../Elements/CaseStudySara/ClientReview";
import Solution from "./../../Elements/CaseStudySara/Solution";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../../images/banner/5.jpg");

function Case3() {
  return (
    <div>
      {" "}
      <>
        <Helmet>
          <title>Case Study | Sara Consultancy</title>
          <meta name="description" content="Case Study | Sara Consultancy" />
          <meta name="keywords" content="Case Study | Sara Consultancy" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/case-study-sara-consultancy"
          />
        </Helmet>
        <Header2 />
        <div className="page-content case__study__page">
          <Banner />
          <Overview />
          <Challenges />
          <Solution />
          <Results />
          <Social />
          <Techstack />
          {/* <Innovation /> */}
          <ClientsReview />
        </div>

        <Footer2 />
      </>
    </div>
  );
}

export default Case3;
