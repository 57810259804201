import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             
                        <div className="qpqq">
                          
                            <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="m-b30 bg-white">
                                        
                                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/icon/responsive-design.png')} alt="" />

                                        
                                    </div>
                                        
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-12 col-md-12">
                                    <div className="section-head">
                                <div className="sx-separator-outer">
                                    <div className="">
                                        <h3 className="ert678">What makes custom software development a necessity for businesses or startups?</h3>
                                        
                                    </div>
                                    <ul className="alal">
                                        <li>Up to 50% of your product isn’t built from scratch</li>
                                        <li>The frameworks we use lay the foundation, so we don't have to build basic things like logins and notifications</li>
                                        <li>We have a Private library of reusable components for DevSquad clients only</li>
                                    </ul>
                                    <ul className="alal23 fgfgfg   ">
                                        <li>User Friendly</li>
                                        <li>User Friendly</li>
                                        <li>Responsive</li>
                                       
                                    </ul>
                                </div>
                            </div>
                                </div>
                                
                                
                                </div>
                                
                        </div>
                        <div className="SEOform">
                            <form className="cons-contact-form2 form-transparent" method="post" action="#">
                            <div className="section-head">
                                    <div className="sx-separator-outer separator-center">
                                        <div className="bg-moving bg-repeat-x">
                                            <h4 className="">Get A Enquiry</h4>
                                            <p>Want To Drive More Organic Traffic And Leads? Call Us Now Explore the Hidden Potential Of Your Website.</p>
                                        </div>
                                    </div>
                                </div>
                                                            
                                                            <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="input input-animate">
                                                                
                                                                <input defaultValue="" type="text" name="username" id="name" placeholder="Enter Your Name" required />
                                                                <span className="spin" />
                                                            </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input input-animate">
                                                                
                                                                <input defaultValue="" type="email" name="username" id="email" placeholder="Enter Your Email" required />
                                                                <span className="spin" />
                                                            </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="input input-animate">
                                                                
                                                                <input defaultValue="" type="phone" name="username" id="phone" placeholder="Enter Your Phone" required />
                                                                <span className="spin" />
                                                            </div>
                                                            </div>
                                                            <div className="col-lg-2">
                                                               <div class="client-logo-pic1">
                                                               <div className="text-left">
                                                               <NavLink to="#" className="site-button btn-half"><span>Submit</span></NavLink>
                                                               </div>
                                                               </div>
                                                            </div>
                                                            </div>
                                                            
                                                        </form>
                        </div>
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;