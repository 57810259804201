import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import About from "./../Elements/EngagemenModels/About";
import Banner from "./../Elements/EngagemenModels/Banner";
import EMTable from "./../Elements/EngagemenModels/EMTableSection";
import EMContent from "./../Elements/EngagemenModels/EMContent";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/web-development-banner.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Engagement Models - Shadow Infosystem</title>
          <meta
            name="description"
            content="Get top-notch website maintenance services at Shadow infosystem. Enhance performance, security, and functionality. Trust us for seamless management."
          />
          <meta name="keywords" content="Top IT Company in Noida" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <Banner />
          <EMTable />
          <EMContent />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
