import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/PrivacyPolicy/privacy1.png");

class Privacy1 extends React.Component {
  render() {
    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-5">
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="card card-privacy">
                      <div className="card-body">
                        <h5 className="card-title heading">
                          Protecting Your Privacy
                        </h5>

                        <p className="card-text">
                          Shadow infosystem is committed to providing you with
                          the highest levels of customer service. This includes
                          protecting your privacy. Shadow infosystem is bound by
                          the Commonwealth Privacy Act 1988, which sets out a
                          number of principles concerning the protection of your
                          personal information.
                        </p>
                        <p className="card-text">
                          Set out below is information that Shadow infosystem is
                          required to communicate to its customers. Shadow
                          infosystem recommends that you keep this information
                          for future reference.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="card card-privacy">
                      <div className="card-body">
                        <h5 className="card-title heading">
                          Your personal information
                        </h5>

                        <p className="card-text">
                          Personal information held by Shadow infosystem may
                          include your name, current & previous addresses,
                          telephone/phone number, email address, bank account or
                          credit card details, occupation, driver’s licence
                          number & your Shadow infosystem PIN, username or
                          password. Shadow infosystem also holds details of your
                          Shadow infosystem services (including their status).
                        </p>
                        <p className="card-text">
                          If you choose not to provide certain personal
                          information, Shadow infosystem may not be able to
                          provide you with the services you require, or the
                          level of service on which Shadow infosystem prides
                          itself.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-5 mb-5">
                <div className="sx-separator-outer separator-left">
                  <div className="ertyui">
                    <h3 className="ert678">
                      How we collect personal information
                    </h3>
                    <div className="mt-3">
                      <h5>
                        Shadow infosystem collects personal information in a
                        number of ways, including:
                      </h5>
                    </div>

                    <ul className="ul-style-disc mb-3">
                      {" "}
                      <li className="mb-3">
                        <span className="fa fa-circle"></span> Directly from
                        you, when you provide information by phone or in
                        application forms, or when you submit your personal
                        details through Shadow infosystem’s websites (e.g.
                        during www. shadowinfosystem.com registration or trial
                        services){" "}
                      </li>
                      <li className="mb-3">
                        <span className="fa fa-circle"></span> from third
                        parties such as Shadow infosystem’s related companies,
                        credit reporting agencies or your representatives{" "}
                      </li>
                      <li className="mb-3">
                        <span className="fa fa-circle"></span> from publicly
                        available sources of information
                      </li>
                      <li className="mb-3">
                        {" "}
                        <span className="fa fa-circle"></span> in response to a
                        survey, contest or sweepstakes
                      </li>
                      <li className="mb-3">
                        {" "}
                        <span className="fa fa-circle"></span> from the
                        organisations identified below under ‘When Shadow
                        infosystem discloses your personal information’{" "}
                      </li>
                      <li className="">
                        <span className="fa fa-circle"></span> from Shadow
                        infosystem’s own records of how you use your Shadow
                        infosystem services; or when legally required to do so.
                      </li>
                      <br />
                      <li className="mb-3">
                        {" "}
                        Also, your personal information is collected so that
                        Shadow infosystem can promote and market its services to
                        you (including by way of direct mail, telemarketing, SMS
                        and MMS messages). Shadow infosystem does this to keep
                        you informed of its products, services and special
                        offers. If you do not wish Shadow infosystem to promote
                        and market its products, services and special offers to
                        you, please call +91-8285560008.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-2 mb-10">
                <div className="sx-separator-outer separator-left">
                  <div className="ertyui">
                    <h3 className="ert678">
                      When we disclose your personal information
                    </h3>
                    <div className="mt-3">
                      <h5>
                        In order to deliver the services you require, Shadow
                        infosystem may disclose your personal information to
                        organisations outside of Shadow infosystem. Your
                        personal information is disclosed to these organisations
                        only in relation to Shadow infosystem providing its
                        services to you. These organisations may carry out
                        Shadow infosystem’s:
                      </h5>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <ul className="ul-style-disc mt-3">
                          <li className="mb-3">
                            <span className="fa fa-circle"></span> Customer
                            enquiries{" "}
                          </li>
                          <li className="mb-3">
                            <span className="fa fa-circle"></span> Mailing
                            operations{" "}
                          </li>
                          <li className="mb-3">
                            <span className="fa fa-circle"></span> Billing and
                            debt-recovery functions
                          </li>
                          <li className="mb-3">
                            {" "}
                            <span className="fa fa-circle"></span> Information
                            technology services
                          </li>
                          <li className="mb-3">
                            {" "}
                            <span className="fa fa-circle"></span> Installation,
                            maintenance and repair services
                          </li>
                          <li className="mb-3">
                            <span className="fa fa-circle"></span> Marketing and
                            telemarketing services
                          </li>
                          <li className="mb-3">
                            <span className="fa fa-circle"></span> Market
                            research
                          </li>
                          <li className="mb-3">
                            <span className="fa fa-circle"></span> Web site
                            usage analysis
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <div class="inline-links privacy-owl">
                          <img
                            src={require("./../../../images/PrivacyPolicy/privacy2.jpg")}
                            alt="Inteshape"
                          />{" "}
                        </div>
                      </div>
                      <ul>
                        <li className="">
                          {" "}
                          Also, your personal information is collected so that
                          Shadow infosystem can promote and market its services
                          to you (including by way of direct mail,
                          telemarketing, SMS and MMS messages). Shadow
                          infosystem does this to keep you informed of its
                          products, services and special offers. If you do not
                          wish Shadow infosystem to promote and market its
                          products, services and special offers to you, please
                          call +91-8285560008.
                        </li>{" "}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-5">
                <div className="card card-privacy">
                  <div className="card-body">
                    <h5 className="card-title heading">
                      Shadow infosystem’s web sites
                    </h5>
                    <p className="">
                      In some cases, cookies may collect and store personal
                      information about you. Shadow infosystem extends the same
                      privacy protection to your personal information whether
                      gathered via cookies or from other sources. You can adjust
                      your Internet browser to disable cookies or to warn you
                      when cookies are being used. However, if you disable
                      cookies, you may not be able to access certain areas of
                      our web sites or take advantage of the improved web site
                      experience that cookies offer.{" "}
                    </p>
                    <p className="">
                      Shadow infosystem may use ‘cookies’ on various web sites.
                      Cookies are an industry standard and most major web sites
                      use them. A cookie is a small text file that our web sites
                      may place on your computer. Usually, cookies are used as a
                      means for Shadow infosystem’s web sites to remember your
                      preferences. As such, cookies are designed to improve your
                      experience of Shadow infosystem’s web sites.{" "}
                    </p>
                    <p className="">
                      Shadow infosystem also collects Internet Protocol (IP)
                      addresses. IP addresses are assigned to computers on the
                      internet to uniquely identify them within the global
                      network. Shadow infosystem collects and manages IP
                      addresses as part of the service of providing internet
                      session management and for security purposes.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Privacy1;
