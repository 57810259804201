import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Technology integration",
    description:
      "Schools must keep up with the latest technology and integrate it into the curriculum, which can be challenging for teachers and administrators who may not be tech-savvy.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Assessment and testing",
    description:
      "Schools must administer and analyze assessments and test scores to evaluate student progress and make decisions about curriculum and instruction.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Limited resources",
    description:
      "Schools may have limited resources such as budget, technology, and staff, which can impact their ability to provide quality education and meet the needs of all students.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Digital Learning Solutions",
    description:
      "With the rise of e-learning, an IT company can provide schools with a range of digital learning solutions, such as online course materials, interactive quizzes, and e-books. This will help schools to enhance the quality of education and provide students with a more engaging learning experience.",
  },
  {
    number: <div class="number fontColor5">5</div>,
    heading: "Student Management Systems",
    description:
      "Schools can face challenges in managing large amounts of student data, such as attendance records, grades, and behavioral data. IT companies can provide student management systems that can help schools to store, organize and analyze student data more efficiently.",
  },
  {
    number: <div class="number fontColor6">6</div>,
    heading: "Security Solutions",
    description:
      "Schools need to ensure the safety of their students and staff. IT companies can provide security solutions that can help schools to secure their campuses and protect their networks from cyber threats.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h2 class="ert678">Challenges & Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
