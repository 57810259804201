import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const services = [
    {
        image: require('./../../images/client-logo/Microtek-logo-update.png'),
        
    },
    {
        image: require('./../../images/client-logo/Microtek-logo-update.png'),
       
    },
    {
        image: require('./../../images/client-logo/Microtek-logo-update.png'),
        
    }
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class SimilarProjects extends React.Component {
    render() {
        const options = {
            loop:true,
            autoplay:true,
            center: false,
            items:1,
            margin:40,
            nav:false,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:1
                },			
                991:{
                    items:1
                }
            
                
            }
        };
        return (
            <>
                <div className="section-full p-tb80 bg-gray inner-page-padding">
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                           
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                                <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel1 owl-btn-vertical-center" {...options}>
                                    {services.map((item, index) => (
                                        <div key={index} className="item">
                                            <div className="sx-box   image-single-carousel" style={{ backgroundImage: 'url(' + item.image + ')' }}>
                                                
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default SimilarProjects;