import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import SEOServicesDelhi from "./../Elements/SEOServicesDelhi";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best SEO Services Company in Delhi, NCR | Shadow infosystem</title>
          <meta name="description" content="Upraise your online presence with top SEO services in Delhi, NCR. Drive traffic, increase visibility, and dominate search rankings with us." />
          <meta name="keywords" content="Shadow infosystem, the SEO services company in Delhi NCR, offers customized solutions that help you to get more website traffic and leads." />
          <link rel="canonical" href="https://www.shadowinfosystem.com/seo-services-delhi" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <SEOServicesDelhi />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
