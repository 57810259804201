import React from 'react';
import Header2 from './../Common/Header3';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import WebBanner from './../Elements/Hosting/WebBanner';
import WebWebSiteTech from './../Elements/Hosting/WebWebSiteTech';
import WebWebSiteProcess from './../Elements/Hosting/WebWebSiteProcess';
import WebTypeDesign from './../Elements/Hosting/WebTypeDesign';
import WebAbout from './../Elements/Hosting/WebAbout';
import WebWhyChooseUs from './../Elements/Hosting/WebWhyChooseUs';
import Webclient from './../Elements/Hosting/Webclient';
import WebAboutNumber from './../Elements/Hosting/WebAboutNumber';
import WebCustomPlan from './../Elements/Hosting/WebCustomPlan';
import WebFAQ from './../Elements/Hosting/WebFAQ';
import CommenEnquiry from './../Elements/CommenEnquiry';
import Testimonials from './../Elements/Testimonials';
import { Helmet } from 'react-helmet';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
            <Helmet>
        <title>Top Cloud Service Providers | Cloud Security Services</title>
        <meta name="description" content="Discover reliable cloud service providers with advanced cloud security services. Shadow infosystem offers scalable, secure cloud solutions for your business needs." />
        <meta name="keywords" content="Website Development Company in Noida" />
        <link rel="canonical" href="https://www.shadowinfosystem.com/managed-cloud-services" /> 
        
      </Helmet>
                <Header2 />
                <div className="page-content">
                    <WebBanner/>
                    <WebAbout/>
                    <WebTypeDesign />
                    <WebWhyChooseUs />
                     <CommenEnquiry />
                    <Testimonials/>
                    {/*<WebFAQ/>*/}
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;