import React from "react";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

function termContent() {
  return (
    <>
      <div className="section-full mobile-page-padding p-b50 p-t80 back-ground-color">
        <div className="container-fluid">
          <div className="section-content">
            <div className="container">
              <div className="section-head">
                <div className="row">
                  <div className="col-md-12">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <h2 className="ert678 heading">
                          Social Media Marketing Terms of Service
                        </h2>
                        <p>
                          This Agreement is executed on (starting date) between,
                          Shadow infosystem (P) Limited and (who will here on
                          out be referred to as “the Client/Agency”). It is
                          agreed by and between the Client and the Shadow
                          infosystem (P) Limited as follows:
                        </p>
                        <h2 className="ert678 heading">Agency Appointment</h2>
                        <p>
                          The Client hereby appoints Shadow infosystem (P)
                          Limited to handle all advertising, communication and
                          related work on social media required for its brand:
                          (brand name).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <h2 className="ert678 heading">Agency Services</h2>
                        <p>
                          Shadow infosystem (P) Limited shall provide to the
                          Client, services, which shall include the following:
                        </p>
                        <ul className="sdnvdn">
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Consulting for online marketing.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Developing relevant and creative content to
                            reach your target customers.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Creating, curating, and managing all published
                            images, videos, and written content on your
                            business’s social media pages.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Launching carefully developed paid advertising
                            campaigns through Facebook ads, Google ads, and
                            Instagram promotions.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Conducting online advocacy and open a stream
                            for cross-promotions.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Developing and expand community outreach
                            efforts.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Monitoring, listening, and responding
                            appropriately to users while cultivating leads and
                            sales.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Overseeing the designs of your profile
                            pictures, thumbnails, ads, landing pages, etc.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Monitoring online reviews and responding where
                            appropriate.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Compiling organized reports for you showing
                            return of investment (ROI).
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Engaging in dialogues on social media,
                            answering questions, and interacting with other
                            users where appropriate.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Cultivating leads and sales by using marketing
                            strategies that are proven by testing and metrics.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Testing new and creative marketing strategies
                            through social media.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Monitoring trends in social media tools,
                            applications, channels, design, etc.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Identifying threats and opportunities in
                            user-generated content surrounding your business’s
                            social media pages, and notifying you of any
                            credible or notable threats.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Analysing marketing campaign data and using
                            this analysis to make recommendations and plans for
                            revising social media, content marketing, and social
                            advertising campaigns.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Analysing, reviewing, and reporting on the
                            effectiveness of campaigns in an effort to maximize
                            results.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Setting up social media accounts on platforms
                            such as Facebook, Twitter, Instagram, etc. as
                            specified at checkout.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Monitoring, managing, and posting for accounts
                            on these platforms as specified at checkout.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i>{" "}
                            &nbsp;Managing all social media communication.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;Any
                            other services agreed on by both parties that is
                            specified at checkout.
                          </li>
                          <li>
                            <i className="fa fa-check-square-o"></i> &nbsp;The
                            Company shall not be responsible for profiles or
                            their image/content streams dropped or excluded by a
                            search engine or social media site for any reason.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="sx-separator-outer separator-left">
                      <div className="ertyui">
                        <h2 className="ert678 heading">
                          Commencement And Duration
                        </h2>
                        <p>
                          This agreement is deemed to commence from (starting
                          date), and shall remain in force for (specified
                          number) days. The agreement may be renewed thereafter
                          as may be mutually agreed by both parties.
                        </p>
                        <h2 className="ert678 heading">Confidentiality</h2>
                        <p>
                          Shadow infosystem (P) Limited shall maintain absolute
                          confidentiality with respect to any confidential
                          information received from the Client. Shadow
                          infosystem (P) Limited shall not disclose any such
                          information without obtaining Client’s specific prior
                          consent, otherwise than in compliance with statutory
                          requirements.
                        </p>
                        <h2 className="ert678 heading">Governing Laws</h2>
                        <p>
                          The Client and Shadow infosystem (P) Limited shall
                          comply with all statutory regulations.
                        </p>
                        <h2 className="ert678 heading">
                          Agency Fees And Remuneration
                        </h2>
                        <p>
                          The Client agrees to pay the Agency a retainer fee of
                          (Specified Amount) per month. This amount will be
                          payable at the date of purchase, upon receipt of
                          invoice from Shadow infosystem (P) Limited Charges for
                          any other services including video creation,
                          application development, media buy, purchase of
                          licensed images and content, etc. if applicable, will
                          be charged extra.
                        </p>
                        <h2 className="ert678 heading">Indemnity</h2>
                        <p>
                          Shadow infosystem (P) Limited shall indemnify and keep
                          the Client indemnified against all losses, expenses or
                          damages that may be suffered by the Client due to any
                          default or breach of terms by Shadow infosystem (P)
                          Limited under this Agreement. The Client shall
                          indemnify and keep Shadow infosystem (P) Limited
                          indemnified against all losses, expenses or damages
                          that may be suffered by Shadow infosystem (P) Limited
                          due to any default or breach of terms by the Client
                          under this Agreement.
                        </p>
                        <h2 className="ert678 heading">Force Majeure</h2>
                        <p>
                          Neither the Client, nor Shadow infosystem (P) Limited,
                          shall be liable for any default, delay or lapse
                          occurring due to events beyond their control including
                          riot, strike, theft, war, famine, or natural disaster.
                        </p>
                        <h2 className="ert678 heading">Evaluation</h2>
                        <p>
                          The Client and Shadow infosystem (P) Limited shall
                          evaluate progress under this agreement at the
                          beginning of every payment period and take corrective
                          action as may be required.
                        </p>
                        <h2 className="ert678 heading">
                          Modification In Terms
                        </h2>
                        <p>
                          Any changes in the terms and conditions contained
                          herein shall have effect only prospectively, and shall
                          be valid only if recorded in writing and signed by the
                          authorized officials of the Client and Shadow
                          infosystem (P) Limited.
                        </p>
                        <h2 className="ert678 heading">Waiver</h2>
                        <p>
                          The failure of either party at any time to enforce any
                          provision of this Agreement, shall in no way affect
                          its right thereafter to require complete performance
                          by the other party, other than a lack of payment.
                          Further, waiver of any breach of any provision shall
                          not be held to be a waiver for any subsequent
                          breaches. Any waiver shall be valid only if it is
                          recorded in writing and signed by the authorized
                          officials of the Client and Shadow infosystem (P)
                          Limited.
                        </p>
                        <h2 className="ert678 heading">Termination</h2>
                        <p>
                          Either party may terminate this agreement by giving a
                          2-week notice to the other, without assigning any
                          reason whatsoever. The obligations of the parties
                          shall continue during the notice period.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default termContent;
