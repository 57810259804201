import React from 'react';
import Header2 from './../Common/Header3';
import Footer2 from './../Common/Footer2';
import Tbanner from './../Elements/Testimonial/Tbanner';
import TVideo from './../Elements/Testimonial/TVideo';
import TText from './../Elements/Testimonial/TText';
import { Helmet } from 'react-helmet';

class Service1 extends React.Component {
    render() {
        return (
            <>
            <Helmet>
        <title>Client Stories: Experiences with Shadow infosystem</title>
        <meta name="description" content="Get to Know the success stories and client testimonials at Shadow infosystem. Explore firsthand experiences with our innovative IT solutions and expertise." />
        <meta name="keywords" content="Top IT Company" />
        
      </Helmet>
                <Header2 />
                <div className="page-content">
                   <Tbanner/> 
                   <TVideo/>
                   <TText/>
                   {/*<TFAQ/>*/}
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;