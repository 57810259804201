import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">My Dental Kraft</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo ">
                  <img
                    src={require("./../../../images/mydentalkraft/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">My Dental Kraft</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location:</b>
                      LGF-104, Orange County Square, Ahinsa Khand-1,
                      Indirapuram, oppo. Aditya Mall, Ghaziabad- 201014
                    </li>
                    <li>
                      <b>Services:</b> Dental Clinic
                    </li>
                    <li>
                      <b>Website:</b>{" "}
                      <a
                        href="https://mydentalkraft.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.mydentalkraft.com
                      </a>
                    </li>
                  </ul>
                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul className="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/mydentalkraft"
                          className="facebook"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/mydentalkraft/"
                          className="instagram"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://twitter.com/DentalKraft1"
                          className="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            className="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@DentalKraft"
                          target="_blank"
                          className="youtube"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/mydentalkraft/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/mydentalkraft/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__15">
                        Dental Kraft is a multispecialty & multi–chair dental
                        clinic catering to residents of Indirapuram & NCR. This
                        clinic has leading specialists (MDS) like Orthodontists,
                        Root Canal Specialists, Oral and Maxillofacial surgeons,
                        and Implantologists which provides specialized care to
                        the patients and makes this clinic one of the best
                        dental clinics in Indirapuram Ghaziabad & NCR.
                      </p>

                      <p className="mb__0">
                        In addition to general dentistry services like teeth
                        cleaning, fillings, and extractions, My Dental Kraft
                        also offer specialty services like cosmetic dentistry
                        and dental implants.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
