import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <h3 className="wfgrbwe ert678 mb-3">
                    Our Technology Expertise
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-3.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/php.webp")}
                          alt=""
                        />
                        <h4 className="post-title">PHP</h4>
                      </div>
                      <div className="">
                        <p>
                          PHP remains one of the most widely used scripting
                          languages for web development. Our experts create
                          dynamic and interactive web applications that offer
                          excellent performance and reliability. Whether it’s a
                          small-scale website or a large enterprise application,
                          our PHP solutions are designed to scale with your
                          business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-4.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/laravel-icon-497x512-uwybstke.png")}
                          alt=""
                        />
                        <h4 className="post-title">Laravel</h4>
                      </div>
                      <div className="">
                        <p>
                          Laravel is a PHP framework known for its elegant
                          syntax and robust features. Our Laravel developers
                          craft web applications that are both powerful and
                          expressive. From complex e-commerce platforms to
                          content management systems, Laravel framework helps us
                          deliver solutions that are feature-rich and easy to
                          maintain.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images/WebsiteImage/Image-1.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/structure.png")}
                          alt=""
                        />
                        <h4 className="post-title">ReactJS</h4>
                      </div>
                      <div className="">
                        <p>
                          ReactJS is a powerful front-end library, used for
                          building user interfaces, especially for single-page
                          applications. Our developers leverage ReactJS to
                          create seamless, responsive, and highly interactive
                          user experiences. With ReactJS, we ensure your web
                          application is fast, scalable, and easy to maintain.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/node-js.png")}
                          alt=""
                        />
                        <h4 className="post-title">Node.js</h4>
                      </div>
                      <div className="">
                        <p>
                          Node.js allows for the development of fast and
                          scalable server-side applications. At Shadow
                          Infosystem, we use Node.js to build high-performance
                          back-end services and APIs. Its non-blocking,
                          event-driven architecture makes it perfect for
                          applications that require real-time communication and
                          high concurrency.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-5.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/icon/social-media.png")}
                          alt=""
                        />
                        <h4 className="post-title">Codeigniter</h4>
                      </div>
                      <div className="">
                        <p>
                          Codeigniter is a lightweight PHP framework that
                          enables rapid web application development. Our team
                          utilizes Codeigniter to create dynamic websites and
                          applications that require minimal configuration. Its
                          simplicity and speed make it an ideal choice for small
                          to medium-sized projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images//WebsiteImage/Image-2.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/head-icon/dot-net.webp")}
                          alt=""
                        />
                        <h4 className="post-title">.Net</h4>
                      </div>
                      <div className="">
                        <p>
                          This is a versatile and robust platform for building
                          enterprise-grade applications. Our .Net developers
                          excel in creating secure, scalable, and
                          high-performance web applications. Whether it’s web
                          services, desktop applications, or cloud-based
                          solutions, .Net offers a comprehensive environment for
                          development.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div className="hilite-title common-enq-bot1 p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
