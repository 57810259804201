import React from "react";
import { NavLink } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TypeDesign from "./../TypeDesign";
var bgimg1 = require("./../../../images/background/home-banner.jpg");

class pppSolution extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x">
                  <h2 className="wfgrbwe ert678 heading text-white mb-3">
                    Our PPC Solutions Includes
                  </h2>
                  <p className="text-white">
                    <b>
                      Our comprehensive PPC solutions include but are not
                      restricted to the following:
                    </b>
                  </p>
                </div>
              </div>
            </div>
            {/* TITLE END */} {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info  bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="sx-post-title ">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../../images/head-icon/keywords.webp")}
                            alt=""
                          />
                          <h3 className="post-title text-dark sub-heading">
                            Keyword Suggestions
                          </h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          Our PPC specialists conduct thorough analyses to
                          pinpoint keywords that promise increased inquiries and
                          conversion opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="sx-post-title ">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../../images/locationpagesimages/9.png")}
                            alt=""
                          />
                          <h3 className="post-title text-dark sub-heading">
                            PPC Copywriting
                          </h3>
                        </div>
                      </div>
                      <div className="">
                        <p>
                          Crafting an enticing title and description is crucial
                          for getting more clicks. Our team of creative writers
                          is dedicated to preparing captivating copies for your
                          PPC campaign.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="sx-post-title ">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../../images/locationpagesimages/14.png")}
                            alt=""
                          />
                          <h3 className="post-title text-dark sub-heading">
                            Bid Management
                          </h3>
                        </div>
                      </div>
                      <div className="">
                        <p>
                          Our team of PPC experts strategically bids on keywords
                          likely to yield higher ROI. Each bid is vigilantly
                          monitored, prioritizing the most targeted yet least
                          competitive keywords.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="sx-post-title ">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../../images/locationpagesimages/Search_seo_optimization_by_increasing_the_number_of_followers.png")}
                            alt=""
                          />
                          <h3 className="post-title text-dark sub-heading">
                            Search Advertising
                          </h3>
                        </div>
                      </div>
                      <div className="">
                        <p>
                          With the expertise of our PPC specialists, we assist
                          you in identifying the optimal set of keywords and
                          devising a strategy to attract more traffic to your
                          website.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="sx-post-title ">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../../images/locationpagesimages/2.png")}
                            alt=""
                          />
                          <h3 className="post-title text-dark sub-heading">
                            Conversion/Call/Sales Tracking
                          </h3>
                        </div>
                      </div>
                      <div className="">
                        <p>
                          Every click, call, conversion, and sale is
                          meticulously tracked by our team, providing you with
                          the data needed to calculate the benefits.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="sx-post-info bg-white sdfgj56564 qweqwe servdert-one1">
                      <div className="sx-post-title ">
                        <div className="WhyChooseShadow">
                          <img
                            className=""
                            src={require("./../../../images/locationpagesimages/12.png")}
                            alt=""
                          />
                          <h3 className="post-title text-dark sub-heading">
                            Performance Reporting
                          </h3>
                        </div>
                      </div>

                      <div className="">
                        <p>
                          Regular documentation and sharing of PPC advertisement
                          reports enable you to analyze click-through rates,
                          traffic, and ROI effectively.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Why Choose</strong>
          </div>
        </div>
      </>
    );
  }
}

export default pppSolution;
