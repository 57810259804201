import React from "react";

var bnrimg = require("./../../../images/banner/7.jpg");

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="page-content">
          <div className="section-full p-t80 p-b80 inner-page-padding bg-gray">
            {/*<div className="trtrtrtyuy"></div>*/}
            <div className="container">
              <div className="faq-1">
                <div className="row">
                  <div className="col-md-12">
                    {/* TITLE START */}
                    <div className="section-head">
                      <div className="sx-separator-outer">
                        <div className="bg-moving bg-repeat-x">
                          <h3 className="wfgrbwe ert678 text-center">
                            Website Maintenance Services FAQs
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* Accordian */}
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              Could you clarify what is included in this
                              service?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              Our website maintenance service encompasses
                              routine checks for performance, security, and user
                              experience. We provide content updates, implement
                              security patches, address issues promptly, and
                              ensure continuous improvement for a seamless and
                              secure online presence.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Do I need to sign a long-term contract?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              No, at Shadow infosystem, we offer flexibility.
                              Our services are designed to meet your needs
                              without imposing long-term contracts. Enjoy the
                              freedom to engage with us based on your
                              requirements and preferences.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Could you please clarify what is meant by
                              "Unlimited Technical Support"?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              "Unlimited Technical Support" from Shadow
                              infosystem means unrestricted access to our expert
                              team for assistance with any technical issues.
                              Enjoy continuous support, ensuring your systems
                              run smoothly and efficiently without limitations
                              or constraints.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How long does it take to get my changes made after
                              I submit a maintenance request?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              The time to implement changes after submitting a
                              maintenance request generally depends on the
                              nature of the changes. This process ensures
                              thorough testing and quality assurance, promoting
                              a smooth transition and minimizing disruptions to
                              your system or service.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Is my Web site “down” while you work on it?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Yes, your website may experience temporary
                              downtime while we work on it to implement changes
                              or perform maintenance. We aim to minimize this
                              downtime to ensure the least impact on your online
                              presence.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
      </>
    );
  }
}

export default Faq;
