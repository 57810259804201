import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-12">
                    <div className="">
                      <img
                        src={require("./../../../images/update1/main2.webp")}
                        alt=""
                      />

                      {/* <div class="row bertfv">
                        <div class="col-lg-12"></div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12">
                    <div className="m-b30 bg-white">
                      <h3 class="ert678">About Shadow</h3>
                      <p>
                        Shadow infosystem, an esteemed IT company, epitomizes
                        the pinnacle of excellence in the realms of cyber
                        security and digital transformation. Distinguished for
                        our meticulous approach in tailoring solutions to the
                        unique needs of our esteemed clientele, we are
                        unwaveringly committed to utilizing cutting-edge
                        technologies that seamlessly align with the aspirations
                        of our clients.
                      </p>
                      <p>
                        In the dynamic landscape of IT, our experienced team of
                        expert marketers, designers, and developers possess the
                        insight to plan campaigns that transcend conventional
                        boundaries. In our relentless pursuit of tangible
                        results, metrics such as leads and revenue generated
                        occupy the forefront of our focus. The yardstick of our
                        success is intrinsically tied to the prosperity of our
                        clients.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="m-b30 bg-white">
                      <p>
                        As evidence of our unwavering commitment to security, we
                        proudly declare compliance with the most stringent
                        requirements laid down by the Ministry of Defence and
                        the Ministry of Law Enforcement. Furthermore, our
                        coveted ISO 27001:2013 certification serves as a beacon
                        of trust for over 1500+ customers who rely on our
                        expertise.
                      </p>
                      <p>
                        Accolades adorn our journey, with 16 excellence awards
                        in IT services, underscoring our prowess and dedication.
                        Having extended our innovative solutions to more than 40
                        diverse business categories, our enthusiasm to foster a
                        seamless and secure online experience is unwavering.
                      </p>
                      <p>
                        Spanning India, the USA, and Canada, the Shadow
                        infosystem transcends corporate boundaries. More than a
                        company, we symbolize sophistication, expertise, and an
                        unwavering commitment to fortify the digital landscape,
                        ensuring it thrives securely for everyone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>About</strong>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
