import React from "react";
import { NavLink } from "react-router-dom";
import TypeDesign from "./../TypeDesign";

const services = [
  {
    image: require("./../../../images/icon/trophy-star.png"),
    count: "01",
    title: "Excellence",
  },
  {
    image: require("./../../../images/icon/stats.png"),
    count: "02",
    title: "Innovation",
  },
  {
    image: require("./../../../images/icon/reliable.png"),
    count: "03",
    title: "Collaboration",
  },
  {
    image: require("./../../../images/icon/group.png"),
    count: "04",
    title: "Accountability",
  },
];

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Services6 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding bg-gray  p-t80 p-b50 bg-repeat overflow-hide"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            <div className="large-title-block text-center">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-right">
                  <div className="">
                    <h3 className="">
                      Identify, Harness, Transform &<br /> Achieve with Shadow
                    </h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
            </div>
            <div className="row">
              <div className="col-md-6">
                {/* TITLE START */}
                <div className="section-head">
                  <div className="sx-separator-outer separator-left">
                    <div className="">
                      <h3 className="p-00">
                        Shadow value your business and offers a wide array of
                        intuitive experience.
                      </h3>
                    </div>
                  </div>
                  <div className="mb-3">
                    <TypeDesign />
                  </div>
                </div>
                {/* TITLE END */}
              </div>
              <div className="col-md-6">
                <div className="section-content">
                  <div className="row number-block-two-outer">
                    {services.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-12 m-b30"
                        key={index}
                      >
                        <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                          <div className="figcaption back-ground-color p-a30">
                            <img className="fe76554" src={item.image} alt="" />
                            <h4 className="m-t0">{item.title}</h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services6;
