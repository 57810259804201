import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/background/cross-line2.png');

class Blog2 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
            animateOut: 'fadeOut',
        };
        return (
            <>
                 
                <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
                
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x">
                                    <h3 className="wfgrbwe ert678">Why Choose Shadow</h3>
                                    <p>Our company is known for providing optimum services that are reliable and cost effective.<br/> We have expertise in our domain and a lot to offer.</p>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                            
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info  bg-white sdfgj56564">

                                            
                                            
                                            <div className=" WhyChooseShadow">
                                            <img className="iocn1234" src={require('./../../../images/icon/reliable.png')} alt="" />  
                                                <h4 className="post-title">Experience</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>To deliver top-notch websites, we ensure to thoroughly get them tested before we deliver them to you. Automated and manual testing both are implemented and all the newest quality assurance parameters are followed.</p>
                                            </div>
                                            <div className="clearfix">
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                            
                                            <div className=" WhyChooseShadow">
                                            <img className="iocn1234" src={require('./../../../images/icon/trust.png')} alt="" />  
                                                <h4 className="post-title">Professional Team</h4>

                                            </div>
                                            
                                            <div className="">
                                                <p>To deliver top-notch websites, we ensure to thoroughly get them tested before we deliver them to you. Automated and manual testing both are implemented and all the newest quality assurance parameters are followed.</p>
                                            </div>
                                            

                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow ">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                            
                                            <div className=" WhyChooseShadow">
                                            <img className="iocn1234" src={require('./../../../images/icon/customer-service.png')} alt="" />  
                                                <h4 className="post-title">Agile Methodology</h4>
                                            </div>
                                           
                                            <div className="">
                                                <p>To deliver top-notch websites, we ensure to thoroughly get them tested before we deliver them to you. Automated and manual testing both are implemented and all the newest quality assurance parameters are followed.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                              
                                            <div className=" WhyChooseShadow">
                                            <img className="iocn1234" src={require('./../../../images/icon/together.png')} alt="" />
                                                <h4 className="post-title">Quality Assurance Check</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>To deliver top-notch websites, we ensure to thoroughly get them tested before we deliver them to you. Automated and manual testing both are implemented and all the newest quality assurance parameters are followed.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                              
                                            <div className=" WhyChooseShadow">
                                            <img className="iocn1234" src={require('./../../../images/icon/team-support.png')} alt="" />
                                                <h4 className="post-title">Creativity</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>To deliver top-notch websites, we ensure to thoroughly get them tested before we deliver them to you. Automated and manual testing both are implemented and all the newest quality assurance parameters are followed.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* COLUMNS 2 */}
                                    <div className="blog-post blog-lg date-style-3 block-shadow">
                                        
                                        <div className="sx-post-info bg-white sdfgj56564">
                                            
                                            
                                            <div className=" WhyChooseShadow">
                                            <img className="iocn1234" src={require('./../../../images/icon/good-review.png')} alt="" />  
                                                <h4 className="post-title">User Interests</h4>
                                            </div>
                                            
                                            <div className="">
                                                <p>To deliver top-notch websites, we ensure to thoroughly get them tested before we deliver them to you. Automated and manual testing both are implemented and all the newest quality assurance parameters are followed.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                               
                                
                                
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Why Choose</strong>
                    </div>
                </div>
                
            </>
        );
    }
};

export default Blog2;