import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 amirder`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             
                        <div className="qpqq">
                          
                            <div className="row">
                                
                                <div className="col-xl-7 col-lg-12 col-md-12">
                                <div className="">
                                <div className="sx-separator-outer separator-left">
                                    <div className="ertyui">
                                        <h3 className="">Expert Level PHP Development</h3>
                                        
                                    </div>
                                </div>
                            </div>
                                    <div className="m-b30">
                                        
                                        <p>It is essential to provide a robust digital experience to build a strong customer-brand relationship for a long. PHP framework offers cutting-edge technical proficiency to websites and apps. The comprehensive suite of features, widespread community, and increasing reliability over PHP, makes it an essential scripting language. And, this is the reason, we offer competitive PHP development services to the enterprise of all sizes and scale. We, at OrangeMantra, are a leading PHP developmnet company that offers advanced, custom PHP development services for diverse business requirements.</p>
                                         <ul className="alal23 fgfgfg   ">
                                        <li>Easy and Simple</li>
                                        <li>Extremely Flexible</li>
                                        <li>Efficient Performance</li>
                                        </ul>
                                        <ul className="alal23 rtyu123">
                                        <li>Cost-Efficient</li>
                                        <li>Gives Web Developer More Control</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-12 col-md-12">
                                <img src={require('./../../../images/projects/4380744-01.png')} alt="" />
                                </div>
                                
                                </div>
                                
                        </div>
                        
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;