import React from "react";
const solutions = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Digital Transformation",
    description:
      "The consultancy industry is highly competitive, and new players are entering the market all the time. Established consultancy services companies must continually innovate to differentiate themselves and stay ahead of the competition.",
  },
  {
    number: <div class="number fontColor4">2</div>,
    heading: "Staff Management",
    description:
      "An IT company can provide software solutions for managing employee schedules, training, and performance reviews. They can also provide tools for automating HR tasks, such as payroll and benefits administration.",
  },
  {
    number: <div class="number fontColor2">3</div>,
    heading: "Technology and Regulatory Compliancen",
    description:
      "An IT company can help consultancy services companies stay up-to-date with the latest technology and regulatory requirements. They can provide guidance on implementing new systems and tools, as well as ensure compliance with data privacy and security regulations",
  },
  {
    number: <div class="number fontColor3">4</div>,
    heading: "Client Acquisition and Retention",
    description:
      "An IT company can help consultancy services companies build their online presence and reputation through website design, search engine optimization, and social media management. They can also help companies implement client retention strategies, such as personalized marketing campaigns and feedback collection mechanisms.",
  },
];

function Solution() {
  return (
    <>
      <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
        <div className="container p-t80">
          <div className="row">
            <div className="col-md-12 sx-separator-outer separator-center">
              <div className="case-banner">
                <h2 class="ert678">The Solutions</h2>
              </div>
            </div>
            <div className="col-md-12 sx-separator-outer separator-center"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul class="infoGraphic">
                {solutions.map((item, index) => (
                  <li>
                    <div class="numberWrap">
                      {item.number}
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <h3>{item.heading}</h3>
                      <p>{item.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solution;
