import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Digital Marketing & Branding Support",
    description:
      "An IT company can help businesses to stand out and compete in a crowded market by developing digital marketing and branding strategies that can help businesses to improve their online visibility and reputation.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Scheduling & Time Management Tools",
    description:
      "To help tree care businesses manage seasonal fluctuations in demand, IT companies can develop scheduling and time management tools that can help businesses to plan their workloads effectively and optimize their resources.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Safety Management Software",
    description:
      "An IT company can develop safety management software that can help tree care professionals to manage risks and hazards, improve safety training, and ensure compliance with safety regulations.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Equipment Tracking & Management Software",
    description:
      "IT companies can develop equipment tracking and management software that can help businesses keep track of their equipment, maintenance schedules, and associated costs.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
