import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const projects = [
  {
    image: require("./../../../images/312212917_484502590364616_8145911098452374932_n.jpg"),
  },
  {
    image: require("./../../../images/312212917_484502590364616_8145911098452374932_n.jpg"),
  },
];

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Projects4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      center: false,
      items: 3,
      margin: 40,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          margin: 15,
        },
      },
    };
    return (
      <>
        <div className="section-full inner-page-padding p-b50 p-t80 ">
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    {/* TITLE START */}
                    <div className="m-b30 bg-white">
                      <h3 class="ert678">
                        We Grow Your Business, With Our Innovative Solutions
                      </h3>
                      <p>
                        <b>Understanding Project Requirements</b>
                      </p>
                      <p>
                        Shadow is a cyber security and digital transformation
                        company. Our solutions are tailored to our client’s
                        needs and we employ the latest technologies to meet our
                        client’s goals. Our expert marketers, designers, and
                        developers know how to drive results. That’s why metrics
                        like leads and revenue generated are always in sharp
                        focus. Eventually, we are as successful as our clients
                        are.{" "}
                      </p>
                    </div>
                    {/* TITLE END */}
                  </div>
                  <div className="col-md-6">
                    <div className="work-carousel-outer">
                      <img
                        src={require("./../../../images/update1/inovation.webp")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Projects4;
