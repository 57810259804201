import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from './../Common/Header3';
import Footer2 from './../Common/Footer2';
import Banner from './../Elements/Banner';

var bgimg1 = require('./../../images/bacgarref.png');

class ShopCart extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                
                    {/* OUR BEST SERVICES SECTION  START*/}
                    <div className="section-full  p-t80 p-b50 mobile-page-padding fscs1213098" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                        <div className="container">
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="color-change bg-moving bg-repeat-x white-text">
                                <p><b>Current</b></p>
                                    <h2 className="wfgrbwe ert678">Account Detail</h2>
                                </div>
                            </div>
                        </div>
                            <div className="section-content">
                                <div id="no-more-tables">
                                    <table className="table-bordered  table-condensed cf wt-responsive-table shopping-table">
                                       
                                        <tbody>
                                            <tr>
                                            <td data-title="Name">Name</td>
                                                <td data-title="Shadow infosystem Private Limited">Shadow infosystem Private Limited</td>
                                            </tr>
                                            <tr>
                                            <td data-title="Bank Name">Bank Name </td>
                                                <td data-title="ICICI Bank">ICICI Bank</td>
                                            </tr>
                                            <tr>
                                            <td data-title="Account Number">Account Number </td>
                                                <td data-title="071805000516">071805000516</td>
                                            </tr>
                                            <tr>
                                            <td data-title="RTGS/NEFT IFC Code">RTGS/NEFT IFC Code </td>
                                                <td data-title="ICIC0000718">ICIC0000718</td>
                                            </tr>
                                            <tr>
                                            <td data-title="Swift Code">Swift Code </td>
                                                <td data-title="ICICINBBCTS">ICICINBBCTS</td>
                                            </tr>
                                            <tr>
                                            <td data-title="Account Type">Account Type   </td>
                                                <td data-title="Current">Current</td>
                                            </tr>
                                            <tr>
                                            <td data-title="PAN No.">PAN No.</td>
                                                <td data-title="AASCS7622K">AASCS7622K</td>
                                            </tr>
                                            <tr>
                                            <td data-title="GSTIN">GSTIN</td>
                                                <td data-title="09AASCS7622K2ZK">09AASCS7622K2ZK</td>
                                            </tr>
                                            
                                        </tbody>

                                    </table>
                                    <div className="buttonfgerb">
                                                        <div className="header-section-12">
                                                            <a className="nwhafjwn" 
                                                            href="https://api.whatsapp.com/send?phone=whatsappphonenumber&
                                                            text=Name : *Shadow infosystem Private Limited*
                                                            Bank Name: *ICICI Bank*
                                                            Account Number: *071805000516*
                                                            Account Number: *071805000516*
                                                            RTGS/NEFT IFC Code: *ICIC0000718*
                                                            Swift Code: *ICICINBBCTS*
                                                            Account Type: *Current*
                                                            PAN No.: *AASCS7622K*
                                                            GSTIN: *09AASCS7622K2ZK*

                                                            ">Share On WhatsApp</a>
                                                        </div>

                                                       
                                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* OUR BEST SERVICES SECTION END */}
                </div>

                <Footer2/>
            </>
        );
    };
};

export default ShopCart;