import React from "react";

const overview = [
  {
    icon: <i class="fa fa-line-chart"></i>,
    heading: "Marketing & Branding",
    description:
      "An IT company can help an IVF center develop and execute the digital marketing strategies to reach out to the potential patients. This can include developing a website, creating social media campaigns, and managing the online advertising.",
  },
  {
    icon: <i class="fa fa-database"></i>,
    heading: "Data Management",
    description:
      "An IT company can provide customized software solutions to manage and store the patient data securely. It can also help to develop and integrate the electronic medical record (EMR) systems for better and streamlined record-keeping.",
  },
  {
    icon: <i class="fa fa-money"></i>,
    heading: "Cost Savings",
    description:
      "IT solutions can help an IVF center reduce costs by automating manual processes & improving operational efficiency. For instance, automating billing, & inventory management can reduce staffing requirements & expenses.",
  },
  {
    icon: <i class="fa fa-hospital-o"></i>,
    heading: "Improved Patient Care",
    description:
      "By using technology, an IVF center can enhance the quality of care & treatment offered to patients. An IT company can provide solutions such as remote monitoring, AI-assisted diagnosis, which help doctors make more accurate and informed decisions.",
  },
];

class Overview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="mb__25 bg-white">
                  <h2 class="ert678">Overview</h2>
                  <p className="mb__15">
                    Here are some potential advantages for a person with tree
                    care experience by partnering with an IT company :
                  </p>
                </div>
              </div>
              {overview.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div class="card-wrap mt__0">
                    <div class="card-header three">{item.icon}</div>
                    <div class="card-content">
                      <h3 class="card-title">{item.heading}</h3>
                      <p class="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Overview;
