import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 amirder`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             
                        <div className="qpqq">
                          
                            <div className="row">
                                
                                <div className="col-xl-7 col-lg-12 col-md-12">
                                <div className="">
                                <div className="sx-separator-outer separator-left">
                                    <div className="ertyui">
                                        <h3 className="ert678">WordPress Design & Development</h3>
                                        
                                    </div>
                                </div>
                            </div>
                                    <div className="m-b30">
                                        
                                        <p>Gone are days of HTML websites. Now is the age of WordPress websites. From start-ups to big brands, companies prefer using the WordPress platform to build websites. Not just for blogs, WordPress offers great possibilities for building beautiful websites with rich user experience. From eCommerce and entertainment websites to stunning corporate websites, WordPress can be used to construct a webspace the way you want it. We are a WordPress development company with experienced WordPress developers apt at building great websites. Our WordPress development services include everything from design and development to content and customization as per client needs.</p>
                                         <ul className="alal23 fgfgfg   ">
                                        <li>Easy and Simple</li>
                                        <li>Extremely Flexible</li>
                                        <li>Efficient Performance</li>
                                        </ul>
                                        <ul className="alal23 rtyu123">
                                        <li>Cost-Efficient</li>
                                        <li>Gives Web Developer More Control</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-12 col-md-12">
                                <img src={require('./../../../images/projects/tab-img31-removebg-preview.png')} alt="" />
                                </div>
                                
                                </div>
                                
                        </div>
                        
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;