import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    function checkMandatory388974000001449001() {
      var mndFileds = new Array("Last Name");
      var fldLangVal = new Array("First\x20Name");
      for (let i = 0; i < mndFileds.length; i++) {
        var fieldObj =
          document.forms["WebToContacts388974000001449001"][mndFileds[i]];
        if (fieldObj) {
          if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
            if (fieldObj.type == "file") {
              alert("Please select a file to upload.");
              fieldObj.focus();
              return false;
            }
            alert(fldLangVal[i] + " cannot be empty.");
            fieldObj.focus();
            return false;
          } else if (fieldObj.nodeName == "SELECT") {
            if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
              alert(fldLangVal[i] + " cannot be none.");
              fieldObj.focus();
              return false;
            }
          } else if (fieldObj.type == "checkbox") {
            if (fieldObj.checked == false) {
              alert("Please accept  " + fldLangVal[i]);
              fieldObj.focus();
              return false;
            }
          }
          try {
            if (fieldObj.name == "Last Name") {
              let name = fieldObj.value;
            }
          } catch (e) {}
        }
      }
      if (!validateFileUpload388974000001449001()) {
        return false;
      }

      var urlparams = new URLSearchParams(window.location.search);
      if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
        var webform = document.getElementById("webform");
        var service = urlparams.get("service");
        var smarturlfield = document.createElement("input");
        smarturlfield.setAttribute("type", "hidden");
        smarturlfield.setAttribute("value", service);
        smarturlfield.setAttribute("name", "service");
        webform.appendChild(smarturlfield);
      }
      document
        .querySelector(".crmWebToEntityForm .formsubmit")
        .setAttribute("disabled", true);
    }

    function validateFileUpload388974000001449001() {
      var uploadedFiles = document.getElementById("theFile388974000001449001");
      var totalFileSize = 0;
      if (uploadedFiles.files.length > 3) {
        alert("You can upload a maximum of three files at a time.");
        return false;
      }
      if ("files" in uploadedFiles) {
        if (uploadedFiles.files.length != 0) {
          for (var i = 0; i < uploadedFiles.files.length; i++) {
            var file = uploadedFiles.files[i];
            if ("size" in file) {
              totalFileSize = totalFileSize + file.size;
            }
          }
          if (totalFileSize > 20971520) {
            alert("Total file(s) size should not exceed 20MB.");
            return false;
          }
        }
      }
      return true;
    }
    function tooltipShow388974000001449001(el) {
      var tooltip = el.nextElementSibling;
      var tooltipDisplay = tooltip.style.display;
      if (tooltipDisplay == "none") {
        var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
        for (let i = 0; i < allTooltip.length; i++) {
          allTooltip[i].style.display = "none";
        }
        tooltip.style.display = "block";
      } else {
        tooltip.style.display = "none";
      }
    }
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding pt-5 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="">
                <div className="">
                  <div className="row caree">
                    <div className="col-xl-7 col-lg-7 col-md-12">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="">
                            <div className="career-one heading mt-5">
                              <h1>Are you ready to take on the challenge?</h1>
                            </div>
                          </div>
                          <p>
                            Joining Shadow infosystem means becoming part of a
                            dynamic company committed to inclusively fostering
                            growth and sustainability. Our team comprises
                            diverse individuals from around the globe, united in
                            our mission to leverage technology for
                            transformative change and to shape a future that
                            reflects our collective vision.
                          </p>
                          <p>
                            At Shadow infosystem, we collaborate to drive
                            innovation and empower the world's leading
                            businesses. We strive to exceed expectations and
                            challenge ourselves to reach new heights constantly.
                            Together, we cultivate fulfilling careers and infuse
                            our work with the human touch that drives meaningful
                            progress.
                          </p>
                          <p>
                            Join us in revolutionizing technology and making a
                            difference. As a customer-centric IT solutions
                            provider, we've experienced tremendous growth thanks
                            to the dedication of our team. At our core, we're
                            driven by the belief in providing equal
                            opportunities for all and positively impacting
                            society.
                          </p>
                          {/*<h6 className="sub-heading">
                            Shadow. Get the future you want.
                          </h6>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-12 sfdd mt-3">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div
                            id="crmWebToEntityForm"
                            class="zcwf_lblTopBottom crmWebToEntityForm caree-fome"
                          >
                            <form
                              id="webform"
                              action="https://crm.zoho.in/crm/WebToContactForm"
                              name="WebToContacts388974000001449001"
                              method="POST"
                              enctype="multipart/form-data"
                              onSubmit='javascript:document.charset="UTF-8"; return checkMandatory388974000001449001()'
                              accept-charset="UTF-8"
                            >
                              <input
                                type="text"
                                className="care-nrew-fo"
                                name="xnQsjsdp"
                                value="7a4cd3bb4a4060b6a47f39136d0c8f747abcdb4e39c36c2dce0f875d94b2b72d"
                              />
                              <input
                                type="hidden"
                                name="zc_gad"
                                id="zc_gad"
                                value=""
                              />
                              <input
                                type="text"
                                className="care-nrew-fo"
                                name="xmIwtLD"
                                value="976f1b857781d3ce0e1ae4c0f6351e6bf075ebcde164c06efdc0789b504c6d2e079de18064dfc09ba6026e3979b278f9"
                              />
                              <input
                                type="text"
                                className="care-nrew-fo"
                                name="actionType"
                                value="Q29udGFjdHM="
                              />
                              <input
                                type="text"
                                className="care-nrew-fo"
                                name="returnURL"
                                value="https&#x3a;&#x2f;&#x2f;www.shadowinfosystem.com&#x2f;thank-you&#x2f;"
                              />

                              <div class="">
                                <b>
                                  Join the Exceptional Team of Shadow infosystem
                                </b>
                              </div>
                              <p className="care-title-new">
                                Fill the Form and Seize the Opportunity!
                              </p>
                              <div class="zcwf_row">
                                <div class="zcwf_col_fld">
                                  <input
                                    type="text"
                                    id="Last_Name"
                                    name="Last Name"
                                    maxlength="80"
                                    placeholder="First Name"
                                  />
                                  <div class="zcwf_col_help"></div>
                                </div>
                              </div>
                              <div class="zcwf_row">
                                <div class="zcwf_col_fld">
                                  <input
                                    type="text"
                                    ftype="email"
                                    autocomplete="false"
                                    id="Email"
                                    name="Email"
                                    placeholder="Email"
                                    crmlabel=""
                                    maxlength="100"
                                  />
                                  <div class="zcwf_col_help"></div>
                                </div>
                              </div>
                              <div class="zcwf_row">
                                <div class="zcwf_col_fld">
                                  <input
                                    type="text"
                                    id="Phone"
                                    name="Phone"
                                    maxlength="50"
                                    placeholder="Phone"
                                  />
                                  <div class="zcwf_col_help"></div>
                                </div>
                              </div>
                              <div class="zcwf_row">
                                <div class="zcwf_col_fld">
                                  <textarea
                                    id="Description"
                                    name="Description"
                                    placeholder="Description"
                                    className="mncs098986"
                                  ></textarea>
                                  <div class="zcwf_col_help"></div>
                                </div>
                              </div>
                              <div class="zcwf_row">
                                <div class="zcwf_col_fld">
                                  <div class="clearB">
                                    <input
                                      type="file"
                                      class="zcwf_file"
                                      name="theFile"
                                      placeholder="Upload a File"
                                      id="theFile388974000001449001"
                                      multiple
                                    />
                                    <div class="zcwf_col_help"></div>
                                  </div>
                                  <p className="dwfwdw-careere">
                                    File(s) size limit is 20MB.
                                  </p>
                                </div>
                              </div>
                              <div class="zcwf_row">
                                <div class="zcwf_col_lab"></div>
                                <div class="zcwf_col_fld">
                                  <input
                                    type="submit"
                                    id="formsubmit"
                                    className="formsubmit zcwf_button sw34rtghbvcxer"
                                    value="Submit"
                                    title="Submit"
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <p className="con-sdcsc">
                        <strong className="con-note">Notice:</strong>
                        <br />
                        Our company and placement associates do not require any
                        payment from candidates for placement or hiring
                        services. If you choose to pay any third party for
                        training and placement services, the company will not
                        assume responsibility for such transactions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
