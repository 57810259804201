import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import About from "./../Elements/career/About";
import About2 from "./../Elements/career/About2";
import Banner from "./../Elements/career/Banner";
import CulAbout from "./../Elements/career/CulAbout";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/web-development-banner.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Explore Your Future with Shadow infosystem</title>
          <meta
            name="description"
            content="Explore exciting career opportunities at Shadow infosystem. Join us for a rewarding journey in technology, innovation, and professional growth."
          />
          <meta name="keywords" content="Website Development Company" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/career"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <Banner />
          <About />
          {/* <CulAbout/> */}
          <About2 />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
