import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Olivastu</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo normal_logo">
                  <img
                    src={require("./../../../images/olivastu/logo.webp")}
                    className=""
                    width="100"
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Olivastu</h5>
                  <ul className="scnv-case mb__0">
                    {/* <li>
                      <b>Location : </b>
                      J-12/14, Rajouri Garden Extension, New Delhi (110027)
                    </li> */}
                    <li>
                      <b>Speciality : </b> Smoking Accessories
                    </li>
                    <li className="pb__0">
                      <b>Website : </b>{" "}
                      <a
                        href="https://www.olivastu.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.olivastu.com
                      </a>
                    </li>
                  </ul>
                  {/* <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>

                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/ritualsnail/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/nail.rituals/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/olivastu/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/olivastu/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Olivastu is the most reliable online head shop in the
                        UK, positioned to serve all potheads and 420
                        enthusiasts. They provide the best bongs on the market,
                        bringing the largest collection of smoking paraphernalia
                        to personalise and optimise user's smoking experience.
                        They pride themselves in bringing their customers a
                        quick, discreet, simple, and pleasant shopping
                        experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
