import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Limited Online Presence",
    description:
      "Logistics companies may not have an active online presence, making it difficult for them to reach potential customers.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "High Competition",
    description:
      "The logistics industry is highly competitive, and it can be challenging to stand out in the crowd.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Complex Service Offerings",
    description:
      "Logistics services are often complex, making it challenging to communicate the value proposition to potential customers.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Lack of Customer Awareness",
    description:
      "Many customers may not be aware of the different logistics services available or how they can benefit from them.",
  },
  {
    number: <div class="number fontColor5">5</div>,
    heading: "Leveraging Social Media",
    description:
      "Use social media platforms to promote your services, connect with potential customers, and build a community around your brand.",
  },
  {
    number: <div class="number fontColor1">6</div>,
    heading: "Offering Value to Customers",
    description:
      "Create informative content that educates customers about the different logistics services available and how they can benefit from them.",
  },
  {
    number: <div class="number fontColor2">7</div>,
    heading: "Focusing on SEO",
    description:
      "Optimize your website for search engines by using relevant keywords and phrases that your potential customers are searching for. Additionally, create valuable content on your website that showcases your expertise and offers value to your customers.",
  },
  {
    number: <div class="number fontColor3">8</div>,
    heading: "Using Paid Advertising",
    description:
      "Consider using paid advertising on social media platforms or search engines to increase your reach and generate leads.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
