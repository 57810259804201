import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Environmental Regulations",
    description:
      "Tree care services need to comply with various environmental regulations related to waste disposal, pesticide use, and other factors that can add to the complexity of running a business.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Competition",
    description:
      "The tree care industry is competitive, and it can be challenging for small businesses to stand out and compete with larger companies.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Equipment costs",
    description:
      "Tree care equipment, such as chainsaws, pruning shears, and safety gear, can be expensive, and the cost of purchasing and maintaining equipment can be a challenge for small businesses.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Safety hazards",
    description:
      "Tree care work involves working at height, with sharp tools, and around heavy equipment, which can pose significant safety risks to workers and customers.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding dfbb7680978 p-b10 bg-repeat">
          <div className="container p-t80">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
